import { createContext, useLayoutEffect, useMemo, useState } from "react";

export type PaginationContext = {
  pageCount: number;
  onPageChange: (currentPage: number) => void;

  itemsPerPage: number;
  total: number;
  currentPageInternal: number;
  setCurrentPageInternal: React.Dispatch<React.SetStateAction<number>>;
  pageRangeDisplayed?: number;
};

export const paginationContext = createContext<PaginationContext>({
  pageCount: 1,
  onPageChange: () => null,
  itemsPerPage: 10,
  total: 10,
  currentPageInternal: 1,
  setCurrentPageInternal: () => null,
  pageRangeDisplayed: 5,
});

/**
 * This looks pointless but it was the only way I could get useContext to return the current context outside
 * of the components packages. When passed `paginationContext` directly it would return the default value.
 * I can only assume it is a reference issue
 * @returns {PaginationContext}
 */
export const getPaginationContextReference = () => paginationContext;

export interface PaginationContextProviderProps {
  pageCount: number;
  onPageChange: (currentPage: number) => void;
  currentPage: number;
  itemsPerPage: number;
  total: number;
  children: React.ReactNode;
  pageRangeDisplayed?: number;
}

export const PaginationContextProvider = ({
  children,
  ...rest
}: PaginationContextProviderProps) => {
  const { currentPage } = rest;
  const [currentPageInternal, setCurrentPageInternal] = useState(currentPage);
  useLayoutEffect(() => {
    if (currentPageInternal === currentPage) {
      return;
    }
    setCurrentPageInternal(currentPage);
  }, [currentPage]);

  return (
    <paginationContext.Provider
      value={useMemo(
        () => ({ ...rest, currentPageInternal, setCurrentPageInternal }),
        [
          rest.onPageChange,
          rest.currentPage,
          rest.total,
          rest.itemsPerPage,
          rest.pageRangeDisplayed,
          currentPageInternal,
          setCurrentPageInternal,
        ]
      )}
    >
      {children}
    </paginationContext.Provider>
  );
};
