import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { ConstraintsLayerType } from "../layer_types/ConstraintsLayerType";
import { Layer } from "../types";

export const POWER_TOWERS_AND_CABLES: Layer = {
  id: ConstraintsCategory.POWER,
  layerType: ConstraintsLayerType,
  title: "Power Towers, Lines & Cables",
  requiredFeatureAccess: [Feature.powerLayer],
  toggleable: true,
  description: "Location of towards, pylons and cables",
  layerConfig: {
    showTitle: true,
    disableHatch: true,
    rootCategory: ConstraintsCategory.POWER,
    dedupePoints: false,
    categorySchema: [
      { key: "power_cables" },
      { key: "power_overhead_lines" },
      { key: "power_towers" },
    ],
    attributeFilters: [
      {
        attribute: "voltage_class",
        options: [
          { value: "Transmission", display_name: "Transmission (>132kV)" },
          { value: "Extra High Voltage (EHV)", display_name: "Extra High Voltage (33-132kV)" },
          { value: "High Voltage (HV)", display_name: "High Voltage (1-32kV)" },
          { value: "Low Voltage (LV)", display_name: "Low Voltage (<1kV)" },
          { value: "Unknown", display_name: "Unknown" },
        ],
      },
    ],
  },
};
