import cn from "classnames";
import { Checkbox, CheckboxState } from "react-migration/components/DeprecatedCheckbox";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { CheckboxSchema } from "../Multilayer/layer_types/ConstraintsLayerType";
import { useControlTracking } from "../Multilayer/ControlTrackingContext";

type SourceStatusFilterProps = {
  statusSchema: CheckboxSchema[];
  disabled: boolean;
  hiddenStatuses: string[];
  setHiddenStatuses: (value: string[]) => void;
};

export function SourceStatusFilters({
  statusSchema,
  disabled = false,
  hiddenStatuses,
  setHiddenStatuses,
}: SourceStatusFilterProps) {
  const { t } = useTranslation();
  const { trackControlChange } = useControlTracking();

  function handleToggle(status: string) {
    trackControlChange({
      filterName: status,
      filterState: hiddenStatuses.includes(status),
    });

    setHiddenStatuses(
      hiddenStatuses.includes(status)
        ? (hiddenStatuses ?? []).filter((x) => x !== status)
        : [...(hiddenStatuses ?? []), status]
    );
  }

  return (
    <div className="atlas-flex atlas-divide-x atlas-divide-neutral-300 atlas-gap-2">
      {statusSchema.map((x) => {
        const displayName = t(`constraints.statuses.${x.key}`);

        return (
          <div key={x.key} className="not-first:atlas-pl-2">
            <label className="atlas-flex atlas-items-center atlas-gap-x-2 atlas-text-sm atlas-select-none">
              <span className={cn({ "atlas-opacity-50": disabled })}>{displayName}</span>
              <Checkbox
                disabled={disabled}
                value={
                  hiddenStatuses?.includes(x.key) ? CheckboxState.EMPTY : CheckboxState.CHECKED
                }
                onChange={() => handleToggle(x.key)}
              />
            </label>
          </div>
        );
      })}
    </div>
  );
}
