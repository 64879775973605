import { forwardRef, PropsWithChildren, ReactNode } from "react";
import * as RadixSelect from "@radix-ui/react-select";
import clsx from "clsx";
import { Icon } from "react-migration/components/Icon";

export enum SelectSize {
  SM = "sm",
  XS = "xs",
}

export type SelectProps = { size?: `${SelectSize}`; placeholder?: string } & PropsWithChildren &
  RadixSelect.SelectProps;

function Select({
  children,
  disabled,
  size = SelectSize.SM,
  placeholder = "",
  ...props
}: SelectProps) {
  return (
    <RadixSelect.Root disabled={disabled} {...props}>
      <RadixSelect.Trigger
        className={clsx(
          "atlas-w-full atlas-inline-flex atlas-items-center atlas-justify-between atlas-gap-2 atlas-rounded-lg atlas-border atlas-border-blueGrey-200 [&>span:first-child]:atlas-truncate",
          {
            "atlas-bg-white atlas-text-blueGrey-950": !disabled,
            "atlas-bg-blueGrey-100 atlas-text-blueGrey-400 atlas-pointer-events-none": disabled,
            "atlas-text-sm atlas-h-9 atlas-px-3": size === SelectSize.SM,
            "atlas-text-xs atlas-h-7 atlas-px-2": size === SelectSize.XS,
          }
        )}
        asChild
      >
        <div>
          <RadixSelect.Value className="atlas-grow atlas-truncate" placeholder={placeholder} />
          <RadixSelect.Icon className="atlas-shrink-0">
            <Icon
              icon="icon-lt-arrow-down-s-line"
              size={size === SelectSize.SM ? "sm" : "xs"}
              extraClasses={clsx("atlas-pointer-events-none", {
                "atlas-text-neutral-500": disabled,
                "atlas-text-lg": size === SelectSize.SM,
                "atlas-text-md": size === SelectSize.XS,
              })}
            />
          </RadixSelect.Icon>
        </div>
      </RadixSelect.Trigger>
      <RadixSelect.Portal>
        <RadixSelect.Content className="atlas-overflow-hidden atlas-max-w-96 atlas-z-20 atlas-rounded-lg atlas-bg-white atlas-shadow-md atlas-border atlas-border-blueGrey-200">
          <RadixSelect.ScrollUpButton className="atlas-flex atlas-h-6 atlas-cursor-default atlas-items-center atlas-justify-center atlas-bg-white atlas-text-blueGrey-600">
            <Icon icon="icon-lt-arrow-up-s-line" />
          </RadixSelect.ScrollUpButton>
          <RadixSelect.Viewport className="atlas-px-2 atlas-py-3">{children}</RadixSelect.Viewport>
          <RadixSelect.ScrollDownButton className="atlas-flex atlas-h-6 atlas-cursor-default atlas-items-center atlas-justify-center atlas-bg-white atlas-text-blueGrey-600">
            <Icon icon="icon-lt-arrow-down-s-line" />
          </RadixSelect.ScrollDownButton>
        </RadixSelect.Content>
      </RadixSelect.Portal>
    </RadixSelect.Root>
  );
}

const SelectSeperator = () => (
  <RadixSelect.Separator className="atlas-m-1 atlas-h-px atlas-bg-blueGrey-200" />
);

type SelectOptionProps = { badge?: ReactNode; children: string } & Omit<
  RadixSelect.SelectItemProps,
  "children"
>;

const SelectOption = forwardRef<HTMLDivElement, SelectOptionProps>(
  ({ children, className, badge, ...props }, forwardedRef) => (
    <RadixSelect.Item
      className={clsx(
        "atlas-relative atlas-grow atlas-flex atlas-gap-2 atlas-justify-between atlas-h-8 atlas-select-none atlas-items-center atlas-rounded-lg atlas-px-2 atlas-text-sm atlas-leading-none data-[disabled]:atlas-cursor-not-allowed data-[disabled]:atlas-text-blueGrey-300 data-[highlighted]:atlas-bg-button-ghost-hover data-[highlighted]:atlas-text-content-link-hover data-[highlighted]:atlas-outline-none",
        className
      )}
      ref={forwardedRef ?? null}
      {...props}
    >
      <RadixSelect.ItemText asChild>
        <span className="atlas-truncate atlas-h-8 atlas-pt-[9px]" title={children}>
          {children}
        </span>
      </RadixSelect.ItemText>{" "}
      {badge}
    </RadixSelect.Item>
  )
);

SelectOption.displayName = "SelectOption";

Select.Option = SelectOption;
Select.Seperator = SelectSeperator;
Select.Size = SelectSize;

export { Select };
