import clsx from "clsx";
import { ModalV2 } from "react-migration/components/ModalV2/ModalV2";

export type TopographyLayerDialogProps = {
  region: "US" | "UK";
  label?: string;
  disabled?: boolean;
};

export function TopographyLayerDialog({
  label,
  region,
  disabled = false,
}: TopographyLayerDialogProps) {
  return (
    <div
      className={clsx(
        "atlas-shrink-0 atlas-flex atlas-text-sm atlas-text-blueGrey-950 atlas-gap-x-1 atlas-items-center",
        {
          "atlas-opacity-50": disabled,
        }
      )}
    >
      {label && <div>{label}</div>}
      <ModalV2.Root>
        <ModalV2.Trigger disabled={disabled}>
          <i className="icon-lt-question-line atlas-text-lg atlas-text-content-tertiary" />
        </ModalV2.Trigger>
        <ModalV2.Body title={"Topography"}>
          {region === "UK" ? <DialogInfoUK /> : <DialogInfoUS />}
        </ModalV2.Body>
      </ModalV2.Root>
    </div>
  );
}

function DialogInfoUK() {
  return (
    <div className="atlas-flex atlas-flex-col atlas-gap-y-4">
      <div>
        <p>
          The topography layer is made available using data supplied by the Ordnance Survey and the
          Environment Agency.
        </p>
      </div>

      <div>
        <div className="atlas-font-semibold">Contours</div>
        <div>
          <p>
            Contours are accurate to 10m and are made available by Ordnance Survey through their OS
            Contours 50 package. Contours can help you understand where elevation changes within a
            local area and to begin to understand how this could impact a prospective site.
          </p>
          <p>Contains public sector information licensed under the Open Government License v3.0</p>
        </div>
      </div>

      <div>
        <div className="atlas-font-semibold">Hillshade</div>
        <div>
          <p>
            Hillshade is made available by the Environment Agency and is accurate to 1m. Hillshade
            simulates light on terrain and adds artistic depth, also helping you understand how the
            land changes in and around your prospective site.
          </p>
          <p>
            &copy; Environment Agency copyright and/or database right 2022. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
}

function DialogInfoUS() {
  return (
    <div className="atlas-flex atlas-flex-col atlas-gap-y-4">
      <div>
        <p>Data available from U.S. Geological Survey, National Geospatial Program.</p>
      </div>

      <div>
        <div className="atlas-font-semibold">Contours</div>
        <div>
          Contours can help you understand where elevation changes within a local area and to begin
          to understand how this could impact a prospective site.
        </div>
      </div>

      <div>
        <div className="atlas-font-semibold">Hillshade</div>
        <div>
          Hillshade simulates light on terrain and adds artistic depth, also helping you understand
          how the land changes in and around your prospective site.
        </div>
      </div>
    </div>
  );
}
