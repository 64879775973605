import { Checkbox, CheckboxState } from "react-migration/components/DeprecatedCheckbox";
import { DrawingItem } from "src/js/types/DrawingItem";
import bbox from "@turf/bbox";
import distance from "@turf/distance";
import { DetailsList } from "react-migration/components/DetailsList";
import { GenericMeasurements } from "react-migration/components/GenericMeasurements";
import { getLengthVariants } from "react-migration/lib/util/getAreaAndPerimeter";
import { updatedSelectedGeometry } from "src/js/stores/drawing/actions/updatedSelectedGeometry";
import { getMeasurementSystemPreference } from "src/js/stores/user/actions/getSetting";
import { length } from "util/units";
import { AnnotationSpecProps } from "./types";

export function CircleSpec({ drawing }: AnnotationSpecProps) {
  const unitPreference = getMeasurementSystemPreference();
  const hasLabel = drawing.properties.label;
  const [minX, , maxX, maxY] = bbox(drawing.geometry);
  const radiusInMeters = distance([minX, maxY], [maxX, maxY], { units: "meters" }) / 2;

  const radiusVariants = getLengthVariants(radiusInMeters, unitPreference);

  const onLabelToggle = (value: boolean) => {
    const newDrawing: DrawingItem = {
      ...drawing,
      properties: {
        ...drawing.properties,
        label: value,
      },
    };

    updatedSelectedGeometry(newDrawing);
  };

  const walkingDistance = length.format(radiusInMeters, {
    unitType: "walking",
  });

  return (
    <div className="atlas-flex atlas-flex-col atlas-gap-3">
      <GenericMeasurements
        measurements={[
          { label: "Radius", variants: radiusVariants },
          { label: "Walking", variants: [walkingDistance, walkingDistance] },
        ]}
      />
      <DetailsList>
        <DetailsList.Item label={"Show label"}>
          <Checkbox
            value={hasLabel ? CheckboxState.CHECKED : CheckboxState.EMPTY}
            onChange={(newValue) => onLabelToggle(newValue)}
          ></Checkbox>
        </DetailsList.Item>
      </DetailsList>
    </div>
  );
}
