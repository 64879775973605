import { InfoList } from "react-migration/components/InfoList";
import { HTMLProps } from "react";

import { MeasurementSystem } from "src/js/stores/user/store";

import { PPADot } from "../../PPADot";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { isPerAreaMode } from "react-migration/domains/comparables/util/isPerAreaMode";
import { isPerUnitMode } from "react-migration/domains/comparables/util/isPerUnitMode";
import {
  PerAreaPriceModes,
  PerUnitPriceModes,
  PriceModes,
  ZooplaPricePerAreaModes,
  ZooplaPricePerUnitModes,
} from "react-migration/domains/comparables/typings/PriceModes";
import { formattedPrice } from "react-migration/domains/comparables/util/Prices";
import { formatDate } from "react-migration/domains/comparables/util/Dates";

const dataNotAvailableTranslations: Record<PriceModes, string> = {
  [PerAreaPriceModes.PPSM]: "comparables.map.sidebar.value.record.ppsm.not_available",
  [PerAreaPriceModes.EPPSM]: "comparables.map.sidebar.value.record.eppsm.not_available",
  [PerUnitPriceModes.EP]: "comparables.map.sidebar.value.record.ep.not_available",
  [PerUnitPriceModes.PRICE]: "comparables.map.sidebar.value.record.price.not_available",
  [ZooplaPricePerUnitModes.RENT_ASKING]:
    "comparables.map.sidebar.value.record.zoopla_asking_rental.not_available",
  [ZooplaPricePerUnitModes.RENT_ESTIMATE]:
    "comparables.map.sidebar.value.record.zoopla_estimated_rental.not_available",
  [ZooplaPricePerUnitModes.VAL_ESTIMATE]:
    "comparables.map.sidebar.value.record.zoopla_valuation.not_available",
  [ZooplaPricePerUnitModes.VAL_LAST]:
    "comparables.map.sidebar.value.record.zoopla_last_value_available",
  [ZooplaPricePerAreaModes.RENT_ASKING]:
    "comparables.map.sidebar.value.record.zoopla_asking_rental_per_area.not_available",
  [ZooplaPricePerAreaModes.RENT_ESTIMATE]:
    "comparables.map.sidebar.value.record.zoopla_estimated_rental_per_area.not_available",
  [ZooplaPricePerAreaModes.VAL_ESTIMATE]:
    "comparables.map.sidebar.value.record.zoopla_valuation_per_area.not_available",
  [ZooplaPricePerAreaModes.VAL_LAST]:
    "comparables.map.sidebar.value.record.zoopla_last_value_per_area.not_available",
};

export interface TransactionProps extends HTMLProps<HTMLDivElement> {
  area: number;
  currency: string;
  date: string;
  distribution?: number[];
  priceMode: PriceModes;
  price: number;
  unitPreference: MeasurementSystem;
}
export const Transaction = ({
  area,
  currency,
  date,
  distribution,
  priceMode,
  price,
  unitPreference,
}: TransactionProps) => {
  const { t } = useTranslation();
  return (
    <InfoList.ItemValue
      className="atlas-flex atlas-items-center atlas-justify-between atlas-grow atlas-py-1"
      data-testid="transaction"
    >
      <div className="atlas-flex atlas-items-center">
        <div className="atlas-text-content-tertiary">{date ? formatDate(date) : ""}</div>
      </div>
      <div className="atlas-flex atlas-items-center">
        {(priceMode === PerAreaPriceModes.PPSM || priceMode === PerAreaPriceModes.EPPSM) && (
          <PPADot area={area} price={price} distribution={distribution} />
        )}
        {(isPerUnitMode(priceMode) && price) || (isPerAreaMode(priceMode) && area) ? (
          <span className="atlas-font-semibold atlas-text-content-secondary">
            {formattedPrice({
              priceMode,
              price,
              area,
              unitPreference,
              currency,
            })}
          </span>
        ) : (
          <span>
            {t(dataNotAvailableTranslations[priceMode], {
              currency: t(`units.currency.${currency}`),
              unit: t(
                `units.distance.short.${
                  unitPreference === MeasurementSystem.METRIC ? "metric" : "imperial"
                }`
              ),
            })}
          </span>
        )}
      </div>
    </InfoList.ItemValue>
  );
};
