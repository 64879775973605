import { CheckboxState } from "react-migration/components/DeprecatedCheckbox";
import type { RecursiveTree, TreeSchema, CheckboxTreeChange } from "../types";
import { listDescendants } from "../utils";

export type CheckboxItem = {
  checkboxState: CheckboxState;
  handleCheckboxChange(checked: boolean): void;
};

export interface CheckboxTreeRecursiveProps {
  treeSchema: TreeSchema[];
  visibleNodeIds: string[];
  onChange: CheckboxTreeChange;
}

const DEFAULT_SCHEMA = {
  showChildren: true,
};

export function checkboxTreeRecursive<U>(
  tree: RecursiveTree<U>,
  props: CheckboxTreeRecursiveProps
): RecursiveTree<
  CheckboxItem &
    U & {
      //TODO: make sort and checkbox effect recurse downwards, rather than bubbling values upwards.
      controlledNodes: string[];
    }
> {
  const { treeSchema, visibleNodeIds, onChange } = props;

  const checkboxSchema = treeSchema.find(({ key }) => key == tree.id) ?? DEFAULT_SCHEMA;

  // Which children should I display under me to the user.
  const childNodes = checkboxSchema.showChildren
    ? tree.childNodes.map((n) => checkboxTreeRecursive(n, props))
    : [];

  // Which children do I control and sent to the tile service.
  const controlledNodes = listDescendants(tree);

  const handleCheckboxChange = (checked: boolean) => {
    onChange({
      id: tree.id,
      checked: checked,
      visibleNodeIds: [
        ...visibleNodeIds.filter((k) => !controlledNodes.includes(k)),
        ...(checked ? controlledNodes : []),
      ],
    });
  };

  const checkboxState = controlledNodes.every((c) => visibleNodeIds.includes(c))
    ? CheckboxState.CHECKED
    : controlledNodes.some((c) => visibleNodeIds.includes(c))
    ? CheckboxState.INDETERMINATE
    : CheckboxState.EMPTY;

  return {
    ...tree,
    childNodes,
    checkboxState,
    handleCheckboxChange,
    controlledNodes,
  };
}
