import { DrawingItem } from "src/js/types/DrawingItem";
import { drawingStore } from "../store";

export const deleteDrawing = (id: DrawingItem["id"]) => {
  const actions = drawingStore.reduxStore?.actions;
  const store = drawingStore.reduxStore?.store;
  if (!store || !actions) return false;

  store.dispatch(actions.deleteDrawing(id));
  return true;
};
