import { createContext, useContext } from "react";
import { Theme } from "react-migration/lib/theme/Theme";

interface ContentProviderValues {
  theme: Theme;
}

export const ContentProvider = createContext({} as ContentProviderValues);

export const useContentProvider = () => useContext(ContentProvider);
