export function CalendarContainer({ children }: React.PropsWithChildren) {
  return (
    <div className="atlas-p-2 atlas-rounded-md atlas-shadow-md atlas-bg-background-white atlas-text-content-primary">
      {children}
    </div>
  );
}

export function calendarContainerWithFooter(Footer: React.ComponentType) {
  return function CalendaContainerWithFooter({ children }: React.PropsWithChildren) {
    return (
      <div className="atlas-p-2 atlas-rounded-md atlas-shadow-md atlas-bg-background-white atlas-text-content-primary atlas-flex atlas-flex-col">
        {children}
        <div className="atlas-flex atlas-justify-center atlas-mt-2">
          <Footer />
        </div>
      </div>
    );
  };
}
