import { Button } from "react-migration/components/DeprecatedButton";
import { Dropdown } from "react-migration/components/Dropdown";
import { emailFrequencyOptions } from "../util/constants";
import { useUpdateEmailFrequency } from "../hooks/useUpdateEmailFrequency";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

export const EmailAlerts = () => {
  const { t } = useTranslation();
  const {
    isFormOpen,
    emailFrequency,
    alertPeriod,
    handleCloseForm,
    handleOpenFormClick,
    handleDropdownChange,
  } = useUpdateEmailFrequency();
  return (
    <div className="atlas-text-base atlas-flex atlas-gap-1 atlas-items-baseline atlas-mb-4">
      <span className="atlas-pt-[6px] atlas-pb-2">
        {alertPeriod === 0 && t("dashboard.planning_alerts.you_are_not_receiving_alerts_by_email")}
        {isFormOpen && alertPeriod > 0
          ? t("dashboard.planning_alerts.alert_note", {
              alertPeriod: "",
              alertPeriodNumber: "",
            })
          : t("dashboard.planning_alerts.alert_note", {
              alertPeriod: alertPeriod === 1 ? "day." : "days.",
              alertPeriodNumber: alertPeriod === 1 ? "" : alertPeriod,
            })}
      </span>
      {isFormOpen ? (
        <div className="atlas-flex atlas-gap-2">
          <div className="atlas-min-w-[200px]">
            <Dropdown
              options={emailFrequencyOptions}
              handleValueChange={handleDropdownChange}
              placeholder=""
              size="medium"
              value={emailFrequency}
            />
          </div>
          <Button.Primary onClick={handleCloseForm}>
            {t("dashboard.planning_alerts.done")}
          </Button.Primary>
        </div>
      ) : (
        <span
          onClick={handleOpenFormClick}
          className="atlas-text-blue-600 atlas-cursor-pointer hover:atlas-underline"
        >
          {t("dashboard.planning_alerts.change_period")}
        </span>
      )}
    </div>
  );
};
