import { Button } from "react-migration/components/DeprecatedButton";
import { Loading } from "react-migration/components/Loading";
import { RefObject, useMemo } from "react";
import { YourAddresses } from "react-migration/domains/dashboard/yourAddresses/YourAddresses";
import { Address } from "react-migration/domains/dashboard/yourAddresses/typings";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

type AddressType = {
  onCancel: () => void;
  setSelectedAddress: (address: Partial<Address>) => void;
  setSelectedStep: (v: number) => void;
  userAddresses: { userAddresses: Address[] } | undefined;
  userAddressLoading: boolean;
  refetch: () => unknown;
  addressFormRef?: RefObject<HTMLDivElement>;
};

export const PurchaseTitleModalAddress = ({
  onCancel,
  setSelectedStep,
  setSelectedAddress,
  userAddresses,
  userAddressLoading,
  refetch,
  addressFormRef,
}: AddressType) => {
  const { t } = useTranslation();

  const addresses = useMemo(() => {
    return userAddresses?.userAddresses ?? [];
  }, [userAddresses]);

  const selectedAddressId = useMemo(() => {
    const defaultAddress = addresses.find((address) => address.is_default);
    return defaultAddress?._id;
  }, [addresses]);

  const selectedAddress =
    addresses.find((x) => x._id === selectedAddressId) ??
    addresses.find((x) => x.is_default) ??
    addresses[0];

  const onAddressChange = async () => {
    await refetch();
  };

  const setNext = () => {
    if (addressFormRef?.current) {
      addressFormRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    }
    setSelectedAddress(selectedAddress);
    setSelectedStep(2);
  };

  return (
    <div
      data-testid="step-1-select-address"
      className="atlas-flex atlas-flex-col atlas-mx-4 atlas-h-full"
    >
      {userAddressLoading ? (
        <div className="atlas-overflow-y-auto atlas-max-h-[400px] atlas-grow atlas-pt-20">
          <Loading />
        </div>
      ) : (
        <div className="atlas-overflow-y-auto atlas-max-h-[400px] atlas-grow">
          <YourAddresses
            inModal={true}
            onAddressChange={onAddressChange}
            addressFormRef={addressFormRef}
          />
        </div>
      )}
      <div className="atlas-flex atlas-justify-end atlas-pt-4">
        <Button.Secondary onClick={onCancel} data-testid="cancel-button" className="atlas-mr-2">
          {t("title_purchase.cancel")}
        </Button.Secondary>
        <Button.Primary
          disabled={!selectedAddress || userAddressLoading}
          onClick={() => setNext()}
          type="button"
          data-testid="purchase-button"
        >
          Next
        </Button.Primary>
      </div>
    </div>
  );
};
