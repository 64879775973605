import { MaskExtension } from "@deck.gl/extensions";
import { MVTLayer, Position, TextLayer, LayerContext } from "deck.gl";
import { CompositeLayer, UpdateParameters } from "@deck.gl/core";
import { IconLayerProps } from "@deck.gl/layers/dist/icon-layer/icon-layer";

import Feature from "src/js/stores/user/Feature";
import { MeasurementSystem } from "src/js/stores/user/store";
import { ENVIRONMENT } from "src/js/util/environment";

import { characterSet, getPosition } from "./comparablesLayerUtil";
import {
  memoizedZooplaTileDataFilter,
  textAccessors,
  zooplaPriceModeToRawValue,
} from "./zooplaComparablesLayerUtil";
import { zooplaPricePerUnitLayers } from "./zooplaPricePerUnitLayers";
import { head, pipe, prop } from "ramda";
import { createWeakMemo } from "src/js/util/memoization";
import {
  ZooplaComparableTile,
  ZooplaComparableTileProperties,
} from "react-migration/lib/typings/ComparablesTile";
import { AggregatedZooplaComparableTile } from "react-migration/domains/comparables/typings/AggregatedZooplaComparableTile";
import {
  ZooplaPriceModes,
  ZooplaPricePerAreaModes,
  ZooplaPricePerUnitModes,
} from "react-migration/domains/comparables/typings/PriceModes";
import getPermissions from "src/js/stores/user/actions/getPermissions";
import hasBetaFeature from "src/js/stores/user/actions/hasBetaFeature";
const properties = prop<ZooplaComparableTile[]>("properties");
const highestProperty = pipe<
  [AggregatedZooplaComparableTile],
  ZooplaComparableTile[],
  ZooplaComparableTile
>(properties, head);
const getAggregatedPosition = pipe<
  [AggregatedZooplaComparableTile],
  ZooplaComparableTile,
  Position
>(highestProperty, getPosition);

type AggregatedTextGetter = (x: AggregatedZooplaComparableTile) => string;
type AggregatedTextGetters = Record<ZooplaPriceModes, AggregatedTextGetter>;

const getAggregatedText: Record<"metric" | "imperial", AggregatedTextGetters> = {
  metric: [
    ...Object.values(ZooplaPricePerUnitModes),
    ...Object.values(ZooplaPricePerAreaModes),
  ].reduce<AggregatedTextGetters>(
    (acc, priceMode) => ({
      ...acc,
      [priceMode]: pipe(highestProperty, textAccessors.metric[priceMode]),
    }),
    {} as AggregatedTextGetters
  ),
  imperial: [
    ...Object.values(ZooplaPricePerUnitModes),
    ...Object.values(ZooplaPricePerAreaModes),
  ].reduce<AggregatedTextGetters>(
    (acc, priceMode) => ({
      ...acc,
      [priceMode]: pipe(highestProperty, textAccessors.imperial[priceMode]),
    }),
    {} as AggregatedTextGetters
  ),
};

export interface ZooplaLayerProps extends IconLayerProps<AggregatedZooplaComparableTile> {
  minZoom: number;
  maxZoom: number;
  selection?: (GeoJSON.Point | GeoJSON.MultiPoint | undefined)[];
  onViewportLoaded?: () => void;
  onViewportLoading?: () => void;
  priceMode: ZooplaPriceModes;
  // distribution?: number[];
  unitPreference: MeasurementSystem;
  filterFunctions: Array<(c: ZooplaComparableTileProperties) => boolean>;
}

const dedupe = createWeakMemo(function (
  data: ZooplaComparableTile[],
  getValue: (x: ZooplaComparableTileProperties) => number,
  filterFunctions: Array<(c: ZooplaComparableTileProperties) => boolean>
): AggregatedZooplaComparableTile[] {
  const filtered = memoizedZooplaTileDataFilter(data, filterFunctions, getValue);
  const grouped = filtered.reduce<Record<string, ZooplaComparableTile[]>>((acc, data) => {
    const key = `${data.geometry.coordinates[0]},${data.geometry.coordinates[1]}`;
    acc[key] ??= [];
    acc[key].push(data);
    return acc;
  }, {});

  return Object.values(grouped).map((group) => {
    const sorted = group.sort((a, b) => getValue(b.properties) - getValue(a.properties));
    return {
      geometry: sorted[0].geometry,
      type: sorted[0].type,
      properties: sorted,
    };
  });
});

interface ZooplaPPULayerState {
  googleZoomLevel?: number;
}

export class ZooplaPPULayer extends CompositeLayer<ZooplaLayerProps> {
  static componentName = "ZooplaScatterTextLayer";

  // seems redundant, just used for type safety
  setState(state: ZooplaPPULayerState) {
    super.setState(state);
  }

  getGoogleZoom() {
    return Math.round(this.context.viewport.zoom) + 1;
  }

  initializeState(context: LayerContext) {
    super.initializeState(context);

    this.setState({
      googleZoomLevel: this.getGoogleZoom(),
    });
  }

  shouldUpdateState({ changeFlags }: UpdateParameters<this>) {
    const newZoomLevel = this.getGoogleZoom();

    if (newZoomLevel !== this.state.googleZoomLevel) {
      this.setState({ googleZoomLevel: newZoomLevel });
      return true;
    }

    return changeFlags.propsOrDataChanged;
  }

  renderLayers() {
    const geofenceGeometries = getPermissions()?.geofencesGeometries;
    const maskExtensions = [] as unknown[];
    let maskId: string | undefined;
    const state = this.state as ZooplaPPULayerState;
    const labelsVisible = (state?.googleZoomLevel ?? 0) > 16;

    if (geofenceGeometries?.length && !hasBetaFeature(Feature.disableGeofence)) {
      maskExtensions.push(new MaskExtension());
      maskId = "Geofence";
    }
    const { priceMode, unitPreference } = this.props;
    const getText: (x: AggregatedZooplaComparableTile) => string | undefined =
      getAggregatedText[unitPreference || "metric"][priceMode];

    const data = dedupe(
      this.props.data as ZooplaComparableTile[],
      zooplaPriceModeToRawValue[priceMode],
      this.props.filterFunctions
    );

    return [
      zooplaPricePerUnitLayers(priceMode, data, (i: number) =>
        this.getSubLayerProps({
          id: `${this.props.id}__${i}__scatter`,
        })
      ),
      new TextLayer<AggregatedZooplaComparableTile>({
        ...this.getSubLayerProps({
          id: `${this.props.id}__text`,
        }),
        visible: labelsVisible,
        extensions: maskExtensions,
        maskId,
        pickable: true,
        data,
        characterSet,
        getPosition: getAggregatedPosition,
        getText,
        getAngle: 0,
        getPixelOffset: [0, -16],
        getSize: 14,
        fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
        outlineWidth: 5,
        outlineColor: [255, 255, 255, 255],
        fontSettings: {
          sdf: true,
          fontSize: 32,
          buffer: 8,
        },
        getTextAnchor: "middle",
        getAlignmentBaseline: "center",
        // updateTriggers: {
        //   getText: priceMode + unitPreference + lastFiltersIncrementCount,
        //   getPosition: priceMode + unitPreference + lastFiltersIncrementCount,
        // },
        updateTriggers: {
          getText: priceMode + unitPreference,
          getPosition: priceMode + unitPreference,
        },
      }),
    ];
  }
}

export class ZooplaLayer extends CompositeLayer<ZooplaLayerProps> {
  static componentName = "ZooplaLayer";

  renderLayers() {
    const geofenceGeometries = getPermissions()?.geofencesGeometries;
    const maskExtensions = [] as unknown[];
    let maskId: string | undefined;

    if (geofenceGeometries?.length && !hasBetaFeature(Feature.disableGeofence)) {
      maskExtensions.push(new MaskExtension());
      maskId = "Geofence";
    }
    const {
      minZoom,
      maxZoom,
      data,
      priceMode,
      unitPreference,
      filterFunctions,
      onViewportLoaded,
      onViewportLoading,
    } = this.props;
    return [
      new MVTLayer<AggregatedZooplaComparableTile>({
        ...this.getSubLayerProps(),
        extensions: maskExtensions,
        maskId,
        data:
          data && (data as Array<AggregatedZooplaComparableTile>)?.length
            ? data
            : `${ENVIRONMENT.COMPARABLES_SERVICE_URL}/zoopla-tiles/{z}/{x}/{y}`,
        filterFunctions,
        maxRequests: -1,
        binary: false,
        priceMode,
        unitPreference,
        params: { depthTest: false },
        pickable: true,
        minZoom,
        maxZoom,
        renderSubLayers(props) {
          if (!props?.data) {
            return [];
          }

          return [
            new ZooplaPPULayer({
              ...props,
              minZoom: props.minZoom ?? undefined,
              maxZoom: props.maxZoom ?? undefined,
              id: `${props.id}--zoopla-ppu`,
              data: props.data,
            }),
          ];
        },
        // these 2 props are a hack to allow us to set a global data-test-id to aid in e2e testing
        onViewportLoad: () => {
          onViewportLoaded?.();
        },
        fetch(url: string, config) {
          onViewportLoading?.();
          return config.layer.parent?.props.fetch(url, config);
        },
      }),
    ];
  }
}
