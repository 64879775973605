import { useMemo } from "react";
import { StaticMapWithSelection } from "react-migration/domains/sites/card/Map/renderers/StaticMapReact";
import { LayerTypePrintableProps } from "../../../types";
import { renderContourLayer, renderHillshadeLayer } from "../UK/UKTopographyMapLayer";

type HillshadeContourMapLayerPrintableProps = Pick<
  LayerTypePrintableProps,
  "selection" | "onLoaded"
>;

export function HillshadeContourMapLayerPrintable({
  selection,
  onLoaded,
}: HillshadeContourMapLayerPrintableProps) {
  const hillshadeAndContourLayers = useMemo(() => {
    return [renderHillshadeLayer(), renderContourLayer()];
  }, []);

  return (
    <StaticMapWithSelection
      id="hillshade-contour-printable"
      layers={hillshadeAndContourLayers}
      onReady={onLoaded}
      selectionBufferMeters={0}
      selection={selection}
    />
  );
}
