import { FC, FormEvent, useRef } from "react";
import { Checkbox, CheckboxState } from "./Checkbox";
import { TextInputV2 } from "../TextInputV2";
import kebabCase from "lodash/kebabCase";
import { useOnClickOutside } from "usehooks-ts";
import { AnimatePresence, motion } from "framer-motion";
import { containerVariants, listItemVariants } from "./variants";
import clsx from "clsx";

const SHOW_ITEM_SEARCH_THRESHOLD = 6;

export interface ICheckItem {
  key: string;
  name: string;
  checked?: boolean; // applied to the filter
  originalCheckState?: boolean;
  displayName?: { text: string; className?: string }[];
}
export const displayFilterItem = (item: ICheckItem) => {
  // if item has display props with classes then use them
  if (item.displayName) {
    return item.displayName.map((text) => {
      if (text.className) {
        return (
          <span key={kebabCase(text.text)} className={text.className}>
            {text.text}
          </span>
        );
      }
      return text.text + " ";
    });
  }
  return item.name + " ";
};

const IconLeft = <i className="search-icon atlas-text-base icon-lt-search-line" />;

export interface IMultipleCheckboxProps {
  translations: {
    no_results_found: string;
    no_items_provided: string;
  };
  filterItemsFn: (searchTerm: string, itemsToFilter: ICheckItem[]) => ICheckItem[];
  placeHolder?: string;
  handleCheckItemFn?: (results: ICheckItem[]) => void;
  lastCheckItemFn?: (item: ICheckItem, value: boolean) => void;
  items: ICheckItem[];
  searchField: string;
  setSearchField: (results: string) => void;
  checkBoxesAsDropdown?: boolean;
  dropdownOpen?: boolean;
  onClickOutside?: () => void;
  onInputFocus?: React.FocusEventHandler<HTMLInputElement>;
  checkboxesContainerClassNames?: string;
  actions?: React.ReactNode[];
  testId?: string;
}

export const MultipleCheckbox: FC<IMultipleCheckboxProps> = ({
  translations,
  placeHolder,
  handleCheckItemFn,
  lastCheckItemFn,
  items,
  filterItemsFn,
  searchField,
  setSearchField,
  checkBoxesAsDropdown,
  dropdownOpen,
  onClickOutside,
  onInputFocus,
  checkboxesContainerClassNames,
  actions,
  testId = "multi-checkbox",
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    onClickOutside?.();
  });
  const filteredItems = filterItemsFn(searchField, items);

  const handleCheckboxChange = (item: ICheckItem, value: boolean) => {
    handleCheckItemFn?.(
      items.map((localItem) =>
        localItem.key !== item.key
          ? localItem
          : {
              ...localItem,
              checked: value,
            }
      )
    );
    lastCheckItemFn?.(item, value);
  };

  const handleSearch = (event: FormEvent<HTMLInputElement>) => {
    const searchTerm = event.currentTarget.value;
    setSearchField(searchTerm);
  };

  return (
    <div ref={ref} className={clsx(checkBoxesAsDropdown && "atlas-relative")}>
      {items && items.length >= SHOW_ITEM_SEARCH_THRESHOLD && (
        <div className="atlas-flex atlas-flex-row">
          <div className="atlas-flex-grow">
            <TextInputV2
              placeholder={placeHolder}
              onInput={handleSearch}
              IconLeft={IconLeft}
              value={searchField}
              onFocus={onInputFocus}
              dataTestId={testId}
            />
          </div>
        </div>
      )}
      {/* if no results are supplied to the component show this message */}
      {!items.length && (
        <div data-testid={`${testId}-no-items`} className="atlas-flex atlas-p-2">
          {translations.no_items_provided}
        </div>
      )}
      <AnimatePresence>
        {(!checkBoxesAsDropdown || dropdownOpen) && (
          <motion.div
            variants={checkBoxesAsDropdown ? containerVariants : undefined}
            initial="hidden"
            animate="visable"
            exit="hidden"
            transition={checkBoxesAsDropdown ? { duration: 0.2 } : undefined}
            className={clsx(
              "atlas-flex atlas-flex-row atlas-flex-wrap atlas-mt-2",
              checkBoxesAsDropdown &&
                "atlas-absolute atlas-left-0 atlas-right-0 atlas-z-50 atlas-ml-0 atlas-mt-0 atlas-pt-1 atlas-rounded-b atlas-shadow-[0px_4px_6px_-2px_rgba(6,10,20,0.05),0px_10px_15px_-3px_rgba(0,0,0,0.10)]",
              checkboxesContainerClassNames
            )}
          >
            <div className="atlas-flex atlas-flex-col atlas-w-full">
              <div
                className={clsx(
                  "atlas-flex-column atlas-max-h-[200px] atlas-overflow-y-auto",
                  checkBoxesAsDropdown ? "atlas-p-2" : "atlas-pl-3"
                )}
              >
                <ol data-testid={`${testId}-list`}>
                  {filteredItems.map((item) => {
                    return (
                      <motion.li
                        variants={checkBoxesAsDropdown ? listItemVariants : undefined}
                        initial="hidden"
                        animate="visable"
                        exit="hidden"
                        key={item.key}
                        transition={checkBoxesAsDropdown ? { duration: 0.3 } : undefined}
                        className="atlas-mb-2 atlas-flex"
                      >
                        <label className="atlas-flex-1">
                          <Checkbox
                            className="atlas-mr-2"
                            data-testid={`checkbox-${item.name.replaceAll(" ", "-")}`}
                            onChange={(value) => handleCheckboxChange(item, value)}
                            value={item?.checked ? CheckboxState.CHECKED : CheckboxState.EMPTY}
                          />
                          <span className="atlas-relative atlas-top-[-3px]">
                            {displayFilterItem(item)}
                          </span>
                        </label>
                      </motion.li>
                    );
                  })}
                </ol>
              </div>

              {/* if a user is searching through results and none are found */}
              {items.length > 0 && !filteredItems.length && searchField !== "" && (
                <div className="atlas-flex atlas-p-2">{translations.no_results_found}</div>
              )}
              {actions && (
                <div className="atlas-flex atlas-justify-end atlas-gap-2 atlas-p-2 atlas-border-t atlas-border-solid atlas-border-border-divider ">
                  {actions.map((component, i) => (
                    <div key={i}>{component}</div>
                  ))}
                </div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
