import { InfoList } from "react-migration/components/InfoList";
import { Accordion } from "react-migration/components/Accordion";
import { HistoricTransaction, LastValueSource } from "../HistoricTransaction/HistoricTransaction";
import { useState } from "react";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

export function AdditionalTransactions({
  transactions,
}: Readonly<{
  transactions?: {
    value: number;
    date: string;
    currency: "GBP" | "USD";
    source: LastValueSource;
  }[];
}>) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  if (!transactions?.length) {
    return null;
  }

  return (
    <Accordion
      type="single"
      collapsible={true}
      onValueChange={() => setOpen(!open)}
      className="atlas-bg-white"
    >
      <Accordion.Item value="transaction">
        <Accordion.Content>
          {transactions.map(({ value, date, currency, source }, index, { length }) => (
            <div
              data-testid="additional-transaction-item"
              className="atlas-block atlas-w-full atlas-pr-3"
              key={`${value}-${date}`}
            >
              <InfoList.ItemValue className="atlas-px-2">
                <HistoricTransaction
                  first={index === 0}
                  last={index === length - 1}
                  currency={currency}
                  date={date}
                  value={value || 0}
                  source={source}
                />
              </InfoList.ItemValue>
            </div>
          ))}
        </Accordion.Content>
        <Accordion.Trigger>
          <div
            data-testid="additional-transactions-accordion-trigger"
            className="atlas-flex atlas-items-center atlas-justify-between atlas-text-content-action atlas-py-1 atlas-px-3"
          >
            <span>
              {!open
                ? t("comparables.selection_details.more_transactions")
                : t("comparables.selection_details.fewer_transactions")}
            </span>
          </div>
        </Accordion.Trigger>
      </Accordion.Item>
    </Accordion>
  );
}
