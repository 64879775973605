import { useCallback, useEffect, useState } from "react";

export function useVariants(variants?: string[]) {
  const [activeVariantIndex, setActiveVariantIndex] = useState(0);

  useEffect(() => {
    setActiveVariantIndex(0);
  }, [variants]);

  const incrementIndex = useCallback(() => {
    if (!variants) return;

    const newIndex = variants.length === activeVariantIndex + 1 ? 0 : activeVariantIndex + 1;
    setActiveVariantIndex(newIndex);
  }, [activeVariantIndex]);

  return [variants?.[activeVariantIndex], incrementIndex] as const;
}
