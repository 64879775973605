export const ArrowLeftIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" fill="none" viewBox="0 0 8 12">
      <path
        fill="currentColor"
        d="M3.023 6l4.125 4.125-1.178 1.178L.667 6 5.97.697l1.178 1.178L3.023 6z"
      ></path>
    </svg>
  );
};
