import { ReactNode, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import classNames from "classnames";
import { useDebounce } from "usehooks-ts";
import { Theme } from "react-migration/lib/theme/Theme";

export interface DeprecatedTooltipProps {
  children: ReactNode;
  title?: ReactNode;
  description?: string | JSX.Element;
  className?: string;
  descriptionClassName?: string;
  childrenWrapperClassName?: string;
  placement?:
    | "top"
    | "left"
    | "right"
    | "bottom"
    | "top-left"
    | "top-right"
    | "bottom-left"
    | "bottom-right";
  showArrow?: boolean;
  theme?: Theme;
  disabled?: boolean;
  widthClass?: string;
  delayMs?: number;
  xOffset?: number;
  yOffset?: number;
  hideTooltip?: boolean;
  relativePositioning?: boolean;
}

const SPACING = 10;

/**
 * @deprecated Formally just "Tooltip". This component has been deprecated, and replaced with the "Radix Powered" "Tooltip" component (components/Tooltip).
 *
 * Please refrain from using this component, unless:
 *   * You don't have time to refactor.
 *   * You are aware that the new Tooltip Component will have problems in production (typically if you are rendering the component directly in Vue).
 */
export function DeprecatedTooltip({
  children,
  title,
  description,
  className,
  widthClass,
  descriptionClassName = "atlas-whitespace-pre atlas-items-center",
  childrenWrapperClassName,
  placement = "top",
  showArrow = true,
  theme = Theme.Light,
  disabled = false,
  delayMs = 0,
  xOffset = 0,
  yOffset = 0,
  hideTooltip = false,
  relativePositioning,
}: DeprecatedTooltipProps) {
  const [isVisible, setVisible] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  const relativePosition = useMemo(() => {
    switch (placement) {
      case "top":
        return {
          top: 0 - SPACING,
          left: 50,
        };
      case "top-right":
        return {
          top: 0 - SPACING,
          left: 100,
        };

      case "top-left":
        return {
          top: 0 - SPACING,
          left: 20,
        };

      case "bottom":
        return {
          top: 100 + SPACING,
          left: 50,
        };
      case "bottom-right":
        return {
          top: 100 + SPACING,
          left: 100,
        };

      case "bottom-left":
        return {
          top: 100 + SPACING,
          left: 20,
        };
      case "right":
        return {
          top: 50,
          left: 100 + SPACING,
        };
      case "left":
        return {
          top: 50,
          left: 0 - SPACING,
        };
    }
  }, [placement]);

  const tooltipRef = useRef<HTMLDivElement>(null);

  const handleMouseEnter = () => {
    if (disabled) {
      return;
    }
    if (tooltipRef.current) {
      const { top, left, width, height } = tooltipRef.current.getBoundingClientRect();

      setVisible(true);
      switch (placement) {
        case "top":
        case "top-right":
          setPosition({ top: top - SPACING + yOffset, left: left + width / 2 + xOffset });
          break;
        case "top-left":
          setPosition({ top: top - SPACING + yOffset, left: left + width / 2 + 24 + xOffset });
          break;
        case "bottom":
        case "bottom-right":
          setPosition({ top: top + height + SPACING + yOffset, left: left + width / 2 + xOffset });
          break;
        case "bottom-left":
          setPosition({
            top: top + height + SPACING + yOffset,
            left: left + width / 2 + 24 + xOffset,
          });
          break;
        case "right":
          setPosition({
            top: top + height / 2 - 2 + yOffset,
            left: left + width + SPACING + xOffset,
          });
          break;
        case "left":
          setPosition({ top: top + height / 2 - 2 + yOffset, left: left - SPACING + xOffset });
          break;
      }
    }
  };

  const debouncedIsVisible = useDebounce(isVisible, delayMs);
  const showTooltip = isVisible && debouncedIsVisible;

  if (hideTooltip) {
    return <>{children}</>;
  }
  return (
    <div
      className={clsx(
        {
          "atlas-inline-block": !className || !className.includes("atlas-block"),
          "atlas-relative": relativePositioning,
        },
        className
      )}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => {
        setVisible(false);
      }}
      data-testid="tooltip-wrapper"
      ref={tooltipRef}
    >
      <div className={classNames(childrenWrapperClassName ?? "atlas-block")}>{children}</div>
      <div
        style={{
          top: relativePositioning ? `${relativePosition?.top}%` : position.top,
          left: relativePositioning ? `${relativePosition?.left}%` : position.left,
          display: showTooltip ? undefined : "none",
        }}
        data-testid="tooltip-content"
        className={clsx("atlas-fixed atlas-z-[9998]", {
          "-atlas-translate-y-full -atlas-translate-x-1/2": placement === "top",
          "-atlas-translate-y-full -atlas-translate-x-full": placement === "top-left",
          "-atlas-translate-y-full -atlas-translate-x-6": placement === "top-right",
          "atlas-translate-y-0 -atlas-translate-x-1/2": placement === "bottom",
          "atlas-translate-y-0 -atlas-translate-x-full": placement === "bottom-left",
          "atlas-translate-y-0 -atlas-translate-x-6": placement === "bottom-right",
          "-atlas-translate-x-full -atlas-translate-y-1/2": placement === "left",
          "atlas-translate-x-0 -atlas-translate-y-1/2": placement === "right",
          "atlas-absolute": relativePositioning,
        })}
      >
        {/* Tooltip body */}
        <div
          className={clsx(
            "atlas-rounded atlas-px-3",
            widthClass && widthClass,
            title && description ? "atlas-py-3" : "atlas-py-2",
            theme === Theme.Dark ? "atlas-bg-background-inverse-dark" : "atlas-bg-white",
            {
              "atlas-border atlas-border-border-divider-subtle":
                !showArrow && theme === Theme.Light,
            }
          )}
        >
          {title && (
            <div
              className={clsx(
                "atlas-text-xs",
                "atlas-font-semibold",
                theme === Theme.Light
                  ? "atlas-text-content-primary"
                  : "atlas-text-content-inverse-primary"
              )}
            >
              {title}
            </div>
          )}
          {description && (
            <div
              className={clsx(
                "atlas-text-xs",
                "atlas-font-normal",
                theme === Theme.Light
                  ? "atlas-text-content-tertiary"
                  : "atlas-text-content-inverse-secondary",
                {
                  "atlas-mt-1": !!title,
                  "atlas-text-center": !title,
                }
              )}
            >
              <span className={descriptionClassName}>{description}</span>
            </div>
          )}
        </div>

        {/* Tooltip triangle */}
        {showArrow && (
          <div
            className={clsx(
              "atlas-absolute atlas-w-0 atlas-h-0",
              {
                "atlas-border-background-inverse-dark": theme === Theme.Dark,
                "atlas-border-white": theme === Theme.Light,
                "atlas-border-t": ["top", "top-left", "top-right"].includes(placement),
                "atlas-border-b": ["bottom", "bottom-left", "bottom-right"].includes(placement),
                "atlas-border-l": placement === "left",
                "atlas-border-r": placement === "right",
              },
              {
                "atlas-left-1/2 atlas-bottom-0 atlas-translate-y-full -atlas-translate-x-1/2 atlas-border-x-transparent atlas-border-x-8 atlas-border-t-8":
                  placement === "top",
                "atlas-right-6 atlas-bottom-0 atlas-translate-y-full atlas-translate-x-1/2 atlas-border-x-transparent atlas-border-x-8 atlas-border-t-8":
                  placement === "top-left",
                "atlas-left-6 atlas-bottom-0 atlas-translate-y-full -atlas-translate-x-1/2 atlas-border-x-transparent atlas-border-x-8 atlas-border-t-8":
                  placement === "top-right",
                "atlas-left-1/2 atlas-top-0 -atlas-translate-y-full -atlas-translate-x-1/2 atlas-border-x-transparent atlas-border-x-8 atlas-border-b-8":
                  placement === "bottom",
                "atlas-right-6 atlas-top-0 -atlas-translate-y-full atlas-translate-x-1/2 atlas-border-x-transparent atlas-border-x-8 atlas-border-b-8":
                  placement === "bottom-left",
                "atlas-left-6 atlas-top-0 -atlas-translate-y-full -atlas-translate-x-1/2 atlas-border-x-transparent atlas-border-x-8 atlas-border-b-8":
                  placement === "bottom-right",
                "atlas-right-0 atlas-top-1/2 atlas-translate-x-full -atlas-translate-y-1/2 atlas-border-y-transparent atlas-border-y-8 atlas-border-l-8":
                  placement === "left",
                "atlas-left-0 atlas-top-1/2 -atlas-translate-x-full -atlas-translate-y-1/2 atlas-border-y-transparent atlas-border-y-8 atlas-border-r-8":
                  placement === "right",
              }
            )}
          />
        )}
      </div>
    </div>
  );
}
