import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { Layer } from "../types";
import { OwnershipDesignationLayerType } from "../layer_types/ConstraintsLayerType/OwnershipDesignation";
import { SimplifyProfile } from "../layer_types/ConstraintsLayerType";
import { OWNERSHIP_SCOTLAND_LAYER_ID } from "../layer_types/ConstraintsLayerType/OwnershipDesignation/OwnershipDesignationLayerType";

const INTERSECTION_BUFFER_METER = -1;

export const OWNERSHIP_SCOTLAND: Layer = {
  id: OWNERSHIP_SCOTLAND_LAYER_ID,
  layerType: OwnershipDesignationLayerType,
  hideByDefault: true,
  title: "Ownership Boundaries (Scotland)",
  toggleable: true,
  description: "Ownership Boundaries for Scotland",
  requiredFeatureAccess: [Feature.ownershipLayer],
  excludeFromReport: true,
  layerConfig: {
    filterAreaByZoom: true,
    simplifyProfile: SimplifyProfile.LOW,
    rootCategory: ConstraintsCategory.OWNERSHIP_SCOTLAND,
    categorySchema: [{ key: ConstraintsCategory.OWNERSHIP_SCOTLAND, defaultValue: true }],
    intersectionThresholdMeters: INTERSECTION_BUFFER_METER,
  },
};
