import { SupportedLocale } from "src/js/util/dates";
import { MeasurementSystem } from "src/js/stores/user/store";
import { ValuationTypeMapping } from "react-migration/domains/comparables/util/ValuationTypeMapping";
import { Translation } from "react-migration/lib/typings";
import { squareFeetToSquareMeters } from "react-migration/lib/util/conversion";
import { formatToCurrency } from "react-migration/lib/util/numberFormat";
import { pricePerUnitArea } from "react-migration/domains/comparables/util/Prices";
import { ZooplaComparableDTO } from "react-migration/lib/typings/Zoopla";
import { ZooplaField } from "react-migration/domains/comparables/typings/Zoopla";
import { formattedArea } from "react-migration/lib/util/measurements";
import { formatDate } from "react-migration/domains/comparables/util/Dates";

interface MarketValueType {
  t: Translation;
  locale: SupportedLocale;
  unitPreference: MeasurementSystem;
  floorArea?: number;
  currentValue?: number;
}
export const marketValue = ({
  t,
  locale,
  unitPreference,
  floorArea,
  currentValue,
}: MarketValueType) => {
  const area = squareFeetToSquareMeters(floorArea ?? 0);
  const amount = currentValue ?? 0;
  const unit =
    unitPreference === MeasurementSystem.METRIC
      ? t("comparables.sqm.alt")
      : t("comparables.sqft.alt");

  if (!amount) {
    return null;
  }

  const saleValue = formatToCurrency({
    amount: amount,
    locale,
    currency: "GBP",
  });

  if (!area) {
    return saleValue as string;
  }

  const ppa = formatToCurrency({
    amount: pricePerUnitArea({
      price: amount,
      area: area,
      unitPreference,
    }),
    locale,
    currency: "GBP",
  });
  return `${saleValue} - ${ppa} per ${unit}`;
};

export interface FormatZooplaDataProps {
  data?: ZooplaComparableDTO;
  locale: SupportedLocale;
  unitPreference: MeasurementSystem;
  t: Translation;
}

export const formatZooplaData = ({
  data,
  locale,
  unitPreference,
  t,
}: FormatZooplaDataProps): ZooplaField[] => {
  if (!data) {
    return [];
  }

  return [
    /asking ?price/i.test(data.valuation?.rent?.lastValueType ?? "")
      ? {
          title: t("comparables.map.sidebar.value.record.rental.asking_rental_price"),
          value: [
            {
              title: t("comparables.map.sidebar.value.record.rental.asking_rental_price.price"),
              value:
                marketValue({
                  t,
                  locale,
                  unitPreference,
                  floorArea: data.attributes.floorArea,
                  currentValue: data.valuation?.rent?.lastValue,
                }) ?? null,
            },
            {
              title: t("comparables.map.sidebar.value.record.rental.asking_rental_price.date"),
              value:
                (data.valuation?.rent?.lastValueDate &&
                  formatDate(data.valuation?.rent?.lastValueDate)) ??
                null,
            },
          ],
        }
      : {
          title: t("comparables.map.sidebar.value.record.rental.last_valuation"),
          value: [
            {
              title: t("comparables.map.sidebar.value.record.valuation.value"),
              value: data.valuation?.rent?.lastValue
                ? formatToCurrency({
                    amount: data.valuation?.rent?.lastValue ?? 0,
                    locale,
                    currency: "GBP",
                  }) ?? null
                : null,
            },
            {
              title: t("comparables.map.sidebar.value.record.valuation.type"),
              value:
                ValuationTypeMapping[
                  (data.valuation?.rent?.lastValueType as keyof typeof ValuationTypeMapping) ?? ""
                ] ?? data.valuation?.rent?.lastValueType,
            },
            {
              title: t("comparables.map.sidebar.value.record.valuation.valuation_date"),
              value: data.valuation?.rent?.lastValueDate ?? null,
            },
          ],
        },
    {
      title: t("comparables.map.sidebar.value.record.rental.rental_price"),
      value: marketValue({
        t,
        locale,
        unitPreference,
        floorArea: data.attributes.floorArea,
        currentValue: data.valuation?.rent?.currentValue,
      }),
    },
    {
      title: t("comparables.map.sidebar.value.record.rental.energy_rating"),
      value: data.energy?.currentEnergyRating ?? null,
    },
    {
      title: t("comparables.map.sidebar.value.record.zoopla.property_attributes"),
      value: [
        {
          title: t("comparables.map.sidebar.value.record.rental.bedrooms"),
          value: data.attributes.bedrooms ?? null,
        },
        {
          title: t("comparables.map.sidebar.value.record.rental.tenure"),
          value: data.attributes.tenure ?? null,
        },
        {
          title: t("comparables.map.sidebar.value.record.rental.bathrooms"),
          value: data.attributes.bathrooms ?? null,
        },
        {
          title: t("comparables.map.sidebar.value.record.rental.year_built"),
          value: data.attributes.yearBuilt ?? null,
        },
        {
          title: t("comparables.map.sidebar.value.record.rental.receptions"),
          value: data.attributes.receptions ?? null,
        },
        {
          title: t("comparables.map.sidebar.value.record.rental.floor_area"),
          value: data.attributes.floorArea
            ? formattedArea({
                t,
                unitPreference,
                area: squareFeetToSquareMeters(data.attributes.floorArea),
              })
            : null,
        },
      ],
    },
    {
      title: t("comparables.map.sidebar.value.record.sale.last_valuation"),
      value: [
        {
          title: t("comparables.map.sidebar.value.record.valuation.value"),
          value: data.valuation?.sale?.lastValue
            ? formatToCurrency({
                amount: data.valuation?.sale?.lastValue ?? 0,
                locale,
                currency: "GBP",
              }) ?? null
            : null,
        },
        {
          title: t("comparables.map.sidebar.value.record.valuation.type"),
          value:
            ValuationTypeMapping[
              (data.valuation?.sale?.lastValueType as keyof typeof ValuationTypeMapping) ?? ""
            ] ?? data.valuation?.sale?.lastValueType,
        },
        {
          title: t("comparables.map.sidebar.value.record.valuation.valuation_date"),
          value: data.valuation?.sale?.lastValueDate
            ? formatDate(data.valuation.sale.lastValueDate)
            : null,
        },
      ],
    },
    {
      title: t("comparables.map.sidebar.value.record.rental.market_value"),
      tooltipContent: t("comparables.map.sidebar.value.record.rental.market_value_description"),
      value: marketValue({
        t,
        locale,
        unitPreference,
        floorArea: data.attributes.floorArea,
        currentValue: data.valuation?.sale?.currentValue,
      }),
    },
  ];
};
