import classNames from "classnames";
import { PropsWithChildren } from "react";

type DropdownMenuItemTextProps = {
  "data-testid"?: string;
  className?: string;
};

export const DropdownMenuItemText = ({
  children,
  className: additionalClasses,
  "data-testid": dataTestId,
}: PropsWithChildren<DropdownMenuItemTextProps>) => {
  return (
    <div
      data-testid={dataTestId ?? "dropdown-menu-item-text"}
      className={classNames(
        "atlas-rounded",
        "atlas-cursor-default",
        "atlas-p-2",
        "atlas-m-2",
        additionalClasses
      )}
    >
      {children}
    </div>
  );
};
