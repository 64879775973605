import { Icon } from "react-migration/components/Icon";
import { useCallback, useEffect, useRef, useState } from "react";
import { usePrevious } from "react-migration/lib/util/usePrevious";

enum SavingIndicatorState {
  REST,
  SAVING,
  SAVED,
}

interface SavingIndicatorProps {
  saving: boolean;
}

export function SavingIndicator({ saving }: SavingIndicatorProps) {
  const [savingState, setSavingState] = useState(
    saving ? SavingIndicatorState.SAVING : SavingIndicatorState.REST
  );

  const wasSaving = usePrevious(saving);
  const showSavedMessageTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (wasSaving && !saving) {
      setSavingState(SavingIndicatorState.SAVED);

      clearTimeout(showSavedMessageTimeout.current);
      showSavedMessageTimeout.current = setTimeout(() => {
        setSavingState(SavingIndicatorState.REST);
      }, 3000);
    } else if (!wasSaving && saving) {
      setSavingState(SavingIndicatorState.SAVING);
    }
  }, [wasSaving, saving]);

  useEffect(() => () => clearTimeout(showSavedMessageTimeout.current), []);

  return (
    <div className="atlas-absolute atlas-flex atlas-items-center atlas-gap-1 atlas-top-2 atlas-right-0 atlas-text-xs atlas-text-neutral-400">
      {savingState === SavingIndicatorState.SAVING && (
        <>
          <Icon
            icon="icon-lt-loader-4-line"
            size="md"
            extraClasses="atlas-inline-block atlas-animate-spin atlas-origin-center"
          />
          <span>Saving&hellip;</span>
        </>
      )}
      {savingState === SavingIndicatorState.SAVED && (
        <>
          <Icon icon="icon-lt-check-line" size="md" />
          <span>Saved</span>
        </>
      )}
    </div>
  );
}

export function useSavingState() {
  const [isSavingCounter, setIsSavingCounter] = useState(0);

  const startedSaving = useCallback(() => setIsSavingCounter((c) => c + 1), []);
  const stoppedSaving = useCallback(() => setIsSavingCounter((c) => Math.max(c - 1, 0)), []);

  return {
    saving: isSavingCounter > 0,
    startedSaving,
    stoppedSaving,
  };
}
