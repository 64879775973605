import { useState } from "react";
import {
  Transactions,
  TransactionsAccordionStates,
} from "react-migration/domains/comparables/components/Results/Transactions";
import { IComparablesTransactionDTO } from "react-migration/lib/typings/Comparables";
import { PriceModes } from "react-migration/domains/comparables/typings/PriceModes";

interface TransactionsAccordionProps {
  includedResults: IComparablesTransactionDTO[];
  loading: boolean;
  priceMode: PriceModes;
  distribution?: number[];
  setSelectedRecord: (record: IComparablesTransactionDTO | undefined) => void;
}

export function TransactionsAccordion({
  includedResults,
  loading,
  setSelectedRecord,
  priceMode,
  distribution,
}: TransactionsAccordionProps) {
  const [transactionAccordionState, setTransactionAccordionState] = useState(
    TransactionsAccordionStates.CLOSED
  );

  return (
    <Transactions
      accordionState={transactionAccordionState}
      setAccordionState={setTransactionAccordionState}
      includedResults={includedResults}
      loading={loading}
      setSelectedRecord={setSelectedRecord}
      priceMode={priceMode}
      distribution={distribution}
    />
  );
}
