import clsx from "clsx";
import { ReactNode, useEffect, useState } from "react";
import { CheckboxState } from "react-migration/components/DeprecatedCheckbox";
import { TableHeader } from "./TableHeader";
import { TableRows } from "./TableRows";

export type Alignment = "left" | "center" | "right";
export type TableSize = "small" | "medium";

type BaseColumn = {
  id?: string;
  label?: ReactNode;
  align?: Alignment;
  grow?: boolean;
  maxWidth?: string;
  colSpan?: number;
};

type RowKeyColumn<Row extends IRow> = BaseColumn & {
  rowKey: keyof Row;
  render?: never;
};
type CustomRenderColumn<Row extends IRow> = BaseColumn & {
  rowKey?: never;
  render: (row: Row, rowIndex: number) => ReactNode;
};

export type Column<Row extends IRow> = RowKeyColumn<Row> | CustomRenderColumn<Row>;

export type IRow = Record<string, unknown>;

export type Selection<Row, PrimaryKey extends keyof Row> = {
  selectedRowIds: Row[PrimaryKey][];
  /**
   * A function used to update the selected row ids - this allows you to use this table as a controlled component
   */
  updateSelectedRowIds: (rowIds: (Row[PrimaryKey] | null)[]) => void;
  /**
   * Should rows be selected when you click on the actual row, rather than the actual checkbox.
   * Default: False
   */
  selectWithRowClick?: boolean;
  /**
   * Callback function to run side effects, eg. events, when all rows are (de)selected at once
   */
  onSelectAllRows?: (areAllSelected: boolean) => void;
  /**
   * Callback function to run side effects, eg. events, when a row is selected
   * Default: ()=>{}
   */
  onSelectRow?: (row: Row) => void;
  /**
   * You can disable a row based on a row property
   */
  disabled?: (row: Row) => boolean;
};

export interface TableProps<Row extends IRow, PrimaryKey extends keyof Row> {
  /**
   * A property on your row data type that can be used to uniquely identify each row of data and can't be null.
   * eg: if you were displaying labels: `{id: a, labelName: foo}, {id: b, labelName: bar}` , you could use `id`
   */
  primaryKey: PrimaryKey;
  rows: Row[];
  columns: Column<Row>[];
  title?: string;
  actionBarItems?: ((items: Row[]) => ReactNode)[];
  titleHeader?: ReactNode;
  titleHeaderClassNames?: string;
  /**
   * Pass through a selection object if you want your Table to be selectable
   */
  selection?: Selection<Row, PrimaryKey>;
  size?: TableSize;
  /**
   * Custom row class that will be applied to a specific row or each row in the table
   */
  rowClassName?: (row?: Row) => string | undefined;
  /**
   * Custom table class that will be applied to a specific cell or each cell in the table
   */
  cellClassName?: (row: Row, column: Column<Row>) => string | undefined;
  /**
   * Custom table classnames (eg: if we want to set the table to be scrollable horizontally)
   */
  tableClassName?: string;
  tableContainerClassName?: string;
  tableWrapperClassName?: string;
  hideHeader?: boolean;
  onRowHover?: (row?: Row) => void;
  highlightRowOnHover?: boolean;
}

/**
 * A Table component that can take two forms
 * 1) A controlled selectable table, where you have checkboxes and the ability to click and select rows
 *    To use this, pass in the `selection` prop
 * 2) Simple data display, no selection
 */
export function Table<Row extends IRow, PrimaryKey extends keyof Row>({
  hideHeader,
  primaryKey,
  columns,
  rows,
  title,
  actionBarItems,
  titleHeader,
  titleHeaderClassNames,
  selection,
  size = "medium",
  rowClassName,
  cellClassName,
  tableClassName,
  tableContainerClassName,
  tableWrapperClassName,
  onRowHover,
  highlightRowOnHover,
}: TableProps<Row, PrimaryKey>) {
  const [headerCheckboxState, setHeaderCheckboxState] = useState<CheckboxState>(
    CheckboxState.EMPTY
  );

  const selectedRowsLength = selection?.selectedRowIds.length;
  const activeRowsLength = rows.filter((row) => !selection?.disabled?.(row)).length;

  useEffect(() => {
    if (selectedRowsLength === 0) {
      setHeaderCheckboxState(CheckboxState.EMPTY);
    } else if (selectedRowsLength === activeRowsLength) {
      setHeaderCheckboxState(CheckboxState.CHECKED);
    } else {
      setHeaderCheckboxState(CheckboxState.INDETERMINATE);
    }
  }, [selectedRowsLength]);

  const selectAllRows = (areAllSelected: boolean) => {
    selection?.updateSelectedRowIds(
      areAllSelected
        ? rows.map((row) => (!selection?.disabled?.(row) ? row[primaryKey] : null))
        : []
    );

    if (selection && selection.onSelectAllRows) {
      selection.onSelectAllRows(areAllSelected);
    }
  };

  const selectRow = (selectedRow: Row) => {
    if (!selection) {
      return;
    }
    const { updateSelectedRowIds, selectedRowIds } = selection;
    const updateKeyValue = selectedRow[primaryKey];
    const newSelectedItems = selectedRowIds.includes(updateKeyValue)
      ? selectedRowIds.filter((item) => item !== updateKeyValue)
      : [...selectedRowIds, updateKeyValue];
    updateSelectedRowIds(newSelectedItems);

    if (selection.onSelectRow) {
      selection.onSelectRow(selectedRow);
    }
  };

  const isRowSelected = (row: Row) => {
    return !!selection && selection.selectedRowIds.includes(row[primaryKey]);
  };

  const isHeaderElementVisible = title || actionBarItems || titleHeader;

  return (
    <div
      className={clsx(
        "atlas-flex",
        "atlas-flex-col",
        "atlas-max-h-full",
        "atlas-w-full",
        tableWrapperClassName
      )}
      data-testid="table-wrapper"
    >
      {isHeaderElementVisible && (
        <div
          className={clsx(
            "atlas-border",
            "atlas-border-b-0",
            "atlas-border-solid",
            "atlas-border-border-divider-subtle",
            "atlas-rounded-t",
            "atlas-flex",
            "atlas-bg-white",
            {
              "atlas-flex-row atlas-p-4 atlas-items-center atlas-justify-between":
                title || actionBarItems,
            }
          )}
        >
          {titleHeader && (
            <div data-testid="table-custom-header" className={titleHeaderClassNames}>
              {titleHeader}
            </div>
          )}
          {title && (
            <div
              data-testid="table-title"
              className={clsx("atlas-flex", "atlas-text-base", "atlas-font-semibold")}
            >
              {title}
            </div>
          )}
          {actionBarItems && (
            <div data-testid="table-action-bar" className="atlas-flex">
              {actionBarItems?.map((action) => action(rows))}
            </div>
          )}
        </div>
      )}
      <div
        className={clsx(
          "atlas-border-border-divider-subtle",
          "atlas-border",
          "atlas-border-solid",
          "atlas-overflow-hidden",
          "atlas-shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]",
          {
            "atlas-rounded": !title && !actionBarItems && !titleHeader,
            "atlas-rounded-b": isHeaderElementVisible,
          },
          tableContainerClassName
        )}
      >
        <table className={clsx("atlas-min-w-full", "atlas-border-collapse", tableClassName)}>
          {!hideHeader && (
            <TableHeader
              columns={columns}
              selection={
                selection && { checkboxState: headerCheckboxState, selectAll: selectAllRows }
              }
              size={size}
            />
          )}
          <TableRows
            primaryKey={primaryKey}
            rows={rows}
            columns={columns}
            selection={
              selection && {
                isRowSelected,
                selectRow,
                selectWithRowClick: selection.selectWithRowClick,
                disabled: selection.disabled,
              }
            }
            size={size}
            rowClassName={rowClassName}
            cellClassName={cellClassName}
            onRowHover={onRowHover}
            highlightRowOnHover={highlightRowOnHover}
          />
        </table>
      </div>
    </div>
  );
}
