import { PropsWithChildren } from "react";
import * as RadixAccordion from "@radix-ui/react-accordion";
import clsx from "clsx";

export function AccordionItem({
  children,
  disabled,
  className,
  ...rest
}: PropsWithChildren<RadixAccordion.AccordionItemProps>) {
  return (
    <RadixAccordion.Item
      {...rest}
      className={clsx(
        "atlas-outline-none",
        "atlas-cursor-pointer",
        disabled && "atlas-pointer-events-none",
        className
      )}
      disabled={disabled}
    >
      {children}
    </RadixAccordion.Item>
  );
}

export default AccordionItem;
