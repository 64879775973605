export type CornerOffsets = {
  topLeft: { x: number; y: number };
  topRight: { x: number; y: number };
  bottomLeft: { x: number; y: number };
  bottomRight: { x: number; y: number };
};

export const getElementCornerOffsets = (
  elementRef?: React.RefObject<HTMLElement>
): CornerOffsets | undefined => {
  if (elementRef?.current) {
    const rect = elementRef.current.getBoundingClientRect();

    const topLeft = { x: rect.left, y: rect.top };
    const topRight = { x: rect.right, y: rect.top };
    const bottomLeft = { x: rect.left, y: rect.bottom };
    const bottomRight = { x: rect.right, y: rect.bottom };

    return {
      topLeft,
      topRight,
      bottomLeft,
      bottomRight,
    };
  }
};

export default getElementCornerOffsets;
