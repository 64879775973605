interface EnvironmentType {
  ACCOUNTS_SERVICE_PRIVATE_API_URL?: string;
  ACCOUNTS_SERVICE_PUBLIC_API_URL?: string;
  API_URL?: string;
  APP_ENV: string;
  APP_NPM_VERSION?: string;
  APP_VERSION: string;
  BASE_URL?: string;
  CONSTRAINTS_API_URL?: string;
  CONSTRAINTS_VEC_TILE_API_URL?: string;
  DATADOG_SERVICE_NAME: string;
  DATADOG_APPLICATION_ID: string;
  DATADOG_CLIENT_TOKEN: string;
  DATADOG_SITE: string;
  MCSEARCH_SERVICE_URL: string;
  SUPPORTING_SERVICE_URL: string;
  OWNERSHIP_SERVICE_URL?: string;
  COMPARABLES_SERVICE_URL?: string;
  BASE_VEC_TILE_API_URL?: string;
  LANDFUND_URL?: string;
  DRAWINGS_SERVICE_URL?: string;
  GOOGLE_MAPS_API_KEY: string;
  GOOGLE_MAPS_MAP_ID?: string;
  MAPBOX_API_KEY: string;
  INTERCOM_APP_ID?: string;
  LOG_PRODUCT_EVENTS_TO_CONSOLE?: boolean | string;
  LOGIN_URL: string;
  LOGOUT_URL: string;
  OS_MAPS_API_URL?: string;
  PLANNING_SERVICE_URL?: string;
  PLANNING_SERVICE_V2_URL?: string;
  PLANNING_SERVICE_V2_URL_EXTERNAL?: string;
  PUSHER_APP_KEY?: string;
  PUSHER_EXCLUDED_USERS?: string; // comma delimited list
  GRAPHQL_GATEWAY_URL: string;
  SITES_SERVICE_URL?: string;
  LETTERS_SERVICE_URL?: string;
  STRIPE_PUBLIC_KEY?: string;
  STRIPE_US_PUBLIC_KEY?: string;
  VEC_TILE_API_URL?: string;
  YOUR_ACCOUNT_URL?: string;
  USERSNAP_GLOBAL_API_KEY?: string;
  USERSNAP_PROJECT_API_KEY?: string;
  ZAPIER_CLIENT_ID?: string;
  APP_URL?: string;
}

export const PRIVATE_APP = !/\/public\/map\//.test(location?.pathname);

export const ENVIRONMENT: EnvironmentType = {
  ACCOUNTS_SERVICE_PRIVATE_API_URL: process.env.ACCOUNTS_SERVICE_PRIVATE_API_URL,
  ACCOUNTS_SERVICE_PUBLIC_API_URL: process.env.ACCOUNTS_SERVICE_PUBLIC_API_URL,
  API_URL: process.env.API_URL,
  APP_ENV: process.env.APP_ENV || "dev",
  APP_NPM_VERSION: process.env.APP_NPM_VERSION,
  APP_VERSION: process.env.APP_VERSION || "dev",
  BASE_URL: process.env.BASE_URL,
  CONSTRAINTS_API_URL: process.env.CONSTRAINTS_API_URL,
  CONSTRAINTS_VEC_TILE_API_URL: process.env.CONSTRAINTS_VEC_TILE_API_URL,
  DATADOG_SERVICE_NAME: process.env.DATADOG_SERVICE_NAME || "",
  DATADOG_APPLICATION_ID: process.env.DATADOG_APPLICATION_ID || "",
  DATADOG_CLIENT_TOKEN: process.env.DATADOG_CLIENT_TOKEN || "",
  DATADOG_SITE: process.env.DATADOG_SITE || "",
  MCSEARCH_SERVICE_URL: process.env.MCSEARCH_SERVICE_URL || "",
  SUPPORTING_SERVICE_URL: process.env.SUPPORTING_SERVICE_URL || "",
  OWNERSHIP_SERVICE_URL: process.env.OWNERSHIP_SERVICE_URL,
  COMPARABLES_SERVICE_URL: process.env.COMPARABLES_SERVICE_URL,
  BASE_VEC_TILE_API_URL: process.env.BASE_VEC_TILE_API_URL,
  LANDFUND_URL: process.env.LANDFUND_URL,
  DRAWINGS_SERVICE_URL: process.env.DRAWINGS_SERVICE_URL,
  GOOGLE_MAPS_API_KEY: process.env.GOOGLE_MAPS_API_KEY || "not-found",
  GOOGLE_MAPS_MAP_ID: process.env.GOOGLE_MAPS_MAP_ID,
  MAPBOX_API_KEY: process.env.MAPBOX_API_KEY || "",
  INTERCOM_APP_ID: process.env.INTERCOM_APP_ID,
  LOG_PRODUCT_EVENTS_TO_CONSOLE: process.env.LOG_PRODUCT_EVENTS_TO_CONSOLE,
  LOGIN_URL: process.env.LOGIN_URL!,
  LOGOUT_URL: process.env.LOGOUT_URL || "",
  OS_MAPS_API_URL: process.env.OS_MAPS_API_URL,
  PLANNING_SERVICE_URL: process.env.PLANNING_SERVICE_URL,
  PLANNING_SERVICE_V2_URL: process.env.PLANNING_SERVICE_V2_URL,
  PLANNING_SERVICE_V2_URL_EXTERNAL: process.env.PLANNING_SERVICE_V2_URL_EXTERNAL,
  PUSHER_APP_KEY: process.env.PUSHER_APP_KEY,
  PUSHER_EXCLUDED_USERS: process.env.PUSHER_EXCLUDED_USERS,
  GRAPHQL_GATEWAY_URL: process.env.GRAPHQL_GATEWAY_URL as string,
  SITES_SERVICE_URL: process.env.SITES_SERVICE_URL,
  LETTERS_SERVICE_URL: process.env.LETTERS_SERVICE_URL,
  STRIPE_PUBLIC_KEY: process.env.STRIPE_PUBLIC_KEY,
  STRIPE_US_PUBLIC_KEY: process.env.STRIPE_US_PUBLIC_KEY,
  VEC_TILE_API_URL: process.env.VEC_TILE_API_URL,
  YOUR_ACCOUNT_URL: process.env.YOUR_ACCOUNT_URL,
  USERSNAP_GLOBAL_API_KEY: process.env.USERSNAP_GLOBAL_API_KEY,
  USERSNAP_PROJECT_API_KEY: process.env.USERSNAP_PROJECT_API_KEY,
  ZAPIER_CLIENT_ID: process.env.ZAPIER_CLIENT_ID,
  APP_URL: process.env.APP_URL as string,
};
