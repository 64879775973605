import { Button } from "react-migration/components/DeprecatedButton";
import { Tooltip } from "react-migration/components/Tooltip";
import { IconButton } from "react-migration/components/IconButton";
import { logEvent } from "react-migration/lib/util/logEvent";

export const UKQuickOptions = ({
  handleAmountValueChange,
}: {
  handleAmountValueChange: (v: number) => void;
}) => {
  const quickTopup = (v: number) => {
    logEvent("Top Up Quick", { amount: v });
    handleAmountValueChange(v);
  };

  return (
    <div className="atlas-mt-2 atlas-flex atlas-gap-1">
      <Button.Secondary onClick={() => quickTopup(30)}>£30</Button.Secondary>
      <Button.Secondary onClick={() => quickTopup(50)}>£50</Button.Secondary>
      <Button.Secondary onClick={() => quickTopup(80)}>£80</Button.Secondary>
      <Button.Secondary onClick={() => quickTopup(200)}>£200</Button.Secondary>
      <Button.Secondary onClick={() => quickTopup(500)}>£500</Button.Secondary>
      <Tooltip.Wrapper>
        <Tooltip.Trigger
          className="atlas-pointer-events-auto"
          onClick={(event) => event.preventDefault()}
        >
          <IconButton>
            <span className="atlas-text-neutral-500 hover:atlas-text-blue-600 atlas-ml-2 atlas-pb-1">
              <i className="icon-lt-information-line atlas-text-base atlas-text-[26px]" />
            </span>
          </IconButton>
        </Tooltip.Trigger>
        <Tooltip.Content align="center" side="bottom" size="sm">
          <UKPriceTable />
        </Tooltip.Content>
      </Tooltip.Wrapper>
    </div>
  );
};

const UKPriceTable = () => {
  return (
    <table className="atlas-border-separate atlas-p-2">
      <thead>
        <tr>
          <th>Item</th>
          <th>Price</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Letter</td>
          <td>£1.20</td>
        </tr>
        <tr>
          <td className="atlas-pr-3">Title Register / Plan</td>
          <td>£3</td>
        </tr>
        <tr>
          <td>Utility Report</td>
          <td>£720</td>
        </tr>
      </tbody>
    </table>
  );
};
