import { FC, ReactElement, useCallback, useMemo } from "react";

import { convertStringToDate, formattedDateRange, formattedYearRange } from "src/js/util/dates";

import { FilterDateRange } from "./FilterDates";
import { FilterFloorArea } from "./FilterFloorArea";
import { FilterNewBuilds } from "./FilterNewBuilds";
import { FilterPropertyTypes } from "./FilterPropertyTypes";
import { FilterTenure } from "./FilterTenure";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";
import { MeasurementSystem } from "src/js/stores/user/store";
import { FilterBedrooms } from "./FilterBedrooms";
import { isEqual } from "lodash";
import { Theme } from "react-migration/lib/theme/Theme";
import { DeprecatedTooltip } from "react-migration/components/DeprecatedTooltip";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { hiddenFieldsByFeature } from "../../util/hiddenFields";
import { isZoopla } from "../../util/isZoopla";
import { defaultFilters } from "../../util/defaultFilters";
import { PriceModes } from "../../typings/PriceModes";
import { ComparablesFilterOptions, DCSCondition } from "../../typings/Comparables";
import { PropertyDesignationGQL } from "react-migration/lib/typings/Comparables";
import { defaultDcsConditions } from "../../util/Filters";
import { Button } from "react-migration/components/Button/Button";

const DisabledMessage: FC<{
  children: ReactElement;
  enableTooltip?: boolean;
  description?: JSX.Element;
}> = ({ children, description, enableTooltip }) => {
  const { t } = useTranslation();
  if (enableTooltip) {
    const defaultDescription = t(
      "comparables.map.sidebar.filters.filter_disabled_for_zoopla_line_1"
    );

    return (
      <DeprecatedTooltip
        theme={Theme.Dark}
        placement="top"
        description={description || defaultDescription}
        className="atlas-flex atlas-flex-col atlas-align-middle"
      >
        {children}
      </DeprecatedTooltip>
    );
  }

  return children ?? null;
};

export type ComparablesFilterFields =
  | "dateFrom"
  | "dateTo"
  | "dcsConditions"
  | "dcsConditions.Commercial"
  | "dcsConditions.Other"
  | "dcsConditions.Residential"
  | "newBuildsOnly"
  | "tenure"
  | "floorArea"
  | "bedrooms"
  | "yearBuiltFrom"
  | "yearBuiltTo";

export interface FiltersProps {
  disabled?: boolean;
  disabledFields?: Set<ComparablesFilterFields>;
  hiddenFields?: Set<ComparablesFilterFields>;
  priceMode?: PriceModes;
  filters: ComparablesFilterOptions;
  setFilters: (filters: ComparablesFilterOptions) => void;
  unitPreference: MeasurementSystem;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventTracking?: (values: { filterName: string; filterState: any }) => void;
  theme?: Theme;
}

export const Filters: FC<FiltersProps> = ({
  disabled,
  disabledFields,
  filters,
  priceMode,
  setFilters,
  unitPreference,
  theme,
  hiddenFields,
  eventTracking,
}) => {
  const { t } = useTranslation();
  const region = hasFeature(Feature.usAccess) ? "US" : "GB";
  const selectedDCSConditions = !filters.dcsConditions
    ? defaultDcsConditions(region)
    : filters.dcsConditions;

  const updateFilters = useCallback(
    (val: Partial<ComparablesFilterOptions>) => {
      if (eventTracking) {
        for (const [key, value] of Object.entries(val)) {
          eventTracking({ filterName: key, filterState: value });
        }
      }
      const updatedFilters: ComparablesFilterOptions = {
        ...filters,
        dcsConditions: selectedDCSConditions,
        ...val,
      };
      if (!isEqual(updatedFilters, filters)) {
        setFilters(updatedFilters);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, setFilters, eventTracking]
  );

  const startDate = filters.dateFrom === null ? null : convertStringToDate(filters.dateFrom);
  const setStartDate = (date?: Date | null) =>
    updateFilters({ dateFrom: date ? formattedDateRange(date) : null });

  const endDate = filters.dateTo === null ? null : convertStringToDate(filters.dateTo);
  const setEndDate = (date?: Date | null) =>
    updateFilters({ dateTo: date ? formattedDateRange(date) : null });

  const setDCSConditions = useCallback(
    (dcsConditions: DCSCondition[]) => updateFilters({ dcsConditions }),
    [updateFilters]
  );
  const setShowNewBuildOnly = useCallback(
    (newBuildsOnly: boolean) => updateFilters({ newBuildsOnly }),
    [updateFilters]
  );
  const setTenureType = useCallback(
    (tenure: ComparablesFilterOptions["tenure"]) => updateFilters({ tenure }),
    [updateFilters]
  );
  const [yearBuiltFrom, setYearBuiltFrom] = useMemo(
    () => [
      filters.yearBuiltFrom ? convertStringToDate(filters.yearBuiltFrom.toString()) : undefined,
      (date?: Date | null) =>
        updateFilters({
          yearBuiltFrom: date ? formattedYearRange(date) : undefined,
        }),
    ],
    [filters, updateFilters]
  );
  const [yearBuiltTo, setYearBuiltTo] = useMemo(
    () => [
      filters.yearBuiltTo ? convertStringToDate(filters.yearBuiltTo.toString()) : undefined,
      (date?: Date | null) =>
        updateFilters({ yearBuiltTo: date ? formattedYearRange(date) : undefined }),
    ],
    [filters, updateFilters]
  );
  const setAreaMin = useCallback(
    (min: number) => {
      updateFilters({
        floorArea: {
          min: isNaN(Number(min)) || Number(min) === 0 ? undefined : (min as number),
          max: filters.floorArea?.max,
        },
      });
    },
    [filters, updateFilters]
  );
  const setAreaMax = useCallback(
    (max: number) => {
      updateFilters({
        floorArea: {
          min: filters.floorArea?.min,
          max: isNaN(Number(max)) ? undefined : (max as number),
        },
      });
    },
    [filters, updateFilters]
  );
  const setBedroomsMin = useCallback(
    (min: number) => {
      updateFilters({
        bedrooms: {
          min: isNaN(min) ? undefined : min,
          max: filters.bedrooms?.max,
        },
      });
    },
    [filters, updateFilters]
  );

  const setBedroomsMax = useCallback(
    (max: number) => {
      updateFilters({
        bedrooms: {
          min: filters.bedrooms?.min,
          max: isNaN(max) ? undefined : max,
        },
      });
    },
    [filters, updateFilters]
  );
  // there are 2 ways to hide fields.
  // * pass in hiddenFields - for settings users can change in the app or different display requirements for different areas of the app e.g. comparables within the site card has limited fields
  // * via features. These don't change during the course of a user interacting with the app and so are hardcoded in to this field
  // * via selected price mode - some fields are hidden based on the price mode selected e.g. year built filter is only present for zoopla
  const computedHiddenFields = useMemo(
    () =>
      hiddenFieldsByFeature.reduce((set, [hideCheck, fields]) => {
        if (hideCheck()) {
          return new Set([...set, ...fields]);
        }
        return set;
      }, hiddenFields || new Set<ComparablesFilterFields>()),
    [hiddenFields]
  );

  return (
    <div
      data-testid="filters"
      className={`${disabled ? "atlas-cursor-not-allowed " : ""}atlas-relative`}
    >
      <div className="atlas-p-3 atlas-flex atlas-flex-col atlas-text-xs md:atlas-text-sm">
        <DisabledMessage enableTooltip={disabledFields?.has("dcsConditions")}>
          <FilterPropertyTypes
            disabledFields={
              new Set(
                [...(disabledFields ?? [])]
                  .filter((a) => /\.(Commercial|Residential|Other)/.test(a))
                  .map((a) => a.replace(/^dcsConditions\./, "") as PropertyDesignationGQL)
              )
            }
            hiddenFields={
              new Set(
                [...(computedHiddenFields ?? [])]
                  .filter((a) => /\.(Commercial|Residential|Other)/.test(a))
                  .map((a) => a.replace(/^dcsConditions\./, "") as PropertyDesignationGQL)
              )
            }
            disabled={disabled || disabledFields?.has("dcsConditions")}
            selectedPropTypes={selectedDCSConditions}
            setSelectedPropTypes={setDCSConditions}
            theme={theme}
          />
        </DisabledMessage>
        {computedHiddenFields?.has("dateFrom") && computedHiddenFields?.has("dateTo") ? null : (
          <DisabledMessage
            enableTooltip={disabledFields?.has("dateFrom") && disabledFields?.has("dateTo")}
          >
            <FilterDateRange
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              disabled={
                disabled || (disabledFields?.has("dateFrom") && disabledFields?.has("dateTo"))
              }
              label={
                isZoopla(priceMode)
                  ? t("comparables.map.sidebar.filters.filters.zoopla_date")
                  : t("comparables.map.sidebar.filters.filters.date")
              }
            />
          </DisabledMessage>
        )}
        {computedHiddenFields?.has("tenure") ? null : (
          <DisabledMessage enableTooltip={disabledFields?.has("tenure")}>
            <FilterTenure
              disabled={disabled || disabledFields?.has("tenure")}
              tenureType={filters.tenure}
              setTenureType={setTenureType}
              theme={theme}
            />
          </DisabledMessage>
        )}
        {computedHiddenFields?.has("floorArea") ? null : (
          <DisabledMessage enableTooltip={disabledFields?.has("floorArea")}>
            <FilterFloorArea
              disabled={disabled || disabledFields?.has("floorArea")}
              setAreaMin={setAreaMin}
              areaMin={filters.floorArea?.min}
              areaMax={filters.floorArea?.max}
              setAreaMax={setAreaMax}
              unitPreference={unitPreference}
              theme={theme}
            />
          </DisabledMessage>
        )}
        {computedHiddenFields?.has("yearBuiltFrom") &&
        computedHiddenFields?.has("yearBuiltTo") ? null : (
          <DisabledMessage
            description={t("comparables.map.sidebar.filters.built_filter_disabled_for_epc")}
            enableTooltip={
              disabledFields?.has("yearBuiltFrom") || disabledFields?.has("yearBuiltTo")
            }
          >
            <FilterDateRange
              disabled={
                disabled ||
                (disabledFields?.has("yearBuiltFrom") && disabledFields?.has("yearBuiltTo"))
              }
              startDate={yearBuiltFrom}
              setStartDate={setYearBuiltFrom}
              endDate={yearBuiltTo}
              setEndDate={setYearBuiltTo}
              mode="year"
              label={t("comparables.map.sidebar.filters.filters.built")}
            />
          </DisabledMessage>
        )}
        {computedHiddenFields?.has("bedrooms") ? null : (
          <DisabledMessage
            description={t("comparables.map.sidebar.filters.bedroom_filter_disabled_for_epc")}
            enableTooltip={disabledFields?.has("bedrooms")}
          >
            <FilterBedrooms
              disabled={disabled || disabledFields?.has("bedrooms")}
              min={
                disabled || disabledFields?.has("bedrooms") || filters.bedrooms?.min == null
                  ? NaN
                  : filters.bedrooms.min
              }
              setMin={setBedroomsMin}
              max={
                disabled || disabledFields?.has("bedrooms") || filters.bedrooms?.max == null
                  ? NaN
                  : filters.bedrooms.max
              }
              setMax={setBedroomsMax}
              theme={theme}
            />
          </DisabledMessage>
        )}
        {computedHiddenFields?.has("newBuildsOnly") ? null : (
          <DisabledMessage enableTooltip={disabledFields?.has("newBuildsOnly")}>
            <FilterNewBuilds
              disabled={disabled || disabledFields?.has("newBuildsOnly")}
              showNewBuildOnly={!!filters.newBuildsOnly}
              setShowNewBuildOnly={setShowNewBuildOnly}
              theme={theme}
            />
          </DisabledMessage>
        )}
      </div>
      <div className="atlas-p-2 atlas-flex atlas-justify-end">
        <Button
          variant="secondary"
          disabled={disabled}
          onClick={() => {
            updateFilters(structuredClone({ ...defaultFilters, dateFrom: null, dateTo: null }));
          }}
        >
          {t("comparables.filters.clear")}
        </Button>
      </div>
    </div>
  );
};

Filters.displayName = "Filters";
