import { ASPECT_CATEGORIES } from "../constants";
import { useMemo } from "react";
import { Hatch } from "react-migration/domains/constraints/components/Hatch/Hatch";
import { Column, Table } from "react-migration/components/Table";

export function TopographyAspectMapLegend() {
  // remove flat from aspect table for now
  const aspectLegend = ASPECT_CATEGORIES.slice(1).map(({ label, color }) => ({
    label,
    color,
  }));

  const columns = useMemo(() => {
    return [
      {
        label: "Category",
        grow: false,
        render: ({ color, label }) => (
          <div className="atlas-flex atlas-gap-2">
            <Hatch borderColor={color} fillColor={color} />
            <span>{label}</span>
          </div>
        ),
      },
    ] as Column<(typeof aspectLegend)[number]>[];
  }, []);

  return (
    <Table
      primaryKey="label"
      columns={columns}
      rows={aspectLegend}
      size="small"
      hideHeader={true}
      highlightRowOnHover={false}
    />
  );
}
