import classNames from "classnames";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import {
  useLoadRecipients,
  LoadRecipientsProps,
} from "react-migration/domains/sites/hooks/useLoadRecipients/useLoadRecipients";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useSnapshot } from "valtio";
import { LetterStep, letterStore } from "src/js/stores/letter/store";
import { loadRecipients, updateCurrentStep, updateLetterName } from "src/js/stores/letter/actions";

import { Button } from "react-migration/components/DeprecatedButton";
import { Loading } from "react-migration/components/Loading";

import { SelectRecipientsFormProvider } from "../forms/SelectRecipientsForm/SelectRecipientsFormProvider";
import { LetterEventName, logLetterEvent } from "./helpers/logLetterEvent";
import { useFilters } from "../pipeline/PipelineProvider/hooks/useFilters";
import { SelectRecipientsFormData } from "../forms/SelectRecipientsForm/SelectRecipientsFormSchema";
import {
  SELECT_RECIPIENTS_FORM_ID,
  SelectRecipientsForm,
} from "../forms/SelectRecipientsForm/SelectRecipientsForm";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { siteStore } from "src/js/stores/site/store";
import { mergeFormDataWithRecipientData } from "../forms/SelectRecipientsForm/mergeFormDataWithRecipientData";

type SelectEditableRecipientsStepContentProps = {
  onSubmit: (formData: SelectRecipientsFormData) => void;
};

export const SelectEditableRecipientsStepContent = ({
  onSubmit,
}: SelectEditableRecipientsStepContentProps) => {
  const { t } = useTranslation();

  const { selectedRecipientIds } = useSnapshot(letterStore);

  const { formState } = useFormContext<SelectRecipientsFormData>();

  const isNextEnabled =
    selectedRecipientIds && selectedRecipientIds?.length > 0 && formState.isValid;

  return (
    <div
      data-testid="select-editable-recipients-step"
      className="atlas-h-full atlas-w-full atlas-flex atlas-flex-col"
    >
      <Header />
      <div
        data-testid="select-recipients-form-container"
        className={classNames("atlas-flex", "atlas-grow", "atlas-w-full", "atlas-overflow-hidden")}
      >
        <SelectRecipientsForm onSubmit={onSubmit} />
      </div>
      <Footer>
        <div className="atlas-flex atlas-flex-grow atlas-justify-end">
          <Button.Primary disabled={!isNextEnabled} type="submit" form={SELECT_RECIPIENTS_FORM_ID}>
            {t("sites.letters.next_create_letter_button")}
          </Button.Primary>
        </div>
      </Footer>
    </div>
  );
};

export const SelectEditableRecipientsStep = () => {
  const { t } = useTranslation();
  const { selectedStageId, letterName } = useSnapshot(letterStore);
  const filters = useFilters();
  const { siteCardId: siteId } = useSnapshot(siteStore);

  /** There are two main entry points to the letters flow at the moment
   * - A single site from the site card
   * - A selection from the Kanban board
   *
   * A single site must ignore all filters that are active and just do a site id lookup
   * A selection must include any filtering params that are available in the kanban view
   *
   */
  let loadRecipientsProps: LoadRecipientsProps;

  if (siteId) {
    const emptyFilters = {
      searchText: "",
      localAuthorities: [],
      siteLabels: [],
      assignees: [],
      stageIds: [], //stageIds are ignored in useLoadRecipients
    };

    loadRecipientsProps = {
      siteIds: siteId ? [siteId] : undefined,
      filters: emptyFilters,
    };
  } else {
    loadRecipientsProps = {
      stageIds: selectedStageId ? [selectedStageId] : undefined,
      filters: {
        ...filters,
        stageIds: [], //stageIds are ignored in useLoadRecipients so we remove here for confusion
      },
    };
  }

  const { loading, recipients } = useLoadRecipients(loadRecipientsProps);

  const recipientsFormData: SelectRecipientsFormData | undefined = useMemo(() => {
    return {
      recipients:
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        recipients?.map(({ fullAddress: _f, propertyAddress: _p, ...recipient }) => ({
          ...recipient,
          isSelected: recipient.isGranted,
        })) ?? [],
    };
  }, [recipients]);

  const onFormSubmit = (formData: SelectRecipientsFormData) => {
    if (!recipients) return;

    if (!letterName) {
      updateLetterName(t("sites.letters.untitled_letter"));
    }

    const updatedRecipients = mergeFormDataWithRecipientData(recipients, formData);

    loadRecipients(updatedRecipients);
    updateCurrentStep(LetterStep.TemplateEditor);
    logLetterEvent({
      name: LetterEventName.LETTERS_FORM_SELECT_RECIPIENTS_STEP_CREATE_LETTER_BUTTON_CLICKED,
    });
  };

  return (
    <>
      {loading && (
        <div
          className={classNames(
            "atlas-h-full",
            "atlas-w-full",
            "atlas-flex",
            "atlas-justify-center",
            "atlas-items-center",
            "atlas-overflow-hidden"
          )}
        >
          <Loading />
        </div>
      )}
      {!loading && (
        <SelectRecipientsFormProvider data={recipientsFormData}>
          <SelectEditableRecipientsStepContent onSubmit={onFormSubmit} />
        </SelectRecipientsFormProvider>
      )}
    </>
  );
};
