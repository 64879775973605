import { SkeletonLoading } from "react-migration/components/SkeletonLoading";
import { orderBy } from "lodash";
import { useState } from "react";
import { DetailsList } from "react-migration/components/DetailsList";
import cn from "clsx";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { PurchaseTitleModal } from "./PurchaseTitleModal";
import { Feature } from "@turf/helpers";
import { StyleMap, useHoverableSelectionLayer } from "../../../hooks/useHoverableSelectionLayer";
import { TitleFeature, useTitleFeatures } from "./useTitleFeatures";
import { PurchaseTitle } from "./PurchaseTitle";
import { PRICE_PER_TITLE_GBP } from "src/js/util/units_and_constants";
import { useOwnershipLayerTypeContext } from "../Context";
import { PurchaseLocations } from "./PurchaseTitleModal/types";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import UserFeature from "src/js/stores/user/Feature";
import { setShowUpgradeModal } from "src/js/stores/navigation/actions";
import { Title } from "react-migration/domains/ownership/typings/Title";
import {
  Transaction,
  TransactionPurchaseType,
} from "react-migration/domains/ownership/typings/Transaction";
import { formatAddress } from "react-migration/domains/ownership/util/Formatters";
import { TenureType } from "react-migration/domains/ownership/typings/Tenure";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const selectionTitleNumberAccessor = (title: Feature<any, Title>) => title?.properties?.id;

const transactionSuccessful = (status: string) =>
  status.includes("success") || (status.includes("pending") && !status.includes("failure"));

export const TITLE_STYLE_MAP: StyleMap = {
  idle: {
    lineColor: [0, 0, 0, 0],
    fillColor: [0, 0, 0, 0],
  },
  hovered: {
    lineColor: [255, 0, 0, 255],
    fillColor: [255, 0, 0, 255 * 0.2],
  },
};

type TitlesSectionProps = {
  titles: Title[];
  selectedTitleNumber?: string;
};

export const TitlesSection = ({ titles, selectedTitleNumber }: TitlesSectionProps) => {
  const [hoveredId, setHoveredId] = useState<string>();
  const { titleFeatures, loading } = useTitleFeatures(titles);
  const { selectTitleFeature } = useOwnershipLayerTypeContext();

  useHoverableSelectionLayer({
    features: titleFeatures,
    hoveredId,
    pickable: false,
    styleMap: TITLE_STYLE_MAP,
    setHoveredId,
    idAccessor: selectionTitleNumberAccessor,
  });

  if (loading) {
    return (
      <div className="atlas-relative atlas-h-[150px]">
        <SkeletonLoading rows={3} />
      </div>
    );
  }

  return [...(titleFeatures || [])]
    .sort((titleFeature) => (titleFeature.properties?.title_no === selectedTitleNumber ? -1 : 1))
    .map((titleFeature) => (
      <div
        key={titleFeature.properties?.id}
        onMouseEnter={() => setHoveredId(titleFeature.properties?.id)}
        onMouseLeave={() => setHoveredId(undefined)}
      >
        <TitleDetail
          titleFeature={titleFeature}
          isHovered={titleFeature.properties?.id === hoveredId}
          isSelectable={
            !selectedTitleNumber || titleFeature.properties?.title_no !== selectedTitleNumber
          }
          onSelectTitleFeature={selectTitleFeature}
        />
      </div>
    ));
};

type TitleDetailProps = {
  titleFeature: TitleFeature;
  isHovered: boolean;
  isSelectable: boolean;
  onSelectTitleFeature(titleFeature: TitleFeature): void;
};

const TitleDetail = ({
  titleFeature,
  isHovered,
  isSelectable,
  onSelectTitleFeature,
}: TitleDetailProps) => {
  const title = titleFeature.properties!;
  const [showTitlePlanModal, setShowTitlePlanModal] = useState(false);
  const [showTitleRegisterModal, setShowTitleRegisterModal] = useState(false);
  const { t } = useTranslation();
  const registeredLease = title.registered_lease;
  const orderedTransactions = orderBy(
    title.transactions,
    (x) =>
      x.purchase?.title_document?.created
        ? new Date(x.purchase?.title_document?.created?.toString())
        : "",
    "desc"
  );
  const titlePlan = orderedTransactions.find(
    (x) => x.purchase?.type === TransactionPurchaseType.TITLE_PLAN_DOCUMENT
  );

  const titleRegister = orderedTransactions.find(
    (x) => x.purchase?.type === TransactionPurchaseType.TITLE_REGISTER_DOCUMENT
  );

  const address = title.addresses?.[0];

  return (
    <div
      className={cn("atlas-space-y-2 atlas-p-2", {
        "atlas-bg-neutral-50": isHovered,
      })}
    >
      <div className="atlas-font-thin atlas-mb-3 atlas-text-sm atlas-select-none atlas-flex atlas-flex-col">
        <span className="atlas-text-content-tertiary">
          {title.tenure && TENURE_DISPLAY_NAME.get(title.tenure)}
        </span>
        <span
          className={cn("atlas-font-semibold atlas-select-none", {
            "atlas-text-content-secondary": !isSelectable,
            "atlas-text-content-link hover:atlas-text-content-link-hover hover:atlas-underline atlas-cursor-pointer":
              isSelectable,
          })}
          onClick={() => isSelectable && onSelectTitleFeature(titleFeature)}
          title={
            isSelectable
              ? t("ownership.titles_section.select_title", {
                  titleNo: titleFeature.properties?.title_no,
                })
              : undefined
          }
        >
          {title.title_no}
        </span>
      </div>
      <div className="atlas-space-y-2 atlas-text-xs">
        {address && (
          <DetailsList>
            <DetailsList.Item label="Address">{formatAddress(address)}</DetailsList.Item>
            <DetailsList.Item label="Lease end date">
              {registeredLease?.end_date
                ? new Date(registeredLease.end_date).toLocaleDateString()
                : t("not_applicable.short")}
            </DetailsList.Item>
          </DetailsList>
        )}
        <div className="atlas-flex atlas-gap-2">
          <TitleRegister
            transaction={titleRegister}
            onClick={() =>
              hasFeature(UserFeature.documentPurchase)
                ? setShowTitleRegisterModal(true)
                : setShowUpgradeModal(true)
            }
          />
          <TitlePlan transaction={titlePlan} onClick={() => setShowTitlePlanModal(true)} />
        </div>
      </div>
      {showTitleRegisterModal && (
        <PurchaseTitleModal
          title={t("ownership.titles_section.purchase_title_register_modal_title", {
            titleNo: title.title_no,
          })}
          titlesToPurchase={[title.title_no!]}
          onCancel={() => setShowTitleRegisterModal(false)}
          transactionPurchaseType={TransactionPurchaseType.TITLE_REGISTER_DOCUMENT}
          location={PurchaseLocations.Map}
        />
      )}
      {showTitlePlanModal && (
        <PurchaseTitleModal
          title={t("ownership.titles_section.purchase_title_plan_modal_title", {
            titleNo: title.title_no,
          })}
          titlesToPurchase={[title.title_no!]}
          onCancel={() => setShowTitlePlanModal(false)}
          transactionPurchaseType={TransactionPurchaseType.TITLE_PLAN_DOCUMENT}
          location={PurchaseLocations.Map}
        />
      )}
    </div>
  );
};

interface TitleRegisterProps {
  transaction?: Transaction;
  onClick(): void;
}

export function TitleRegister({ transaction, onClick }: TitleRegisterProps) {
  const { t } = useTranslation();
  return (
    <PurchaseTitle.Wrapper icon="icon-lt-file-line">
      <PurchaseTitle.Heading>{t("ownership.titles_section.title_register")}</PurchaseTitle.Heading>
      {transaction && transactionSuccessful(transaction.state?.type || "") && (
        <PurchaseTitle.ViewPurchased
          titleType={TransactionPurchaseType.TITLE_REGISTER_DOCUMENT}
          transaction={transaction}
        />
      )}

      <PurchaseTitle.Price onClick={onClick}>
        {transaction && transactionSuccessful(transaction.state?.type || "")
          ? t("ownership.titles_section.repurchase_for_price", {
              price: PRICE_PER_TITLE_GBP,
            })
          : t("ownership.titles_section.purchase_for_price", {
              price: PRICE_PER_TITLE_GBP,
            })}
      </PurchaseTitle.Price>
    </PurchaseTitle.Wrapper>
  );
}

interface TitlePlanProps {
  transaction?: Transaction;
  onClick(): void;
}

export function TitlePlan({ transaction, onClick }: TitlePlanProps) {
  const { t } = useTranslation();

  return (
    <PurchaseTitle.Wrapper icon="icon-lt-file-line">
      <PurchaseTitle.Heading>{t("ownership.titles_section.title_plan")}</PurchaseTitle.Heading>
      {transaction && transactionSuccessful(transaction.state?.type || "") && (
        <PurchaseTitle.ViewPurchased
          titleType={TransactionPurchaseType.TITLE_PLAN_DOCUMENT}
          transaction={transaction}
        />
      )}

      <PurchaseTitle.Price onClick={onClick}>
        {transaction && transactionSuccessful(transaction.state?.type || "")
          ? t("ownership.titles_section.repurchase_for_price", {
              price: PRICE_PER_TITLE_GBP,
            })
          : t("ownership.titles_section.purchase_for_price", {
              price: PRICE_PER_TITLE_GBP,
            })}
      </PurchaseTitle.Price>
    </PurchaseTitle.Wrapper>
  );
}

const TENURE_DISPLAY_NAME = new Map([
  [TenureType.FREEHOLD, "Freehold"],
  [TenureType.LEASEHOLD, "Leasehold"],
  [TenureType.UNKNOWN_OTHER, "Unknown"],
]);
