import { PlanningResultRow } from "./PlanningResultRow";
import { Loading } from "react-migration/components/Loading";
import { Waypoint } from "react-waypoint";
import { NoData } from "../NoData/NoData";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { PlanningAlertResultResponse } from "react-migration/domains/dashboard/typings/PlanningAlertResultsResponse";

type PlanningResultsTableProps = {
  handleWaypointEnter: () => void;
  data: PlanningAlertResultResponse | undefined;
  loading: boolean;
};

export const PlanningResultsTable = ({
  handleWaypointEnter,
  data,
  loading,
}: PlanningResultsTableProps) => {
  const { t } = useTranslation();

  if (!loading && !data?.planningAlertResults.nodes.length) {
    return (
      <NoData title={t("dashboard.planning_alerts.no_results")}>
        <p>{t("dashboard.planning_alerts.edit_your_alert")}</p>
      </NoData>
    );
  }

  return (
    <div data-testid="planning-results-table" className="atlas-mb-2">
      {data?.planningAlertResults.nodes.map((result) => (
        <PlanningResultRow key={result._id} result={result} />
      ))}
      {data?.planningAlertResults.pageInfo.hasNextPage && (
        <Waypoint onEnter={handleWaypointEnter} />
      )}
      {loading && (
        <div className="atlas-mt-2">
          <Loading />
        </div>
      )}
    </div>
  );
};
