import { useState } from "react";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { UnsupportedCouncils } from "./councilInstructions";
import { logEvent } from "react-migration/lib/util/logEvent";
import { Button } from "react-migration/components/Button";
import { ModalV2 } from "react-migration/components/ModalV2/ModalV2";
import { ClipboardCopy } from "react-migration/components/ClipboardCopy";

type ViewAppModalProps = {
  authorityName?: string;
  authorityUrl?: string | null;
  appealRef?: string | null;
  applicationRef?: string;
};

export const ViewApplicationButton = ({
  authorityName,
  authorityUrl,
  appealRef,
  applicationRef = "",
}: ViewAppModalProps) => {
  const { t } = useTranslation();
  const [isViewAppModalOpen, setIsViewAppModalOpen] = useState(false);
  const councilWithNoLinkSupport =
    UnsupportedCouncils[authorityName as keyof typeof UnsupportedCouncils];

  const council = councilWithNoLinkSupport;
  return (
    <>
      {(appealRef || authorityUrl) && (
        <Button
          variant="secondary"
          onClick={() => {
            let url = "";
            if (appealRef) {
              url = `https://acp.planninginspectorate.gov.uk/ViewCase.aspx?CaseID=${appealRef}`;
            } else if (authorityUrl) {
              url = authorityUrl;
            }
            logEvent("Planning App council website link click");
            window.open(url, "_blank");
            councilWithNoLinkSupport && setIsViewAppModalOpen(true);
          }}
          leadIcon="lt-external-link-line"
          className="atlas-text-xs !atlas-py-1"
        >
          {t(
            appealRef
              ? "planning.planning_application_list.planning_application_list.planning_appeal_details.view_appeal"
              : "planning.planning_application_list.planning_application_list.planning_appeal_details.view_application"
          )}
        </Button>
      )}
      {isViewAppModalOpen && (
        <div className="atlas-fixed">
          <ModalV2.Root defaultOpen={true} onOpenChange={() => setIsViewAppModalOpen(false)}>
            <ModalV2.Body
              title={t("components.view_planning_app_button.view_application")}
              className="!atlas-z-[1000]"
              contentClassName="!atlas-max-w-[600px]"
            >
              <div className="atlas-flex atlas-flex-col atlas-break-words">
                <div className="atlas-mb-2">
                  {t("components.view_planning_app_button.intro", {
                    councilName: council.name,
                  })}
                </div>

                <div className="atlas-flex atlas-items-center atlas-py-4">
                  <div className="atlas-pr-4">
                    {t("components.view_planning_app_button.reference")}
                  </div>
                  <ClipboardCopy text={appealRef || applicationRef} />
                </div>

                <ol className="atlas-list-inside atlas-space-y-2">
                  <li>
                    {t("components.view_planning_app_button.click_here_to_open_portal", {
                      hereLink: (
                        <a target="_blank" rel="noreferrer" href={council.link}>
                          {t("components.view_planning_app_button.here")}
                        </a>
                      ),
                    })}
                  </li>
                  {council.prePaste && <li>{council.prePaste}</li>}
                  <li>
                    {t("components.view_planning_app_button.paste")}{" "}
                    <strong>{council.refName}</strong>
                  </li>
                  <li>{t("components.view_planning_app_button.search")}</li>
                </ol>
              </div>
            </ModalV2.Body>
          </ModalV2.Root>
        </div>
      )}
    </>
  );
};
