import { PropsWithChildren } from "react";
import * as RadixRadioGroup from "@radix-ui/react-radio-group";
import { RadioGroupIndicator, RadioGroupItem } from "./components";
import clsx from "clsx";

export type RadioGroupProps = PropsWithChildren<RadixRadioGroup.RadioGroupProps>;

export function RadioGroup({ className, children, ...rest }: RadioGroupProps) {
  return (
    <RadixRadioGroup.Root className={clsx("atlas-flex", "atlas-flex-col", className)} {...rest}>
      {children}
    </RadixRadioGroup.Root>
  );
}

RadioGroup.Indicator = RadioGroupIndicator;
RadioGroup.Item = RadioGroupItem;

export default RadioGroup;
