import {
  ConstrainedListSearchFilterDTO,
  IConstrainedListSearchFilter,
} from "./ConstrainedListSearchFilter";
import { DateRangeSearchFilterDTO, IDateRangeSearchFilter } from "./DateRangeSearchFilter";
import { ILocationFilter, LocationFilterDTO } from "./LocationSearchFilter";
import { IMultiTextFilter, MultiTextFilterDTO } from "./MultiTextSearchFilter";
import {
  INestedConstrainedListSearchFilter,
  NestedConstrainedListSearchFilterDTO,
} from "./NestedConstrainedListSearchFilter";
import { INumberSearchFilter, NumberSearchFilterDTO } from "./NumberSearchFilter";
import { INumberRangeSearchFilter, NumberRangeSearchFilterDTO } from "./NumberRangeSearchFilter";
import { ITextSearchFilter, TextSearchFilterDTO } from "./TextSearchFilter";
import { RegionSearchFilterDTO } from "./RegionSearchFilter";

export type CompoundFilterId =
  | "planning-applications-id"
  | "amenities-filter"
  | "owner-portfolio-id";

export enum CompoundFilterMust {
  MATCH_ALL = "MATCH_ALL",
  MATCH_NONE = "MATCH_NONE",
}

export type CompoundFilterMustDTO = keyof typeof CompoundFilterMust;

export interface ICompoundFilter {
  id: CompoundFilterId;
  filters: Array<
    | IConstrainedListSearchFilter
    | IDateRangeSearchFilter
    | ILocationFilter
    | IMultiTextFilter
    | INestedConstrainedListSearchFilter
    | INumberRangeSearchFilter
    | ITextSearchFilter
    | INumberSearchFilter
  >;
  must: CompoundFilterMust;
  type: "compound";
}

export type CompoundFilterDTO = Omit<ICompoundFilter, "must" | "filters"> & {
  must: CompoundFilterMustDTO;
  filters: Array<
    | ConstrainedListSearchFilterDTO
    | DateRangeSearchFilterDTO
    | LocationFilterDTO
    | MultiTextFilterDTO
    | NestedConstrainedListSearchFilterDTO
    | NumberRangeSearchFilterDTO
    | TextSearchFilterDTO
    | NumberSearchFilterDTO
    | RegionSearchFilterDTO
  >;
};
