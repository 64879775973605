import { Icon } from "react-migration/components/Icon";
import { LtIcons } from "@landtechnologies/icons/font/lt-icons";
import { ComponentProps } from "react";

interface LTIconProps extends ComponentProps<typeof Icon> {
  icon: `${LtIcons}`;
}

export function LTIcon({ icon, ...props }: LTIconProps) {
  return <Icon icon={`icon-${icon}`} {...props} />;
}
