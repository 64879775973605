import { MaskExtension } from "@deck.gl/extensions";
import { MVTLayer, TextLayer } from "deck.gl";
import { CompositeLayer } from "@deck.gl/core";
import { IconLayerProps } from "@deck.gl/layers/dist/icon-layer/icon-layer";
import { ScatterplotLayerProps } from "@deck.gl/layers";

import Feature from "src/js/stores/user/Feature";
import { MeasurementSystem } from "src/js/stores/user/store";
import { ENVIRONMENT } from "src/js/util/environment";

import { pricePerAreaLayers } from "./pricePerAreaLayers";
import { pricePerUnitLayers } from "./pricePerUnitLayers";
import {
  characterSet,
  emptyArray,
  getPosition,
  textAccessors,
  memoizedDataFilter,
} from "./comparablesLayerUtil";
import hasBetaFeature from "src/js/stores/user/actions/hasBetaFeature";
import { CorePriceModes } from "react-migration/domains/comparables/typings/PriceModes";
import { ComparableTile } from "react-migration/lib/typings/ComparablesTile";
import getPermissions from "src/js/stores/user/actions/getPermissions";
export interface ComparablesLayerProps extends IconLayerProps<ComparableTile> {
  minZoom: number;
  maxZoom: number;
  selection?: (GeoJSON.Point | GeoJSON.MultiPoint | undefined)[];
  onViewportLoaded?: () => void;
  onViewportLoading?: () => void;
  priceMode: CorePriceModes;
  distribution: number[];
  unitPreference: MeasurementSystem;
  filterFunctions: Array<(c: ComparableTile["properties"]) => boolean>;
}

function scatterLayers(
  priceMode: CorePriceModes,
  distribution: number[],
  data: ComparableTile[],
  getSubLayerProps: (i: number) => ScatterplotLayerProps<ComparableTile>
) {
  return [
    ...pricePerAreaLayers(priceMode, distribution, data, getSubLayerProps),
    ...pricePerUnitLayers(priceMode, distribution, data, getSubLayerProps),
  ];
}

let lastFilters: Array<(c: ComparableTile["properties"]) => boolean>;
let lastFiltersIncrementCount = 0;

export class PricePaidLayer extends CompositeLayer<ComparablesLayerProps> {
  static componentName = "PricePaidLayer";

  renderLayers() {
    const geofenceGeometries = getPermissions()?.geofencesGeometries;
    const maskExtensions = [] as MaskExtension[];
    let maskId: string | undefined;

    if (geofenceGeometries?.length && !hasBetaFeature(Feature.disableGeofence)) {
      maskExtensions.push(new MaskExtension());
      maskId = "Geofence";
    }
    const { priceMode, distribution, unitPreference, filterFunctions } = this.props;

    if (lastFilters !== filterFunctions) {
      lastFiltersIncrementCount = (lastFiltersIncrementCount + 1) % 100000;
    }

    const data = memoizedDataFilter(this.props.data as ComparableTile[], filterFunctions);
    return [
      ...scatterLayers(priceMode, distribution, data, (i: number) =>
        this.getSubLayerProps({
          id: `${this.props.id}__${i}__scatter`,
        })
      ),
      new TextLayer<ComparableTile>({
        ...this.getSubLayerProps({
          id: `${this.props.id}__text`,
        }),
        extensions: maskExtensions,
        maskId,
        pickable: true,
        data,
        characterSet,
        getPosition,
        getText: textAccessors[unitPreference || "metric"][priceMode],
        getAngle: 0,
        getPixelOffset: [0, -16],
        getSize: 14,
        fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
        outlineWidth: 5,
        outlineColor: [255, 255, 255, 255],
        fontSettings: {
          sdf: true,
          fontSize: 32,
          buffer: 8,
        },
        getTextAnchor: "middle",
        getAlignmentBaseline: "center",
        updateTriggers: {
          getText: priceMode + unitPreference + lastFiltersIncrementCount,
          getPosition: priceMode + unitPreference + lastFiltersIncrementCount,
        },
      }),
    ];
  }
}

export class ComparablesLayer extends CompositeLayer<ComparablesLayerProps> {
  static componentName = "ComparablesLayer";

  renderLayers() {
    const geofenceGeometries = getPermissions()?.geofencesGeometries;
    const maskExtensions = [] as MaskExtension[];
    let maskId: string | undefined;

    if (geofenceGeometries?.length && !hasBetaFeature(Feature.disableGeofence)) {
      maskExtensions.push(new MaskExtension());
      maskId = "Geofence";
    }
    const {
      minZoom,
      maxZoom,
      data,
      priceMode,
      distribution = emptyArray,
      unitPreference,
      filterFunctions,
      onViewportLoaded,
      onViewportLoading,
    } = this.props;

    return [
      new MVTLayer<ComparableTile>({
        ...this.getSubLayerProps(),
        extensions: maskExtensions,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        maskId,
        data:
          data && (data as Array<ComparableTile>)?.length
            ? data
            : `${ENVIRONMENT.COMPARABLES_SERVICE_URL}/tiles/{z}/{x}/{y}`,
        maxRequests: -1,
        binary: false,
        parameters: { depthTest: false },
        filterFunctions,
        priceMode,
        distribution,
        unitPreference,
        pickable: true,
        minZoom,
        maxZoom,
        renderSubLayers(props) {
          if (!props?.data) {
            return [];
          }

          return [
            new PricePaidLayer({
              ...props,
              minZoom: props.minZoom ?? undefined,
              maxZoom: props.maxZoom ?? undefined,
              id: `${props.id}--price`,
              data: props.data,
            }),
          ];
        },
        // these 2 props are a hack to allow us to set a global data-test-id to aid in e2e testing
        onViewportLoad: () => {
          onViewportLoaded?.();
        },
        fetch(url: string, config) {
          onViewportLoading?.();
          return config.layer.parent?.props.fetch(url, config);
        },
      }),
    ];
  }
}
