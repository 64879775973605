import { PropsWithChildren, useLayoutEffect, useRef } from "react";
import { useOnClickOutside } from "usehooks-ts";

import { useDropdownMenuContext } from "../../DropdownMenuProvider";
import clsx from "clsx";

export type DropdownMenuContentDimensions = {
  height: number;
  width: number;
};

export type DropdownMenuContentProps = {
  className?: string;
  "data-testid"?: string;
  style?: React.CSSProperties;
  onOpenStateChanged?: (dimensions: DropdownMenuContentDimensions) => void;
};

export const DropdownMenuContent = ({
  children,
  className: additionalClasses,
  "data-testid": dataTestId,
  style: additionalStyles,
  onOpenStateChanged,
}: PropsWithChildren<DropdownMenuContentProps>) => {
  const ref = useRef<HTMLDivElement>(null);
  const { isOpen, menuPositionStyleProperties, menuWidthClassName, closeMenu } =
    useDropdownMenuContext();

  useLayoutEffect(() => {
    if (isOpen) {
      onOpenStateChanged?.({
        height: ref.current?.getBoundingClientRect().height ?? 0,
        width: ref.current?.getBoundingClientRect().width ?? 0,
      });
    }
  }, [isOpen]);

  const handleClickOutside = () => {
    if (isOpen === false) return;
    closeMenu();
  };
  useOnClickOutside(ref, handleClickOutside);

  return (
    <>
      {isOpen && (
        <div
          ref={ref}
          data-testid={dataTestId ?? "dropdown-menu-content"}
          style={{
            ...menuPositionStyleProperties,
            ...additionalStyles,
          }}
          className={clsx(
            "atlas-fixed",
            "atlas-box-border",
            "atlas-bg-white",
            "atlas-rounded",
            "atlas-shadow-md",
            "atlas-z-50",
            menuWidthClassName,
            additionalClasses
          )}
          onClick={(event) => event.stopPropagation()}
        >
          {children}
        </div>
      )}
    </>
  );
};
