import classNames from "classnames";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useSnapshot } from "valtio";

import { Button } from "react-migration/components/DeprecatedButton";

import { FinalStepWrapper } from "./FinalStepWrapper";
import { letterStore } from "src/js/stores/letter/store";

type LetterSuccessProps = {
  onViewLettersClick: () => void;
  onGoToSitesClick: () => void;
};

export const LetterSuccess = ({ onViewLettersClick, onGoToSitesClick }: LetterSuccessProps) => {
  const { t } = useTranslation();
  const { selectedStageId } = useSnapshot(letterStore);

  return (
    <FinalStepWrapper>
      <div
        className={classNames(
          "atlas-flex",
          "atlas-w-20",
          "atlas-h-20",
          "atlas-justify-center",
          "atlas-items-center",
          "atlas-rounded-full"
        )}
      >
        <i
          className={classNames(
            "icon-lt-clock-line",
            "atlas-text-6xl",
            "atlas-flex",
            "atlas-text-content-primary"
          )}
        />
      </div>

      <span
        className={classNames(
          "atlas-my-4",
          "atlas-text-content",
          "atlas-font-semibold",
          "atlas-text-xl",
          "atlas-leading-7"
        )}
      >
        {t("sites.letters.your_letters_are_being_created")}
      </span>

      <span
        className={classNames(
          "atlas-font-normal",
          "atlas-text-base",
          "atlas-leading-6",
          "atlas-text-center",
          "atlas-whitespace-pre-line",
          "atlas-text-content-secondary",
          "atlas-mb-4"
        )}
      >
        {t("sites.letters.our_system_is_creating_pdfs")}
      </span>

      <span
        className={classNames(
          "atlas-font-normal",
          "atlas-text-base",
          "atlas-leading-6",
          "atlas-text-center",
          "atlas-whitespace-pre-line",
          "atlas-text-content-secondary"
        )}
      >
        {t("sites.letters.to_check_the_status_of_your_batch")}
      </span>

      <Button.Primary
        data-testid="view-letters-button"
        className="atlas-my-4"
        onClick={onViewLettersClick}
      >
        {selectedStageId
          ? t("sites.letters.view_letter_campaigns")
          : t("sites.letters.view_letters")}
      </Button.Primary>

      <Button.Secondary data-testid="go-to-sites-button" onClick={onGoToSitesClick}>
        {t("sites.letters.go_to_saved_sites")}
      </Button.Secondary>
    </FinalStepWrapper>
  );
};
