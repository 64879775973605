import { useMemo, useState } from "react";
import { ZodError, SafeParseReturnType } from "zod";
import { ApolloError, useQuery } from "@apollo/client";
import { cleanTypename } from "react-migration/lib/util/cleanTypename";
import { LandTechEndpoints, routedClient } from "react-migration/lib/persistence/apollo";
import ASPECT_STATS_QUERY from "./aspectStatsByGeometry.gql";
import { aspectStatsSchema } from "./aspectStatsSchema";
import {
  AspectStatsData,
  AspectStatsStrict,
  QueryTopographyAspectStatsByGeometryArgs,
} from "./types";

interface UseAspectStatsQueryOptions {
  onLoaded?(): void;
}

export function useAspectStatsQuery(
  geometry?: QueryTopographyAspectStatsByGeometryArgs["geometry"] | null,
  options?: UseAspectStatsQueryOptions
) {
  const cleanedGeometry = useMemo(() => geometry && cleanTypename(geometry), [geometry]);
  const [parsed, setParsed] = useState<SafeParseReturnType<unknown, AspectStatsStrict>>();
  const { data, ...rest } = useQuery<AspectStatsData, QueryTopographyAspectStatsByGeometryArgs>(
    ASPECT_STATS_QUERY,
    {
      variables: {
        geometry: cleanedGeometry!,
      },
      skip: !cleanedGeometry,
      client: routedClient,
      context: {
        endpoint: LandTechEndpoints.Gateway,
      },
      onCompleted(data) {
        setParsed(aspectStatsSchema.safeParse(data?.topographyAspectStatsByGeometry));
        options?.onLoaded?.();
      },
      onError() {
        options?.onLoaded?.();
      },
    }
  );

  let error: ZodError | ApolloError | undefined = rest.error;

  if (parsed && !parsed.success) {
    error = parsed.error;
  }

  return { data: data?.topographyAspectStatsByGeometry, error, ...rest };
}
