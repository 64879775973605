import { useEffect } from "react";
import { useAtkinsOrder } from "../hooks/useAtkinsOrder";
import { router } from "src/js/router/router";
import ROUTES from "src/js/router/routes";
import { Loading } from "react-migration/components/Loading";

const SITE_ID_QUERY_PARAM_KEY = "siteId";

export const DownloadUtilityReport = () => {
  const params = new URL(window.location.toString()).searchParams;
  const siteId = params.get(SITE_ID_QUERY_PARAM_KEY);

  if (!siteId) {
    router.replace(ROUTES.MAP);
  }

  const { data, error } = useAtkinsOrder({ siteId: siteId! });

  useEffect(() => {
    if (error) {
      router.replace(ROUTES.MAP);
    }

    if (!data) {
      return;
    }

    if (data.atkinsOrder.signedReportUrl) {
      window.location.replace(data?.atkinsOrder.signedReportUrl);
    } else {
      router.replace(ROUTES.MAP);
    }
  }, [data, error]);

  return (
    <div className="atlas-w-full atlas-flex atlas-justify-center atlas-content-center atlas-p-10">
      <Loading />
    </div>
  );
};
