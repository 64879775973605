import {
  ZooplaComparableTile,
  ZooplaComparableTileProperties,
} from "react-migration/lib/typings/ComparablesTile";
import { CurriedFunction1, curry, identity, property } from "lodash";
import {
  PriceModes,
  ZooplaPriceModes,
  ZooplaPricePerAreaModes,
  ZooplaPricePerUnitModes,
} from "react-migration/domains/comparables/typings/PriceModes";
import { modifiers } from "react-migration/lib/util/numberFormat";
import { MeasurementSystem } from "src/js/stores/user/store";
import { createWeakMemo } from "src/js/util/memoization";
import { msqToFtsq } from "src/js/util/units_and_constants";

const numberFormatter = new Intl.NumberFormat();
export function toNumber(value: unknown): number {
  const asNumber = Number(value);
  if (isNaN(asNumber)) {
    return 0;
  }
  return asNumber;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getValueFromProp = curry(function (pick: (x: any) => unknown, item: unknown) {
  return toNumber(pick(item));
}, 2);

export const memoizedZooplaTileDataFilter = createWeakMemo(function (
  data: ZooplaComparableTile[],
  filterFunctions: Array<(c: ZooplaComparableTileProperties) => boolean>,
  getValue: (x: ZooplaComparableTileProperties) => number
) {
  return data.filter(
    (item) =>
      getValue(item.properties) > 0 &&
      filterFunctions.every((filterFunction) => filterFunction(item.properties))
  );
});

export const formatZooplaPricing = curry(function formatZooplaPricing(
  getValue: (x: ZooplaComparableTileProperties) => number,
  conversion: (x: number) => number,
  c: ZooplaComparableTile
) {
  const value = conversion(getValue(c.properties));
  const modifier = modifiers.find(({ test }) => test(Number(value)));
  if (!modifier || !value) {
    return "";
  }

  return `£${numberFormatter.format(modifier.modifier(value))}${modifier.suffix}`;
},
3);

export const zooplaPriceModeToRawValue: Record<
  ZooplaPriceModes,
  (x: ZooplaComparableTileProperties) => number
> = {
  [ZooplaPricePerUnitModes.RENT_ASKING]: getValueFromProp(property("valuation.rent.lastValue")),
  [ZooplaPricePerUnitModes.RENT_ESTIMATE]: getValueFromProp(
    property("valuation.rent.currentValue")
  ),
  [ZooplaPricePerUnitModes.VAL_ESTIMATE]: getValueFromProp(property("valuation.sale.currentValue")),
  [ZooplaPricePerUnitModes.VAL_LAST]: getValueFromProp(property("valuation.sale.lastValue")),
  [ZooplaPricePerAreaModes.RENT_ASKING]: getValueFromProp(
    (props) => props["valuation.rent.lastValue"] / props["attributes.floorArea"]
  ),
  [ZooplaPricePerAreaModes.RENT_ESTIMATE]: getValueFromProp(
    (props) => props["valuation.rent.currentValue"] / props["attributes.floorArea"]
  ),
  [ZooplaPricePerAreaModes.VAL_ESTIMATE]: getValueFromProp(
    (props) => props["valuation.sale.currentValue"] / props["attributes.floorArea"]
  ),
  [ZooplaPricePerAreaModes.VAL_LAST]: getValueFromProp(
    (props) => props["valuation.sale.lastValue"] / props["attributes.floorArea"]
  ),
};

const zooplaRentalEstimatePrice = formatZooplaPricing(
  zooplaPriceModeToRawValue[ZooplaPricePerUnitModes.RENT_ESTIMATE]
)(identity);
const zooplaRentalAskingPrice = formatZooplaPricing(
  zooplaPriceModeToRawValue[ZooplaPricePerUnitModes.RENT_ASKING]
)(identity);
const zooplaValuationPrice = formatZooplaPricing(
  zooplaPriceModeToRawValue[ZooplaPricePerUnitModes.VAL_ESTIMATE]
)(identity);
const zooplalastValuePrice = formatZooplaPricing(
  zooplaPriceModeToRawValue[ZooplaPricePerUnitModes.VAL_LAST]
)(identity);
const zooplaPerAreaRentEstimate = formatZooplaPricing(
  zooplaPriceModeToRawValue[ZooplaPricePerAreaModes.RENT_ESTIMATE]
);
const zooplaPerAreaRentAsking = formatZooplaPricing(
  zooplaPriceModeToRawValue[ZooplaPricePerAreaModes.RENT_ASKING]
);
const zooplaPerAreaValuation = formatZooplaPricing(
  zooplaPriceModeToRawValue[ZooplaPricePerAreaModes.VAL_ESTIMATE]
);
const zooplaPerAreaValuationLast = formatZooplaPricing(
  zooplaPriceModeToRawValue[ZooplaPricePerAreaModes.VAL_LAST]
);

export const textAccessors: Record<
  MeasurementSystem,
  Record<ZooplaPriceModes, CurriedFunction1<ZooplaComparableTile, string>>
> = {
  imperial: {
    [ZooplaPricePerUnitModes.RENT_ESTIMATE]: zooplaRentalEstimatePrice,
    [ZooplaPricePerUnitModes.RENT_ASKING]: zooplaRentalAskingPrice,
    [ZooplaPricePerUnitModes.VAL_ESTIMATE]: zooplaValuationPrice,
    [ZooplaPricePerUnitModes.VAL_LAST]: zooplalastValuePrice,
    [ZooplaPricePerAreaModes.RENT_ESTIMATE]: zooplaPerAreaRentEstimate(identity),
    [ZooplaPricePerAreaModes.RENT_ASKING]: zooplaPerAreaRentAsking(identity),
    [ZooplaPricePerAreaModes.VAL_ESTIMATE]: zooplaPerAreaValuation(identity),
    [ZooplaPricePerAreaModes.VAL_LAST]: zooplaPerAreaValuationLast(identity),
  },
  metric: {
    [ZooplaPricePerUnitModes.RENT_ESTIMATE]: zooplaRentalEstimatePrice,
    [ZooplaPricePerUnitModes.RENT_ASKING]: zooplaRentalAskingPrice,
    [ZooplaPricePerUnitModes.VAL_ESTIMATE]: zooplaValuationPrice,
    [ZooplaPricePerUnitModes.VAL_LAST]: zooplalastValuePrice,
    [ZooplaPricePerAreaModes.RENT_ESTIMATE]: zooplaPerAreaRentEstimate(msqToFtsq),
    [ZooplaPricePerAreaModes.RENT_ASKING]: zooplaPerAreaRentAsking(msqToFtsq),
    [ZooplaPricePerAreaModes.VAL_ESTIMATE]: zooplaPerAreaValuation(msqToFtsq),
    [ZooplaPricePerAreaModes.VAL_LAST]: zooplaPerAreaValuationLast(msqToFtsq),
  },
};

export function isRental(x?: PriceModes) {
  return (
    x === ZooplaPricePerUnitModes.RENT_ESTIMATE ||
    x === ZooplaPricePerUnitModes.RENT_ASKING ||
    x === ZooplaPricePerAreaModes.RENT_ESTIMATE ||
    x === ZooplaPricePerAreaModes.RENT_ASKING
  );
}
