import { Icon, IconSizeType } from "react-migration/components/Icon";
import { Tooltip } from "react-migration/components/Tooltip";
import { Theme } from "react-migration/lib/theme/Theme";
import clsx from "clsx";

type UserUploadedIconProps = {
  showIcon?: boolean;
  className?: string;
  size?: IconSizeType;
};

export const UserUploadedIcon = ({
  showIcon = false,
  className,
  size = "sm",
}: UserUploadedIconProps) => {
  return (
    showIcon && (
      <Tooltip.Wrapper>
        <Tooltip.Content align="start" side="bottom" theme={Theme.Dark} size="sm">
          <Tooltip.Description>User uploaded</Tooltip.Description>
        </Tooltip.Content>
        <Tooltip.Trigger>
          <Icon
            size={size}
            icon="icon-lt-upload-cloud-success-line"
            extraClasses={clsx(
              className,
              "atlas-bg-[#EFF6FF] atlas-border atlas-border-[#3B82F6] atlas-text-[#3B82F6]",
              "atlas-rounded",
              "atlas-px-0.5"
            )}
            data-testid="uploaded-icon"
          />
        </Tooltip.Trigger>
      </Tooltip.Wrapper>
    )
  );
};
