import { useMemo, useState } from "react";
import { SkeletonLoading } from "react-migration/components/SkeletonLoading";
import { Column, Table } from "react-migration/components/Table";
import { Hatch } from "react-migration/domains/constraints/components/Hatch/Hatch";
import { useSlopeStatsQuery } from "./Slope/useSlopeStatsQuery";
import { SlopeBarSparkline } from "./charts/SlopeBarSparkline";
import { SLOPE_CATEGORIES } from "./constants";
import type {
  EnrichedSlopeCategory,
  SlopeCategory,
  TopographySelectionSidebarInnerProps,
} from "./types";

const SLOPE_COLUMNS = [
  {
    label: "Category",
    grow: false,
    render: ({ color, name }) => (
      <div className="atlas-flex atlas-gap-2">
        <Hatch borderColor={color} fillColor={color} />
        <span>{name}</span>
      </div>
    ),
  },
  {
    label: "Gradient",
    render: ({ description }) => <span>{description}</span>,
  },
  {
    label: "Coverage",
    render: ({ percentage }) => <span>{percentage}%</span>,
  },
] as Column<EnrichedSlopeCategory>[];

export function SlopeSummary({ selection, onLoaded }: TopographySelectionSidebarInnerProps) {
  const {
    data: slopeStats,
    loading: loadingSlopeStats,
    error: slopeError,
  } = useSlopeStatsQuery(selection.feature?.geometry, {
    onLoaded,
  });

  const [activeCategory, setActiveCategory] = useState<SlopeCategory | undefined>();

  const categoryChanging = (row: SlopeCategory | undefined) => {
    setActiveCategory(row);
  };

  const mappedSlopeStats = useMemo(() => {
    const slopeResults = slopeStats ?? [];

    return SLOPE_CATEGORIES.map((category) => {
      const slopeCategoryResult = slopeResults.find(
        (slopeResult) => slopeResult?.category === category.categoryKey
      );

      return {
        percentage: slopeCategoryResult?.percentage ?? 0,
        ...category,
      } as EnrichedSlopeCategory;
    });
  }, [slopeStats]);

  if (slopeError) {
    // Caught by boundary above
    throw slopeError;
  }

  if (loadingSlopeStats) {
    return (
      <div className="atlas-relative atlas-h-[324px] atlas-w-full">
        <SkeletonLoading rows={1} showPadding={false} />
      </div>
    );
  }

  return (
    <>
      <SlopeBarSparkline
        categories={mappedSlopeStats}
        activeCategoryKey={activeCategory?.categoryKey}
      />
      <Table
        primaryKey="categoryKey"
        columns={SLOPE_COLUMNS}
        rows={mappedSlopeStats}
        size="small"
        onRowHover={(row) => categoryChanging(row)}
      />
    </>
  );
}
