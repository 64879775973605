import { _WMSLayer as WMSLayer } from "@deck.gl/geo-layers";
import { ENVIRONMENT } from "src/js/util/environment";
import { buildConstraintsTileUrl } from "react-migration/domains/constraints/components/ConstraintLayer/ConstraintLayer";
import { DesignationLayerLight } from "react-migration/domains/constraints/components/ConstraintLayer/DesignationLayer";
import { BitmapTileLayer } from "react-migration/lib/map/layers/BitmapTileLayer";
import { useMapLayer } from "react-migration/lib/map/useMapLayer";
import { LayerTypeMapLayerProps } from "../../../types";
import { SimplifyProfile } from "../../ConstraintsLayerType";
import { useSelectedTopographyLayer } from "../useSelectedTopographyLayer";
import {
  ASPECT_COLOR_MAP,
  ASPECT_SLOPE_COLOR_MAP,
  ASPECT_SLOPE_TILE_BUFFER_SCALE_FACTOR,
  ASPECT_TILE_BUFFER_SCALE_FACTOR,
  SLOPE_COLOR_MAP,
  SLOPE_TILE_BUFFER_SCALE_FACTOR,
  TopographyLayer,
} from "../constants";

export const UKTopographyMapLayer = ({ visible, zOrder }: LayerTypeMapLayerProps) => {
  const [selectedLayer] = useSelectedTopographyLayer();

  if (!visible) {
    return null;
  }

  if (selectedLayer === TopographyLayer.hillshade) {
    return <HillshadeTopographyLayer zOrder={zOrder} />;
  }

  if (selectedLayer === TopographyLayer.contour) {
    return <ContourTopographyLayer zOrder={zOrder} />;
  }

  if (selectedLayer === TopographyLayer.hillshadeContour) {
    return (
      <>
        <HillshadeTopographyLayer zOrder={zOrder} />
        <ContourTopographyLayer zOrder={zOrder} />;
      </>
    );
  }

  if (selectedLayer === TopographyLayer.slope) {
    return <SlopeTopographyLayer zOrder={zOrder} />;
  }

  if (selectedLayer === TopographyLayer.aspect) {
    return <AspectTopographyLayer zOrder={zOrder} />;
  }

  if (selectedLayer === TopographyLayer.aspectSlope) {
    return <AspectSlopeTopographyLayer zOrder={zOrder} />;
  }

  return null;
};

function buildSlopeTileUrlTemplate() {
  const queryParams = new URLSearchParams();

  queryParams.append("color_map", SLOPE_COLOR_MAP);
  queryParams.append("tile_buffer_factor", SLOPE_TILE_BUFFER_SCALE_FACTOR.toString());

  return `${ENVIRONMENT.SUPPORTING_SERVICE_URL}/topography/tiles/slope/{z}/{x}/{y}?${queryParams}`;
}

export function renderSlopeLayer(opts?: { allowUnderzoom: boolean }) {
  return new BitmapTileLayer({
    id: "topography-slope-layer-raster",
    data: buildSlopeTileUrlTemplate(),
    opacity: 0.6,
    minZoom: 14,
    sourceTileScaleFactor: SLOPE_TILE_BUFFER_SCALE_FACTOR,
    refinementStrategy: "no-overlap",
    extent: opts?.allowUnderzoom ? [-180.0, -90.0, 180.0, 90.0] : undefined,
  });
}

function buildAspectTileUrlTemplate() {
  const queryParams = new URLSearchParams();

  queryParams.append("color_map", ASPECT_COLOR_MAP);
  queryParams.append("tile_buffer_factor", ASPECT_TILE_BUFFER_SCALE_FACTOR.toString());

  return `${ENVIRONMENT.SUPPORTING_SERVICE_URL}/topography/tiles/aspect/{z}/{x}/{y}?${queryParams}`;
}

export function renderAspectLayer(opts?: { allowUnderzoom: boolean }) {
  return new BitmapTileLayer({
    id: "topography-aspect-layer-raster",
    data: buildAspectTileUrlTemplate(),
    opacity: 0.6,
    minZoom: 14,
    sourceTileScaleFactor: ASPECT_TILE_BUFFER_SCALE_FACTOR,
    refinementStrategy: "no-overlap",
    extent: opts?.allowUnderzoom ? [-180.0, -90.0, 180.0, 90.0] : undefined,
  });
}

function buildAspectSlopeTileUrlTemplate() {
  const queryParams = new URLSearchParams();

  queryParams.append("color_map", ASPECT_SLOPE_COLOR_MAP);
  queryParams.append("tile_buffer_factor", ASPECT_SLOPE_TILE_BUFFER_SCALE_FACTOR.toString());

  return `${ENVIRONMENT.SUPPORTING_SERVICE_URL}/topography/tiles/aspect-slope/{z}/{x}/{y}?${queryParams}`;
}

export function renderAspectSlopeLayer(opts?: { allowUnderzoom: boolean }) {
  return new BitmapTileLayer({
    id: "topography-aspect-slope-layer-raster",
    data: buildAspectSlopeTileUrlTemplate(),
    opacity: 0.6,
    minZoom: 15,
    sourceTileScaleFactor: ASPECT_SLOPE_TILE_BUFFER_SCALE_FACTOR,
    refinementStrategy: "no-overlap",
    extent: opts?.allowUnderzoom ? [-180.0, -90.0, 180.0, 90.0] : undefined,
  });
}

export function renderHillshadeLayer() {
  return new WMSLayer({
    id: `topology-hillshade`,
    data: "https://environment.data.gov.uk/spatialdata/lidar-composite-digital-terrain-model-dtm-1m/wms?request=GetCapabilities&service=WMS&version=1.3.0",
    serviceType: "wms",
    layers: ["Lidar_Composite_Hillshade_DTM_1m"],
    opacity: 0.3,
    minZoom: 6,
    maxZoom: 24,
  });
}

function SlopeTopographyLayer({ zOrder }: Pick<LayerTypeMapLayerProps, "zOrder">) {
  useMapLayer(() => ({ zOrder, layer: renderSlopeLayer() }), [zOrder]);
  return null;
}

function AspectTopographyLayer({ zOrder }: Pick<LayerTypeMapLayerProps, "zOrder">) {
  useMapLayer(() => ({ zOrder, layer: renderAspectLayer() }), [zOrder]);
  return null;
}

function AspectSlopeTopographyLayer({ zOrder }: Pick<LayerTypeMapLayerProps, "zOrder">) {
  useMapLayer(() => ({ zOrder, layer: renderAspectSlopeLayer() }), [zOrder]);
  return null;
}

function HillshadeTopographyLayer({ zOrder }: Pick<LayerTypeMapLayerProps, "zOrder">) {
  useMapLayer(() => ({ zOrder, layer: renderHillshadeLayer() }), [zOrder]);
  return null;
}

export function renderContourLayer() {
  return new DesignationLayerLight({
    id: "topology-contour",
    minZoom: 11,
    data: buildConstraintsTileUrl({
      rootCategory: "uk_terrain",
      simplifyProfile: SimplifyProfile.LOW,
    }),
    featureIsVisible: () => true,
  });
}

function ContourTopographyLayer({ zOrder }: Pick<LayerTypeMapLayerProps, "zOrder">) {
  useMapLayer(() => ({ zOrder, layer: renderContourLayer() }), [zOrder]);
  return null;
}
