import { DividerV2 } from "react-migration/components/DividerV2";
import { addMonths } from "date-fns";
import { useEffect } from "react";

import Feature from "src/js/stores/user/Feature";

import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { Account } from "react-migration/domains/constraints/typings/applicationTypes/Account";
import { routedClient } from "react-migration/lib/persistence/apollo/routedClient";

import { UsageRow } from "./UsageRow";
import ACCOUNT_DOCUMENT from "../apollo/queries/account.gql";
import { useAccountQuery } from "../hooks/useAccountQuery";
import { CurrentPlan } from "./CurrentPlan";
import { OwnershipViews } from "./OwnershipViews";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import { formatDate } from "react-migration/domains/comparables/util/Dates";

export const Usage = () => {
  const { t } = useTranslation();
  const { data } = useAccountQuery();
  const account = data?.accountWithViews as Account;

  useEffect(() => {
    routedClient.refetchQueries({ include: [ACCOUNT_DOCUMENT] });
  }, []);

  // work out the ownership date + the start of the billing cycle
  const ownershipViewsResetDate = account?.ownership_views.next_reset_date ?? "";
  let ownershipViewsBillingCycleStart = undefined;
  if (ownershipViewsResetDate !== "") {
    ownershipViewsBillingCycleStart = addMonths(
      new Date(ownershipViewsResetDate),
      -1
    ).toISOString();
  }

  return (
    <div className="atlas-pt-6">
      <DividerV2 className="atlas-mb-6" />
      <p className="atlas-font-semibold atlas-text-xl atlas-mb-5 atlas-text-neutral-800">
        {t("dashboard.credit_summary.usage")}
      </p>
      <CurrentPlan />

      {hasFeature(Feature.usAccess) && hasFeature(Feature.skipTracing) && (
        <UsageRow
          title="Skip trace credits"
          progressBarColor="atlas-bg-blue-600"
          used={account?.skip_traces.used}
          cap={account?.skip_traces.cap}
          unlimitedMessage="Unlimited skip trace credits"
          isUnlimited={false}
          resetDate={formatDate(ownershipViewsResetDate)}
        >
          Used for performing a skip trace on a saved site. See your skip tracing results{" "}
          <a href="sites/skip-tracing">here</a>.
        </UsageRow>
      )}

      {hasFeature(Feature.usAccess) && hasFeature(Feature.physicalSend) && (
        <UsageRow
          title="Letter credits"
          progressBarColor="atlas-bg-blue-600"
          used={account?.included_letter_send.used}
          cap={account?.included_letter_send.cap}
          unlimitedMessage="Unlimited letter sending credits"
          isUnlimited={false}
          resetDate={formatDate(ownershipViewsResetDate)}
        >
          Used for sending letters to selected addresses. View your sent letters and their statuses{" "}
          <a href="sites/letters/batches">here</a>.
        </UsageRow>
      )}

      {hasFeature(Feature.ownershipLayer) && (
        <div>
          <UsageRow
            title={t("dashboard.credit_summary.ownership_clicks")}
            progressBarColor="atlas-bg-blue-600"
            used={account?.ownership_views.used}
            cap={account?.ownership_views.cap}
            unlimitedMessage={t("dashboard.credit_summary.unlimited")}
            isUnlimited={hasFeature(Feature.unlimitedOwnershipClicks)}
            resetDate={formatDate(ownershipViewsResetDate)}
          >
            {t("dashboard.credit_summary.used_if_you_click_on")}
            {ownershipViewsBillingCycleStart && (
              <OwnershipViews fromDate={ownershipViewsBillingCycleStart} />
            )}
          </UsageRow>
        </div>
      )}

      {hasFeature(Feature.planningLayer) && (
        <UsageRow
          title={t("dashboard.credit_summary.planning_views")}
          used={account?.planning_views.used}
          cap={account?.planning_views.cap}
          unlimitedMessage={t("dashboard.credit_summary.unlimited")}
          isUnlimited={hasFeature(Feature.unlimitedPlanningClicks)}
          resetDate={
            account?.planning_views.next_reset_date
              ? formatDate(account?.planning_views.next_reset_date)
              : ""
          }
        >
          {t("dashboard.credit_summary.used_when_you_view")}
        </UsageRow>
      )}
    </div>
  );
};
