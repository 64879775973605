import { InputMask } from "@react-input/mask";
import { format, parseISO, isValid } from "date-fns";
import React, { useEffect, useRef } from "react";
import { DatePickerGranularity } from "./types";

interface DateTextInputProps {
  onDateChange: (date: Date | null | undefined) => void;
  granularity: DatePickerGranularity;
  selectedDate: Date | null | undefined;
  testId?: string;
}

type GranularityFormat = Record<DatePickerGranularity, string>;

const mask: GranularityFormat = {
  year: "YYYY",
  month: "MM/YYYY",
  day: "DD/MM/YYYY",
};

const formatStringMap: GranularityFormat = {
  year: "yyyy",
  month: "MM/yyyy",
  day: "dd/MM/yyyy",
};

export const DateTextInput = ({
  onDateChange,
  selectedDate,
  granularity,
  testId,
}: DateTextInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const dateFormatter: Record<DatePickerGranularity, (val: string) => void> = {
    month: (str) => {
      if (str.length === 7) {
        const month = str.substring(0, 2);
        const year = str.substring(3, 7);
        if (isValid(`${year}-${month}`)) {
          const date = parseISO(`${year}-${month}`);
          onDateChange(date);
        }
      } else {
        onDateChange(null);
      }
    },
    year: (str) => {
      if (str.length === 4) {
        const selectedYear = Number(str);
        const newDate = new Date();
        newDate.setFullYear(selectedYear);
        if (isValid(selectedYear)) {
          onDateChange(newDate);
        }
      } else {
        onDateChange(null);
      }
    },

    day: () => null,
  };
  useEffect(() => {
    if (inputRef.current) {
      if (selectedDate) {
        inputRef.current.value = format(selectedDate, formatStringMap[granularity]);
      } else if (selectedDate === null) {
        inputRef.current.value = formatStringMap[granularity].toUpperCase();
      }
    }
  }, [selectedDate]);
  return (
    <div>
      <InputMask
        mask={mask[granularity]}
        replacement={{ D: /\d/, M: /\d/, Y: /\d/ }}
        data-testid={testId}
        showMask
        ref={inputRef}
        defaultValue={selectedDate ? format(selectedDate, formatStringMap[granularity]) : ""}
        autoComplete="off"
        onChange={(e) => {
          const str = e.currentTarget.value;
          dateFormatter[granularity](str);
        }}
        onKeyUp={(e) => {
          if (
            e.key === "Backspace" &&
            e.currentTarget.value.toLocaleLowerCase() ===
              formatStringMap[granularity].toLocaleLowerCase()
          ) {
            onDateChange(null);
          }
        }}
      />
    </div>
  );
};
