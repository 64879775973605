import { LayoutGroup, motion } from "framer-motion";

import clsx from "clsx";
import { useId } from "react";

type DefaultValue = string | number;

export type TextSwitchOptionType<T> = {
  label: string; // has to be an i18n translation key
  value: T;
};

export enum TextSwitchSize {
  XXS = "XXS",
  XS = "XS",
}

type Props<T> = {
  options: [TextSwitchOptionType<T>, TextSwitchOptionType<T>];
  value: T;
  onChange: (value: T) => void;
  name: string;
  label: string; // for accessibility, has to be an i18n translation key
  widthClass?: string;
  theme?: "default" | "light";
  size?: TextSwitchSize;
  disabled?: boolean;
};

export function TextSwitch<T extends DefaultValue>({
  options,
  value,
  onChange,
  name,
  label,
  widthClass,
  disabled,
  theme = "default",
  size = TextSwitchSize.XS,
}: Props<T>) {
  const id = useId();
  return (
    <div
      role="radiogroup"
      aria-label={label}
      className={clsx(
        "atlas-flex",
        "atlas-h-full",
        "atlas-bg-background-light",
        "atlas-border",
        "atlas-border-border-divider-subtle",
        "atlas-rounded",
        theme === "default" && "atlas-p-0.5",
        theme === "light" && "atlas-p-px",
        widthClass
      )}
    >
      <LayoutGroup>
        {options.map((option) => {
          const inputId = `radio-${option.value}`;
          const labelId = `radio-${option.value}-label`;
          const inputLabel = option.label;
          const isChecked = value === option.value;

          return (
            <motion.label
              key={option.label}
              htmlFor={inputId}
              id={labelId}
              data-checked={isChecked}
              data-testid={labelId}
              initial={false}
              className={clsx(
                "atlas-flex",
                "atlas-items-center",
                "atlas-justify-center",
                "atlas-flex-1",
                "atlas-p-1",
                "atlas-m-0",
                "atlas-text-center",
                "atlas-text-xs",
                "atlas-font-bold",
                "atlas-relative",
                "atlas-z-[1]",
                "atlas-cursor-pointer",
                "atlas-transition-colors",
                "atlas-select-none",
                !isChecked && "atlas-text-content-secondary",
                isChecked && "atlas-text-content-on-inverse-action",
                {
                  "atlas-opacity-50": disabled,
                }
              )}
            >
              {isChecked && (
                <motion.div
                  className={clsx(
                    "atlas-absolute",
                    "atlas-inset-0",
                    "atlas-z-10 atlas-rounded-sm",
                    theme === "default" && "atlas-bg-background-action-alt-subtle",
                    theme === "light" && "atlas-bg-blue-600"
                  )}
                  layoutId={`outline-${name}-${id}`}
                  transition={{
                    type: "spring",
                    stiffness: 500,
                    damping: 35,
                  }}
                  initial={false}
                />
              )}
              <span
                className={clsx(
                  "atlas-relative",
                  "atlas-z-20",
                  theme === "light" && isChecked && "atlas-text-white",
                  theme === "light" && "atlas-font-thin atlas-p-1 atlas-text-blue-600",
                  {
                    "atlas-text-2xs": size === TextSwitchSize.XXS,
                    "atlas-text-xs": size === TextSwitchSize.XS,
                    "opacity-50": disabled,
                  }
                )}
              >
                {inputLabel}
              </span>
              <input
                disabled={disabled}
                data-testid="text-switch-input"
                id={inputId}
                aria-labelledby={labelId}
                className="atlas-hidden"
                type="radio"
                name={name}
                checked={isChecked}
                value={option.value}
                onChange={() => onChange(option.value)}
              />
            </motion.label>
          );
        })}
      </LayoutGroup>
    </div>
  );
}
