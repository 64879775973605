import {
  CSSProperties,
  PropsWithChildren,
  RefObject,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useDebounce, useEventListener } from "usehooks-ts";

import { DropdownMenuTriggerAlignment } from "./components/DropdownMenuTrigger";
import { calculateMenuStyleProperties } from "./util/calculateMenuStyleProperties";
import getElementCornerOffsets from "./util/getElementCornerOffsets";
import { noop } from "lodash";

// Tailwind doesn't support string interpolation!
export const DropdownMenuSize = {
  AUTO: { className: "atlas-w-auto", width: 0 },
  SMALL: { className: "atlas-w-[300px]", width: 300 },
  MEDIUM: { className: "atlas-w-[600px]", width: 600 },
  LARGE: { className: "atlas-w-[900px]", width: 900 },
} as const;

type Props = {
  size?: (typeof DropdownMenuSize)[keyof typeof DropdownMenuSize];
  menuHeight?: number;
  menuWidth?: number;
  className?: string;
  isOpen?: boolean;
  onMenuClosed?: () => void;
  offset?: number;
};

const MENU_OFFSET = 10;

type DropdownContextState = {
  isOpen: boolean;
  openMenu: (
    buttonRef: RefObject<HTMLButtonElement | HTMLDivElement>,
    alignment: DropdownMenuTriggerAlignment,
    additionalOffset?: number
  ) => void;
  closeMenu: () => void;
  menuPositionStyleProperties?: CSSProperties;
  menuWidth: number;
  menuWidthClassName: string;
  menuOffset: number;
};

export const DropdownContext = createContext<DropdownContextState>({
  isOpen: false,
  menuPositionStyleProperties: undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  openMenu: () => {
    console.error("ERROR - toggleOpen prop for DropdownContext not implemented");
  },
  closeMenu: () => {
    console.error("ERROR - toggleOpen prop for DropdownContext not implemented");
  },
  menuWidth: 0,
  menuWidthClassName: "",
  menuOffset: MENU_OFFSET,
});

export const DropdownMenuProvider = ({
  size = DropdownMenuSize.SMALL,
  menuHeight,
  menuWidth,
  className: additionalClasses,
  isOpen: isMenuOpenProp,
  onMenuClosed = noop,
  offset = MENU_OFFSET,
  children,
}: PropsWithChildren<Props>) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [menuPositionStyleProperties, setMenuPositionStyleProperties] = useState<CSSProperties>();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const debouncedWindowWidth = useDebounce(windowWidth);
  const debouncedWindowHeight = useDebounce(windowHeight);

  const [triggerRef, setTriggerRef] = useState<RefObject<HTMLButtonElement | HTMLDivElement>>();
  const [additionalOffset, setAdditionalOffset] = useState(0);
  const [alignment, setAlignment] = useState<DropdownMenuTriggerAlignment>(
    DropdownMenuTriggerAlignment.RIGHT
  );

  useEffect(() => {
    if (isMenuOpenProp !== undefined) {
      setIsOpen(isMenuOpenProp);
    }
  }, [isMenuOpenProp]);

  useEventListener("resize", () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  });

  const openMenu = useCallback(
    (
      buttonRef: RefObject<HTMLButtonElement | HTMLDivElement>,
      _alignment: DropdownMenuTriggerAlignment,
      _additionalOffset = 0
    ) => {
      setIsOpen(true);
      setTriggerRef(buttonRef);
      setAlignment(_alignment);
      setAdditionalOffset(_additionalOffset);
    },
    []
  );

  const closeMenu = useCallback(() => {
    setIsOpen(false);
    setMenuPositionStyleProperties(undefined);
    onMenuClosed();
  }, [isOpen, windowHeight, windowWidth]);

  useLayoutEffect(() => {
    if (isOpen) {
      const buttonOffsets = getElementCornerOffsets(triggerRef);

      if (!buttonOffsets) return;

      const styleProperties = calculateMenuStyleProperties({
        menuOffset: offset,
        menuWidth: menuWidth ?? size.width,
        menuHeight,
        buttonOffsets,
        additionalOffset,
        alignment,
        windowWidth: debouncedWindowWidth,
        windowHeight: debouncedWindowHeight,
      });

      setMenuPositionStyleProperties(styleProperties);
    }
  }, [
    isOpen,
    debouncedWindowWidth,
    debouncedWindowHeight,
    additionalOffset,
    alignment,
    menuHeight,
    menuWidth,
    size,
    triggerRef,
  ]);

  const contextValue = useMemo(
    () => ({
      isOpen,
      menuPositionStyleProperties,
      openMenu,
      closeMenu,
      menuWidth: menuWidth ?? size.width,
      menuWidthClassName: size.className,
      menuOffset: offset,
    }),
    [isOpen, menuPositionStyleProperties, openMenu, closeMenu, size, menuWidth, offset]
  );

  return (
    <DropdownContext.Provider value={contextValue}>
      <div data-testid="dropdown-menu-container" className={additionalClasses}>
        {children}
      </div>
    </DropdownContext.Provider>
  );
};

export const useDropdownMenuContext = () => useContext(DropdownContext);
