import { RangeInputProps, RangeInput } from ".";

interface RangeInputButtonProps extends RangeInputProps {
  wrapperClassName?: string;
  inputClassName?: string;
}

export const RangeInputWithButtons: React.FC<RangeInputButtonProps> = ({
  wrapperClassName,
  ...rest
}) => {
  return (
    <div className={wrapperClassName}>
      <RangeInput {...rest} />
    </div>
  );
};

export default RangeInputWithButtons;
