import { SkeletonLoading } from "react-migration/components/SkeletonLoading";
import { DesignationHatch } from "react-migration/domains/constraints/components/Hatch";
import { SourceStatus } from "react-migration/domains/constraints/designation/components/SourceStatus";
import { useDesignationById } from "react-migration/domains/constraints/designation/hooks/useDesignationById";
import { LayerTypeSelectionHeaderProps } from "react-migration/layouts/map/Multilayer/types";
import {
  DesignationSubTitle,
  DesignationTitle,
} from "../../../../designations/Designation/Designation";
import {
  getDesignationFillColour,
  getDesignationLineColour,
} from "react-migration/domains/constraints/designation/style/accessors";

export function DesignationSelectionHeader({ selection }: LayerTypeSelectionHeaderProps) {
  const { designation, loading } = useDesignationById(selection.id);
  const fillColor = designation && getDesignationFillColour(designation);
  const lineColor = designation && getDesignationLineColour(designation);

  return (
    <>
      {loading && (
        <div className="atlas-relative atlas-h-10" data-testid="designation-header-loading">
          <SkeletonLoading rows={1} showPadding={false} />
        </div>
      )}

      {designation && (
        <div
          className="atlas-flex atlas-flex-col atlas-gap-2 atlas-text-sm"
          data-test-id="designation-header"
        >
          <div className="atlas-flex atlas-flex-col atlas-gap-2">
            <div className="atlas-flex atlas-items-start atlas-justify-between">
              <div className="atlas-flex atlas-items-start atlas-gap-2">
                <div className="atlas-flex-shrink-0 atlas-mt-1">
                  <DesignationHatch
                    id={designation.sub_category_id}
                    fillColorOverride={fillColor}
                    lineColorOverride={lineColor}
                  />
                </div>
                <div>
                  <DesignationTitle designation={designation} />
                  <DesignationSubTitle designation={designation} />
                </div>
              </div>
              <div>
                <SourceStatus status={designation.source.status} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
