import { Button } from "react-migration/components/DeprecatedButton";
import { TextInputV2 } from "react-migration/components/TextInputV2";
import { useContext, useEffect, useRef, useState } from "react";

import { SiteFiltersContext } from "../SiteFilters";
import classNames from "classnames";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

export interface SiteSearchProps {
  className?: string;
}

export const SiteSearch = ({ className }: SiteSearchProps) => {
  const { t } = useTranslation();
  const { onFilterUpdate, useSearchString, updateSearchString } = useContext(SiteFiltersContext);

  const searchString = useSearchString();

  const searchRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState(searchString);

  useEffect(() => {
    if (searchString !== value) {
      setValue(searchString);
    }
    // deliberately excluding value here to prevent this effect from running twice
    // because we mutate value as part of the effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString, onFilterUpdate]);

  const onSubmit = () => {
    updateSearchString(value);
    onFilterUpdate("searchText");
    searchRef.current?.blur();
  };

  return (
    <div data-testid="site-search" className={className}>
      <TextInputV2
        ref={searchRef}
        size="medium"
        dataTestId="site-search-input"
        placeholder={t("sites.pipeline.filters.search_for_sites")}
        IconRight={
          <Button.Borderless
            data-testid="site-search-button"
            onClick={onSubmit}
            className="!atlas-px-2.5"
          >
            <div
              className={classNames(
                "atlas-w-4",
                "atlas-h-4",
                "atlas-cursor-pointer",
                "atlas-text-white"
              )}
            >
              <SearchIcon />
            </div>
          </Button.Borderless>
        }
        iconContainerProps={{ className: "atlas-bg-background-action-alt" }}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onSubmit();
            searchRef.current?.blur();
          } else if (e.key === "Escape") {
            setValue(searchString);
            searchRef.current?.blur();
          }
        }}
      />
    </div>
  );
};

const SearchIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
      <g clipPath="url(#clip0_3421_2488)">
        <path
          fill="currentColor"
          d="M12.021 11.078l2.856 2.854-.944.944-2.854-2.856a6.002 6.002 0 01-9.745-4.687c0-3.312 2.688-6 6-6a6.002 6.002 0 014.687 9.745zm-1.337-.495a4.665 4.665 0 00-3.35-7.917 4.665 4.665 0 00-4.667 4.667 4.665 4.665 0 007.917 3.35l.1-.1z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_3421_2488">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
