import Feature from "src/js/stores/user/Feature";
import { UKTopographyLayerType } from "../layer_types/TopographyLayerType";
import { Layer } from "../types";

export const TOPOGRAPHY_LAYER_UK: Layer = {
  id: "TopographyLayer",
  layerType: UKTopographyLayerType,
  requiredFeatureAccess: [Feature.topographyUK],
  hideIfNoAccess: true,
  description: "Topography",
  title: "Topography",
  hideByDefault: true,
  toggleable: true,
  collapsed: true,
  label: {
    color: "BLUE",
    translationKey: "map.explore.label.new",
  },
};
