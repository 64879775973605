import { createContext, useContext, useMemo, useState } from "react";
import { Layer, LayerTypeProviderProps } from "../../types";
import {
  ConstraintsStore,
  ConstraintsStoreAction,
  useConstraintsStore,
} from "../ConstraintsLayerType/ConstraintsContext/store";
import { ConstraintsLayer } from "../ConstraintsLayerType";

import { PLANNING_AUTHORITY_LAYER_TYPE_ID } from "./constants";

export enum LPA_DISPLAY_BY {
  Boundaries = "boundaries",
  PIF = "pif",
  CallForSites = "call_for_sites",
}

const isPlanningAuthorityLayerType = (l: Layer): l is ConstraintsLayer =>
  l.layerType.id === PLANNING_AUTHORITY_LAYER_TYPE_ID;

interface PlanningAuthorityLayerTypeContextProps {
  displayByFilter: LPA_DISPLAY_BY;
  setDisplayByFilter: React.Dispatch<LPA_DISPLAY_BY>;
  localPlanAgeFilter?: number;
  setLocalPlanAgeFilter: React.Dispatch<number | undefined>;
  housingLandSupplyRemainingFilter?: number;
  setHousingLandSupplyRemainingFilter: React.Dispatch<number | undefined>;
  housingDeliveryTestFilter?: number;
  setHousingDeliveryTestFilter: React.Dispatch<number | undefined>;
  constraintsStore: ConstraintsStore;
  dispatch: React.Dispatch<ConstraintsStoreAction>;
}

export const PlanningAuthorityLayerTypeContext = createContext(
  {} as PlanningAuthorityLayerTypeContextProps
);

export const usePlanningAuthorityLayerTypeContext = () =>
  useContext(PlanningAuthorityLayerTypeContext);

export function PlanningAuthorityLayerTypeProvider({
  id,
  children,
  layers,
}: LayerTypeProviderProps) {
  const LOCAL_STORAGE_KEY = `${id}-planning-authority-bundle`;
  const [displayByFilter, setDisplayByFilter] = useState<LPA_DISPLAY_BY>(LPA_DISPLAY_BY.PIF);
  const localPlanningLayers = useMemo(() => layers.filter(isPlanningAuthorityLayerType), [layers]);
  const [constraintsStore, dispatch] = useConstraintsStore(LOCAL_STORAGE_KEY, localPlanningLayers);

  const [localPlanAgeFilter, setLocalPlanAgeFilter] = useState<number | undefined>(undefined);
  const [housingLandSupplyRemainingFilter, setHousingLandSupplyRemainingFilter] = useState<
    number | undefined
  >(undefined);
  const [housingDeliveryTestFilter, setHousingDeliveryTestFilter] = useState<number | undefined>(
    undefined
  );

  return (
    <PlanningAuthorityLayerTypeContext.Provider
      value={{
        displayByFilter: displayByFilter,
        setDisplayByFilter: setDisplayByFilter,
        localPlanAgeFilter: localPlanAgeFilter,
        setLocalPlanAgeFilter: setLocalPlanAgeFilter,
        housingLandSupplyRemainingFilter,
        setHousingLandSupplyRemainingFilter,
        housingDeliveryTestFilter: housingDeliveryTestFilter,
        setHousingDeliveryTestFilter: setHousingDeliveryTestFilter,
        constraintsStore,
        dispatch,
      }}
    >
      {children}
    </PlanningAuthorityLayerTypeContext.Provider>
  );
}
