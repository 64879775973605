import classNames from "classnames";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { Switch } from "react-migration/components/Switch";
import { TextInput } from "react-migration/components/TextInput";
import { Controller, useFormContext } from "react-hook-form";
import { SiteLetterImageUpload } from "./SiteLetterImageUpload";
import { updateIsUploadingSignature } from "src/js/stores/letter/actions";
import { Banner } from "react-migration/domains/sites/components/Banner";
import { useSnapshot } from "valtio";
import { labelClassNames } from "../style/labelClassNames";
import { TemplateFormData } from "../TemplateFormDataSchema";
import { letterStore } from "src/js/stores/letter/store";

interface SignatureBlockProps {
  id: string;
  page: number;
}

export const SignatureBlock = ({ id, page }: SignatureBlockProps) => {
  const { t } = useTranslation();
  const {
    isUploadingSignature,
    letter: { signaturePage },
  } = useSnapshot(letterStore);

  const enabled = page === signaturePage;

  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<TemplateFormData>();

  return (
    <div
      className={classNames("atlas-border-b", "atlas-border-border-divider", "atlas-px-3")}
      data-testid={`${id}-wrapper`}
    >
      <Controller
        render={({ field }) => (
          <div className={classNames("atlas-flex", "atlas-justify-between")}>
            <span
              className={classNames(
                "atlas-font-semibold",
                "atlas-text-sm",
                "atlas-leading-5",
                "atlas-mb-4",
                "atlas-pt-3.5"
              )}
            >
              {t("sites.letters.letter_form.signature_block")}
            </span>
            <Switch
              size="small"
              label={""}
              showLabel={false}
              id={id}
              onToggle={() => {
                field.onChange(field.value === page ? 0 : page);
              }}
              value={field.value === page}
            />
          </div>
        )}
        name={"signaturePage"}
        control={control}
      />

      {enabled && (
        <>
          <TextInput
            data-testid="signOff-input"
            label={t("sites.letters.letter_form.sign_off")}
            labelClassName={classNames(labelClassNames)}
            {...register("signOff")}
            error={(errors.signOff?.message && t(errors.signOff.message as string)) ?? undefined}
          />

          <div className="atlas-mb-8" data-testid="signature-image">
            {isUploadingSignature && (
              <Banner
                showIcon
                variant="warning"
                message={t("sites.letters.letter_form.please_wait_for_the_image", {
                  label: "signature",
                })}
                className="atlas-my-2"
              />
            )}
            <span className={classNames(labelClassNames)}>
              {t("sites.letters.letter_form.signature_image")}
            </span>
            <Controller
              render={({ field }) => (
                <SiteLetterImageUpload
                  field={field}
                  onUploadStateChange={updateIsUploadingSignature}
                  data-testid="signature-upload"
                />
              )}
              name={"signature"}
              control={control}
            />
            {Boolean(errors.signature?.message) && (
              <div className="atlas-flex atlas-flex-row-reverse atlas-mr-2">
                <span
                  data-testid="signature-error-message"
                  className="atlas-mt-1 atlas-text-red-700 atlas-text-xs"
                >
                  {t(errors.signature!.message as string)}
                </span>
              </div>
            )}
          </div>

          <TextInput
            data-testid="contact-name-input"
            label={t("sites.letters.letter_form.your_name")}
            labelClassName={classNames(labelClassNames)}
            {...register("contactName")}
            error={
              (errors.contactName?.message && t(errors.contactName.message as string)) ?? undefined
            }
          />

          <TextInput
            data-testid="contact-email-input"
            label={t("sites.letters.letter_form.email_address")}
            labelClassName={classNames(labelClassNames)}
            {...register("contactEmail")}
            error={
              (errors.contactEmail?.message && t(errors.contactEmail.message as string)) ??
              undefined
            }
          />

          <TextInput
            data-testid="contact-phone-input"
            label={t("sites.letters.letter_form.phone_number")}
            labelClassName={classNames(labelClassNames)}
            {...register("contactPhone")}
            error={
              (errors.contactPhone?.message && t(errors.contactPhone.message as string)) ??
              undefined
            }
          />
        </>
      )}
    </div>
  );
};
