import { useMemo } from "react";
import { Column } from "react-migration/components/Table";
import { Transaction } from "../../typings/TransactionsResponse";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { dateString, SupportedLocale } from "util/dates";
import { DescriptionCell } from "../components/DescriptionCell";
import { i18n } from "src/js/i18n";
import { formatPurchaseAmount, formatPurchaseType } from "../util/formatters";

export const useDocumentColumns = () => {
  const { t } = useTranslation();

  return useMemo<Column<Transaction>[]>(
    () => [
      {
        label: t("dashboard.transactions.date"),
        render: ({ created }) =>
          created ? dateString(created, i18n.locale as SupportedLocale) : "",
        grow: true,
      },
      {
        label: t("dashboard.transactions.user"),
        render: ({ user }) =>
          user?.firstname && user?.surname ? `${user.firstname} ${user.surname}` : "unknown user",
        grow: true,
      },
      {
        label: t("dashboard.transactions.type"),
        render: ({ purchase }) => formatPurchaseType(purchase),
        grow: true,
      },
      {
        label: t("dashboard.transactions.balance_difference"),
        render: (transaction) => formatPurchaseAmount(transaction),
        grow: true,
        align: "right",
      },
      {
        label: t("dashboard.transactions.description"),
        render: (transaction) => <DescriptionCell transaction={transaction} />,
        grow: true,
      },
    ],
    [t]
  );
};
