import { useQuery } from "@apollo/client";
import { LandTechEndpoints, routedClient } from "react-migration/lib/persistence/apollo";
import COMPARABLE_BY_TRANSACTION_ID from "../apollo/comparablesByTransactionIds.gql";
import { IComparablesTransactionDTO } from "react-migration/lib/typings/Comparables";
import { useMemo } from "react";

interface ComparableByTransactionIdHookArgs {
  transactionId: string;
}

export function useComparableByTransactionId({ transactionId }: ComparableByTransactionIdHookArgs) {
  const result = useQuery<{
    comparableByTransactionId: IComparablesTransactionDTO[];
  }>(COMPARABLE_BY_TRANSACTION_ID, {
    variables: { transaction_ids: [transactionId] },
    client: routedClient,
    context: {
      endpoint: LandTechEndpoints.Gateway,
    },
  });

  return useMemo(
    () => ({
      data: result.data?.comparableByTransactionId[0],
      loading: result.loading,
      error: result.error,
    }),
    [result.data, result.loading, result.error]
  );
}
