import { createContext, PropsWithChildren, useContext, useState } from "react";
import * as Accordion from "@radix-ui/react-accordion";
import clsx from "clsx";
import { Icon } from "react-migration/components/Icon";
import { Tooltip } from "react-migration/components/Tooltip";
import { useUpdateEffect } from "usehooks-ts";
import { Theme } from "react-migration/lib/theme/Theme";
import { LtIcons } from "@landtechnologies/icons/font/lt-icons";

const DEFAULT_OPEN_FINGERS: string[] = [];
const KitKatContext = createContext({ openFingers: DEFAULT_OPEN_FINGERS });

interface TagMiniStat {
  type: "TAG";
  value: string;
  color: "BLUE" | "YELLOW" | "GREEN" | "RED";
}

export type KitKatStat = TagMiniStat;

interface TagProps {
  tag: TagMiniStat;
}

function Tag({ tag }: TagProps) {
  return (
    <div
      className={clsx("atlas-text-xs atlas-px-1", {
        "atlas-rounded atlas-bg-statusGreen-200 atlas-text-statusGreen-700": tag.color === "GREEN",
        "atlas-rounded atlas-bg-platformBlue-200 atlas-text-platformBlue-700": tag.color === "BLUE",
        "atlas-rounded atlas-bg-statusAmber-200 atlas-text-statusAmber-700": tag.color === "YELLOW",
        "atlas-rounded atlas-bg-statusRed-200 atlas-text-statusRed-700": tag.color === "RED",
      })}
    >
      {tag.value}
    </div>
  );
}

interface FingerProps extends PropsWithChildren {
  id: string;
  /** LandTech Icon  */
  icon: `${LtIcons}`;
  /** Finger heading e.g. "Protected Land" */
  heading: string;
  /** Stats to appear in header */
  miniStats: KitKatStat[];
  tooltip?: string;
  trackExpandedChange?: (id: string, expanded: boolean) => void;
}

function Finger({
  id,
  heading,
  icon,
  miniStats,
  tooltip,
  children,
  trackExpandedChange,
}: FingerProps) {
  const { openFingers } = useContext(KitKatContext);
  const expanded = openFingers.includes(id);

  useUpdateEffect(() => {
    trackExpandedChange?.(id, expanded);
  }, [expanded, id, trackExpandedChange]);

  return (
    <Accordion.Item value={id} asChild>
      <div
        className={clsx("atlas-px-2 atlas-py-1 atlas-border atlas-rounded", {
          "atlas-bg-blueGrey-50 atlas-border-blueGrey-300": expanded,
          "atlas-border-transparent": !expanded,
        })}
      >
        <Accordion.Header asChild>
          <div className="atlas-flex atlas-justify-between atlas-items-center atlas-relative atlas-w-full">
            {!!children && <Accordion.Trigger className="atlas-absolute atlas-inset-0" />}
            <div className="atlas-flex atlas-gap-1 atlas-items-center atlas-text-blueGrey-950">
              <Icon icon={`icon-${icon}`} size="sm" />
              <h2 className="atlas-font-normal atlas-text-sm">{heading}</h2>
            </div>
            <div className="atlas-flex atlas-gap-2 atlas-items-center atlas-relative">
              {miniStats
                .map((stat, index) => {
                  return stat.type === "TAG" ? <Tag key={index} tag={stat} /> : null;
                })
                .filter(Boolean)}

              {tooltip && (
                <Tooltip.Wrapper ignoreClick>
                  <Tooltip.Content size="sm" side="bottom" align="end" theme={Theme.Light}>
                    <Tooltip.Description>{tooltip}</Tooltip.Description>
                  </Tooltip.Content>
                  <Tooltip.Trigger>
                    <i className="icon-lt-information-line" />
                  </Tooltip.Trigger>
                </Tooltip.Wrapper>
              )}
              {children && (
                <Accordion.Trigger asChild>
                  <i
                    className={clsx("icon-lt-arrow-down-s-line atlas-transition-transform", {
                      "atlas-rotate-180": expanded,
                    })}
                  />
                </Accordion.Trigger>
              )}
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Content>{children}</Accordion.Content>
      </div>
    </Accordion.Item>
  );
}

export function FingerLoading() {
  return (
    <div className="atlas-animate-pulse atlas-w-full">
      <div className="atlas-bg-blueGrey-200 atlas-h-6 atlas-w-full atlas-rounded" />
    </div>
  );
}

function Wrapper({ children }: PropsWithChildren) {
  const [openFingers, setOpenFingers] = useState<string[]>(DEFAULT_OPEN_FINGERS);

  return (
    <KitKatContext.Provider value={{ openFingers }}>
      <Accordion.Root
        value={openFingers}
        onValueChange={setOpenFingers}
        className="atlas-relative atlas-flex atlas-flex-col atlas-gap-2"
        type="multiple"
      >
        {children}
      </Accordion.Root>
    </KitKatContext.Provider>
  );
}

export const KitKat = {
  Finger,
  FingerLoading,
  Wrapper,
};
