import classNames from "classnames";
import { PropsWithChildren, SyntheticEvent, useRef } from "react";

import { useDropdownMenuContext } from "../../DropdownMenuProvider";
import { DropdownMenuTriggerAlignment } from "../DropdownMenuTrigger";

export type DropdownMenuSubTriggerProps = {
  className?: string;
  "data-testid"?: string;
};

export const DropdownMenuSubTrigger = ({
  className: additionalClasses,
  children,
  "data-testid": dataTestId,
}: PropsWithChildren<DropdownMenuSubTriggerProps>) => {
  const ref = useRef<HTMLDivElement>(null);
  const { isOpen, openMenu, closeMenu } = useDropdownMenuContext();

  const handleOnClick = (event: SyntheticEvent) => {
    event.stopPropagation();
    if (isOpen === true) {
      closeMenu();
    } else {
      // adding offset as the margin from the class altas-m-2 is ignored by the calculation
      openMenu(ref, DropdownMenuTriggerAlignment.RIGHT, 8);
    }
  };

  return (
    <div
      ref={ref}
      data-testid={dataTestId ?? "dropdown-menu-sub-trigger"}
      className={classNames(
        "atlas-rounded",
        "atlas-cursor-pointer",
        "atlas-p-2",
        "atlas-m-2",
        "hover:atlas-bg-neutral-100",
        "focus:atlas-bg-neutral-100",
        additionalClasses
      )}
      onClick={handleOnClick}
    >
      {children}
    </div>
  );
};
