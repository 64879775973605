import { Tooltip } from "react-migration/components/Tooltip";
import { Theme } from "react-migration/lib/theme/Theme";

export const InfoTooltip = ({
  content,
  tooltipContent,
  dataTestId,
}: {
  content: string;
  tooltipContent: string;
  dataTestId: string;
}) => {
  return (
    <div className="atlas-flex atlas-items-center atlas-gap-x-1" data-testid={dataTestId}>
      <span>{content}</span>
      <Tooltip.Wrapper>
        <Tooltip.Trigger
          className="atlas-pointer-events-auto"
          onClick={(event) => event.preventDefault()}
        >
          <i className="icon-lt-information-line atlas-text-lg atlas-cursor-pointer atlas-text-content-info" />
        </Tooltip.Trigger>
        <Tooltip.Content align="center" side="bottom" theme={Theme.Dark} size="sm">
          <span className="atlas-whitespace-normal">{tooltipContent}</span>
        </Tooltip.Content>
      </Tooltip.Wrapper>
    </div>
  );
};
