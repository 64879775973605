import { useCallback } from "react";
import { LayerTypeControlPageProps } from "../../types";
import { LPA_DISPLAY_BY, usePlanningAuthorityLayerTypeContext } from "./PlanningAuthorityContext";
import { NumericInput } from "react-migration/components/NumericInput";
import { Select } from "react-migration/components/Select";
import clsx from "clsx";
import { ConstraintLayerConfig } from "../ConstraintsLayerType";
import { useLayerStore } from "../ConstraintsLayerType/ConstraintsContext/store";
import { DesignationHatch } from "react-migration/domains/constraints/components/Hatch";

import { ModalV2 } from "react-migration/components/ModalV2/ModalV2";
import { GenericCategoryFilter } from "react-migration/layouts/map/Constraints/GenericCategoryFilter";
import { ConstraintsCategory } from "react-migration/layouts/map/Constraints/types";
import { useControlTracking } from "../../ControlTrackingContext";

export function PlanningAuthorityControlPage({ layer, disabled }: LayerTypeControlPageProps) {
  const { debouncedTrackControlChange } = useControlTracking();

  const {
    displayByFilter,
    localPlanAgeFilter,
    housingLandSupplyRemainingFilter,
    housingDeliveryTestFilter,
    setDisplayByFilter,
    setLocalPlanAgeFilter,
    setHousingLandSupplyRemainingFilter,
    setHousingDeliveryTestFilter,
    constraintsStore,
    dispatch,
  } = usePlanningAuthorityLayerTypeContext();

  const handleDisplayByChange = useCallback(
    (value: LPA_DISPLAY_BY) => {
      debouncedTrackControlChange({
        filterName: "displayBy",
        filterState: value,
      });
      setDisplayByFilter(value);
    },
    [setDisplayByFilter, debouncedTrackControlChange]
  );

  const handleLocalPlanAgeChange = useCallback(
    (localPlanAge: number) => {
      debouncedTrackControlChange({ filterName: "localPlanAge", filterState: localPlanAge });
      setLocalPlanAgeFilter(localPlanAge);
    },
    [setLocalPlanAgeFilter, debouncedTrackControlChange]
  );

  const handleHousingLandSupplyChange = useCallback(
    (housingLandSupply: number) => {
      debouncedTrackControlChange({
        filterName: "housingLandSupply",
        filterState: housingLandSupply,
      });
      setHousingLandSupplyRemainingFilter(housingLandSupply);
    },
    [setHousingLandSupplyRemainingFilter, debouncedTrackControlChange]
  );

  const handleHousingDeliveryTestChange = useCallback(
    (housingDeliveryTest: number) => {
      debouncedTrackControlChange({
        filterName: "housingDeliveryTest",
        filterState: housingDeliveryTest,
      });
      setHousingDeliveryTestFilter(housingDeliveryTest);
    },
    [setHousingDeliveryTestFilter, debouncedTrackControlChange]
  );

  const [layerState, layerActions, layerSelectors] = useLayerStore(layer.id, {
    constraintsStore,
    dispatch,
  });

  const keyModal = (
    <ModalV2.Root>
      <ModalV2.Trigger>
        <i className="icon-lt-question-line atlas-text-lg atlas-text-content-tertiary" />
      </ModalV2.Trigger>
      <ModalV2.Body title="Local Planning Authorities">
        <div className="atlas-flex atlas-flex-col atlas-between">
          <p>
            Insights into local planning authorities can help you target your development to an
            opportune area
          </p>
          <div className="atlas-pt-1">
            <p className="atlas-font-semibold">Boundaries</p>
            <p>Visualise a planning authority using its boundary</p>
          </div>
          <div className="atlas-pt-1 atlas-pb-2.5">
            <p className="atlas-font-semibold">Presumption in favour</p>
            <div className="atlas-flex atlas-pl-3 atlas-items-center">
              <div className="atlas-w-9 atlas-h-4 atlas-bg-[#0D9488]" />
              <div className="atlas-ml-2">Presumption in favour is likely to apply</div>
            </div>
            <div className="atlas-flex atlas-pl-3 atlas-items-center">
              <div className="atlas-w-9 atlas-h-4 atlas-bg-[#F97316]" />
              <div className="atlas-ml-2">Presumption in favour is unlikely to apply</div>
            </div>
            <div className="atlas-flex atlas-pl-3 atlas-items-center">
              <div className="atlas-w-9 atlas-h-4 atlas-bg-[#808080]" />
              <div className="atlas-ml-2">Presumption in favour status is unknown</div>
            </div>
          </div>
          <div className="atlas-pt-1">
            <p className="atlas-font-semibold">Call for sites</p>
            <div className="atlas-flex atlas-pl-3 atlas-items-center">
              <div className="atlas-w-9 atlas-h-4 atlas-bg-[#0D9488]" />
              <div className="atlas-ml-2">An active call for sites is published</div>
            </div>
          </div>
        </div>
      </ModalV2.Body>
    </ModalV2.Root>
  );

  const categorySchema = (layer.layerConfig as ConstraintLayerConfig).categorySchema;

  return (
    <>
      <div className="atlas-flex atlas-flex-col atlas-gap-1">
        <div
          className={clsx(
            "atlas-flex atlas-flex-row atlas-items-center atlas-gap-1 atlas-text-sm atlas-font-medium",
            {
              "atlas-opacity-50": disabled,
            }
          )}
        >
          <span>Display by</span>
          {keyModal}
        </div>
        <Select value={displayByFilter} disabled={disabled} onValueChange={handleDisplayByChange}>
          <Select.Option value={LPA_DISPLAY_BY.Boundaries}>Boundaries</Select.Option>
          <Select.Option value={LPA_DISPLAY_BY.PIF}>Presumption in favour</Select.Option>
          <Select.Option value={LPA_DISPLAY_BY.CallForSites}>Call for sites</Select.Option>
        </Select>
      </div>
      <GenericCategoryFilter
        id={ConstraintsCategory.LOCAL_PLANNING_AUTHORITY}
        disabled={disabled}
        categorySchema={categorySchema}
        visibleCategoryIds={layerState.visibleCategories}
        categoryTree={layerSelectors.getCategoryTree()}
        setVisibleCategories={layerActions.setVisibleCategories}
        Hatch={DesignationHatch}
      />
      <div
        className={clsx("atlas-text-base atlas-py-3", {
          "atlas-opacity-50": disabled,
        })}
      >
        Filters
      </div>
      <div className="atlas-flex atlas-flex-col atlas-gap-3">
        <div className="atlas-flex atlas-flex-col atlas-gap-1">
          <label
            htmlFor="local-plan-age"
            className={clsx("atlas-font-semibold", {
              "atlas-opacity-50": disabled,
            })}
          >
            Local plans older than
          </label>
          <div className="atlas-flex atlas-items-center atlas-gap-2">
            <NumericInput
              id="local-plan-age"
              className="atlas-max-w-[80px]"
              size="small"
              value={localPlanAgeFilter}
              minValue={0}
              maxValue={10}
              onChange={handleLocalPlanAgeChange}
              isDisabled={disabled}
              placeholder="any"
            />
            <div className="atlas-opacity-50">years</div>
          </div>
        </div>

        <div className="atlas-flex atlas-flex-col atlas-gap-1">
          <label
            htmlFor="housing-land-supply"
            className={clsx("atlas-font-semibold", {
              "atlas-opacity-50": disabled,
            })}
          >
            Housing land supply remaining
          </label>
          <div className="atlas-flex atlas-items-center atlas-gap-2">
            <NumericInput
              id="housing-land-supply"
              className="atlas-max-w-[80px]"
              size="small"
              value={housingLandSupplyRemainingFilter}
              minValue={0}
              maxValue={10}
              onChange={handleHousingLandSupplyChange}
              isDisabled={disabled}
              placeholder="any"
            />
            <div className="atlas-opacity-50">years or less</div>
          </div>
        </div>

        <div className="atlas-flex atlas-flex-col atlas-gap-1">
          <label
            htmlFor="housing-delivery-test"
            className={clsx("atlas-font-semibold", {
              "atlas-opacity-50": disabled,
            })}
          >
            Housing delivery test
          </label>
          <div className="atlas-flex atlas-items-center atlas-gap-2">
            <NumericInput
              id="housing-delivery-test"
              className="atlas-max-w-[80px]"
              size="small"
              value={housingDeliveryTestFilter}
              minValue={0}
              maxValue={1000}
              onChange={handleHousingDeliveryTestChange}
              isDisabled={disabled}
              placeholder="any"
            />
            <div className="atlas-opacity-50 atlas-pr-6">% or less</div>
          </div>
        </div>
      </div>
    </>
  );
}
