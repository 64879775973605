import { useEffect, useRef, useState } from "react";
import { Theme } from "react-migration/lib/theme/Theme";
import { twMerge } from "tailwind-merge";

export enum CheckboxState {
  CHECKED = "checked",
  INDETERMINATE = "indeterminate",
  EMPTY = "empty",
}

export interface CheckboxProps {
  value?: CheckboxState;
  onChange: (checked: boolean) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  id?: string;
  theme?: Theme;
  className?: string;
  "data-testid"?: string;
  controlled?: boolean;
}

/**
 * @deprecated Component is deprecated. Use `Checkbox` from `react-migration/components/Checkbox` instead.
 */
export const Checkbox = ({
  id,
  value,
  disabled = false,
  onChange,
  onClick,
  theme = Theme.Light,
  className,
  ["data-testid"]: testId = "checkbox",
  controlled = true,
}: CheckboxProps) => {
  const checkboxRef = useRef<HTMLInputElement>(null);
  const [checked, setChecked] = useState(value === CheckboxState.CHECKED);
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = () => {
    if (controlled) {
      onChange(value !== CheckboxState.CHECKED);
    } else {
      setChecked(!checked);
      onChange(!checked);
    }
  };

  useEffect(() => {
    if (!controlled) {
      setChecked(value === CheckboxState.CHECKED);
    }
  }, [value, controlled]);

  useEffect(() => {
    if (checkboxRef.current) {
      if (value === CheckboxState.CHECKED) {
        checkboxRef.current.checked = true;
        checkboxRef.current.indeterminate = false;
      } else if (value === CheckboxState.EMPTY) {
        checkboxRef.current.checked = false;
        checkboxRef.current.indeterminate = false;
      } else if (value === CheckboxState.INDETERMINATE) {
        checkboxRef.current.checked = false;
        checkboxRef.current.indeterminate = true;
      }
    }
  }, [value]);

  return (
    <>
      <input
        id={id}
        ref={checkboxRef}
        value={value}
        disabled={disabled}
        checked={controlled ? undefined : checked}
        type="checkbox"
        onClick={onClick}
        onChange={handleChange}
        data-testid={testId}
        className={twMerge(
          `atlas-w-4 atlas-h-4 atlas-border-2 atlas-rounded-lg atlas-flex-shrink-0`,
          disabled
            ? `atlas-text-content-disabled-${
                theme === Theme.Light ? "light" : "dark"
              } atlas-border-border-disabled-${theme === Theme.Light ? "light" : "dark"}`
            : "atlas-border-neutral-3 hover:atlas-border-neutral-4 atlas-accent-platform-700 hover:atlas-border-platform-800",
          className
        )}
      />
      <svg
        className=" atlas-absolute atlas-w-4 atlas-h-4 atlas-mt-1 atlas-hidden peer-checked:atlas-block atlas-pointer-events-none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polyline points="20 6 9 17 4 12"></polyline>
      </svg>
    </>
  );
};
