import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import clsx from "classnames";
import { MouseEventHandler, forwardRef } from "react";
import { Theme } from "react-migration/lib/theme/Theme";

enum DisabledTextContentColor {
  Light = "atlas-text-content-disabled-light",
  Dark = "atlas-text-content-disabled-dark",
}

enum DisabledBorderColor {
  Light = "atlas-border-border-disabled-light",
  Dark = "atlas-border-border-disabled-dark",
}

const DatePickerInput = forwardRef(
  (
    props: {
      onClick?: MouseEventHandler<HTMLElement>;
      value?: JSX.Element;
      placeholder?: JSX.Element;
      theme?: Theme;
      disabled?: boolean;
      labelClassName?: string;
      className?: string;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: any
  ) => {
    const theme = (props.theme as Theme) || Theme.Light;
    return (
      <div
        onClick={props.onClick}
        className={clsx(
          "atlas-flex atlas-items-center atlas-z-50",
          "atlas-border atlas-border-solid atlas-rounded-md",
          props.disabled
            ? `atlas-cursor-not-allowed ${DisabledBorderColor[theme]}`
            : "atlas-cursor-pointer atlas-border-neutral-300 hover:atlas-border-blue-600 atlas-justify-between",
          props.className
        )}
      >
        <label
          onClick={props.onClick}
          ref={ref}
          className={`${props.disabled ? DisabledTextContentColor[theme] : ""} atlas-px-1 ${
            props.labelClassName ? props.labelClassName : ""
          }`}
        >
          {props.value || props.placeholder}
        </label>
        <i
          className={`icon-lt-calendar-line atlas-text-base atlas-px-1 ${
            props.disabled ? DisabledTextContentColor[theme] : ""
          }`}
        />
      </div>
    );
  }
);

DatePickerInput.displayName = "DatePickerInput";

export { DatePicker, DatePickerInput };
