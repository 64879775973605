import { useMemo } from "react";
import * as SeparatorPrimitive from "@radix-ui/react-separator";
import clsx from "clsx";

export type DividerV2Props = SeparatorPrimitive.SeparatorProps;

export function DividerV2({
  orientation = "horizontal",
  decorative = true,
  className: classNameProps,
}: DividerV2Props) {
  const classNames = useMemo(
    () =>
      orientation === "vertical" ? "atlas-w-px atlas-min-h-full" : "atlas-h-px atlas-min-w-full",
    [orientation]
  );
  return (
    <SeparatorPrimitive.Root
      className={clsx("atlas-bg-neutral-300", classNames, classNameProps)}
      orientation={orientation}
      decorative={decorative}
    />
  );
}

export default DividerV2;
