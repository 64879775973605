/* eslint-disable @typescript-eslint/no-explicit-any */
import { MVTLayerProps } from "@deck.gl/geo-layers/dist/mvt-layer/mvt-layer";
import { MVTLayer, HexagonLayer, CompositeLayer } from "deck.gl";
import { ENVIRONMENT } from "src/js/util/environment";
import {
  getPosition,
  compsFeatureTypeGetters,
  memoizedDataFilter,
} from "../ComparablesLayer/comparablesLayerUtil";
import { MaskExtension } from "@deck.gl/extensions";
import Feature from "src/js/stores/user/Feature";
import { createWeakMemo } from "src/js/util/memoization";
import { always } from "ramda";
import { CorePriceModes } from "react-migration/domains/comparables/typings/PriceModes";
import { ComparableTile } from "react-migration/lib/typings/ComparablesTile";
import getPermissions from "src/js/stores/user/actions/getPermissions";
import hasBetaFeature from "src/js/stores/user/actions/hasBetaFeature";
// using Properties here as the tile information comes direct from the database
// and doesn't have enum values mapped by graphql or sit within an MVT
export interface ComparablesHeatMapLayerProps extends MVTLayerProps<ComparableTile> {
  minZoom: number;
  maxZoom: number;
  opacity?: number;
  onViewportLoaded?: () => void;
  onViewportLoading?: () => void;
  selection?: (GeoJSON.Point | GeoJSON.MultiPoint | undefined)[];
  filterFunctions: Array<(c: ComparableTile["properties"]) => boolean>;
  priceMode: CorePriceModes;
  distribution?: number[];
}

const emptyArray: number[] = [];
const always1 = always(1);

const removeEmptyValues = createWeakMemo(
  (data: ComparableTile[], getter: (x: ComparableTile) => unknown) => {
    return data?.filter(getter) || emptyArray;
  }
);

export class ComparablesHeatMapSubLayer extends CompositeLayer<ComparablesHeatMapLayerProps> {
  static componentName = "ComparablesHeatMapSubLayer";
  renderLayers() {
    const geofenceGeometries = getPermissions()?.geofencesGeometries;
    const maskExtensions = [] as MaskExtension[];
    let maskId: string | undefined;

    if (geofenceGeometries?.length && !hasBetaFeature(Feature.disableGeofence)) {
      maskExtensions.push(new MaskExtension());
      maskId = "Geofence";
    }
    const { priceMode, distribution, filterFunctions } = this.props.parameters as {
      priceMode: CorePriceModes;
      distribution: number[];
      filterFunctions: Array<(c: ComparableTile["properties"]) => boolean>;
    };
    const data = memoizedDataFilter(
      removeEmptyValues(
        this.props.data as unknown as ComparableTile[],
        compsFeatureTypeGetters[priceMode] ?? always1
      ),
      filterFunctions ?? emptyArray
    );
    return [
      new HexagonLayer<ComparableTile>({
        ...this.getSubLayerProps({
          id: "hex-heatmap",
        }),
        extensions: maskExtensions,
        maskId,
        radius: 36.95, //this.parent.parent.internalState.viewport.zoom > 16 ? 12 : 24,
        pickable: true,
        getPosition,
        // don't use distribution.length - 1 as it is the 100th percentile and skews the heatmap
        colorDomain: distribution && [distribution[0], distribution[distribution.length - 2]],
        // cap the limit at the 95th percentile (distribution.length - 2) otherwise properties above this
        // limit will not be included in the rendered data
        getColorWeight: (point: ComparableTile) =>
          Math.min(Number(point.properties[priceMode]), distribution[distribution.length - 2]),
        colorAggregation: "MEAN",
        data,
        updateTriggers: {
          getPosition: priceMode,
          getColorWeight: distribution && distribution.join(",") + priceMode,
          colorDomain: distribution && distribution.join(",") + priceMode,
        },
      }), // need to cast because MVTLayerProps is missing maskId),
    ];
  }
}

export class ComparablesHeatMapTileLayer extends CompositeLayer<ComparablesHeatMapLayerProps> {
  static componentName = "ComparablesHeatMapLayer";

  renderLayers() {
    const geofenceGeometries = getPermissions()?.geofencesGeometries;
    const maskExtensions = [] as MaskExtension[];
    let maskId: string | undefined;

    if (geofenceGeometries?.length && !hasBetaFeature(Feature.disableGeofence)) {
      maskExtensions.push(new MaskExtension());
      maskId = "Geofence";
    }
    const {
      minZoom,
      maxZoom,
      filterFunctions,
      onViewportLoaded,
      onViewportLoading,
      priceMode,
      distribution = emptyArray,
    } = this.props;
    return [
      new MVTLayer<ComparableTile>({
        ...this.getSubLayerProps(),
        extensions: maskExtensions,
        maskId,
        data: `${ENVIRONMENT.COMPARABLES_SERVICE_URL}/heatmap-tiles/{z}/{x}/{y}`,
        parameters: { depthTest: false, filterFunctions, priceMode, distribution },
        pickable: true,
        minZoom,
        maxZoom,
        maxRequests: 40,
        tileSize: 128,
        opacity: 0.8,
        binary: false,
        renderSubLayers(props) {
          if (!props.data) {
            return [];
          }

          return [
            // @ts-expect-error layer only used in beta atm
            new ComparablesHeatMapSubLayer({
              ...props,
              id: `${props.id}--price-heat-map`,
              data: props.data,
            }),
          ];
        },
        // these 2 props are a hack to allow us to set a global data-test-id to aid in e2e testing
        onViewportLoad: () => {
          onViewportLoaded?.();
        },
        fetch(url: string, config) {
          onViewportLoading?.();
          return config.layer.parent?.props.fetch(url, config);
        },
      }), // need to cast because MVTLayerProps is missing maskId
    ];
  }
}
