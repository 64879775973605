import { memo, useEffect, useState } from "react";

export type LoadingProps = {
  startColour?: string;
  finalColour?: string;
  relativeSizing?: boolean;
};

export const Loading = memo(
  ({
    startColour = "platform-100",
    finalColour = "platform-700",
    relativeSizing = false,
  }: LoadingProps) => {
    const [color, setColor] = useState(startColour);

    useEffect(() => {
      const timer = setInterval(() => {
        setColor(color === finalColour ? startColour : finalColour);
      }, 500);
      return () => {
        clearInterval(timer);
      };
    }, [color]);

    const dotSize = relativeSizing
      ? "atlas-w-[0.75em] atlas-h-[0.75em] atlas-p-[0.25em]"
      : "atlas-w-3 atlas-h-3 atlas-p-1";
    return (
      <div className={`atlas-justify-center atlas-flex`} data-testid="Loading">
        {/** classes have been added here so they are not tree shaken... */}
        <span className="atlas-sr-only atlas-bg-landInsight-800 atlas-bg-[#000000]">Loading</span>
        <div
          className={`atlas-bg-${color} atlas-block ${dotSize} atlas-rounded-full atlas-duration-100 atlas-transition-colors atlas-ease-in-out`}
          data-testid="loadingDot"
        ></div>
        <div
          className={`atlas-bg-${color} atlas-block ${dotSize} ${
            relativeSizing ? "atlas-mx-[0.25em]" : "atlas-mx-1"
          } atlas-rounded-full atlas-delay-100 atlas-duration-100 atlas-transition-colors atlas-ease-in-out`}
          data-testid="loadingDot"
        ></div>
        <div
          className={`atlas-bg-${color} atlas-block ${dotSize} atlas-rounded-full atlas-blue-circle atlas-delay-200 atlas-duration-100 atlas-transition-colors atlas-ease-in-out`}
          data-testid="loadingDot"
        ></div>
      </div>
    );
  }
);

Loading.displayName = "Loading";
