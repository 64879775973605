import { FC, useCallback, useEffect } from "react";
import { useSnapshot } from "valtio";
import { Button } from "react-migration/components/DeprecatedButton";
import { Section } from "react-migration/components/Section";
import { withSkeletonLoading } from "react-migration/components/SkeletonLoading";
import { refreshSiteGeometryStore } from "src/js/stores/siteGeometry/actions/refreshSiteGeometryStore";
import { mapStore } from "src/js/stores/map/store";
import { router } from "src/js/router/react-router";
import { GeometryMeasurements } from "react-migration/components/GeometryMeasurements";
import { SiteStageSelect } from "react-migration/domains/sites/components/SiteStageSelect";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useSiteById } from "react-migration/domains/sites/hooks/useSiteById";
import { logEventV2 } from "react-migration/lib/util/logEventV2";
import { ShareSiteButton } from "../ShareSiteButton/ShareSiteButton";
import { EditableSiteDescription } from "./EditableSiteDescription";
import { EditableSiteBoundary } from "./EditableSiteBoundary";
import { EditableSiteLabels } from "./EditableSiteLabels";
import { EditableSiteTitle } from "./EditableSiteTitle";
import { DeleteSiteButton } from "./DeleteSiteButton";
import { SavingIndicator, useSavingState } from "./SavingIndicator";
import { EditableSiteAssignees } from "./EditableSiteAssignees";

interface SiteDetailsProps {
  siteId: string;
  onDelete?(): void;
}

export const EditableSiteDetails: FC<SiteDetailsProps> = withSkeletonLoading<SiteDetailsProps>(
  ({ hideSkeletonLoading, siteId, onDelete }) => {
    const { t } = useTranslation();
    const { saving, startedSaving, stoppedSaving } = useSavingState();
    const { site, loading } = useSiteById(siteId);
    const { googleMap } = useSnapshot(mapStore);

    useEffect(() => {
      if (!loading) {
        hideSkeletonLoading();
      }
    }, [hideSkeletonLoading, loading]);

    const handleStopSaving = useCallback(
      ({
        source,
        action = "update",
        success,
      }: {
        source: string;
        action?: string;
        success: boolean;
      }) => {
        stoppedSaving();
        if (success) {
          logEventV2({
            name: "Workbench card click",
            properties: {
              cardName: "Site Details",
              clickDetail: source,
              action,
            },
          });
        }
      },
      [stoppedSaving]
    );

    const onSaveComplete = useCallback(
      (source: string, success = true) => {
        handleStopSaving({ source, success });
        googleMap && refreshSiteGeometryStore(googleMap);
      },
      [googleMap, handleStopSaving]
    );

    if (!site) return <div className="atlas-h-[220px]" />;

    return (
      <Section
        data-testid="site-details"
        bodyClasses="atlas-p-3 atlas-flex atlas-flex-col atlas-gap-6"
      >
        <div className="atlas-flex atlas-justify-between atlas-items-center">
          <h1 className="atlas-text-base atlas-font-extralight atlas-text-content-secondary">
            {t("sites.card.site_details.heading")}
          </h1>
          <div className="atlas-flex atlas-justify-end atlas-gap-2.5">
            <ShareSiteButton siteId={siteId} title={t("sidebar.site.share")} />
            <Button.Secondary
              size="small"
              onClick={() => {
                router.push({ name: "sites", query: { site_id: siteId } });
              }}
              className="atlas-hidden md:atlas-inline-flex"
            >
              {t("sidebar.site.view_site")}
            </Button.Secondary>
            <DeleteSiteButton siteId={siteId} onDelete={onDelete} />
          </div>
        </div>
        <div>
          <div className="atlas-divide-y atlas-divide-neutral-200">
            <div className="atlas-pb-3">
              <GeometryMeasurements geometry={site.geometry} />
            </div>
            <div className="atlas-relative atlas-flex atlas-pt-3 atlas-flex-col atlas-gap-3 atlas-text-xs">
              <div className="atlas-flex atlas-gap-3">
                <label className="atlas-text-content-secondary atlas-font-semibold atlas-w-20 atlas-flex-shrink-0 atlas-leading-8">
                  {t("sites.card.site_card.boundary")}
                </label>
                <EditableSiteBoundary
                  site={site}
                  startedSaving={startedSaving}
                  stoppedSaving={onSaveComplete}
                />
              </div>
              <div className="atlas-flex atlas-gap-3">
                <label className="atlas-text-content-secondary atlas-font-semibold atlas-w-20 atlas-flex-shrink-0 atlas-leading-8">
                  {t("sites.card.site_card.title")}
                </label>
                <EditableSiteTitle
                  site={site}
                  startedSaving={startedSaving}
                  stoppedSaving={onSaveComplete}
                />
              </div>
              <div className="atlas-flex atlas-gap-3">
                <label className="atlas-text-content-secondary atlas-font-semibold atlas-w-20 atlas-flex-shrink-0 atlas-leading-8">
                  {t("sites.card.site_card.stage")}
                </label>
                <SiteStageSelect
                  site={site}
                  triggerType={SiteStageSelect.TriggerType.Select}
                  onStartedSaving={startedSaving}
                  onError={() => handleStopSaving({ source: "Stage", success: false })}
                  onCompleted={() => onSaveComplete("Stage", true)}
                />
              </div>
              <div className="atlas-flex atlas-gap-3">
                <label className="atlas-text-content-secondary atlas-font-semibold atlas-w-20 atlas-flex-shrink-0 atlas-leading-8">
                  {t("sites.card.site_card.description")}
                </label>
                <EditableSiteDescription
                  site={site}
                  startedSaving={startedSaving}
                  stoppedSaving={handleStopSaving}
                />
              </div>
              <div className="atlas-flex atlas-justify-between atlas-gap-2">
                <div className="atlas-flex atlas-flex-wrap atlas-flex-row atlas-gap-2 atlas-items-center">
                  <EditableSiteLabels
                    site={site}
                    startedSaving={startedSaving}
                    stoppedSaving={handleStopSaving}
                  />
                </div>
                <div className="atlas-flex atlas-items-center">
                  <EditableSiteAssignees
                    site={site}
                    truncateCount={5}
                    startedSaving={startedSaving}
                    stoppedSaving={handleStopSaving}
                  />
                </div>
              </div>
              <SavingIndicator saving={saving} />
            </div>
          </div>
        </div>
      </Section>
    );
  },
  { rows: 3 }
);
