import PortalVue from "portal-vue";
import { drawingStore } from "./js/stores/drawing/store";
import { mapStore } from "./js/stores/map/store";
import { osMapPrintStore } from "./js/stores/osMapPrint/store";
import { siteGeometryStore } from "./js/stores/siteGeometry/store";
import { navigationStore } from "./js/stores/navigation/store";
import { userStore } from "./js/stores/user/store";
import { siteStore } from "./js/stores/site/store";
import { letterStore } from "./js/stores/letter/store";
import { planningStore } from "./js/stores/planning/store";
import { searchStore } from "./js/stores/search/store";
import { siteFiltersStore } from "react-migration/domains/sites/pipeline/filters/SiteFiltersStore/store";

import Vue from "vue";
import VueApollo from "vue-apollo";
import VueLocalStorage from "vue-localstorage";
import VueOnlinePlugin from "vue-navigator-online";
import VueCookies from "vue-cookies";
import VueRouter from "vue-router";
import { VuePlugin as Vuera } from "@landtechnologies/vuera";
import App from "./js/App.vue";
import { router } from "./js/router/router";
import ROUTES from "./js/router/routes";
import { initialiseApolloProvider } from "./js/util/apollo";
import { initialiseUser } from "./js/util/user";
import { ENVIRONMENT, PRIVATE_APP } from "./js/util/environment";
import setupTestHelpers from "./js/setupTestHelpers";
import "./js/util/stripe"; // side effect so loaded after page load https://stripe.com/docs/disputes/prevention/advanced-fraud-detection
import "./index.css";
import { i18n } from "./js/i18n";
import deckPlugin from "./js/plugins/deck.gl";
import valtioPlugin from "./js/plugins/valtio";
import { Store } from "./js/plugins/valtio/store";
import { sitesPipelineStore } from "./react-migration/domains/sites/pipeline/store/store";
import { initialiseMemoryUsageTracking } from "./js/util/trackMemoryUsage";

initialiseMemoryUsageTracking();

Vue.use(VueRouter);
Vue.use(VueApollo);
Vue.use(PortalVue);
Vue.use(VueLocalStorage, { bind: true });
Vue.use(VueOnlinePlugin); // Provides the "isOnline" property on all components
Vue.use(VueCookies);
Vue.use(deckPlugin);
// We can either pass the stores directly to the plugin or as a stores object like Vue eg. :
// Vue.use(valtioPlugin, {
//   mapStore,
//   drawingStore,
//   siteGeometryStore,
//   userStore,
//   osMapPrintStore,
// });
Vue.use(valtioPlugin);
Vue.use(Vuera);

// this imported function is a no-op when running in non-e2e test environment
setupTestHelpers(router, mapStore);

const apolloProvider = initialiseApolloProvider({
  apiUrl: ENVIRONMENT.API_URL,
  graphqlGatewayUrl: ENVIRONMENT.GRAPHQL_GATEWAY_URL,
  constraintsApiUrl: ENVIRONMENT.CONSTRAINTS_API_URL,
  loginUrl: ENVIRONMENT.LOGIN_URL,
  appEnv: ENVIRONMENT.APP_ENV,
  accountsServicePrivateApiUrl: ENVIRONMENT.ACCOUNTS_SERVICE_PRIVATE_API_URL,
  planningServiceUrl: ENVIRONMENT.PLANNING_SERVICE_V2_URL_EXTERNAL,
});

if (PRIVATE_APP) {
  await initialiseUser(apolloProvider.clients.accountServicePrivateApi);
}

const app = new Vue({
  i18n,
  router,
  el: "#app",
  components: { App },
  apolloProvider,
  beforeCreate: function () {
    window.A = this;
  },
  // @ts-expect-error - currently in js
  store: new Store({
    mapStore,
    drawingStore,
    siteGeometryStore,
    userStore,
    osMapPrintStore,
    siteStore,
    planningStore,
    letterStore,
    sitesPipelineStore,
    searchStore,
    siteFiltersStore,
    navigationStore,
  }),
  provide: function () {
    return {
      routes: ROUTES,
    };
  },
  render(createElement) {
    return createElement("app");
  },
});

setTimeout(async () => {
  const sw = await import(
    /* webpackChunkName: 'serviceworker-window' */
    /* webpackPrefetch: true */
    // @ts-expect-error yes - we do want to import a .ts extension - it is compiled by webpack....  shutup typescript interpreter
    "./js/service-worker-window.ts"
  );
  sw.init();
}, 20000);
window.APP = app; // for debug purposes
