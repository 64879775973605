import { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { Deck, LayersList } from "deck.gl";
import { centerOfMass } from "@turf/turf";
import { debounce } from "lodash";
import classNames from "classnames";
import buffer from "@turf/buffer";
import { geoJsonPolyToGoogleBounds, getZoomLevelForBounds } from "src/js/util/map_util";
import { SelectionFeature } from "src/js/stores/map/store";
import { ENVIRONMENT } from "src/js/util/environment";
import { SelectionLayer } from "react-migration/layouts/map/Multilayer/Bundle/useSelectionMapLayer";
import { RenderQueueActionType, useRenderQueue } from "../RenderQueueContext";
import { DeckStaticMapLayersLoadNotifierWidget } from "./DeckStaticMapLayersLoadNotifierWidget";

const { GOOGLE_MAPS_API_KEY } = ENVIRONMENT;

export interface StaticMapWithLayersProps {
  id: string;
  center: {
    lat: number;
    lng: number;
  };
  zoom: number;
  height: number;
  width: number;
  // mapType?: "roadmap" | "satellite" | "hybrid" | "terrain";
  mapType?: string;
  layers?: LayersList;
  onReady?: () => void;
}

export const StaticMap = ({
  id,
  center,
  zoom,
  height,
  width,
  layers,
  onReady,
  mapType = "satellite",
}: StaticMapWithLayersProps) => {
  const { isRendering, dispatch } = useRenderQueue();
  const [deck, setDeck] = useState<Deck | null>(null);
  const [deckImage, setDeckImage] = useState<string | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [deckRendered, setDeckRendered] = useReducer(() => true, false);
  const [layersLoaded, setLayersLoaded] = useState(false);

  const onAfterRender = useMemo(() => {
    return debounce(() => {
      setDeckRendered();
    }, 3000);
  }, []);

  useEffect(() => {
    return () => {
      onAfterRender.cancel();
    };
  }, [onAfterRender]);

  useEffect(() => {
    return () => {
      if (deck) {
        deck.finalize();
        setDeck(null);
      }
    };
  }, [deck]);

  const shouldRenderDeckGL = !!layers;

  const lat = +center.lat.toFixed(6);
  const lng = +center.lng.toFixed(6);

  const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=${zoom}&size=${width}x${height}&key=${GOOGLE_MAPS_API_KEY}&maptype=${mapType}`;

  // revoking the URL on unmount
  // to make sure all links to WebGL context are removed and nothing stops GC from removing the canvas and its context
  useEffect(() => {
    return () => {
      if (deckImage) {
        URL.revokeObjectURL(deckImage);
      }
    };
  }, [deckImage, id]);

  useEffect(() => {
    shouldRenderDeckGL && dispatch({ type: RenderQueueActionType.ADD_TO_QUEUE, id });
  }, [dispatch, id, shouldRenderDeckGL]);

  useEffect(() => {
    if (!deck && containerRef.current && isRendering(id)) {
      const newDeck = new Deck({
        parent: containerRef.current,
        initialViewState: {
          longitude: center.lng,
          latitude: center.lat,
          zoom: zoom - 1,
          pitch: 0,
          bearing: 0,
        },
        style: {
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
        },
        controller: null,
        layers,
        onAfterRender,
        widgets: [new DeckStaticMapLayersLoadNotifierWidget(id, () => setLayersLoaded(true))],
      });
      setDeck(newDeck);
    }
  }, [center.lat, center.lng, deck, id, isRendering, layers, onAfterRender, zoom]);

  useEffect(() => {
    if (isRendering(id) && deckRendered && deck) {
      deck.redraw("redraw before converting to image");

      const deckCanvas = deck.getCanvas();

      if (deckCanvas && layersLoaded) {
        const img = deckCanvas.toDataURL("image/png");
        setDeckImage(img);
        deck.finalize();
        setDeck(null);
        dispatch({ type: RenderQueueActionType.FINISH_RENDERING, id });
        onReady?.();
      }
    }
  }, [deck, deckRendered, dispatch, id, isRendering, onReady, layersLoaded]);

  return (
    <div style={{ position: "relative", width, height }}>
      <div
        className={classNames("atlas-relative", "atlas-h-full", "atlas-w-full")}
        ref={containerRef}
      />
      <img
        src={mapUrl}
        alt="Static Map"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      />
      {deckImage && (
        <img
          src={deckImage}
          alt="DeckGL overlay"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        />
      )}
    </div>
  );
};

const EMPTY_LAYER_LIST: LayersList = [];

type StaticMapWithSelectionProps = Omit<
  StaticMapWithLayersProps,
  "center" | "zoom" | "width" | "height"
> & {
  selection: SelectionFeature;
  width?: number;
  height?: number;
  selectionBufferMeters?: number;
};

export const StaticMapWithSelection = ({
  selection,
  width = 280,
  height = 280,
  layers = EMPTY_LAYER_LIST,
  selectionBufferMeters = 60,
  ...rest
}: StaticMapWithSelectionProps) => {
  const selectionGeometry = selection.feature?.geometry;

  const bounds = useMemo(() => {
    if (!selectionGeometry) return null;

    return geoJsonPolyToGoogleBounds(
      buffer(selectionGeometry, selectionBufferMeters, { units: "meters" }).geometry
    );
  }, [selectionGeometry, selectionBufferMeters]);

  const center = useMemo(() => {
    if (!selectionGeometry) return null;

    const center = centerOfMass(selectionGeometry);

    return { lat: center.geometry.coordinates[1], lng: center.geometry.coordinates[0] };
  }, [selectionGeometry]);

  const zoom = useMemo(
    () => bounds && getZoomLevelForBounds(bounds, { width, height }),
    [bounds, height, width]
  );

  const layersWithSelection = useMemo(() => {
    return [...layers, new SelectionLayer({ selection })];
  }, [layers, selection]);

  if (!bounds || !center || !zoom) return null;

  return (
    <StaticMap
      layers={layersWithSelection}
      center={center}
      height={height}
      width={width}
      zoom={zoom}
      {...rest}
    />
  );
};
