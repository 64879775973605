import { useMemo } from "react";
import { Loading } from "react-migration/components/Loading";
import { SelectionFeature } from "src/js/stores/map/store";
import { PlanningApplication } from "react-migration/domains/planning/types";
import { usePlanningApplicationsSummaryByGeometry } from "../SelectionSidebar/usePlanningApplicationsSummaryByGeometry";
import { formatSummary } from "./util/formatSummary";
import { SummaryError } from "./components/SummaryError";
import { Summary } from "./components/Summary";

type PlanningApplicationsSummaryType = {
  selection: SelectionFeature;
  referenceApplications: PlanningApplication[];
  setDetailSelection: (selection: SelectionFeature) => void;
};

export const PlanningApplicationsSummary = ({
  selection,
  referenceApplications,
  setDetailSelection,
}: PlanningApplicationsSummaryType) => {
  const { data, loading } = usePlanningApplicationsSummaryByGeometry(
    selection?.feature?.geometry ?? null,
    true
  );

  const formattedContent = useMemo(
    () => formatSummary({ data, referenceApplications }),
    [data, referenceApplications]
  );

  if (loading) {
    return (
      <div className="atlas-p-6">
        <Loading />
      </div>
    );
  }

  return (
    <div className="atlas-relative atlas-p-2">
      {!loading && formattedContent.length ? (
        <Summary
          content={formattedContent}
          selection={selection}
          setDetailSelection={setDetailSelection}
        />
      ) : (
        <SummaryError />
      )}
    </div>
  );
};
