import { useCallback, useEffect, useMemo, useReducer } from "react";
import { useAssessmentTabs } from "./useAssessmentTabs";
import { Layer } from "../map/Multilayer/types";
import { SelectionFeature, SelectionType } from "src/js/stores/map/store";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { tabTranslationKey } from "./utils";
import { useSelectionHandler } from "../map/Multilayer/selection_types/useSelectionHandler";
import { useSiteReportLogo } from "./useSiteReportLogo";
import { SiteOptions } from "./siteOptions";
import { useSiteById } from "react-migration/domains/sites/hooks/useSiteById";
import { RenderQueueProvider } from "react-migration/domains/sites/card/Map/RenderQueueContext";
import { useCheckLayerAccess } from "../map/Multilayer/Bundle/useLayerAccess";

function useFilteredAssessmentTabs(activeAssessmentLayers: string[]): Record<string, Layer[]> {
  const assessmentTabs = useAssessmentTabs();
  const checkLayerAccess = useCheckLayerAccess();

  return useMemo(
    () =>
      Object.fromEntries(
        assessmentTabs
          .map((tabData) => [
            tabData.key,
            tabData.layers.filter(
              (layer) => activeAssessmentLayers.includes(layer.id) && checkLayerAccess(layer)
            ),
          ])
          .filter(([, layers]) => layers.length > 0)
      ),
    [activeAssessmentLayers, assessmentTabs, checkLayerAccess]
  );
}

interface SiteReportContentProps {
  activeAssessmentLayers: string[];
  siteOptions: string[];
  selection: SelectionFeature;
  onReportLoaded(hasLoaded: boolean): void;
}

export type LayerState = "loaded" | "unmounted";
const loadedLayersReducer = (state: number, action: LayerState): number => {
  switch (action) {
    case "loaded":
      return state + 1;
    case "unmounted":
      return state - 1;
  }
};

/**
 * Component that renders configured site report content.
 * Used in both the preview & by the print wrapper.
 */
export function SiteReportContent({
  activeAssessmentLayers,
  siteOptions,
  selection,
  onReportLoaded,
}: SiteReportContentProps) {
  const { t } = useTranslation();
  const { logo } = useSiteReportLogo(selection.id!);

  const SelectionHandler = useSelectionHandler(selection);

  const filteredAssessmentTabs = useFilteredAssessmentTabs(activeAssessmentLayers);
  const [summaryLoaded, setSummaryLoaded] = useReducer(() => true, false);

  const [loadedLayersCount, updateLayerLoadingStatus] = useReducer(loadedLayersReducer, 0);

  useEffect(() => {
    const hasSummaryLoaded = !SelectionHandler.SelectionPrintableSummary || summaryLoaded;

    const totalLayers = Object.values(filteredAssessmentTabs).flat().length;
    const hasAllLayersLoaded = loadedLayersCount === totalLayers;

    onReportLoaded(hasAllLayersLoaded && hasSummaryLoaded);
  }, [
    SelectionHandler.SelectionPrintableSummary,
    filteredAssessmentTabs,
    loadedLayersCount,
    onReportLoaded,
    summaryLoaded,
  ]);

  return (
    <RenderQueueProvider>
      <div className="[&>div]:atlas-mb-4 [&>div:last-child]:atlas-mb-0">
        {logo && (
          <div
            data-testid="report-logo"
            className="atlas-flex atlas-w-full atlas-mt-4 atlas-mb-4 atlas-justify-center"
          >
            <img
              id="output"
              alt="logo"
              placeholder="Upload Logo"
              className="atlas-h-40 atlas-mb-4"
              src={logo.data}
            />
          </div>
        )}
        {SelectionHandler.SelectionPrintableSummary && (
          <SelectionHandler.SelectionPrintableSummary
            selection={selection}
            onLoaded={setSummaryLoaded}
          />
        )}

        {isSiteSelection(selection) && siteOptions.includes(SiteOptions.SITE_DESCRIPTION) && (
          <SiteDescription selection={selection} />
        )}

        {Object.entries(filteredAssessmentTabs)
          .filter(([, layers]) => !!layers.length)
          .map(([tab, layers]) => (
            <div
              className="atlas-hidden has-[div]:atlas-block [&>div]:atlas-mb-4 [&>div:last-child]:atlas-mb-0 print:atlas-break-inside-avoid-page"
              key={tab}
            >
              <h2 className="atlas-mb-2">{t(tabTranslationKey(tab))}</h2>

              {layers.map((layer) => (
                <SiteReportLayer
                  key={layer.id}
                  layer={layer}
                  selection={selection}
                  onLayerStateChange={updateLayerLoadingStatus}
                />
              ))}
            </div>
          ))}
      </div>
    </RenderQueueProvider>
  );
}

function isSiteSelection(selection: SelectionFeature) {
  return selection.type === SelectionType.SITE;
}

interface SiteDescriptionProps {
  selection: SelectionFeature;
}

function SiteDescription({ selection }: SiteDescriptionProps) {
  const { site } = useSiteById(selection.id);

  if (!site?.notes) return null;

  return (
    <div>
      <h3>Site Description</h3>
      <p>{site?.notes ? site.notes : "No Description"}</p>
    </div>
  );
}

type SiteReportLayerProps = Readonly<{
  layer: Layer;
  selection: SelectionFeature;
  onLayerStateChange: (status: LayerState) => void;
}>;

function SiteReportLayer({ layer, selection, onLayerStateChange }: SiteReportLayerProps) {
  const Printable = layer.layerType.Printable;

  const onLoaded = useCallback(() => {
    onLayerStateChange("loaded");
  }, [onLayerStateChange]);

  const onUnmount = useCallback(() => {
    onLayerStateChange("unmounted");
  }, [onLayerStateChange]);

  if (!Printable) return null;

  return (
    <section className="[&:empty]:atlas-hidden">
      <Printable selection={selection} layer={layer} onLoaded={onLoaded} onUnmount={onUnmount} />
    </section>
  );
}
