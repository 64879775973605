import * as Popover from "@radix-ui/react-popover";
import { ComponentProps, PropsWithChildren } from "react";
import { Icon } from "react-migration/components/Icon";

interface InfoPopoverProps extends PropsWithChildren {
  popoverSide?: "top" | "bottom" | "left" | "right";
  size?: ComponentProps<typeof Icon>["size"];
}

export function InfoPopover({ children, size = "sm", popoverSide = "top" }: InfoPopoverProps) {
  return (
    <Popover.Root>
      <Popover.Trigger className="atlas-text-neutral-400 hover:atlas-text-neutral-500">
        <Icon icon="icon-lt-information-line" size={size} cursor="pointer" />
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          side={popoverSide}
          sideOffset={4}
          className="atlas-shadow-md atlas-rounded atlas-text-xs atlas-text-left atlas-px-5 atlas-py-3 atlas-bg-neutral-700 atlas-text-white atlas-max-w-xs"
        >
          <Popover.Arrow className="atlas-fill-neutral-700" />
          {children}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
