import { FC } from "react";
import { SkeletonLoading } from "react-migration/components/SkeletonLoading";

export const LoadingPanel: FC = () => {
  return (
    <div
      className="atlas-relative atlas-h-80 atlas-bg-white atlas-border-8 atlas-border-white"
      data-testid="ownership-selection-header-loading"
    >
      <SkeletonLoading rows={3} showPadding={false} />
    </div>
  );
};
