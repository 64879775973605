import { InfoList } from "react-migration/components/InfoList";
import { HTMLProps } from "react";

import { MeasurementSystem } from "src/js/stores/user/store";

import { PerUnitPriceModes } from "../../../typings/PriceModes";
import { HistoricTransactionTimelineItem } from "./HistoricTransactionTimelineItem";
import { formatDate } from "react-migration/domains/comparables/util/Dates";
import { formattedPrice } from "react-migration/domains/comparables/util/Prices";

export type LastValueSource =
  | "Land Registry"
  | "Registers of Scotland"
  | "Asking Price"
  | "Valuation"
  // "" is for the US version
  | "";

export interface TransactionProps extends HTMLProps<HTMLDivElement> {
  currency: string;
  date: string;
  first: boolean;
  last: boolean;
  source: LastValueSource;
  value: number;
}
export const HistoricTransaction = ({
  currency,
  date,
  first,
  last,
  source,
  value,
}: TransactionProps) => {
  return (
    <InfoList.ItemValue
      className="atlas-flex atlas-items-center atlas-py-1 atlas-text-content-tertiary"
      data-testid="historic-transaction"
    >
      <div className="atlas-flex atlas-items-center atlas-w-28">
        <HistoricTransactionTimelineItem first={first} last={last} />
        <div className="atlas-mr-2">{date ? formatDate(date) : ""}</div>
      </div>
      <div className="atlas-text-xs atlas-text-content-secondary atlas-flex-grow atlas-w-32 atlas-pl-3 atlas-text-nowrap">
        {source}
      </div>
      <div className="atlas-text-content-secondary atlas-font-semibold">
        {formattedPrice({
          priceMode: PerUnitPriceModes.PRICE,
          price: value,
          currency,
          // as we only display unit price we can hard code these two cost/area values
          area: 0,
          unitPreference: MeasurementSystem.METRIC,
        })}
      </div>
    </InfoList.ItemValue>
  );
};
