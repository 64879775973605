import clsx from "clsx";

import { Switch } from "react-migration/components/Switch";

type FilterToggleProps = {
  name: string;
  prefix?: string;
  color: string;
  enabled: boolean;
  setEnabled: () => void;
  disabled?: boolean;
};
export const FilterToggle = ({
  name,
  prefix,
  color,
  enabled,
  setEnabled,
  disabled,
}: FilterToggleProps) => (
  <div className="atlas-flex atlas-justify-between">
    <div className="atlas-flex atlas-items-center atlas-space-x-2">
      <div
        style={{ backgroundColor: color }}
        className={`atlas-flex atlas-items-center atlas-justify-center atlas-rounded-full atlas-p-2 atlas-text-white atlas-h-6 atlas-w-6`}
      >
        <div>{name.substring(0, 1).toUpperCase()}</div>
      </div>
      <div className={clsx("atlas-font-bold", { "atlas-opacity-50": disabled })}>{name}</div>
    </div>
    <Switch
      id={`${prefix ?? "planning"}-${name}`}
      label={name}
      disabled={disabled}
      onToggle={setEnabled}
      value={enabled}
    />
  </div>
);
