import clsx from "clsx";
import { Theme } from "react-migration/lib/theme/Theme";

type SwitchSize = "small" | "normal";
type LabelSide = "left" | "right";

function borderAndBackgrounds(
  backgroundClass: string,
  disabled?: boolean,
  value?: unknown,
  theme: Theme = Theme.Light
) {
  if (disabled) {
    return `${backgroundClass} atlas-bg-opacity-50`;
  }

  if (value) {
    return "atlas-bg-background-action-alt";
  }

  return backgroundClass;
}

export function Switch({
  disabled,
  label,
  id,
  onToggle,
  showLabel,
  value = false,
  size = "normal",
  theme = Theme.Light,
  labelSide = "right",
  backgroundClass = "atlas-bg-background-inverse-light",
  disabledTextContentClass = `atlas-text-content-disabled-${
    theme === Theme.Light ? "light" : "dark"
  }`,
  showLabelClass = "atlas-text-xs atlas-mr-3",
  ...rest
}: {
  disabled?: boolean;
  label: string;
  id: string;
  onToggle: (e: React.ChangeEvent<HTMLInputElement>) => void;
  showLabel?: boolean;
  value?: boolean;
  size?: SwitchSize;
  theme?: Theme;
  labelSide?: LabelSide;
  backgroundClass?: string;
  disabledTextContentClass?: string;
  showLabelClass?: string;
}) {
  return (
    <div
      className={clsx("switch-wrapper", "atlas-flex", "atlas-items-center", "atlas-justify-center")}
      data-testid={`${id}-switch`}
      {...rest}
    >
      <label
        htmlFor={id}
        className={clsx(
          "atlas-flex",
          "atlas-items-center",
          "atlas-cursor-pointer",
          disabled ? disabledTextContentClass : "",
          labelSide === "left" ? "atlas-flex-row-reverse" : ""
        )}
        onClick={(e) => {
          e.stopPropagation();
        }}
        data-testid="switch-check"
      >
        <div className="atlas-relative">
          <input
            data-testid={`${id}-switch-input`}
            type="checkbox"
            id={id}
            onChange={onToggle}
            disabled={disabled}
            checked={value}
            className="atlas-h-[1px] atlas-w-0 atlas-m-[-1px] atlas-overflow-hidden atlas-absolute"
            aria-checked={value}
          />
          <div
            className={clsx(
              "atlas-block",
              size === "small" ? "atlas-w-8" : "atlas-w-10",
              size === "small" ? "atlas-h-5" : "atlas-h-6",
              "atlas-rounded-full",
              borderAndBackgrounds(backgroundClass, disabled, value, theme)
            )}
          />
          <div
            className={clsx(
              "atlas-dot",
              "atlas-absolute",
              size === "small" ? "atlas-left-0.5" : "atlas-left-1",
              size === "small" ? "atlas-top-0.5" : "atlas-top-1",
              "atlas-bg-white",
              "atlas-w-4",
              "atlas-h-4",
              "atlas-rounded-full",
              "atlas-transition",
              value && (size === "small" ? "atlas-translate-x-3" : "atlas-translate-x-4"),
              disabled ? "atlas-bg-opacity-50" : ""
            )}
            data-testid="switch-dot"
          />
        </div>
        <div
          className={
            showLabel
              ? showLabelClass
              : "atlas-h-[1px] atlas-w-0 atlas-m-[-1px] atlas-overflow-hidden atlas-absolute"
          }
          data-testid="switch-label"
        >
          {label}
        </div>
      </label>
    </div>
  );
}
