import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { TextInputV2 } from "react-migration/components/TextInputV2";

import { updateBatchName } from "../../store/actions/updateBatchName";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useBatchName } from "../../store/hooks/useBatchName";
import { useParcels } from "../../store/hooks/useParcels";
import { useSelectedParcelIds } from "../../store/hooks/useSelectedParcelIds";
import { useTotalSiteCount } from "../../store/hooks/useTotalSiteCount";
import logSiteCardEvent from "src/js/view/sites/logSiteCardEvent";

export const SkipTraceOrderTableHeader = () => {
  const { t } = useTranslation();

  const storedOrderName = useBatchName();
  const parcels = useParcels();
  const selectedParcelIds = useSelectedParcelIds();
  const totalSiteCount = useTotalSiteCount();

  const [orderName, setOrderName] = useState(storedOrderName);

  useEffect(() => {
    setOrderName(storedOrderName);
  }, [storedOrderName]);

  const selectedParcelsCount = useMemo(() => {
    return Object.keys(selectedParcelIds).filter((key) => selectedParcelIds[key]).length;
  }, [selectedParcelIds]);

  const orderNameChangeHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setOrderName(e.target.value);
  }, []);

  const orderNameInputBlurHandler = useCallback(() => {
    updateBatchName(orderName);
    logSiteCardEvent({ name: "Skip Trace - Batch Sites Table Named" });
  }, [orderName]);

  return (
    <div className="atlas-flex-row atlas-p-4" data-testid="skip-trace-order-table-header">
      <div className="atlas-flex atlas-flex-col atlas-gap-2">
        <div className="atlas-w-80">
          <TextInputV2
            dataTestId="skip-trace-name-input"
            placeholder={t("sites.skip_tracing.order_table.enter_batch_name")}
            IconLeft={<EditIcon />}
            value={orderName}
            size="medium"
            onChange={orderNameChangeHandler}
            onBlur={orderNameInputBlurHandler}
          />
        </div>
        <span
          className="atlas-flex atlas-text-xs"
          data-testid="skip-trace-order-table-header-subtitile"
        >
          <span>
            {t("sites.skip_tracing.order_table_header.info_subtitle_parcels", {
              selectedParcelsCount,
              totalParcelsCount: parcels.length,
            })}
          </span>{" "}
          •{" "}
          {t("sites.skip_tracing.order_table_header.info_subtitle_site_count", {
            totalSiteCount,
          })}
        </span>
      </div>
    </div>
  );
};

const EditIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="edit-line" clipPath="url(#clip0_3757_15105)">
        <path
          id="Icon"
          d="M4.276 10.6674L11.0373 3.90604L10.0947 2.96337L3.33333 9.72471V10.6674H4.276ZM4.82867 12.0007H2V9.17204L9.62333 1.54871C9.74835 1.42373 9.91789 1.35352 10.0947 1.35352C10.2714 1.35352 10.441 1.42373 10.566 1.54871L12.452 3.43471C12.577 3.55973 12.6472 3.72926 12.6472 3.90604C12.6472 4.08282 12.577 4.25236 12.452 4.37737L4.82867 12.0007ZM2 13.334H14V14.6674H2V13.334Z"
          fill="#1F2937"
        />
      </g>
      <defs>
        <clipPath id="clip0_3757_15105">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
