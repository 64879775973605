import {
  DropdownMenuItem,
  DropdownMenuItemLink,
  DropdownMenuItemText,
} from "./components/DropdownMenuItem";
import {
  DropdownMenuTrigger,
  DropdownMenuTriggerAlignment,
} from "./components/DropdownMenuTrigger";

import { DropDownMenuArrow } from "./components/DropDownMenuArrow";
import { DropdownMenuContent } from "./components/DropdownMenuContent";
import { DropdownMenuProvider } from "./DropdownMenuProvider";
import { DropdownMenuSeparator } from "./components/DropdownMenuSeparator";
import { DropdownMenuSubTrigger } from "./components/DropdownMenuSubTrigger";

export const DropdownMenu = {
  Provider: DropdownMenuProvider,
  Content: DropdownMenuContent,
  Item: DropdownMenuItem,
  ItemLink: DropdownMenuItemLink,
  ItemText: DropdownMenuItemText,
  Separator: DropdownMenuSeparator,
  Trigger: DropdownMenuTrigger,
  SubTrigger: DropdownMenuSubTrigger,
  TriggerAlignment: DropdownMenuTriggerAlignment,
  Arrow: DropDownMenuArrow,
};

export { useDropdownMenuContext } from "./DropdownMenuProvider";

export default DropdownMenu;
