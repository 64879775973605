import area from "@turf/area";
import { SelectionFeature } from "src/js/stores/map/store";
import { MAX_AREA_CONSTRAINT_HECTARES } from "./constants";

export function useMaxAreaSelectedElevationConstraint(selection: SelectionFeature) {
  // When updating this ensure to update the constant in the BE lambda to match

  const MAX_AREA_CONSTRAINT_METERS = MAX_AREA_CONSTRAINT_HECTARES * 10000;
  const selectionGeometry = selection.feature?.geometry;
  const selectionFeatureArea = selectionGeometry ? area(selectionGeometry) : 0;

  const exceedsMaximumAreaConstraint = selectionFeatureArea > MAX_AREA_CONSTRAINT_METERS;

  return { exceedsMaximumAreaConstraint };
}
