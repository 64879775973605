import {
  PropertyCategoryGQL,
  PropertyOwnerTypeGQL,
  PropertySubCategoryGQL,
} from "react-migration/lib/typings/Comparables";
import { ZooplaComparableDTO } from "react-migration/lib/typings/Zoopla";
import { RecordType } from "react-migration/domains/comparables/typings/Record";
import { ftsqToMsq } from "src/js/util/units_and_constants";
import {
  ZooplaPriceModes,
  ZooplaPricePerAreaModes,
  ZooplaPricePerUnitModes,
} from "../typings/PriceModes";

const getPriceFromZoopla = (z: ZooplaComparableDTO, priceMode: ZooplaPriceModes) => {
  if (
    priceMode === ZooplaPricePerUnitModes.RENT_ASKING ||
    priceMode === ZooplaPricePerAreaModes.RENT_ASKING ||
    priceMode === ZooplaPricePerUnitModes.RENT_ESTIMATE ||
    priceMode === ZooplaPricePerAreaModes.RENT_ESTIMATE
  ) {
    return z?.valuation?.rent?.lastValue ?? 0;
  } else {
    return z?.valuation?.sale?.lastValue ?? 0;
  }
};

const getEstPriceFromZoopla = (z: ZooplaComparableDTO, priceMode: ZooplaPriceModes) => {
  if (
    priceMode === ZooplaPricePerUnitModes.RENT_ASKING ||
    priceMode === ZooplaPricePerAreaModes.RENT_ASKING ||
    priceMode === ZooplaPricePerUnitModes.RENT_ESTIMATE ||
    priceMode === ZooplaPricePerAreaModes.RENT_ESTIMATE
  ) {
    return z?.valuation?.rent?.currentValue ?? 0;
  } else {
    return z?.valuation?.sale?.currentValue ?? 0;
  }
};

type formatZooplaRecordsAsComparables = {
  results: ZooplaComparableDTO[];
  priceMode: ZooplaPriceModes;
};

export const formatZooplaRecordAsLTComp = ({
  results,
  priceMode,
}: formatZooplaRecordsAsComparables): RecordType[] => {
  return results.map((z: ZooplaComparableDTO) => {
    const a = z.location?.address ?? {};
    const comp: RecordType = {
      uprn: String(z.uprn),
      address: `${a.propertyNumberOrName} ${a.streetName}, ${a.townOrCity} ${a.postcode}`,
      category: z.attributes.propertyType as unknown as PropertyCategoryGQL,
      country: a.countryCode ?? "GB",
      currency: "GBP",
      date_of_transfer: "",
      designation: "Residential" as const,
      distance_from_center: null,
      est_bedrooms: null,
      estimated_bedrooms_max: null,
      estimated_bedrooms_min: null,
      est_price: getEstPriceFromZoopla(z, priceMode),
      est_price_per_square_metre: null,
      geometry: z.location.coordinates,
      is_new_property: false,
      num_bedrooms: null,
      number_habitable_rooms: null,
      owner_type: "unknown" as PropertyOwnerTypeGQL,
      post_code: a.postcode,
      prev_transactions:
        z.valuation?.sale?.history?.map(({ value, valueDate, valueType, valueSource }) => ({
          price: value,
          uprn: z.uprn,
          date_of_transfer: valueDate,
          transaction_id: `${z.uprn}-${valueDate}-${valueType}-${valueSource}`,
          property_id: z.uprn,
          currency: "GBP",
        })) ?? null,
      price: getPriceFromZoopla(z, priceMode),
      price_per_square_metre: null,
      region: a.locality ?? "",
      sub_category: z.attributes.propertySubType as unknown as PropertySubCategoryGQL,
      tenure: z.attributes.tenure === "Freehold" ? ("F" as const) : ("L" as const),
      total_floor_area: ftsqToMsq(z?.attributes?.floorArea ?? 0),
      town: a.townOrCity,
      transaction_id: String(z.uprn),
    };
    return comp;
  });
};
