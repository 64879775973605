import { Theme } from "react-migration/lib/theme/Theme";
import { RangeFilter } from "../RangeFilter";

import { FC } from "react";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

export interface FilterBedroomsProps {
  disabled?: boolean;
  min?: number;
  setMin: (value: number) => void;
  max?: number;
  setMax: (value: number) => void;
  theme?: Theme;
}

export const FilterBedrooms: FC<FilterBedroomsProps> = ({
  disabled,
  min,
  setMin,
  max,
  setMax,
  theme,
}) => {
  const { t } = useTranslation();

  return (
    <RangeFilter
      disabled={disabled}
      label={t("comparables.map.sidebar.filters.filters.bedrooms.label")}
      min={min}
      setMin={setMin}
      max={max}
      setMax={setMax}
      data-testid="bedrooms-filter"
      theme={theme}
    />
  );
};
