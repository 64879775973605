import { Button } from "react-migration/components/DeprecatedButton";
import classNames from "classnames";

import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import * as React from "react";
import { FileRejection, useDropzone } from "react-dropzone";

export interface ImageUploadHandle {
  clear: () => void;
}

interface ImageUploadProps {
  "data-testid"?: string;
  file?: File;
  onUpload: (file?: File) => void;
}

interface Dimensions {
  height: number;
  width: number;
}

const ImageUploadComponent: React.ForwardRefRenderFunction<ImageUploadHandle, ImageUploadProps> = (
  { onUpload, "data-testid": dataTestId, file },
  forwardedRef
) => {
  const preview = useRef<string>("");
  const [{ height, width }, setDimensions] = useState<Dimensions>({ height: 0, width: 0 });
  const revokePreview = useCallback(() => {
    if (preview.current) {
      URL.revokeObjectURL(preview.current);
    }
    preview.current = "";
  }, [preview]);

  const processFile = useCallback((file: File, isNew?: boolean) => {
    preview.current = URL.createObjectURL(file);
    const image = new Image();
    image.src = preview.current;
    image.onload = (event) => {
      const { height, width } = event.target as HTMLImageElement;
      setDimensions({ height, width });
    };
    if (isNew) {
      onUpload(file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [rejectedFile, setRejectedFile] = useState<FileRejection>();
  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    noClick: false,
    noKeyboard: true,
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
    },
    multiple: false,
    onDrop: ([acceptedFile], [fileRejection]) => {
      revokePreview();
      if (fileRejection) {
        setRejectedFile(fileRejection);
      } else if (acceptedFile) {
        processFile(acceptedFile, true);
      }
    },
  });

  useEffect(() => {
    revokePreview();
    if (file) {
      processFile(file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  useEffect(() => {
    return () => {
      // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
      revokePreview();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearDropzone = useCallback(() => {
    setRejectedFile(undefined);
    onUpload(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(forwardedRef, () => ({
    clear() {
      clearDropzone();
    },
  }));

  const containerBorderClassName = useMemo(() => {
    if (isDragAccept || isDragReject) {
      return "atlas-border-blue-700";
    }
    if (rejectedFile) {
      return "atlas-border-red-500";
    }
    return "atlas-border-border-divider-subtle";
  }, [isDragAccept, isDragReject, rejectedFile]);

  const containerClassName = useMemo(
    () =>
      classNames(
        containerBorderClassName,
        "atlas-bg-white",
        "atlas-rounded-xl",
        "atlas-flex",
        "atlas-flex-col",
        "atlas-items-stretch",
        "atlas-justify-center",
        "atlas-p-4",
        isDragAccept || isDragReject ? "atlas-border-2" : "atlas-border atlas-m-px",
        isDragAccept || isDragReject ? "atlas-h-[148px]" : "atlas-h-[146px]"
      ),
    [isDragAccept, isDragReject, containerBorderClassName]
  );

  const iconClassName = useMemo(
    () =>
      classNames(
        "atlas-h-14",
        "atlas-w-14",
        "atlas-flex",
        "atlas-items-center",
        "atlas-justify-center",
        "atlas-border-8",
        "atlas-rounded-full",
        "atlas-mb-1",
        rejectedFile ? "atlas-border-border-negative-subtle" : "atlas-border-background-light",
        rejectedFile ? "atlas-bg-[#F87171]" : "atlas-bg-background-dark"
      ),
    [rejectedFile]
  );

  const fileUploadedClassName = classNames("atlas-flex", "atlas-flex-row");

  const previewContainerClassName = classNames(
    "atlas-h-[72px]",
    "atlas-w-[72px]",
    "atlas-flex-none",
    "atlas-flex",
    "atlas-align-center",
    "atlas-border",
    "atlas-border-border-divider-subtle",
    "atlas-rounded"
  );

  const previewClassName = classNames("atlas-h-auto", "atlas-w-full", "atlas-object-contain");

  const previewTextClassName = classNames(
    "atlas-ml-5",
    "atlas-flex",
    "atlas-justify-start",
    "atlas-flex-col"
  );

  const emptyStateClassName = classNames("atlas-flex", "atlas-flex-col", "atlas-items-center");

  const onClickUpload = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
  };

  const renderContent = () => {
    if (file) {
      return (
        <div className={fileUploadedClassName}>
          <div className={previewContainerClassName}>
            <img className={previewClassName} src={preview.current} />
          </div>
          <div className={previewTextClassName}>
            <p className={classNames("atlas-text-sm", "atlas-m-0", "atlas-font-semibold")}>
              {file.name}
            </p>
            <p className={classNames("atlas-text-sm", "atlas-m-0")}>{height + "x" + width}</p>
            <p
              className={classNames(
                "atlas-text-sm",
                "atlas-text-content-link",
                "atlas-no-underline",
                "atlas-cursor-pointer",
                "hover:atlas-text-content-link-hover"
              )}
            >
              <span onClick={clearDropzone}>Replace</span>
            </p>
          </div>
        </div>
      );
    }
    if (rejectedFile) {
      return (
        <>
          <div className={classNames(iconClassName)}>
            <i className="icon-lt-error-warning-line atlas-text-xl atlas-text-white" />
          </div>
          <p className={classNames("atlas-text-sm", "atlas-text-red-500")}>
            File size is too large
          </p>
        </>
      );
    }
    return (
      <div className={emptyStateClassName}>
        <div className={iconClassName}>
          <i className="icon-lt-upload-cloud-2-line atlas-text-xl" />
        </div>
        <p className={classNames("atlas-text-sm", "atlas-m-0")}>
          <Button.Borderless className="!atlas-px-1" onClick={(e) => onClickUpload(e)}>
            Click to upload
          </Button.Borderless>
          or drag and drop
        </p>
        <p className={classNames("atlas-text-xs", "atlas-m-0")}>PNG or JPG (max. 500x500px)</p>
      </div>
    );
  };

  return (
    <div
      {...getRootProps({
        className: containerClassName,
      })}
      data-testid={dataTestId || "image-upload"}
    >
      <input {...getInputProps()} data-testid="image-upload-input" />
      {renderContent()}
    </div>
  );
};

export const ImageUpload = forwardRef(ImageUploadComponent);
