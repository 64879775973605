import { useCallback, useEffect, useState } from "react";
import { useBetaFeatureFlag } from "react-migration/lib/user/useFeatureFlag";
import Feature from "src/js/stores/user/Feature";
import { LayerTypePrintableProps } from "../../../types";
import { useMaxAreaSelectedElevationConstraint } from "../useMaxAreaSelectedElevationConstraint";
import { ElevationSummary } from "../TopographySelectionSidebar";
import { useTopographyStatsQuery } from "../useTopographyStatsQuery";
import { SlopeSummary } from "../SlopeSummary";
import { HillshadeContourMapLayerPrintable } from "./HillshadeContourMapLayerPrintable";
import { SlopeMapLayerPrintable } from "./SlopeMapLayerPrintable";
import { useTopographyPrintData } from "./useTopographyPrintData";
import { AspectSummary } from "../AspectSummary";
import { AspectMapLayerPrintable } from "./AspectMapLayerPrintable";

enum Sections {
  Elevation = "Elevation",
  Slope = "Slope",
  SlopeMap = "SlopeMap",
  Aspect = "Aspect",
  AspectMap = "AspectMap",
  HillshadeContour = "HillshadeContour",

  LoadingSections = "LoadingSections",
}

export function TopographyPrintable(props: LayerTypePrintableProps) {
  const { selection, onLoaded, onUnmount } = props;

  const { exceedsMaximumAreaConstraint } = useMaxAreaSelectedElevationConstraint(selection);
  const hasTopographyV2Beta = useBetaFeatureFlag(Feature.topographyV2);
  const hasTopographyV3Beta = useBetaFeatureFlag(Feature.topographyV3);

  const selectionIsWithinAreaConstraint = !exceedsMaximumAreaConstraint;

  const { loading: loadingSections, sections } = useTopographyPrintData(props);

  const [sectionsLoaded, setSectionsLoaded] = useState<Sections[]>([]);

  const markAsLoaded = useCallback((...section: Sections[]) => {
    section.forEach((s) => {
      setSectionsLoaded((sections) => {
        if (sections.includes(s)) {
          return sections;
        }
        return [...sections, s];
      });
    });
  }, []);

  const {
    data: topographyData,
    error,
    loading,
  } = useTopographyStatsQuery(selection.feature?.geometry, {
    selectionIsWithinAreaConstraint,
    // We use a useEffect in the above hook, so we want to avoid an infinite loop,
    // thus need some referential integrity (without returns a new array every time)
    // We could just not pass in stillToLoad as a dep but we'll be good code citizens
    onLoaded: useCallback(() => {
      markAsLoaded(Sections.Elevation);
    }, [markAsLoaded]),
  });

  useEffect(() => {
    const allLoaded = sections.every((s) => sectionsLoaded.includes(s));
    if (!loadingSections && allLoaded) {
      onLoaded();
    }
  }, [sectionsLoaded, sections, loadingSections, onLoaded]);

  useEffect(() => {
    if (!topographyData?.elevation && !loading) {
      markAsLoaded(Sections.Slope, Sections.SlopeMap);
    }
  }, [topographyData, loading, markAsLoaded]);

  useEffect(() => {
    return () => {
      onUnmount();
    };
  }, [onUnmount]);

  const selectionGeometryType = selection.feature?.geometry?.type;
  const selectionSupportsSlopeAnalysis =
    selectionGeometryType === "Polygon" || selectionGeometryType === "MultiPolygon";

  return (
    <section className="atlas-flex atlas-flex-col atlas-gap-y-2 atlas-pointer-events-none">
      <h3>Topography</h3>

      <h4>Overview</h4>
      <div className="atlas-flex atlas-gap-2 atlas-justify-between">
        {!exceedsMaximumAreaConstraint && !!topographyData?.elevation && !error && (
          <div className="atlas-flex-auto">
            <ElevationSummary
              selection={selection}
              data={topographyData}
              gridProps={{ columns: 1 }}
            />
          </div>
        )}

        <div>
          <HillshadeContourMapLayerPrintable
            selection={selection}
            onLoaded={() => markAsLoaded(Sections.HillshadeContour)}
          />
        </div>
      </div>

      {topographyData?.elevation &&
        selectionIsWithinAreaConstraint &&
        selectionSupportsSlopeAnalysis &&
        hasTopographyV2Beta && (
          <>
            <h4>Slope</h4>
            <div className="atlas-flex atlas-gap-2 atlas-justify-between">
              <div className="atlas-flex atlas-flex-1 atlas-flex-col atlas-gap-y-6">
                <SlopeSummary selection={selection} onLoaded={() => markAsLoaded(Sections.Slope)} />
              </div>

              <div className="atlas-flex">
                <SlopeMapLayerPrintable
                  selection={selection}
                  onLoaded={() => markAsLoaded(Sections.SlopeMap)}
                />
              </div>
            </div>

            {hasTopographyV3Beta && (
              <>
                <h4>Aspect</h4>
                <div className="atlas-flex atlas-gap-2 atlas-justify-between">
                  <div className="atlas-flex atlas-flex-1 atlas-flex-col atlas-gap-y-6">
                    <AspectSummary
                      hideChart
                      selection={selection}
                      onLoaded={() => markAsLoaded(Sections.Aspect)}
                    />
                  </div>

                  <div className="atlas-flex">
                    <AspectMapLayerPrintable
                      selection={selection}
                      onLoaded={() => markAsLoaded(Sections.AspectMap)}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
    </section>
  );
}
