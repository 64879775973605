import { DropdownItem } from "react-migration/components/Dropdown";
import { useState } from "react";
import saveUser from "src/js/stores/user/actions/saveUser";
import { updateNotificationsAlertPeriod } from "src/js/stores/user/actions/updateSetting";
import { userStore } from "src/js/stores/user/store";
import { useSnapshot } from "valtio";

export const useUpdateEmailFrequency = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);

  const { user } = useSnapshot(userStore);
  const [emailFrequency, setEmailFrequency] = useState(user.alert_notifications.alert_period);

  const handleOpenFormClick = () => {
    setIsFormOpen(true);
  };

  const handleCloseForm = async () => {
    setIsFormOpen(false);
    updateNotificationsAlertPeriod(emailFrequency);
    await saveUser();
  };
  const handleDropdownChange = (item: DropdownItem) => {
    setEmailFrequency(Number(item.value));
  };

  return {
    isFormOpen,
    handleOpenFormClick,
    handleCloseForm,
    handleDropdownChange,
    emailFrequency: String(emailFrequency),
    alertPeriod: user.alert_notifications?.alert_period,
  };
};
