export const VoltageClassDescription = () => (
  <div className="atlas-flex atlas-flex-col atlas-gap-y-4">
    <div>
      <div className="atlas-font-semibold">Transmission:</div>
      <div>Lines and cables with a voltage level over 132kV.</div>
    </div>

    <div>
      <div className="atlas-font-semibold">Extra High Voltage (EHV):</div>
      <div>Lines and cables with a voltage level between 33kV and 132kV.</div>
    </div>

    <div>
      <div className="atlas-font-semibold">High Voltage (HV):</div>
      <div>Lines and cables with a voltage level of 1kV or greater and less than 33kV.</div>
    </div>

    <div>
      <div className="atlas-font-semibold">Low Voltage (LV)</div>
      <div>Lines and cables with a voltage level of under 1kV.</div>
    </div>

    <div className="atlas-italic atlas-text-neutral-700">
      * These categories reflect the broad categories of voltages in the network. Specific voltages,
      where available, are recorded in the feature attributes. In some cases no voltage data is
      available and records that fall into this group are classified as <b>Unknown</b>.
    </div>
  </div>
);
