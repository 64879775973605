import { z } from "zod";

const pointSchema = z.object({
  type: z.literal("Point"),
  coordinates: z.tuple([z.number(), z.number()]),
});

export const topographyStatsSchema = z.object({
  elevation: z
    .object({
      maxMeters: z.number(),
      minMeters: z.number(),
      maxPoint: pointSchema,
      minPoint: pointSchema,
    })
    .nullish(),
});
