/*
        This is a generated type file made by our GraphQL code generator
        This file will change as you update your backend schema or any queries and mutations you have in your service

        Please commit and check in changes to these files

        If you have a merge conflicts:
        - Pull in the changes
        - Fix conflicts with the main codebase
        - Rerun the codegen
      */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  CoordinatesScalar: { input: any; output: any; }
  Date: { input: any; output: any; }
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
};

export type AdditionalTransaction = {
  __typename?: 'AdditionalTransaction';
  currency?: Maybe<Scalars['String']['output']>;
  date_of_transfer: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  property_id: Scalars['String']['output'];
  transaction_id: Scalars['String']['output'];
  uprn?: Maybe<Scalars['String']['output']>;
};

export type BoundingBoxGeoFilterInput = {
  bottom_right: CoordinatePairInput;
  top_left: CoordinatePairInput;
};

export type CategoryGroup = {
  __typename?: 'CategoryGroup';
  name: Scalars['String']['output'];
  sub_categories?: Maybe<Array<SubcategoryGroup>>;
};

export type Comparable = {
  __typename?: 'Comparable';
  _id: Scalars['ID']['output'];
  address?: Maybe<Scalars['String']['output']>;
  category: PropertyCategoryEnum;
  country?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  date_of_transfer?: Maybe<Scalars['Date']['output']>;
  designation: PropertyDesignationEnum;
  distance_from_center?: Maybe<Scalars['Float']['output']>;
  est_bedrooms?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  est_price?: Maybe<Scalars['Float']['output']>;
  est_price_per_square_metre?: Maybe<Scalars['Float']['output']>;
  estimated_bedrooms_max?: Maybe<Scalars['Int']['output']>;
  estimated_bedrooms_min?: Maybe<Scalars['Int']['output']>;
  geometry?: Maybe<GeoJsonGeometry>;
  is_new_property?: Maybe<Scalars['Boolean']['output']>;
  num_bedrooms?: Maybe<Scalars['Int']['output']>;
  number_habitable_rooms?: Maybe<Scalars['Int']['output']>;
  owner_type?: Maybe<PropertyOwnerTypeEnum>;
  post_code?: Maybe<Scalars['String']['output']>;
  prev_transactions?: Maybe<Array<AdditionalTransaction>>;
  price?: Maybe<Scalars['Int']['output']>;
  price_per_square_metre?: Maybe<Scalars['Float']['output']>;
  property_id: Scalars['String']['output'];
  region?: Maybe<Scalars['String']['output']>;
  rentalValuation?: Maybe<Valuation>;
  sub_category: PropertySubCategoryEnum;
  tenure?: Maybe<Tenure>;
  total_floor_area?: Maybe<Scalars['Float']['output']>;
  town?: Maybe<Scalars['String']['output']>;
  transaction_id: Scalars['ID']['output'];
  uprn?: Maybe<Scalars['String']['output']>;
};

export enum ComparableAggregationField {
  EstPrice = 'est_price',
  EstPricePerSquareMetre = 'est_price_per_square_metre',
  Price = 'price',
  PricePerSquareMetre = 'price_per_square_metre'
}

export type ComparableConnection = {
  __typename?: 'ComparableConnection';
  edges: Array<ComparableEdge>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars['Int']['output'];
};

export type ComparableDistributionSearchResult = {
  __typename?: 'ComparableDistributionSearchResult';
  p0?: Maybe<Scalars['Float']['output']>;
  p5?: Maybe<Scalars['Float']['output']>;
  p10?: Maybe<Scalars['Float']['output']>;
  p15?: Maybe<Scalars['Float']['output']>;
  p20?: Maybe<Scalars['Float']['output']>;
  p25?: Maybe<Scalars['Float']['output']>;
  p30?: Maybe<Scalars['Float']['output']>;
  p35?: Maybe<Scalars['Float']['output']>;
  p40?: Maybe<Scalars['Float']['output']>;
  p45?: Maybe<Scalars['Float']['output']>;
  p50?: Maybe<Scalars['Float']['output']>;
  p55?: Maybe<Scalars['Float']['output']>;
  p60?: Maybe<Scalars['Float']['output']>;
  p65?: Maybe<Scalars['Float']['output']>;
  p70?: Maybe<Scalars['Float']['output']>;
  p75?: Maybe<Scalars['Float']['output']>;
  p80?: Maybe<Scalars['Float']['output']>;
  p85?: Maybe<Scalars['Float']['output']>;
  p90?: Maybe<Scalars['Float']['output']>;
  p95?: Maybe<Scalars['Float']['output']>;
  p100?: Maybe<Scalars['Float']['output']>;
  totalCount: Scalars['Int']['output'];
};

export type ComparableEdge = {
  __typename?: 'ComparableEdge';
  _id: Scalars['String']['output'];
  cursor: Scalars['String']['output'];
  node: Comparable;
};

export type ComparableSearchInput = {
  bedrooms?: InputMaybe<FloatRangeInput>;
  bounding_box_geo_filter?: InputMaybe<BoundingBoxGeoFilterInput>;
  categories?: InputMaybe<Array<PropertyCategoryEnum>>;
  dcs_conditions?: InputMaybe<Array<DcsConditionInput>>;
  designations?: InputMaybe<Array<PropertyDesignationEnum>>;
  is_new_property?: InputMaybe<Scalars['Boolean']['input']>;
  poly_geo_filter?: InputMaybe<PolygonGeoFilterInput>;
  radius_geo_filter?: InputMaybe<RadiusGeoFilterInput>;
  sold_date_range?: InputMaybe<DateRangeInput>;
  sub_categories?: InputMaybe<Array<PropertySubCategoryEnum>>;
  tenure?: InputMaybe<Tenure>;
  total_floor_area_range?: InputMaybe<FloatRangeInput>;
  transaction_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  year_built?: InputMaybe<FloatRangeInput>;
};

export type CoordinatePairInput = {
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
};

export type CsvSearchExport = {
  __typename?: 'CsvSearchExport';
  adopted_constraints?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  agricultural_land_classifications?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  area_building_metric?: Maybe<Scalars['Float']['output']>;
  area_floor_total_metric?: Maybe<Scalars['Float']['output']>;
  area_gross_metric?: Maybe<Scalars['Float']['output']>;
  area_lot_metric?: Maybe<Scalars['Float']['output']>;
  area_lot_width_metric?: Maybe<Scalars['Float']['output']>;
  coverage?: Maybe<Scalars['Float']['output']>;
  date_proprietor_added?: Maybe<Scalars['String']['output']>;
  date_sold?: Maybe<Scalars['String']['output']>;
  emerging_constraints?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  floor_area_ratio?: Maybe<Scalars['Float']['output']>;
  flum?: Maybe<Scalars['String']['output']>;
  full_address?: Maybe<Scalars['String']['output']>;
  geometry?: Maybe<GeoJsonGeometry>;
  geometry_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  is_vacant?: Maybe<Scalars['String']['output']>;
  last_ownership_change?: Maybe<Scalars['String']['output']>;
  last_sale_value?: Maybe<Scalars['Int']['output']>;
  lease_end_date?: Maybe<Scalars['String']['output']>;
  lease_start_date?: Maybe<Scalars['String']['output']>;
  ownership_type?: Maybe<Scalars['String']['output']>;
  poly_id?: Maybe<Scalars['String']['output']>;
  property_address?: Maybe<Scalars['String']['output']>;
  proprietor_name?: Maybe<Scalars['String']['output']>;
  tenure?: Maybe<Scalars['String']['output']>;
  title_numbers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  uprn?: Maybe<Scalars['String']['output']>;
  zoning?: Maybe<Scalars['String']['output']>;
};

export type DcsCondition = {
  __typename?: 'DCSCondition';
  category?: Maybe<PropertyCategoryEnum>;
  designation?: Maybe<PropertyDesignationEnum>;
  sub_category?: Maybe<PropertyCategoryEnum>;
};

export type DcsConditionInput = {
  category?: InputMaybe<PropertyCategoryEnum>;
  designation?: InputMaybe<PropertyDesignationEnum>;
  sub_category?: InputMaybe<PropertySubCategoryEnum>;
};

export type DateRangeInput = {
  from?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DesignationGroup = {
  __typename?: 'DesignationGroup';
  categories?: Maybe<Array<CategoryGroup>>;
  name: Scalars['String']['output'];
};

export type Elevation = {
  __typename?: 'Elevation';
  maxMeters: Scalars['Float']['output'];
  maxPoint: GeoJsonGeometry;
  minMeters: Scalars['Float']['output'];
  minPoint: GeoJsonGeometry;
};

export type EnumFrequencyFieldUpdateOperationsInput = {
  set?: InputMaybe<Frequency>;
};

export type EnumFrequencyFilter = {
  equals?: InputMaybe<Frequency>;
  in?: InputMaybe<Array<Frequency>>;
  not?: InputMaybe<NestedEnumFrequencyFilter>;
  notIn?: InputMaybe<Array<Frequency>>;
};

export type FloatRangeInput = {
  from?: InputMaybe<Scalars['Float']['input']>;
  to?: InputMaybe<Scalars['Float']['input']>;
};

export enum Frequency {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Never = 'NEVER',
  Weekly = 'WEEKLY'
}

export type GeoJsonGeometry = {
  __typename?: 'GeoJSONGeometry';
  coordinates?: Maybe<Scalars['CoordinatesScalar']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type GeoJsonGeometryCollection = {
  __typename?: 'GeoJSONGeometryCollection';
  geometries: Array<GeoJsonGeometry>;
  type: Scalars['String']['output'];
};

export type GeoJsonGeometryInput = {
  coordinates: Scalars['CoordinatesScalar']['input'];
  type: Scalars['String']['input'];
};

export type JsonFilter = {
  array_contains?: InputMaybe<Scalars['JSON']['input']>;
  array_ends_with?: InputMaybe<Scalars['JSON']['input']>;
  array_starts_with?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<Scalars['JSON']['input']>;
  path?: InputMaybe<Array<Scalars['String']['input']>>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export enum LastValueType {
  AskingPrice = 'AskingPrice',
  Sale = 'Sale',
  Valuation = 'Valuation'
}

export type Mutation = {
  __typename?: 'Mutation';
  createOneSavedSearch: SavedSearch;
  createSkipTraceOrder: SkipTraceOrder;
  deleteOneSavedSearch?: Maybe<SavedSearch>;
  updateOneSavedSearch?: Maybe<SavedSearch>;
};


export type MutationCreateOneSavedSearchArgs = {
  data: SavedSearchCreateInput;
};


export type MutationCreateSkipTraceOrderArgs = {
  orderName: Scalars['String']['input'];
  personRequests: Array<SinglePersonRequestInput>;
  siteIds: Array<InputMaybe<Scalars['String']['input']>>;
};


export type MutationDeleteOneSavedSearchArgs = {
  where: SavedSearchWhereUniqueInput;
};


export type MutationUpdateOneSavedSearchArgs = {
  data: SavedSearchUpdateInput;
  where: SavedSearchWhereUniqueInput;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedEnumFrequencyFilter = {
  equals?: InputMaybe<Frequency>;
  in?: InputMaybe<Array<Frequency>>;
  not?: InputMaybe<NestedEnumFrequencyFilter>;
  notIn?: InputMaybe<Array<Frequency>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PolygonGeoFilterInput = {
  coordinates: Array<CoordinatePairInput>;
};

export enum PropertyCategoryEnum {
  Agriculture = 'Agriculture',
  Bungalow = 'Bungalow',
  Flat = 'Flat',
  House = 'House',
  Industrial = 'Industrial',
  Land = 'Land',
  Maisonette = 'Maisonette',
  Office = 'Office',
  Other = 'Other',
  ParkHome = 'Park_Home',
  Retail = 'Retail'
}

export enum PropertyDesignationEnum {
  Commercial = 'Commercial',
  Other = 'Other',
  Residential = 'Residential'
}

export enum PropertyOwnerTypeEnum {
  Company = 'company',
  Government = 'government',
  Individual = 'individual',
  Unknown = 'unknown'
}

export enum PropertySubCategoryEnum {
  Detached = 'Detached',
  Flat = 'Flat',
  Healthcare = 'Healthcare',
  HighStreetRetail = 'High_Street_Retail',
  Hotel = 'Hotel',
  Leisure = 'Leisure',
  Manufacturing = 'Manufacturing',
  Other = 'Other',
  SemiDetached = 'Semi_Detached',
  Speciality = 'Speciality',
  StorageAndDistribution = 'Storage_and_Distribution',
  Terraced = 'Terraced',
  Warehouse = 'Warehouse'
}

export type Query = {
  __typename?: 'Query';
  additionalTransactionsByTransactionId: Array<AdditionalTransaction>;
  comparableByTransactionId: Array<Maybe<Comparable>>;
  comparableDistributionSearch: ComparableDistributionSearchResult;
  comparableSearch: ComparableConnection;
  comparablesByUPRN?: Maybe<Array<Comparable>>;
  csvSearchExportByGeometry?: Maybe<Array<Maybe<CsvSearchExport>>>;
  ownerNameAutocomplete: Array<OwnerNameSuggestion>;
  rentComparableByUPRN?: Maybe<ZooplaComparable>;
  savedSearch?: Maybe<SavedSearch>;
  savedSearches: Array<SavedSearch>;
  searchAllResultInfoByGeometry?: Maybe<Array<Maybe<SearchResultAllInfo>>>;
  searchCriteriaOptions?: Maybe<Array<Maybe<SearchCriteriaOptionType>>>;
  searchResultAddressInfoByGeometry?: Maybe<SearchResultAddressInfo>;
  searchResultClusteredOwnershipInfoByGeometry?: Maybe<SearchResultClusteredOwnershipInfo>;
  searchResultClusteredZoningInfoByGeometry?: Maybe<SearchResultClusteredZoningInfo>;
  searchResultOwnershipInfoByGeometry?: Maybe<SearchResultOwnershipInfo>;
  searchResultOwnershipInfoByGeometryIds: Array<Maybe<SearchResultOwnershipInfo>>;
  searchResultPropertyInfoByGeometry?: Maybe<SearchResultPropertyInfo>;
  searchResultZoningInfoByGeometry?: Maybe<SearchResultZoningInfo>;
  skipTraceOrderDetail: SkipTraceOrder;
  skipTraceOrders?: Maybe<Array<Maybe<SkipTraceOrder>>>;
  topographyAspectStatsByGeometry: Array<Maybe<TopographyAspectStats>>;
  topographyElevationStatsByGeometry: TopographyElevationStats;
  topographySlopeStatsByGeometry: Array<Maybe<TopographySlopeStats>>;
  zooplaComparableByUPRN?: Maybe<ZooplaComparable>;
  zooplaComparableByUPRNs: Array<Maybe<ZooplaComparable>>;
  zooplaComparableSearch: ZooplaComparableConnection;
  zooplaListingsByUPRN?: Maybe<ZooplaListings>;
  zooplaListingsByUPRNs: Array<Maybe<ZooplaListings>>;
};


export type QueryAdditionalTransactionsByTransactionIdArgs = {
  transaction_id: Scalars['String']['input'];
};


export type QueryComparableByTransactionIdArgs = {
  transaction_ids: Array<Scalars['String']['input']>;
};


export type QueryComparableDistributionSearchArgs = {
  field?: InputMaybe<ComparableAggregationField>;
  search: ComparableSearchInput;
};


export type QueryComparableSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search: ComparableSearchInput;
  sort?: InputMaybe<Sort>;
};


export type QueryComparablesByUprnArgs = {
  uprn: Scalars['String']['input'];
};


export type QueryCsvSearchExportByGeometryArgs = {
  geometry_ids: Array<InputMaybe<Scalars['String']['input']>>;
  limit: Scalars['Int']['input'];
};


export type QueryOwnerNameAutocompleteArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  search: Scalars['String']['input'];
};


export type QueryRentComparableByUprnArgs = {
  uprn: Scalars['String']['input'];
};


export type QuerySavedSearchArgs = {
  where: SavedSearchWhereUniqueInput;
};


export type QuerySavedSearchesArgs = {
  cursor?: InputMaybe<SavedSearchWhereUniqueInput>;
  distinct?: InputMaybe<Array<SavedSearchScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SavedSearchOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SavedSearchWhereInput>;
};


export type QuerySearchAllResultInfoByGeometryArgs = {
  geometry_ids: Array<InputMaybe<Scalars['String']['input']>>;
};


export type QuerySearchCriteriaOptionsArgs = {
  type: Scalars['String']['input'];
};


export type QuerySearchResultAddressInfoByGeometryArgs = {
  geometry_id: Scalars['String']['input'];
};


export type QuerySearchResultClusteredOwnershipInfoByGeometryArgs = {
  geometry_id: Scalars['String']['input'];
};


export type QuerySearchResultClusteredZoningInfoByGeometryArgs = {
  geometry_id: Scalars['String']['input'];
};


export type QuerySearchResultOwnershipInfoByGeometryArgs = {
  geometry_id: Scalars['String']['input'];
};


export type QuerySearchResultOwnershipInfoByGeometryIdsArgs = {
  geometry_ids: Array<InputMaybe<Scalars['String']['input']>>;
};


export type QuerySearchResultPropertyInfoByGeometryArgs = {
  geometry_id: Scalars['String']['input'];
};


export type QuerySearchResultZoningInfoByGeometryArgs = {
  geometry_id: Scalars['String']['input'];
};


export type QuerySkipTraceOrderDetailArgs = {
  id: Scalars['String']['input'];
};


export type QueryTopographyAspectStatsByGeometryArgs = {
  geometry: GeoJsonGeometryInput;
};


export type QueryTopographyElevationStatsByGeometryArgs = {
  geometry: GeoJsonGeometryInput;
};


export type QueryTopographySlopeStatsByGeometryArgs = {
  geometry: GeoJsonGeometryInput;
};


export type QueryZooplaComparableByUprnArgs = {
  uprn: Scalars['String']['input'];
};


export type QueryZooplaComparableByUprNsArgs = {
  uprns: Array<Scalars['String']['input']>;
};


export type QueryZooplaComparableSearchArgs = {
  search?: InputMaybe<ZooplaComparableSearchInput>;
};


export type QueryZooplaListingsByUprnArgs = {
  uprn: Scalars['String']['input'];
};


export type QueryZooplaListingsByUprNsArgs = {
  uprns: Array<Scalars['String']['input']>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type RadiusGeoFilterInput = {
  location: CoordinatePairInput;
  max_dist: Scalars['Float']['input'];
  min_dist?: InputMaybe<Scalars['Float']['input']>;
};

export type SavedSearch = {
  __typename?: 'SavedSearch';
  _count?: Maybe<SavedSearchCount>;
  /** the date the saved search was created */
  created_at: Scalars['DateTime']['output'];
  /** JSON definition of the saved search */
  definition: Scalars['JSON']['output'];
  /** the frequency the saved search will be run */
  frequency: Frequency;
  /** ID of the saved search */
  id: Scalars['String']['output'];
  /** name of the saved search */
  name: Scalars['String']['output'];
  package_version: Scalars['String']['output'];
  /** the date the saved search was last updated */
  updated_at: Scalars['DateTime']['output'];
};

export type SavedSearchCount = {
  __typename?: 'SavedSearchCount';
  SavedSearchExecution: Scalars['Int']['output'];
};

export type SavedSearchCreateInput = {
  definition: Scalars['JSON']['input'];
  frequency?: InputMaybe<Frequency>;
  name: Scalars['String']['input'];
};

export type SavedSearchOrderByWithRelationInput = {
  created_at?: InputMaybe<SortOrder>;
  definition?: InputMaybe<SortOrder>;
  frequency?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export enum SavedSearchScalarFieldEnum {
  CreatedAt = 'created_at',
  Definition = 'definition',
  Frequency = 'frequency',
  Id = 'id',
  LastScheduledAt = 'last_scheduled_at',
  Name = 'name',
  PackageVersion = 'package_version',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
  Version = 'version'
}

export type SavedSearchUpdateInput = {
  definition?: InputMaybe<Scalars['JSON']['input']>;
  frequency?: InputMaybe<EnumFrequencyFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type SavedSearchWhereInput = {
  AND?: InputMaybe<Array<SavedSearchWhereInput>>;
  NOT?: InputMaybe<Array<SavedSearchWhereInput>>;
  OR?: InputMaybe<Array<SavedSearchWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  definition?: InputMaybe<JsonFilter>;
  frequency?: InputMaybe<EnumFrequencyFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type SavedSearchWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type SearchResultAddressInfo = {
  __typename?: 'SearchResultAddressInfo';
  full_address?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  uprn?: Maybe<Scalars['String']['output']>;
};

export type SearchResultAllInfo = {
  __typename?: 'SearchResultAllInfo';
  searchResultAddressInfo?: Maybe<SearchResultAddressInfo>;
  searchResultOwnershipInfo?: Maybe<SearchResultOwnershipInfo>;
  searchResultPropertyInfo?: Maybe<SearchResultPropertyInfo>;
  searchResultZoningInfo?: Maybe<SearchResultZoningInfo>;
};

export type SearchResultClusteredOwnershipInfo = {
  __typename?: 'SearchResultClusteredOwnershipInfo';
  cluster_area: Scalars['Float']['output'];
  cluster_geometry: GeoJsonGeometry;
  cluster_id: Scalars['String']['output'];
  geometry_id: Scalars['String']['output'];
  geometry_ids: Array<Scalars['String']['output']>;
  parcels_in_cluster: Scalars['Int']['output'];
  proprietor_name: Scalars['String']['output'];
};

export type SearchResultClusteredZoningInfo = {
  __typename?: 'SearchResultClusteredZoningInfo';
  overlapping_zones?: Maybe<Array<Maybe<SearchResultZoningInfo>>>;
};

export type SearchResultOwnershipInfo = {
  __typename?: 'SearchResultOwnershipInfo';
  cluster_id?: Maybe<Scalars['String']['output']>;
  date_proprietor_added?: Maybe<Scalars['String']['output']>;
  developed_area_ratio?: Maybe<Scalars['Float']['output']>;
  geometry?: Maybe<GeoJsonGeometry>;
  geometry_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  parcel_identifier?: Maybe<Scalars['String']['output']>;
  parcel_size?: Maybe<Scalars['Float']['output']>;
  planning_apps: Array<Maybe<Scalars['String']['output']>>;
  poly_id?: Maybe<Scalars['String']['output']>;
  properties?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  property_address?: Maybe<Scalars['String']['output']>;
  proprietor_name?: Maybe<Scalars['String']['output']>;
  proprietorship?: Maybe<Scalars['String']['output']>;
  tenure?: Maybe<Scalars['String']['output']>;
  title_no?: Maybe<Scalars['String']['output']>;
  title_numbers: Array<Maybe<Scalars['String']['output']>>;
  titles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  zone_codes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type SearchResultPropertyInfo = {
  __typename?: 'SearchResultPropertyInfo';
  area_building_metric?: Maybe<Scalars['Float']['output']>;
  area_floor_total_metric?: Maybe<Scalars['Float']['output']>;
  area_gross_metric?: Maybe<Scalars['Float']['output']>;
  area_lot_metric?: Maybe<Scalars['Float']['output']>;
  area_lot_width_metric?: Maybe<Scalars['Float']['output']>;
  coverage?: Maybe<Scalars['Float']['output']>;
  floor_area_ratio?: Maybe<Scalars['Float']['output']>;
  uprn?: Maybe<Scalars['String']['output']>;
};

export type SearchResultZoningInfo = {
  __typename?: 'SearchResultZoningInfo';
  area_hectares?: Maybe<Scalars['Float']['output']>;
  display_name?: Maybe<Scalars['String']['output']>;
  key_code?: Maybe<Scalars['String']['output']>;
  permitted_uses?: Maybe<Array<Scalars['String']['output']>>;
  zone_geometry?: Maybe<GeoJsonGeometry>;
};

export type SinglePersonRequestInput = {
  apn: Scalars['String']['input'];
  propertyAddress: SkipTraceAddressInput;
};

export type SkipTraceAddress = {
  __typename?: 'SkipTraceAddress';
  street: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type SkipTraceAddressInput = {
  street: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export type SkipTraceOrder = {
  __typename?: 'SkipTraceOrder';
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  siteIds: Array<Maybe<Scalars['String']['output']>>;
  skipTracePerson: Array<SkipTracePerson>;
  teamId: Scalars['String']['output'];
  transaction: Transaction;
  transactionId: Scalars['String']['output'];
};

export type SkipTracePerson = {
  __typename?: 'SkipTracePerson';
  apn?: Maybe<Scalars['String']['output']>;
  correspondenceCity?: Maybe<Scalars['String']['output']>;
  correspondenceCounty?: Maybe<Scalars['String']['output']>;
  correspondenceHouseNumber?: Maybe<Scalars['String']['output']>;
  correspondenceState?: Maybe<Scalars['String']['output']>;
  correspondenceStreet?: Maybe<Scalars['String']['output']>;
  correspondenceZipCode?: Maybe<Scalars['String']['output']>;
  emails?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  isUnmatched?: Maybe<Scalars['Boolean']['output']>;
  litigator?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['JSON']['output']>;
  phoneNumbers?: Maybe<Scalars['JSON']['output']>;
  propertyCity?: Maybe<Scalars['String']['output']>;
  propertyCounty?: Maybe<Scalars['String']['output']>;
  propertyHouseNumber?: Maybe<Scalars['String']['output']>;
  propertyState?: Maybe<Scalars['String']['output']>;
  propertyStreet?: Maybe<Scalars['String']['output']>;
  propertyZipCode?: Maybe<Scalars['String']['output']>;
  responseData?: Maybe<Scalars['JSON']['output']>;
  teamId: Scalars['String']['output'];
};

export type Sort = {
  fieldName: Scalars['String']['input'];
  sortType?: InputMaybe<SortType>;
};

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export enum SortNulls {
  First = 'FIRST',
  Last = 'LAST'
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type SortType = {
  nulls?: InputMaybe<SortNulls>;
  order?: InputMaybe<SortDirection>;
};

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']['input']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type SubcategoryGroup = {
  __typename?: 'SubcategoryGroup';
  name: Scalars['String']['output'];
};

export enum Tenure {
  F = 'F',
  L = 'L'
}

export type TopographyAspectStats = {
  __typename?: 'TopographyAspectStats';
  category: Scalars['String']['output'];
  percentageCoverage: Scalars['Float']['output'];
};

export type TopographyElevationStats = {
  __typename?: 'TopographyElevationStats';
  elevation?: Maybe<Elevation>;
};

export type TopographySlopeStats = {
  __typename?: 'TopographySlopeStats';
  category: Scalars['String']['output'];
  percentage: Scalars['Float']['output'];
};

export type Transaction = {
  __typename?: 'Transaction';
  amount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

export type Valuation = {
  __typename?: 'Valuation';
  currentValue?: Maybe<Scalars['Float']['output']>;
  currentValuePerSqft?: Maybe<Scalars['Float']['output']>;
  history?: Maybe<Array<ValuationHistory>>;
  lastValue?: Maybe<Scalars['Float']['output']>;
  lastValueDate?: Maybe<Scalars['String']['output']>;
  lastValueType?: Maybe<Scalars['String']['output']>;
};

export type ValuationHistory = {
  __typename?: 'ValuationHistory';
  value: Scalars['Float']['output'];
  valueDate: Scalars['String']['output'];
  valueSource: Scalars['String']['output'];
  valueType: Scalars['String']['output'];
};

export type ZooplaAddress = {
  __typename?: 'ZooplaAddress';
  countryCode?: Maybe<Scalars['String']['output']>;
  locality?: Maybe<Scalars['String']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
  propertyNumberOrName?: Maybe<Scalars['String']['output']>;
  streetName?: Maybe<Scalars['String']['output']>;
  townOrCity?: Maybe<Scalars['String']['output']>;
};

export type ZooplaComparable = {
  __typename?: 'ZooplaComparable';
  attributes?: Maybe<ZooplaComparableAttributes>;
  energy?: Maybe<ZooplaEnergyRating>;
  location?: Maybe<ZooplaLocation>;
  uprn?: Maybe<Scalars['String']['output']>;
  valuation?: Maybe<ZooplaComparableValuation>;
};

export type ZooplaComparableAttributes = {
  __typename?: 'ZooplaComparableAttributes';
  bathrooms?: Maybe<Scalars['Int']['output']>;
  bedrooms?: Maybe<Scalars['Int']['output']>;
  floorArea?: Maybe<Scalars['Float']['output']>;
  newBuild?: Maybe<Scalars['Boolean']['output']>;
  propertySubType?: Maybe<Scalars['String']['output']>;
  propertyType?: Maybe<Scalars['String']['output']>;
  receptions?: Maybe<Scalars['Int']['output']>;
  tenure?: Maybe<Scalars['String']['output']>;
  yearBuilt?: Maybe<Scalars['Int']['output']>;
};

export type ZooplaComparableConnection = {
  __typename?: 'ZooplaComparableConnection';
  edges: Array<ZooplaComparableEdge>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars['Int']['output'];
};

export type ZooplaComparableEdge = {
  __typename?: 'ZooplaComparableEdge';
  _id: Scalars['String']['output'];
  cursor: Scalars['String']['output'];
  node: ZooplaComparable;
};

export type ZooplaComparableSearchInput = {
  bedrooms?: InputMaybe<FloatRangeInput>;
  bounding_box_geo_filter?: InputMaybe<BoundingBoxGeoFilterInput>;
  is_new_property?: InputMaybe<Scalars['Boolean']['input']>;
  marketValue?: InputMaybe<FloatRangeInput>;
  poly_geo_filter?: InputMaybe<PolygonGeoFilterInput>;
  propertySubType?: InputMaybe<Array<ZooplaPropertySubTypeEnum>>;
  radius_geo_filter?: InputMaybe<RadiusGeoFilterInput>;
  rentalEstimate?: InputMaybe<FloatRangeInput>;
  rentalValue?: InputMaybe<FloatRangeInput>;
  rentalValueType?: InputMaybe<Array<LastValueType>>;
  sold_date_range?: InputMaybe<DateRangeInput>;
  tenure?: InputMaybe<Tenure>;
  total_floor_area_range?: InputMaybe<FloatRangeInput>;
  yearBuilt?: InputMaybe<FloatRangeInput>;
};

export type ZooplaComparableValuation = {
  __typename?: 'ZooplaComparableValuation';
  rent?: Maybe<Valuation>;
  sale?: Maybe<Valuation>;
};

export type ZooplaEnergyRating = {
  __typename?: 'ZooplaEnergyRating';
  currentEnergyEfficiency?: Maybe<Scalars['Int']['output']>;
  currentEnergyRating?: Maybe<Scalars['String']['output']>;
};

export type ZooplaLatest = {
  __typename?: 'ZooplaLatest';
  latest?: Maybe<ZooplaListing>;
};

export type ZooplaListing = {
  __typename?: 'ZooplaListing';
  url?: Maybe<Scalars['String']['output']>;
};

export type ZooplaListings = {
  __typename?: 'ZooplaListings';
  listings?: Maybe<ZooplaLatest>;
  uprn?: Maybe<Scalars['String']['output']>;
};

export type ZooplaLocation = {
  __typename?: 'ZooplaLocation';
  address?: Maybe<ZooplaAddress>;
  coordinates?: Maybe<Scalars['CoordinatesScalar']['output']>;
  distanceFromSite?: Maybe<Scalars['Int']['output']>;
};

export enum ZooplaPropertySubTypeEnum {
  BungalowProperty = 'Bungalow_Property',
  ConvertedFlat = 'Converted_Flat',
  DetachedBungalow = 'Detached_Bungalow',
  DetachedHouse = 'Detached_House',
  DetachedProperty = 'Detached_Property',
  EndTerraceBungalow = 'End_Terrace_Bungalow',
  EndTerraceHouse = 'End_Terrace_House',
  EndTerraceProperty = 'End_Terrace_Property',
  FlatMaisonette = 'Flat_Maisonette',
  House = 'House',
  MidTerraceBungalow = 'Mid_Terrace_Bungalow',
  MidTerraceHouse = 'Mid_Terrace_House',
  MidTerraceProperty = 'Mid_Terrace_Property',
  Property = 'Property',
  PurposeBuiltFlat = 'Purpose_Built_Flat',
  RetirementFlat = 'Retirement_Flat',
  SemiDetachedBungalow = 'Semi_Detached_Bungalow',
  SemiDetachedHouse = 'Semi_Detached_House',
  SemiDetachedProperty = 'Semi_Detached_Property',
  TerraceProperty = 'Terrace_Property',
  Terraced = 'Terraced',
  TerracedBungalow = 'Terraced_Bungalow',
  TownHouse = 'Town_House'
}

export type OwnerNameSuggestion = {
  __typename?: 'ownerNameSuggestion';
  value: Scalars['String']['output'];
};

export type SearchCriteriaOptionType = {
  __typename?: 'searchCriteriaOptionType';
  children?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CreateSkipTraceOrderMutationVariables = Exact<{
  orderName: Scalars['String']['input'];
  siteIds: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  personRequests: Array<SinglePersonRequestInput> | SinglePersonRequestInput;
}>;


export type CreateSkipTraceOrderMutation = { __typename?: 'Mutation', createSkipTraceOrder: { __typename?: 'SkipTraceOrder', id: string } };

export type SkipTraceOrderDetailQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type SkipTraceOrderDetailQuery = { __typename?: 'Query', skipTraceOrderDetail: { __typename?: 'SkipTraceOrder', id: string, name: string, teamId: string, skipTracePerson: Array<{ __typename?: 'SkipTracePerson', id: string, teamId: string, correspondenceStreet?: string | null, correspondenceHouseNumber?: string | null, correspondenceZipCode?: string | null, correspondenceCity?: string | null, correspondenceCounty?: string | null, correspondenceState?: string | null, propertyStreet?: string | null, propertyHouseNumber?: string | null, propertyZipCode?: string | null, propertyCity?: string | null, propertyCounty?: string | null, propertyState?: string | null, apn?: string | null, litigator?: boolean | null, emails?: any | null, phoneNumbers?: any | null, name?: any | null, responseData?: any | null, isUnmatched?: boolean | null }> } };

export type SkipTraceOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type SkipTraceOrdersQuery = { __typename?: 'Query', skipTraceOrders?: Array<{ __typename?: 'SkipTraceOrder', id: string, name: string, siteIds: Array<string | null>, createdAt: string, transaction: { __typename?: 'Transaction', amount: number } } | null> | null };


export const CreateSkipTraceOrderDocument = gql`
    mutation createSkipTraceOrder($orderName: String!, $siteIds: [String]!, $personRequests: [SinglePersonRequestInput!]!) {
  createSkipTraceOrder(
    orderName: $orderName
    siteIds: $siteIds
    personRequests: $personRequests
  ) {
    id
  }
}
    `;
export type CreateSkipTraceOrderMutationFn = Apollo.MutationFunction<CreateSkipTraceOrderMutation, CreateSkipTraceOrderMutationVariables>;

/**
 * __useCreateSkipTraceOrderMutation__
 *
 * To run a mutation, you first call `useCreateSkipTraceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSkipTraceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSkipTraceOrderMutation, { data, loading, error }] = useCreateSkipTraceOrderMutation({
 *   variables: {
 *      orderName: // value for 'orderName'
 *      siteIds: // value for 'siteIds'
 *      personRequests: // value for 'personRequests'
 *   },
 * });
 */
export function useCreateSkipTraceOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateSkipTraceOrderMutation, CreateSkipTraceOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSkipTraceOrderMutation, CreateSkipTraceOrderMutationVariables>(CreateSkipTraceOrderDocument, options);
      }
export type CreateSkipTraceOrderMutationHookResult = ReturnType<typeof useCreateSkipTraceOrderMutation>;
export type CreateSkipTraceOrderMutationResult = Apollo.MutationResult<CreateSkipTraceOrderMutation>;
export type CreateSkipTraceOrderMutationOptions = Apollo.BaseMutationOptions<CreateSkipTraceOrderMutation, CreateSkipTraceOrderMutationVariables>;
export const SkipTraceOrderDetailDocument = gql`
    query SkipTraceOrderDetail($id: String!) {
  skipTraceOrderDetail(id: $id) {
    id
    name
    teamId
    skipTracePerson {
      id
      teamId
      correspondenceStreet
      correspondenceHouseNumber
      correspondenceZipCode
      correspondenceCity
      correspondenceCounty
      correspondenceState
      propertyStreet
      propertyHouseNumber
      propertyZipCode
      propertyCity
      propertyCounty
      propertyState
      apn
      litigator
      emails
      phoneNumbers
      name
      responseData
      isUnmatched
    }
  }
}
    `;

/**
 * __useSkipTraceOrderDetailQuery__
 *
 * To run a query within a React component, call `useSkipTraceOrderDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkipTraceOrderDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkipTraceOrderDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSkipTraceOrderDetailQuery(baseOptions: Apollo.QueryHookOptions<SkipTraceOrderDetailQuery, SkipTraceOrderDetailQueryVariables> & ({ variables: SkipTraceOrderDetailQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SkipTraceOrderDetailQuery, SkipTraceOrderDetailQueryVariables>(SkipTraceOrderDetailDocument, options);
      }
export function useSkipTraceOrderDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SkipTraceOrderDetailQuery, SkipTraceOrderDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SkipTraceOrderDetailQuery, SkipTraceOrderDetailQueryVariables>(SkipTraceOrderDetailDocument, options);
        }
export function useSkipTraceOrderDetailSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SkipTraceOrderDetailQuery, SkipTraceOrderDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SkipTraceOrderDetailQuery, SkipTraceOrderDetailQueryVariables>(SkipTraceOrderDetailDocument, options);
        }
export type SkipTraceOrderDetailQueryHookResult = ReturnType<typeof useSkipTraceOrderDetailQuery>;
export type SkipTraceOrderDetailLazyQueryHookResult = ReturnType<typeof useSkipTraceOrderDetailLazyQuery>;
export type SkipTraceOrderDetailSuspenseQueryHookResult = ReturnType<typeof useSkipTraceOrderDetailSuspenseQuery>;
export type SkipTraceOrderDetailQueryResult = Apollo.QueryResult<SkipTraceOrderDetailQuery, SkipTraceOrderDetailQueryVariables>;
export const SkipTraceOrdersDocument = gql`
    query SkipTraceOrders {
  skipTraceOrders {
    id
    name
    siteIds
    createdAt
    transaction {
      amount
    }
  }
}
    `;

/**
 * __useSkipTraceOrdersQuery__
 *
 * To run a query within a React component, call `useSkipTraceOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkipTraceOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkipTraceOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useSkipTraceOrdersQuery(baseOptions?: Apollo.QueryHookOptions<SkipTraceOrdersQuery, SkipTraceOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SkipTraceOrdersQuery, SkipTraceOrdersQueryVariables>(SkipTraceOrdersDocument, options);
      }
export function useSkipTraceOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SkipTraceOrdersQuery, SkipTraceOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SkipTraceOrdersQuery, SkipTraceOrdersQueryVariables>(SkipTraceOrdersDocument, options);
        }
export function useSkipTraceOrdersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SkipTraceOrdersQuery, SkipTraceOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SkipTraceOrdersQuery, SkipTraceOrdersQueryVariables>(SkipTraceOrdersDocument, options);
        }
export type SkipTraceOrdersQueryHookResult = ReturnType<typeof useSkipTraceOrdersQuery>;
export type SkipTraceOrdersLazyQueryHookResult = ReturnType<typeof useSkipTraceOrdersLazyQuery>;
export type SkipTraceOrdersSuspenseQueryHookResult = ReturnType<typeof useSkipTraceOrdersSuspenseQuery>;
export type SkipTraceOrdersQueryResult = Apollo.QueryResult<SkipTraceOrdersQuery, SkipTraceOrdersQueryVariables>;