import clsx from "clsx";

interface Step<T> {
  key: T;
  label: string;
}

export interface StepperProps<T> {
  currentStep: T;
  steps: Step<T>[];
  containerClassNames?: string;
}

export function Stepper<T extends number>({
  steps,
  currentStep,
  containerClassNames,
}: StepperProps<T>) {
  const isStepCompleted = (stepKey: T) =>
    steps.map(({ key }) => key).indexOf(stepKey) < currentStep;
  const isActiveStep = (stepKey: T) => steps.map(({ key }) => key).indexOf(stepKey) === currentStep;

  return (
    <div
      className={clsx(
        "atlas-w-full",
        "atlas-min-h-[46px]",
        "atlas-border-y",
        "atlas-border-background-dark",
        "atlas-flex",
        "atlas-flex-row",
        "atlas-flex-no-wrap",
        "atlas-overflow-x-auto",
        "atlas-overflow-y-hidden",
        "atlas-justify-center",
        "atlas-items-center",
        "atlas-gap-x-5",
        containerClassNames
      )}
      data-testid="stepper"
    >
      {steps.map(({ key, label }, index) => (
        <div
          key={`stepper-header-${key}`}
          className="atlas-flex atlas-flex-row atlas-items-center atlas-gap-x-5"
        >
          <div className="atlas-flex atlas-py-3">
            {isStepCompleted(key) ? (
              <div
                className={clsx(
                  "atlas-rounded",
                  "atlas-w-5",
                  "atlas-h-5",
                  "atlas-bg-background-positive",
                  "atlas-flex",
                  "atlas-items-center",
                  "atlas-justify-center"
                )}
                data-testid="stepper-check-icon"
              >
                <i className="icon-lt-check-line atlas-text-white" />
              </div>
            ) : (
              <div
                className={clsx(
                  "atlas-border-2",
                  "atlas-rounded",
                  "atlas-w-5",
                  "atlas-h-5",
                  isActiveStep(key) ? "atlas-border-border-action" : "atlas-border-border-divider"
                )}
                data-testid="stepper-step-number"
              >
                <span
                  className={clsx(
                    "atlas-pt-0.5",
                    "atlas-flex",
                    "atlas-justify-center",
                    "atlas-text-xs",
                    "atlas-leading-3",
                    "atlas-font-medium",
                    isActiveStep(key)
                      ? "atlas-text-border-action-hover"
                      : "atlas-text-content-tertiary"
                  )}
                >
                  {index + 1}
                </span>
              </div>
            )}

            <span
              className={clsx(
                "atlas-text-sm",
                "atlas-ml-2.5",
                "atlas-pt-0.5",
                "atlas-leading-4",
                "atlas-font-medium",
                "atlas-whitespace-nowrap",
                isActiveStep(key) ? "atlas-text-content-primary" : "atlas-text-content-tertiary"
              )}
              data-testid="stepper-label"
            >
              {label}
            </span>
          </div>

          {index < steps.length - 1 && (
            <div data-testid="stepper-divider" key={`divider-${label}`} className="atlas-flex">
              <div className="atlas-justify-center atlas-w-9 atlas-border-t atlas-mt-0 atlas-border-border-divider" />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
