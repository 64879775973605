import { DetailedRow } from "./components";

type Row = {
  id: string;
  expandedContent: React.ReactNode;
  content: React.ReactNode;
};
export type DetailedListProps = {
  rows: Row[];
  activeId?: string;
  expandedId?: string;
  onRowClick?: (value: string | undefined) => void;
};

export const DetailedList = ({ rows, activeId, expandedId, onRowClick }: DetailedListProps) => {
  return (
    <div>
      {rows.map((row) => (
        <DetailedRow
          key={row.id}
          expandedContent={row.expandedContent}
          content={row.content}
          isExpanded={row.id === expandedId}
          isSelected={row.id === activeId}
          onClick={() => {
            onRowClick?.(row.id);
          }}
        />
      ))}
    </div>
  );
};
