import { Button } from "react-migration/components/DeprecatedButton";
import { DeprecatedModal as Modal } from "react-migration/components/DeprecatedModal";
import { memo, useCallback, useMemo, useState } from "react";

import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { TagsSelect } from "react-migration/components/TagsSelect";

import type { SiteOverview } from "../../typings/Site";

import { useLabels } from "./useLabels";

export interface EditableSiteLabelsProps {
  site: Pick<SiteOverview, "_id" | "site_labels">;
  truncate?: boolean;
  startedSaving?(): void;
  stoppedSaving?({
    source,
    success,
    action,
  }: {
    source: string;
    success: boolean;
    action: string;
  }): void;
}

export const EditableSiteLabels = memo(
  ({
    site: { _id: siteId, site_labels },
    truncate = false,
    startedSaving,
    stoppedSaving,
  }: EditableSiteLabelsProps) => {
    const { t } = useTranslation();
    const [labelIdToArchive, setLabelIdToArchive] = useState<string | null>(null);

    const siteLabels = useMemo(() => site_labels?.map((l) => ({ ...l, id: l._id })), [site_labels]);

    const handleStopSaving = useCallback(
      (action: string) => {
        stoppedSaving?.({
          source: "Labels",
          success: true,
          action,
        });
      },
      [stoppedSaving]
    );

    const { allLabels, addLabel, removeLabel, createLabel, updateLabel, archiveLabel } = useLabels({
      siteId,
      startedSaving,
      stoppedSaving: handleStopSaving,
    });

    return (
      <>
        <TagsSelect
          selectedLabels={siteLabels}
          allLabels={allLabels}
          noLabelsText="Add label"
          labelExistsText=""
          searchInputPlaceHolder="Search for Labels"
          onRemoveLabel={removeLabel}
          onAddLabel={(label) => {
            addLabel(label.id);
          }}
          onArchiveLabel={(label: { id: string; name: string }) => {
            setLabelIdToArchive(label.id);
          }}
          onUpdateLabel={({ id, name }) => {
            updateLabel({
              _id: id,
              labelUpdate: { name },
            });
          }}
          onCreateLabel={(name) =>
            createLabel({
              labelCreation: { name },
            })
          }
          truncate={truncate}
          popoverSide="bottom"
        />
        {labelIdToArchive && (
          <Modal
            title={t("components.modal.titles")}
            description={t("sites.card.summary.label_delete_confirmation")}
          >
            <div className="atlas-flex atlas-justify-end atlas-gap-2">
              <Button.Danger
                data-testid="delete-label-confirm"
                onClick={() => {
                  archiveLabel({ _id: labelIdToArchive });
                  setLabelIdToArchive(null);
                }}
              >
                Delete
              </Button.Danger>
              <Button.Secondary onClick={() => setLabelIdToArchive(null)}>Cancel</Button.Secondary>
            </div>
          </Modal>
        )}
      </>
    );
  }
);

EditableSiteLabels.displayName = "EditableSiteLabels";
