import { useMemo } from "react";
import { StaticMapWithSelection } from "react-migration/domains/sites/card/Map/renderers/StaticMapReact";
import { LayerTypePrintableProps } from "../../../types";
import { renderSlopeLayer } from "../UK/UKTopographyMapLayer";

type SlopeMapLayerPrintableProps = Pick<LayerTypePrintableProps, "selection" | "onLoaded">;

export function SlopeMapLayerPrintable({ selection, onLoaded }: SlopeMapLayerPrintableProps) {
  const slopeLayer = useMemo(() => {
    return [renderSlopeLayer({ allowUnderzoom: true })];
  }, []);

  return (
    <StaticMapWithSelection
      id="slope-printable"
      layers={slopeLayer}
      onReady={onLoaded}
      selectionBufferMeters={0}
      selection={selection}
    />
  );
}
