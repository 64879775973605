import { useMemo } from "react";
import clsx from "clsx";
import type { EnrichedSlopeCategory } from "../types";

const BAR_HEIGHT = 24;
const BAR_RADIUS = 4;

interface SlopeBarSparklineProps {
  categories: EnrichedSlopeCategory[];
  activeCategoryKey?: string;
}

export function SlopeBarSparkline({ categories, activeCategoryKey }: SlopeBarSparklineProps) {
  const segments = useMemo(() => {
    const segments = [];
    let x = 0;

    for (const slope of categories) {
      const width = slope.percentage;
      segments.push({
        ...slope,
        x,
        width,
        isActive: activeCategoryKey === slope.categoryKey,
      });
      x += width;
    }

    return segments;
  }, [categories, activeCategoryKey]);

  return (
    <div className="atlas-relative atlas-w-full" style={{ height: BAR_HEIGHT }}>
      <svg
        width="100%"
        xmlns="http://www.w3.org/2000/svg"
        className="atlas-absolute atlas-top-0 atlas-left-0 atlas-w-full atlas-h-full atlas-overflow-visible"
      >
        <mask id="rounded-mask">
          <rect x={0} y={0} width="100%" height="100%" fill="black" />
          <rect rx={BAR_RADIUS} x={0} y={0} width="100%" height="100%" fill="white" />
        </mask>

        <g>
          {segments.map(({ x, width, color, name, percentage, isActive }) => (
            <rect
              key={name}
              x={x + "%"}
              y={0}
              width={width + "%"}
              height={BAR_HEIGHT}
              fill={color}
              mask="url(#rounded-mask)"
              className={clsx("atlas-ease-in-out atlas-duration-500 hover:atlas--translate-y-1", {
                "atlas--translate-y-1": isActive,
              })}
            >
              <title>
                {name} - {percentage}%
              </title>
            </rect>
          ))}
          {segments.slice(1).map(({ x, name }) => (
            <line
              key={name + "-stroke"}
              x1={x + "%"}
              x2={x + "%"}
              y1={-6}
              y2={BAR_HEIGHT}
              stroke="white"
              strokeWidth={2}
            />
          ))}
        </g>
      </svg>
    </div>
  );
}
