import { CheckedIcon, UncheckedIcon } from "../icons";

export type DetailedRowProps = {
  expandedContent?: React.ReactNode;
  content: React.ReactNode;
  isExpanded: boolean;
  isSelected: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
};

export const DetailedRow = ({
  expandedContent,
  content,
  isExpanded,
  isSelected,
  onClick,
}: DetailedRowProps) => {
  return (
    <div className="atlas-flex atlas-flex-col ">
      <div
        onClick={onClick}
        className="atlas-cursor-pointer atlas-flex atlas-items-center atlas-gap-4 atlas-py-3 atlas-border-b atlas-border-0 atlas-border-solid atlas-border-neutral-200"
      >
        {isSelected ? <CheckedIcon /> : <UncheckedIcon />}
        <div className="atlas-flex-grow">{content}</div>
      </div>
      {isExpanded && <div className="atlas-py-4">{expandedContent}</div>}
    </div>
  );
};
