import ReactPaginate, { ReactPaginateProps } from "react-paginate";
import clsx from "clsx";
import { ArrowLeftIcon, ArrowRightIcon } from "./icons";

import { usePaginationContext } from "./hooks/usePaginationContext";

type Page = Parameters<NonNullable<React.ComponentProps<typeof ReactPaginate>["onPageChange"]>>[0];
type PaginationComponentProps = Pick<
  ReactPaginateProps,
  | "activeClassName"
  | "activeLinkClassName"
  | "breakClassName"
  | "breakLinkClassName"
  | "containerClassName"
  | "nextClassName"
  | "nextLinkClassName"
  | "pageClassName"
  | "pageLinkClassName"
  | "previousLinkClassName"
  | "previousClassName"
> & {
  pageButtonClasses?: string;
  wrapperClassName?: string;
};

export const Pagination = ({
  activeClassName,
  activeLinkClassName,
  breakClassName,
  breakLinkClassName,
  containerClassName,
  nextClassName,
  nextLinkClassName,
  pageClassName,
  pageLinkClassName,
  previousClassName,
  previousLinkClassName,
  pageButtonClasses,
  wrapperClassName,
}: PaginationComponentProps) => {
  const {
    onPageChange,
    pageCount,
    currentPageInternal,
    setCurrentPageInternal,
    pageRangeDisplayed = 5,
  } = usePaginationContext();

  const handlePageClick = (page: Page) => {
    setCurrentPageInternal(page.selected);
    onPageChange(page.selected);
  };
  pageButtonClasses ??= clsx(
    "atlas-min-w-[32px] atlas-h-[32px] atlas-overflow-hidden",
    "atlas-flex atlas-justify-stretch atlas-items-stretch"
  );
  return (
    <div
      className={clsx(
        "atlas-text-neutral-600 atlas-flex atlas-items-baseline atlas-gap-2",
        wrapperClassName ?? ""
      )}
    >
      <ReactPaginate
        forcePage={currentPageInternal}
        breakLabel="..."
        previousLabel={<ArrowLeftIcon />}
        nextLabel={<ArrowRightIcon />}
        marginPagesDisplayed={1}
        onPageChange={handlePageClick}
        pageRangeDisplayed={pageRangeDisplayed}
        pageCount={pageCount}
        renderOnZeroPageCount={null}
        containerClassName={clsx("atlas-flex atlas-list-none", containerClassName)}
        pageLinkClassName={clsx(
          "atlas-p-1 atlas-flex atlas-flex-1 atlas-justify-center atlas-items-center hover:atlas-bg-blue-100",
          pageLinkClassName
        )}
        previousLinkClassName={clsx(
          clsx("atlas-p-1 atlas-flex atlas-flex-1 atlas-justify-center atlas-items-center", {
            "hover:atlas-bg-blue-100": currentPageInternal !== 0,
          }),
          previousLinkClassName
        )}
        nextLinkClassName={clsx(
          clsx("atlas-p-1 atlas-flex atlas-flex-1 atlas-justify-center atlas-items-center", {
            "hover:atlas-bg-blue-100": currentPageInternal !== pageCount - 1,
          }),
          nextLinkClassName
        )}
        breakLinkClassName={clsx(
          "atlas-p-1 atlas-flex atlas-flex-1 atlas-justify-center atlas-items-center",
          breakLinkClassName
        )}
        activeClassName={clsx(
          clsx("atlas-text-content-secondary atlas-bg-background-info"),
          activeClassName
        )}
        pageClassName={clsx(pageButtonClasses, pageClassName)}
        breakClassName={clsx(pageButtonClasses, breakClassName)}
        nextClassName={clsx(
          clsx(pageButtonClasses, {
            "atlas-text-neutral-400 atlas-pointer-events-none":
              currentPageInternal === pageCount - 1,
          }),
          nextClassName
        )}
        previousClassName={clsx(
          clsx(pageButtonClasses, {
            "atlas-text-neutral-400 atlas-pointer-events-none": currentPageInternal === 0,
          }),
          previousClassName
        )}
      />
    </div>
  );
};
