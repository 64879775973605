import { endOfMonth, parse } from "date-fns";
import { DATEFORMAT } from "src/js/util/dates";
import {
  gatherConditions,
  mapDCSConditionsToZooplaPropertySubTypes,
  possibleUKCombinations,
  possibleUSCombinations,
} from "./PropertyTypes";
import { ftsqToMsq } from "src/js/util/units_and_constants";
import { disabledEPCFields, disabledZooplaFields } from "./DisabledFilters";
import { hiddenFieldsByFeature } from "./hiddenFields";
import { path, prop } from "ramda";
import {
  ComparableTile,
  ZooplaComparableTileProperties,
} from "react-migration/lib/typings/ComparablesTile";
import { IComparablesTransactionDTO } from "react-migration/lib/typings/Comparables";
import { ZooplaComparableDTO, ZooplaPropertySubTypeGQL } from "react-migration/lib/typings/Zoopla";
import { ComparablesFilterOptions } from "../typings/Comparables";
import { ZooplaPriceModes } from "../typings/PriceModes";
import { ComparablesFilterFields } from "../components/Filters/Filters";

export const defaultDcsConditions = (country: string) =>
  (country === "GB" ? possibleUKCombinations : possibleUSCombinations).flatMap(gatherConditions);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const emptyArray: any[] = [];

function createFilterObject<T, X>(o: {
  [P in keyof T]: null | ((value: T[P]) => ((item: X) => boolean)[]);
}) {
  return o;
}

type NullishString = string | undefined | null;
const getZooplaDTORentalLastValueDate = path<NullishString>(["valuation", "rent", "lastValueDate"]);
const getZooplaDTOSaleLastValueDate = path<NullishString>(["valuation", "sale", "lastValueDate"]);
const getZooplaTileRentalLastValueDate = prop("valuation.rent.lastValueDate");
const getZooplaTileSaleLastValueDate = prop("valuation.sale.lastValueDate");

function createZooplaFilterObject<T, X>(o: {
  [P in keyof T]: null | ((value: T[P], priceMode?: ZooplaPriceModes) => ((item: X) => boolean)[]);
}) {
  return o;
}
export const filterTypes = createFilterObject<
  ComparablesFilterOptions,
  ComparableTile["properties"] | IComparablesTransactionDTO
>({
  bedrooms: (value) => {
    const { min, max } = (value as { min: number; max: number }) || {};
    const filters: Array<
      (item: ComparableTile["properties"] | IComparablesTransactionDTO) => boolean
    > = [];

    if (typeof min === "number") {
      filters.push((properties) => (properties.estimated_bedrooms_min ?? -Infinity) >= min);
    }

    if (typeof max === "number") {
      filters.push((properties) => (properties.estimated_bedrooms_max ?? Infinity) <= max);
    }

    return filters;
  },
  categories: (value) => (value?.length ? [(item) => value.includes(item.category)] : []),
  dateFrom: (value) =>
    value
      ? [
          (item) => {
            const minDate = parse(`01/${value}`, DATEFORMAT, new Date());
            const transferDate = new Date(
              `${item.date_of_transfer.replace(/T\d\d:\d\d:\d\d\..*$/, "")}T23:59:59.999Z`
            );
            return transferDate.valueOf() > minDate.valueOf();
          },
        ]
      : [],
  dateTo: (value) =>
    value
      ? [
          (item) => {
            const maxDate = endOfMonth(parse(`01/${value}`, DATEFORMAT, new Date()));
            const transferDate = new Date(
              `${item.date_of_transfer.replace(/T\d\d:\d\d:\d\d\..*$/, "")}T00:00:00.000Z`
            );
            return transferDate.valueOf() < maxDate.valueOf();
          },
        ]
      : [],
  dcsConditions: (value) =>
    value
      ? [
          (item) =>
            value.some(
              ({ designation, category, subcategory }) =>
                item.designation === (designation ?? item.designation) &&
                item.category === (category ?? item.category) &&
                item.sub_category === (subcategory ?? item.sub_category)
            ),
        ]
      : [],
  designations: (value) => (value?.length ? [(item) => value.includes(item.designation)] : []),
  floorArea: (value) => {
    const { min, max } = (value as { min: number; max: number }) || {};
    const filters: Array<
      (item: ComparableTile["properties"] | IComparablesTransactionDTO) => boolean
    > = [];

    if (typeof min === "number") {
      filters.push((properties) => (properties.total_floor_area ?? -Infinity) >= min);
    }

    if (typeof max === "number") {
      filters.push((properties) => (properties.total_floor_area ?? Infinity) <= max);
    }

    return filters;
  },
  newBuildsOnly: (value) => (value ? [(item) => item.is_new_property === value] : []),
  subcategories: (value) => (value?.length ? [(item) => value.includes(item.sub_category)] : []),
  tenure: (value) => (value === "Any" ? [] : [(item) => !!item.tenure && item.tenure === value]),
});

export const zooplaFilterTypes = createZooplaFilterObject<
  ComparablesFilterOptions,
  ZooplaComparableDTO
>({
  bedrooms: (value) => {
    const { min, max } = (value as { min: number; max: number }) || {};
    const filters: Array<(item: ZooplaComparableDTO) => boolean> = [];

    if (typeof min === "number") {
      filters.push((properties) => (properties.attributes.bedrooms ?? -Infinity) >= min);
    }

    if (typeof max === "number") {
      filters.push((properties) => (properties.attributes.bedrooms ?? Infinity) <= max);
    }

    return filters;
  },
  categories: null,
  dateFrom: (value, priceMode) => {
    const getter = /rental/i.test(priceMode ?? "")
      ? getZooplaDTORentalLastValueDate
      : getZooplaDTOSaleLastValueDate;
    return value
      ? [
          (item) => {
            const minDate = parse(`01/${value}`, DATEFORMAT, new Date());
            const dateString = getter(item);
            const transferDate = new Date(
              `${dateString?.replace?.(/T\d\d:\d\d:\d\d\..*$/, "")}T23:59:59.999Z`
            );
            return !!dateString && transferDate.valueOf() > minDate.valueOf();
          },
        ]
      : [];
  },
  dateTo: (value, priceMode) => {
    const getter = /rental/i.test(priceMode ?? "")
      ? getZooplaDTORentalLastValueDate
      : getZooplaDTOSaleLastValueDate;
    return value
      ? [
          (item) => {
            const maxDate = endOfMonth(parse(`01/${value}`, DATEFORMAT, new Date()));
            const dateString = getter(item);
            const transferDate = new Date(
              `${dateString?.replace?.(/T\d\d:\d\d:\d\d\..*$/, "")}T00:00:00.000Z`
            );
            return !!dateString && transferDate.valueOf() < maxDate.valueOf();
          },
        ]
      : [];
  },
  dcsConditions: null,
  designations: null,
  floorArea: (value) => {
    const { min, max } = (value as { min: number; max: number }) || {};
    const filters: Array<(item: ZooplaComparableDTO) => boolean> = [];

    if (typeof min === "number") {
      filters.push((properties) => ftsqToMsq(properties?.attributes.floorArea ?? -Infinity) >= min);
    }

    if (typeof max === "number") {
      filters.push((properties) => ftsqToMsq(properties?.attributes?.floorArea ?? Infinity) <= max);
    }

    return filters;
  },
  newBuildsOnly: (value) => (value ? [(item) => item.attributes.newBuild === value] : []),
  subcategories: null,
  tenure: (value) => (value === "Any" ? [] : [(item) => item.attributes.tenure?.[0] === value]),
  yearBuiltFrom: (value) => {
    return value
      ? [
          (item) => {
            const year = item.attributes.yearBuilt;
            return year ? year >= value : false;
          },
        ]
      : [];
  },
  yearBuiltTo: (value) => {
    return value
      ? [
          (item) => {
            const year = item.attributes.yearBuilt;
            return year ? year <= value : false;
          },
        ]
      : [];
  },
});

export const zooplaTileFilterTypes = createZooplaFilterObject<
  ComparablesFilterOptions,
  ZooplaComparableTileProperties
>({
  bedrooms: (value) => {
    const { min, max } = (value as { min: number; max: number }) || {};
    const filters: Array<(item: ZooplaComparableTileProperties) => boolean> = [];

    if (typeof min === "number") {
      filters.push((properties) => (properties["attributes.bedrooms"] ?? -Infinity) >= min);
    }

    if (typeof max === "number") {
      filters.push((properties) => (properties["attributes.bedrooms"] ?? Infinity) <= max);
    }

    return filters;
  },
  categories: null,
  dateFrom: (value, priceMode) => {
    const getter = /rental/i.test(priceMode ?? "")
      ? getZooplaTileRentalLastValueDate
      : getZooplaTileSaleLastValueDate;
    return value
      ? [
          (item) => {
            const minDate = parse(`01/${value}`, DATEFORMAT, new Date());
            const dateString = getter(item as Record<"valuation.rent.lastValueDate", string>);
            const transferDate = new Date(
              `${dateString?.replace?.(/T\d\d:\d\d:\d\d\..*$/, "")}T23:59:59.999Z`
            );
            return !!dateString && transferDate.valueOf() > minDate.valueOf();
          },
        ]
      : [];
  },
  dateTo: (value, priceMode) => {
    const getter = /rental/i.test(priceMode ?? "")
      ? getZooplaTileRentalLastValueDate
      : getZooplaTileSaleLastValueDate;
    return value
      ? [
          (item) => {
            const maxDate = endOfMonth(parse(`01/${value}`, DATEFORMAT, new Date()));
            const dateString = getter(item as Record<"valuation.rent.lastValueDate", string>);
            const transferDate = new Date(
              `${dateString?.replace?.(/T\d\d:\d\d:\d\d\..*$/, "")}T00:00:00.000Z`
            );
            return !!dateString && transferDate.valueOf() < maxDate.valueOf();
          },
        ]
      : [];
  },
  dcsConditions: (value) => {
    if (!value) {
      return [];
    }

    const zooplaCompatible = value.filter(({ designation }) => designation === "Residential");

    const propertySubTypes = new Set(mapDCSConditionsToZooplaPropertySubTypes(zooplaCompatible));
    return [
      (properties) =>
        !!properties["attributes.propertySubType"] &&
        // this .replace call essentially does the same mapping as the enum on the API
        propertySubTypes.has(
          properties["attributes.propertySubType"]?.replace?.(
            /[-/ ]/g,
            "_"
          ) as ZooplaPropertySubTypeGQL
        ),
    ];
  },
  designations: null,
  floorArea: (value) => {
    const { min, max } = (value as { min: number; max: number }) || {};
    const filters: Array<(item: ZooplaComparableTileProperties) => boolean> = [];

    if (typeof min === "number") {
      filters.push(
        (properties) =>
          ftsqToMsq(parseFloat(properties["attributes.floorArea"] ?? "-Infinity")) >= min
      );
    }

    if (typeof max === "number") {
      filters.push(
        (properties) =>
          ftsqToMsq(parseFloat(properties["attributes.floorArea"] ?? "Infinity")) <= max
      );
    }

    return filters;
  },
  newBuildsOnly: (value) => (value ? [(item) => item["attributes.newBuild"] === value] : []),
  subcategories: null,
  tenure: (value) =>
    value === "Any"
      ? []
      : [(item) => !!item["attributes.tenure"] && item["attributes.tenure"][0] === value],
  yearBuiltFrom: (value) => {
    return value
      ? [
          (item) => {
            const year = item["attributes.yearBuilt"];
            return year ? year >= value : false;
          },
        ]
      : [];
  },
  yearBuiltTo: (value) => {
    return value
      ? [
          (item) => {
            const year = item["attributes.yearBuilt"];
            return year ? year <= value : false;
          },
        ]
      : [];
  },
});

export function createZooplaFilters(
  filters: ComparablesFilterOptions | undefined,
  exclusions: Set<ComparablesFilterFields>,
  priceMode?: ZooplaPriceModes
): ((item: ZooplaComparableDTO) => boolean)[] {
  if (!filters) {
    return emptyArray;
  }

  const include = (filter: ComparablesFilterFields): boolean => !exclusions?.has(filter);

  return Object.entries(filters).flatMap(
    ([key, value]) =>
      (include(key as ComparablesFilterFields) &&
        zooplaFilterTypes[key as keyof ComparablesFilterOptions]?.(value as never, priceMode)) ||
      []
  );
}

export function createZooplaTileFilters(
  filters: ComparablesFilterOptions | undefined,
  exclusions: Set<ComparablesFilterFields>,
  priceMode: ZooplaPriceModes
): ((item: ZooplaComparableTileProperties) => boolean)[] {
  if (!filters) {
    return emptyArray;
  }

  const include = (filter: ComparablesFilterFields): boolean => !exclusions?.has(filter);

  return Object.entries(filters).flatMap(
    ([key, value]) =>
      (include(key as ComparablesFilterFields) &&
        zooplaTileFilterTypes[key as keyof ComparablesFilterOptions]?.(
          value as never,
          priceMode
        )) ||
      []
  );
}

export function createFilters(
  filters: ComparablesFilterOptions | undefined,
  exclusions: Set<ComparablesFilterFields>
): ((item: ComparableTile["properties"] | IComparablesTransactionDTO) => boolean)[] {
  if (!filters) {
    return emptyArray;
  }

  const include = (filter: ComparablesFilterFields): boolean => !exclusions?.has(filter);

  return Object.entries(filters).flatMap(
    ([key, value]) =>
      (include(key as ComparablesFilterFields) &&
        filterTypes[key as keyof ComparablesFilterOptions]?.(value as never)) ||
      []
  );
}

export function filterZooplaRecords(
  filters: ComparablesFilterOptions,
  data: ZooplaComparableDTO[],
  priceMode: ZooplaPriceModes
): { includedResults: ZooplaComparableDTO[]; excludedResults: ZooplaComparableDTO[] } {
  const filterFunctions = createZooplaFilters(
    filters,
    new Set<ComparablesFilterFields>([
      ...disabledZooplaFields,
      ...hiddenFieldsByFeature.flatMap((check) => (check[0]() ? Array.from(check[1]) : [])),
    ]),
    priceMode
  );
  if (!filterFunctions.length) {
    return {
      includedResults: data,
      excludedResults: [],
    };
  }

  return data.reduce<{
    includedResults: ZooplaComparableDTO[];
    excludedResults: ZooplaComparableDTO[];
  }>(
    (acc, item) => {
      if (filterFunctions.every((filterFn) => filterFn(item))) {
        acc.includedResults.push(item);
      } else {
        acc.excludedResults.push(item);
      }

      return acc;
    },
    { includedResults: [], excludedResults: [] }
  );
}

export function filterRecords(
  filters: ComparablesFilterOptions,
  data: IComparablesTransactionDTO[]
): {
  includedResults: IComparablesTransactionDTO[];
  excludedResults: IComparablesTransactionDTO[];
} {
  const filterFunctions = createFilters(
    filters,
    new Set([
      ...disabledEPCFields,
      ...hiddenFieldsByFeature.flatMap((check) => (check[0]() ? Array.from(check[1]) : [])),
    ])
  );
  if (!filterFunctions.length) {
    return {
      includedResults: data,
      excludedResults: [],
    };
  }

  return data.reduce<{
    includedResults: IComparablesTransactionDTO[];
    excludedResults: IComparablesTransactionDTO[];
  }>(
    (acc, item) => {
      if (filterFunctions.every((filterFn) => filterFn(item))) {
        acc.includedResults.push(item);
      } else {
        acc.excludedResults.push(item);
      }

      return acc;
    },
    { includedResults: [], excludedResults: [] }
  );
}
