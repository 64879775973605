import { Button } from "react-migration/components/DeprecatedButton";
import { Dropdown } from "react-migration/components/Dropdown";
import { TextInputV2 } from "react-migration/components/TextInputV2";
import { Address } from "../typings/UserAddressesResponse";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { LegacyRef, RefObject } from "react";
import { addressSchema, FormFieldsAddress } from "../validationSchema/addressSchema";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";

type AddressFormProps = {
  address?: Address;
  onSubmit: (values: FormFieldsAddress) => void;
  onCancel: () => void;
  loading?: boolean;
  addressFormRef?: RefObject<HTMLDivElement>;
};

export const AddressForm = ({
  address,
  onSubmit,
  onCancel,
  loading,
  addressFormRef,
}: AddressFormProps) => {
  const { t } = useTranslation();
  const { register, handleSubmit, reset, formState } = useForm<FormFieldsAddress>({
    defaultValues: {
      country: address ? address.country : "United Kingdom",
      name: address ? address.receiver_name : "",
      line1: address ? address.line1 : "",
      line2: address ? address.line2 : "",
      postcode: address ? address.postcode : "",
      city: address ? address.city : "",
    },
    resolver: zodResolver(addressSchema),
  });

  return (
    <div className="atlas-ml-[34px]">
      <form
        id="address-form"
        ref={addressFormRef as LegacyRef<HTMLFormElement> | undefined}
        onSubmit={handleSubmit(onSubmit)}
        className="atlas-w-[476px] atlas-flex atlas-flex-col atlas-gap-4"
      >
        <Dropdown
          placeholder=""
          label={t("components.edit_address.country")}
          size="large"
          options={
            hasFeature(Feature.usAccess)
              ? [{ label: "United States", value: "United States" }]
              : [{ label: "United Kingdom", value: "United Kingdom" }]
          }
          value={Feature.usAccess ? "United States" : "United Kingdom"}
          handleValueChange={() => null}
          disabled
        />
        <div>
          <TextInputV2
            errorMessage={formState.errors.name?.message}
            size="large"
            label={t("components.edit_address.full_name")}
            {...register("name")}
          />
        </div>

        <div>
          <TextInputV2
            errorMessage={formState.errors.line1?.message}
            size="large"
            label="Address"
            {...register("line1")}
          />
        </div>
        <div>
          <TextInputV2
            errorMessage={formState.errors.line2?.message}
            size="large"
            label={t("components.edit_address.apartment")}
            {...register("line2")}
          />
        </div>
        <div className="atlas-flex atlas-justify-between atlas-gap-4 ">
          <div className="atlas-flex-grow">
            <TextInputV2
              errorMessage={formState.errors.city?.message}
              size="large"
              label={t("components.edit_address.city")}
              {...register("city")}
            />
          </div>
          <div className="atlas-flex-grow">
            <TextInputV2
              errorMessage={formState.errors.postcode?.message}
              size="large"
              label={t("components.edit_address.postcode")}
              {...register("postcode")}
            />
          </div>
        </div>
        <div className="atlas-flex atlas-gap-3">
          <Button.Secondary
            onClick={() => {
              reset();
              onCancel();
            }}
            type="button"
          >
            {t("components.edit_address.cancel")}
          </Button.Secondary>

          <Button.Primary disabled={loading} type="submit" data-testid="add-address-button">
            {address ? t("components.edit_address.save") : t("components.edit_address.use_address")}
          </Button.Primary>
        </div>
      </form>
    </div>
  );
};
