import clsx from "clsx";
import { HTMLProps, PropsWithChildren } from "react";

function SectionHeader({ title }: { title: string }) {
  return (
    <h4 className="atlas-m-0 atlas-text-base atlas-font-medium atlas-p-3 atlas-border-solid atlas-border-0 atlas-border-b atlas-border-neutral-200">
      {title}
    </h4>
  );
}

function SectionHeaderCustom({ children, ...rest }: HTMLProps<HTMLDivElement>) {
  return (
    <h4
      {...rest}
      className={clsx(
        "atlas-m-0 atlas-text-small atlas-font-medium atlas-p-3 atlas-border-solid atlas-border-0 atlas-border-b atlas-border-neutral-200",
        rest ? rest.className : ""
      )}
    >
      {children}
    </h4>
  );
}

function SectionBody({ children, bodyClasses }: { bodyClasses?: string } & PropsWithChildren) {
  return <div className={bodyClasses}>{children}</div>;
}

function Section({
  title,
  bodyClasses,
  children,
  ...rest
}: { title?: string; bodyClasses?: string } & HTMLProps<HTMLDivElement>) {
  return (
    <div data-testid="sidebar-section" className="atlas-bg-white atlas-relative" {...rest}>
      {title && <SectionHeader title={title} />}
      <SectionBody bodyClasses={bodyClasses}>{children}</SectionBody>
    </div>
  );
}

function SectionCustom({ children, ...rest }: HTMLProps<HTMLDivElement>) {
  return (
    <div data-testid="sidebar-section" className="atlas-bg-white atlas-relative" {...rest}>
      {children}
    </div>
  );
}

export { SectionHeader, SectionBody, Section, SectionCustom, SectionHeaderCustom };
