import { TenureShorthandGQL } from "react-migration/lib/typings/Comparables";

import { Checkbox, CheckboxState as C } from "react-migration/components/DeprecatedCheckbox";
import { Theme } from "react-migration/lib/theme/Theme";
import { FC } from "react";
import { TenureTypes } from "react-migration/domains/comparables/typings/Tenure";

import { useTranslation } from "react-migration/lib/i18n/useTranslation";

export interface FilterTenureProps {
  disabled?: boolean;
  tenureType: "Any" | TenureShorthandGQL;
  setTenureType: (value: "Any" | TenureShorthandGQL) => void;
  theme?: Theme;
}

function calculateNextState(currentTenureType: TenureTypes, changedOption: TenureTypes) {
  if (currentTenureType === TenureTypes.LEASEHOLD) {
    // LEASEHOLD is selected so either deselect LEASEHOLD (which means we select FREEHOLD) or re-select FREEHOLD so becomes ANY
    return changedOption === TenureTypes.LEASEHOLD ? TenureTypes.FREEHOLD : TenureTypes.ANY;
  } else if (currentTenureType === TenureTypes.FREEHOLD) {
    // FREEHOLD is selected so either deselect FREEHOLD (which means we select LEASEHOLD) or re-select LEASEHOLD so becomes ANY
    return changedOption === TenureTypes.FREEHOLD ? TenureTypes.LEASEHOLD : TenureTypes.ANY;
  }

  // ANY is selected so if FREEHOLD is deselected then they type must be LEASEHOLD and vice-versa
  return changedOption === TenureTypes.FREEHOLD ? TenureTypes.LEASEHOLD : TenureTypes.FREEHOLD;
}

export const FilterTenure: FC<FilterTenureProps> = ({
  disabled,
  tenureType,
  setTenureType,
  theme = Theme.Light,
}) => {
  const { t } = useTranslation();

  const freehold = tenureType === TenureTypes.FREEHOLD || tenureType === TenureTypes.ANY;
  const leasehold = tenureType === TenureTypes.LEASEHOLD || tenureType === TenureTypes.ANY;

  const updateTenureType = (type: TenureTypes) => {
    setTenureType(calculateNextState(tenureType as TenureTypes, type));
  };

  const disabledTextColor = `atlas-text-content-disabled-${
    theme === Theme.Light ? "light" : "dark"
  }`;

  return (
    <div data-testid="filter-tenure">
      <h4 className={`atlas-my-2 ${disabled ? disabledTextColor : ""}`}>
        {t("comparables.map.sidebar.filters.filters.tenure.label")}
      </h4>
      <div className="atlas-flex atlas-flex-row atlas-items-center">
        <Checkbox
          disabled={disabled}
          id="tenure-freehold"
          value={freehold ? C.CHECKED : C.EMPTY}
          onChange={() => updateTenureType(TenureTypes.FREEHOLD)}
        />
        <label className={`atlas-pl-1 atlas-mr-4 ${disabled ? disabledTextColor : ""}`}>
          {t("comparables.map.sidebar.filters.filters.tenure.freehold")}
        </label>
        <Checkbox
          disabled={disabled}
          id="tenure-leasehold"
          value={leasehold ? C.CHECKED : C.EMPTY}
          onChange={() => updateTenureType(TenureTypes.LEASEHOLD)}
          theme={theme}
        />
        <label className={`atlas-pl-1${disabled ? disabledTextColor : ""}`}>
          {t("comparables.map.sidebar.filters.filters.tenure.leasehold")}
        </label>
      </div>
    </div>
  );
};
