import classNames from "classnames";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

import { Loading } from "react-migration/components/Loading";

import { FinalStepWrapper } from "./FinalStepWrapper";

export const LetterLoading = () => {
  const { t } = useTranslation();

  return (
    <FinalStepWrapper>
      <div className="atlas-mb-3">
        <Loading />
      </div>
      <span
        className={classNames(
          "atlas-my-4",
          "atlas-text-content",
          "atlas-font-semibold",
          "atlas-text-xl",
          "atlas-leading-7"
        )}
      >
        {t("sites.letters.preparing_letters")}
      </span>
    </FinalStepWrapper>
  );
};
