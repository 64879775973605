import Feature from "src/js/stores/user/Feature";
import { useBetaFeatureFlag } from "react-migration/lib/user/useFeatureFlag";
import { Select } from "react-migration/components/Select";
import { LayerTypeControlPageProps } from "../../../types";
import { useTrackedTopographyLayerFilter } from "../useTrackedTopographyLayerFilter";
import { TopographyLayerDialog } from "../TopographyLayerDialog";
import { ResolutionBadge } from "../ResolutionBadge";
import { TopographyLayer } from "../constants";
import { TopographySlopeMapLegend } from "./TopographySlopeMapLegend";
import { TopographyAspectMapLegend } from "./TopographyAspectMapLegend";

export function UKTopographyControlPage({ disabled }: LayerTypeControlPageProps) {
  const { handleLayerChange, selectedLayer } = useTrackedTopographyLayerFilter();
  const hasTopographyV2Beta = useBetaFeatureFlag(Feature.topographyV2);
  const hasTopographyV3Beta = useBetaFeatureFlag(Feature.topographyV3);

  return (
    <div className="atlas-flex atlas-flex-col atlas-gap-y-2">
      <TopographyLayerDialog region="UK" disabled={disabled} label="Display by" />
      <Select value={selectedLayer} disabled={disabled} onValueChange={handleLayerChange}>
        <Select.Option
          badge={<ResolutionBadge resolution="1m" />}
          value={TopographyLayer.hillshade}
        >
          Hillshade
        </Select.Option>
        <Select.Option badge={<ResolutionBadge resolution="10m" />} value={TopographyLayer.contour}>
          Contour
        </Select.Option>
        <Select.Option
          badge={<ResolutionBadge resolution="1m & 10m" />}
          value={TopographyLayer.hillshadeContour}
        >
          Hillshade &amp; Contour
        </Select.Option>
        {(hasTopographyV2Beta || hasTopographyV3Beta) && <Select.Seperator />}
        {hasTopographyV2Beta && (
          <Select.Option badge={<ResolutionBadge resolution="1m" />} value={TopographyLayer.slope}>
            Slope
          </Select.Option>
        )}
        {hasTopographyV3Beta && (
          <Select.Option badge={<ResolutionBadge resolution="1m" />} value={TopographyLayer.aspect}>
            Aspect
          </Select.Option>
        )}
      </Select>
      {selectedLayer === TopographyLayer.slope && <TopographySlopeMapLegend />}
      {selectedLayer === TopographyLayer.aspect && <TopographyAspectMapLegend />}
    </div>
  );
}
