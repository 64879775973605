import { LayerType } from "../../types";
import { UKPropertyInformationMapLayer } from "./UKPropertyInformationMapLayer";
import { UKPropertyInformationPrintable } from "./UKPropertyInformationPrintable";
import { UKPropertyInformationSelectionSidebar } from "./UKPropertyInformationSelectionSidebar";

export const UKPropertyInformationLayerType: LayerType = {
  SelectionSidebar: UKPropertyInformationSelectionSidebar,
  MapLayer: UKPropertyInformationMapLayer,
  Printable: UKPropertyInformationPrintable,
};
