import { BitmapLayer, BitmapLayerProps } from "@deck.gl/layers";
import { TileLayer, TileLayerProps } from "@deck.gl/geo-layers";
import { ClipExtension } from "@deck.gl/extensions";
import transformScale from "@turf/transform-scale";
import bboxPolygon from "@turf/bbox-polygon";
import bbox from "@turf/bbox";

type BitmapTileLayerProps = TileLayerProps & {
  /** allow for the cropping of scaled tiles */
  sourceTileScaleFactor?: number;
};

export class BitmapTileLayer extends TileLayer {
  constructor(props: BitmapTileLayerProps) {
    super(props, {
      renderSubLayers: BitmapTileLayer._renderSubLayers,
    });
  }

  static _renderSubLayers: TileLayerProps["renderSubLayers"] = (props) => {
    const { boundingBox } = props.tile;
    const { sourceTileScaleFactor } = props as BitmapTileLayerProps;
    let scaleCropSettings: BitmapLayerProps | undefined;

    if (sourceTileScaleFactor) {
      const [[minX, minY], [maxX, maxY]] = boundingBox;
      const boundingBoxPoly = bboxPolygon([minX, minY, maxX, maxY]);
      const scaledBoundingBoxPoly = transformScale(boundingBoxPoly, sourceTileScaleFactor);
      const scaledBoundingBox = bbox(scaledBoundingBoxPoly);

      scaleCropSettings = {
        bounds: scaledBoundingBox,
        extensions: [new ClipExtension()],
        clipBounds: [minX, maxY, maxX, minY],
      } as unknown as BitmapLayerProps;
    }

    return [
      new BitmapLayer(
        props as any,
        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          data: null,
          image: props.data,
          bounds: [boundingBox[0][0], boundingBox[0][1], boundingBox[1][0], boundingBox[1][1]],
        },
        scaleCropSettings
      ),
    ];
  };
}
