import * as React from "react";
import clsx from "clsx";
import { Theme } from "react-migration/lib/theme/Theme";
import { NumericInput } from "react-migration/components/NumericInput";

export type RangeInputSize = "small" | "medium" | "large";

/* props applied only to the start input */
interface StartInputProps {
  startInputSize?: RangeInputSize;
  startInputClassName?: string;
  startInputIsDisabled?: false;
  startInputMaxValue?: number;
  startInputMinValue?: number;
  startInputPlaceholder?: string;
  startInputLabel?: string;
  startInputValue?: number;
  startInputDefaultValue?: number;
  startInputOnBlur?: (value: React.FocusEvent<Element>) => void;
  startInputOnChange?: (value: number) => void;
  startInputOnKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  startInputOnKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}
/* props applied only to the end input */
interface EndInputProps {
  endInputSize?: RangeInputSize;
  endInputClassName?: string;
  endInputIsDisabled?: false;
  endInputMaxValue?: number;
  endInputMinValue?: number;
  endInputPlaceholder?: string;
  endInputLabel?: string;
  endInputValue?: number;
  endInputDefaultValue?: number;
  endInputOnBlur?: (value: React.FocusEvent<Element>) => void;
  endInputOnChange?: (value: number) => void;
  endInputOnKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  endInputOnKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}
/* common props that are/can be applied to both inputs */
export interface RangeInputProps extends StartInputProps, EndInputProps {
  size?: RangeInputSize;
  length?: number;
  inputClassName?: string;
  className?: string;
  isDisabled?: boolean;
  isReadOnly?: false;
  maxValue?: number;
  minValue?: number;
  minDecimals?: number;
  maxDecimals?: number;
  units?: string;
  alwaysShowUnits?: boolean;
  id?: string;
  step?: number;
  "data-testid"?: string;
  theme?: Theme;
}

export const RangeInput: React.FC<RangeInputProps> = ({
  size = "medium",
  length,
  inputClassName,
  className,
  startInputClassName,
  endInputClassName,
  minDecimals = 2,
  maxDecimals = 2,
  isDisabled,
  startInputIsDisabled,
  endInputIsDisabled,
  maxValue,
  startInputMaxValue,
  endInputMaxValue,
  minValue,
  startInputMinValue,
  endInputMinValue,
  startInputPlaceholder,
  endInputPlaceholder,
  startInputLabel,
  endInputLabel,
  startInputDefaultValue,
  endInputDefaultValue,
  startInputValue,
  endInputValue,
  startInputOnBlur,
  startInputOnChange,
  endInputOnBlur,
  endInputOnChange,
  startInputOnKeyDown,
  endInputOnKeyDown,
  startInputOnKeyUp,
  endInputOnKeyUp,
  theme = Theme.Light,
  ...rest
}) => {
  const testId = rest["data-testid"];
  return (
    <div
      className={clsx("atlas-flex atlas-flex-row atlas-items-center", className)}
      data-testid={testId}
    >
      <NumericInput
        {...rest}
        id="start-input"
        label={startInputLabel}
        placeholder={startInputPlaceholder}
        isDisabled={startInputIsDisabled ? startInputIsDisabled : isDisabled}
        maxValue={startInputMaxValue ? startInputMaxValue : maxValue}
        minValue={startInputMinValue ? startInputMinValue : minValue}
        className={startInputClassName ? startInputClassName : inputClassName}
        size={size}
        length={length}
        formatOptions={{ minimumFractionDigits: minDecimals, maximumFractionDigits: maxDecimals }}
        defaultValue={startInputDefaultValue}
        value={startInputValue}
        onBlur={startInputOnBlur}
        onChange={startInputOnChange}
        onKeyDown={startInputOnKeyDown}
        onKeyUp={startInputOnKeyUp}
        data-testid={`${testId || ""}${testId ? "-" : ""}min-value`}
        theme={theme}
      />
      <span
        className={`atlas-mx-2 atlas-flex-shrink-0 ${
          isDisabled
            ? `atlas-text-content-disabled-${theme === Theme.Light ? "light" : "dark"}`
            : ""
        }`}
      >
        -
      </span>
      <NumericInput
        {...rest}
        id="end-input"
        label={endInputLabel}
        placeholder={endInputPlaceholder}
        className={endInputClassName ? endInputClassName : inputClassName}
        isDisabled={endInputIsDisabled ? endInputIsDisabled : isDisabled}
        maxValue={endInputMaxValue ? endInputMaxValue : maxValue}
        minValue={endInputMinValue ? endInputMinValue : minValue}
        size={size}
        length={length}
        formatOptions={{ minimumFractionDigits: minDecimals, maximumFractionDigits: maxDecimals }}
        defaultValue={endInputDefaultValue}
        value={endInputValue}
        onBlur={endInputOnBlur}
        onChange={endInputOnChange}
        onKeyDown={endInputOnKeyDown}
        onKeyUp={endInputOnKeyUp}
        data-testid={`${testId || ""}${testId ? "-" : ""}max-value`}
        theme={theme}
      />
    </div>
  );
};

export default RangeInput;
