import { FullyDefined } from "src";
import curry from "lodash/curry";
import { CheckboxState } from "react-migration/components/DeprecatedCheckbox";
import { DCSCondition } from "../typings/Comparables";
import { DCSConditionGQL } from "react-migration/lib/typings/Comparables";
import { ZooplaPropertySubTypeGQL } from "react-migration/lib/typings/Zoopla";

/**
 * related items do not get rendered but affect the checked status of the item
 * subTypes do get rendered and will affect the checked status of the parent item
 */
export type PropertyTypeOption = DCSCondition & {
  related: FullyDefined<DCSCondition>[];
  i18nCode: string;
  tooltipCode?: string;
  subTypes?: PropertyTypeOption[];
  zooplaRelated?: { propertySubType: ZooplaPropertySubTypeGQL }[];
};
function selfRelated(
  dcs: FullyDefined<DCSCondition> & {
    i18nCode: string;
    tooltipCode?: string;
    subTypes?: PropertyTypeOption[];
  }
): PropertyTypeOption {
  return {
    ...dcs,
    related: [dcs],
  };
}

export function gatherConditions(current: PropertyTypeOption): DCSCondition[] {
  return [...current.related, ...(current.subTypes ?? []).flatMap(gatherConditions)];
}

export function gatherConditionsAndZooplaPropertySubTypes(current: PropertyTypeOption): {
  dcsConditions: DCSCondition[];
  zooplaPropertySubTypes: { propertySubType: ZooplaPropertySubTypeGQL }[];
}[] {
  return [
    { dcsConditions: current.related, zooplaPropertySubTypes: current.zooplaRelated ?? [] },
    ...(current.subTypes ?? []).flatMap(gatherConditionsAndZooplaPropertySubTypes),
  ];
}

const isDCSConditionGQL = (x: DCSCondition | DCSConditionGQL): x is DCSConditionGQL =>
  !!(x as DCSConditionGQL).sub_category;
export function mapDCSConditionsToZooplaPropertySubTypes(
  selectedConditions: DCSCondition[] | DCSConditionGQL[]
): ZooplaPropertySubTypeGQL[] {
  const normalisedSelectedConditions: DCSCondition[] = selectedConditions.map((x) => {
    if (isDCSConditionGQL(x)) {
      return {
        designation: x.designation,
        category: x.category,
        subcategory: x.sub_category,
      };
    }

    return x;
  });
  const mappableTypes = gatherConditionsAndZooplaPropertySubTypes(possibleUKCombinations[1]);
  return mappableTypes.flatMap(({ dcsConditions, zooplaPropertySubTypes }) => {
    if (
      normalisedSelectedConditions.some((selected) =>
        dcsConditions.some((test) => exactMatch(selected, test))
      )
    ) {
      return zooplaPropertySubTypes.map(({ propertySubType }) => propertySubType);
    }

    return [];
  });
}

export function gatheredConditionsSelectedState(
  allConditions: DCSCondition[],
  selectedConditions: DCSCondition[]
) {
  const selected = allConditions.filter((item) =>
    selectedConditions.some((condition) => exactMatch(condition, item))
  );

  if (selected.length === allConditions.length) {
    return CheckboxState.CHECKED;
  }
  if (!selected.length) {
    return CheckboxState.EMPTY;
  }
  return CheckboxState.INDETERMINATE;
}

export const possibleUKCombinations: PropertyTypeOption[] = [
  {
    designation: "Commercial",
    related: [],
    i18nCode: "comparables.designation.commercial",
    subTypes: [
      {
        designation: "Commercial",
        category: "Industrial",
        i18nCode: "comparables.category.industrial",
        related: [],
        subTypes: [
          selfRelated({
            designation: "Commercial",
            category: "Industrial",
            subcategory: "Detached",
            i18nCode: "comparables.subcategory.detached",
          }),
          selfRelated({
            designation: "Commercial",
            category: "Industrial",
            subcategory: "Flat",
            i18nCode: "comparables.subcategory.flat",
          }),
          selfRelated({
            designation: "Commercial",
            category: "Industrial",
            subcategory: "Manufacturing",
            i18nCode: "comparables.subcategory.manufacturing",
          }),
          selfRelated({
            designation: "Commercial",
            category: "Industrial",
            subcategory: "Other",
            i18nCode: "comparables.subcategory.other",
          }),
          selfRelated({
            designation: "Commercial",
            category: "Industrial",
            subcategory: "Semi_Detached",
            i18nCode: "comparables.subcategory.semi_detached",
          }),
          selfRelated({
            designation: "Commercial",
            category: "Industrial",
            subcategory: "Storage_and_Distribution",
            i18nCode: "comparables.subcategory.storage_and_distribution",
          }),
          selfRelated({
            designation: "Commercial",
            category: "Industrial",
            subcategory: "Terraced",
            i18nCode: "comparables.subcategory.terraced",
          }),
          selfRelated({
            designation: "Commercial",
            category: "Industrial",
            subcategory: "Warehouse",
            i18nCode: "comparables.subcategory.warehouse",
          }),
        ],
      },
      selfRelated({
        designation: "Commercial",
        category: "Land",
        subcategory: "Other",
        i18nCode: "comparables.category.land",
      }),
      {
        designation: "Commercial",
        category: "Office",
        i18nCode: "comparables.category.office",
        related: [],
        subTypes: [
          selfRelated({
            designation: "Commercial",
            category: "Office",
            subcategory: "Detached",
            i18nCode: "comparables.subcategory.detached",
          }),
          selfRelated({
            designation: "Commercial",
            category: "Office",
            subcategory: "Flat",
            i18nCode: "comparables.subcategory.flat",
          }),
          selfRelated({
            designation: "Commercial",
            category: "Office",
            subcategory: "Other",
            i18nCode: "comparables.subcategory.other",
          }),
          selfRelated({
            designation: "Commercial",
            category: "Office",
            subcategory: "Semi_Detached",
            i18nCode: "comparables.subcategory.semi_detached",
          }),
          selfRelated({
            designation: "Commercial",
            category: "Office",
            subcategory: "Terraced",
            i18nCode: "comparables.subcategory.terraced",
          }),
        ],
      },
      {
        designation: "Commercial",
        category: "Other",
        i18nCode: "comparables.category.other",
        tooltipCode: "comparables.category.other.tooltip",
        related: [],
        subTypes: [
          selfRelated({
            designation: "Commercial",
            category: "Other",
            subcategory: "Detached",
            i18nCode: "comparables.subcategory.detached",
          }),
          selfRelated({
            designation: "Commercial",
            category: "Other",
            subcategory: "Flat",
            i18nCode: "comparables.subcategory.flat",
          }),
          selfRelated({
            designation: "Commercial",
            category: "Other",
            subcategory: "Healthcare",
            i18nCode: "comparables.subcategory.healthcare",
          }),
          selfRelated({
            designation: "Commercial",
            category: "Other",
            subcategory: "Hotel",
            i18nCode: "comparables.subcategory.hotel",
          }),
          selfRelated({
            designation: "Commercial",
            category: "Other",
            subcategory: "Leisure",
            i18nCode: "comparables.subcategory.leisure",
          }),
          selfRelated({
            designation: "Commercial",
            category: "Other",
            subcategory: "Other",
            i18nCode: "comparables.subcategory.other",
          }),
          selfRelated({
            designation: "Commercial",
            category: "Other",
            subcategory: "Semi_Detached",
            i18nCode: "comparables.subcategory.semi_detached",
          }),
          selfRelated({
            designation: "Commercial",
            category: "Other",
            subcategory: "Speciality",
            i18nCode: "comparables.subcategory.speciality",
          }),
          selfRelated({
            designation: "Commercial",
            category: "Other",
            subcategory: "Terraced",
            i18nCode: "comparables.subcategory.terraced",
          }),
        ],
      },
      {
        designation: "Commercial",
        category: "Retail",
        i18nCode: "comparables.category.retail",
        related: [],
        subTypes: [
          selfRelated({
            designation: "Commercial",
            category: "Retail",
            subcategory: "Detached",
            i18nCode: "comparables.subcategory.detached",
          }),
          selfRelated({
            designation: "Commercial",
            category: "Retail",
            subcategory: "Flat",
            i18nCode: "comparables.subcategory.flat",
          }),
          selfRelated({
            designation: "Commercial",
            category: "Retail",
            subcategory: "High_Street_Retail",
            i18nCode: "comparables.subcategory.high_street_retail",
          }),
          selfRelated({
            designation: "Commercial",
            category: "Retail",
            subcategory: "Other",
            i18nCode: "comparables.subcategory.other",
          }),
          selfRelated({
            designation: "Commercial",
            category: "Retail",
            subcategory: "Semi_Detached",
            i18nCode: "comparables.subcategory.semi_detached",
          }),
          selfRelated({
            designation: "Commercial",
            category: "Retail",
            subcategory: "Terraced",
            i18nCode: "comparables.subcategory.terraced",
          }),
        ],
      },
    ],
  },

  {
    designation: "Residential",
    i18nCode: "comparables.designation.residential",
    related: [],
    subTypes: [
      {
        designation: "Residential",
        subcategory: "Detached",
        i18nCode: "comparables.subcategory.detached",
        related: [
          { designation: "Residential", category: "House", subcategory: "Detached" },
          { designation: "Residential", category: "Other", subcategory: "Detached" },
          { designation: "Residential", category: "Park_Home", subcategory: "Detached" },
          { designation: "Residential", category: "Maisonette", subcategory: "Detached" },
        ],
        zooplaRelated: [
          { propertySubType: "Detached_Property" },
          { propertySubType: "Detached_House" },
        ],
      },
      {
        designation: "Residential",
        subcategory: "Semi_Detached",
        i18nCode: "comparables.subcategory.semi_detached",
        related: [
          { designation: "Residential", category: "House", subcategory: "Semi_Detached" },
          { designation: "Residential", category: "Other", subcategory: "Semi_Detached" },
          { designation: "Residential", category: "Park_Home", subcategory: "Semi_Detached" },
        ],
        zooplaRelated: [
          { propertySubType: "Semi_Detached_Property" },
          { propertySubType: "Semi_Detached_House" },
        ],
      },
      {
        designation: "Residential",
        subcategory: "Terraced",
        i18nCode: "comparables.subcategory.terraced",
        related: [
          { designation: "Residential", category: "House", subcategory: "Terraced" },
          { designation: "Residential", category: "Other", subcategory: "Terraced" },
          { designation: "Residential", category: "Park_Home", subcategory: "Terraced" },
        ],
        zooplaRelated: [
          { propertySubType: "End_Terrace_Property" },
          { propertySubType: "End_Terrace_House" },
          { propertySubType: "Mid_Terrace_Property" },
          { propertySubType: "Mid_Terrace_House" },
          { propertySubType: "Terrace_Property" },
          { propertySubType: "Terraced" },
          { propertySubType: "Town_House" },
        ],
      },
      {
        designation: "Residential",
        category: "Bungalow",
        i18nCode: "comparables.category.bungalow",
        related: [
          { designation: "Residential", category: "Bungalow", subcategory: "Detached" },
          { designation: "Residential", category: "Bungalow", subcategory: "Semi_Detached" },
          { designation: "Residential", category: "Bungalow", subcategory: "Terraced" },
          { designation: "Residential", category: "Bungalow", subcategory: "Other" },
        ],
        zooplaRelated: [
          { propertySubType: "End_Terrace_Bungalow" },
          { propertySubType: "Mid_Terrace_Bungalow" },
          { propertySubType: "Terraced_Bungalow" },
          { propertySubType: "Semi_Detached_Bungalow" },
          { propertySubType: "Bungalow_Property" },
          { propertySubType: "Detached_Bungalow" },
        ],
      },
      {
        designation: "Residential",
        category: "Maisonette",
        // the only reason we had to add the i18nCode value for EVERYTHING was for this one entry which doesn't fit the previous model
        i18nCode: "comparables.category.maisonette_or_flat",
        related: [],
        subTypes: [
          {
            designation: "Residential",
            category: "Maisonette",
            i18nCode: "comparables.category.maisonette",
            related: [
              { designation: "Residential", category: "Maisonette", subcategory: "Flat" },
              { designation: "Residential", category: "Maisonette", subcategory: "Other" },
              { designation: "Residential", category: "Maisonette", subcategory: "Semi_Detached" },
              { designation: "Residential", category: "Maisonette", subcategory: "Terraced" },
            ],
            zooplaRelated: [
              { propertySubType: "Converted_Flat" },
              { propertySubType: "Purpose_Built_Flat" },
              { propertySubType: "Retirement_Flat" },
            ],
          },
          {
            designation: "Residential",
            category: "Flat",
            i18nCode: "comparables.category.flat",
            related: [
              { designation: "Residential", category: "Park_Home", subcategory: "Flat" },
              { designation: "Residential", category: "House", subcategory: "Flat" },
              { designation: "Residential", category: "Bungalow", subcategory: "Flat" },
            ],
            zooplaRelated: [{ propertySubType: "Flat_Maisonette" }],
          },
        ],
      },
      {
        designation: "Residential",
        category: "Other",
        i18nCode: "comparables.category.other",
        tooltipCode: "comparables.category.other.tooltip",
        related: [
          { designation: "Residential", category: "House", subcategory: "Other" },
          { designation: "Residential", category: "Other", subcategory: "Other" },
          { designation: "Residential", category: "Park_Home", subcategory: "Other" },
        ],
        zooplaRelated: [{ propertySubType: "Property" }, { propertySubType: "House" }],
      },
    ],
  },
];

export const possibleUSCombinations: PropertyTypeOption[] = [
  {
    designation: "Commercial",
    i18nCode: "comparables.designation.commercial",
    related: [],
  },
  selfRelated({
    designation: "Residential",
    category: "Other",
    subcategory: "Other",
    i18nCode: "comparables.designation.residential",
    tooltipCode: "comparables.category.other.tooltip",
  }),
  selfRelated({
    designation: "Other",
    category: "Other",
    subcategory: "Other",
    i18nCode: "comparables.designation.other",
    tooltipCode: "comparables.category.other.tooltip",
    subTypes: [
      selfRelated({
        designation: "Commercial",
        category: "Agriculture",
        subcategory: "Other",
        i18nCode: "comparables.category.agriculture",
      }),
      selfRelated({
        designation: "Commercial",
        category: "Industrial",
        subcategory: "Other",
        i18nCode: "comparables.category.industrial",
      }),
      selfRelated({
        designation: "Commercial",
        category: "Land",
        subcategory: "Other",
        i18nCode: "comparables.category.land",
      }),
      selfRelated({
        designation: "Commercial",
        category: "Other",
        subcategory: "Other",
        i18nCode: "comparables.category.other",
        tooltipCode: "comparables.category.other.tooltip",
      }),
    ],
  }),
];

export function rejectInvalidCombinations(country: "UK" | "US", options: DCSCondition[]) {
  const allowed = (country === "US" ? possibleUSCombinations : possibleUKCombinations).flatMap(
    gatherConditions
  );

  return options.filter((option) => allowed.some(curry(exactMatch, 2)(option)));
}

/**
 * matches exactly such that any key defined in either object must exist and match in the other object
 * @param a
 * @param b
 * @returns
 */
export function exactMatch(a: DCSCondition, b: DCSCondition) {
  return (
    a.designation === b.designation && a.category === b.category && a.subcategory === b.subcategory
  );
}

/**
 * matches loosely such that undefined keys in either object are not used to match
 * @param a
 * @param b
 * @returns
 */
export function looseMatch(a: DCSCondition, b: DCSCondition) {
  const keys: Array<keyof DCSCondition> = ["designation", "category", "subcategory"];
  return keys.every((key) => {
    return (a[key] || b[key]) === (b[key] || a[key]);
  });
}
