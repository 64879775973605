import { useEffect, useState } from "react";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { userStore } from "src/js/stores/user/store";
import { Button } from "react-migration/components/DeprecatedButton";
import {
  appendRemoteScript,
  appendRemoteStylesheet,
} from "react-migration/lib/util/appendRemoteScript";
import { ENVIRONMENT } from "src/js/util/environment";
import { logEvent } from "react-migration/lib/util/logEvent";

export const Integrations = () => {
  const { t } = useTranslation();
  const user = userStore.user;

  useEffect(() => {
    appendRemoteScript({
      url: "https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js",
      type: "module",
    });
    appendRemoteStylesheet({
      url: "https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css",
    });
  }, []);

  return (
    <div data-testid="integrations">
      <h2 className="atlas-text-sm atlas-font-medium md:atlas-text-3xl atlas-mb-6">
        {t("dashboard.dashboard.integrations")}
      </h2>
      <h3 className="atlas-font-semibold atlas-text-xl atlas-text-neutral-800">
        {t("dashboard.integrations.api_key")}
      </h3>
      <p
        className="atlas-text-base atlas-text-neutral-600 atlas-mt-5"
        data-testid="header-subtitle"
      >
        {t("dashboard.integrations.description")}
      </p>
      <div className="atlas-mb-4 atlas-text-md atlas-font-mono atlas-text-neutral-600 atlas-text-base atlas-p-4 atlas-border atlas-border-neutral-200 atlas-rounded-t">
        <ClipboardCopy copyText={user.api_key} />
      </div>
      <div>
        <zapier-full-experience
          sign-up-email={user.email}
          sign-up-first-name={user.firstname}
          sign-up-last-name={user.surname}
          client-id={ENVIRONMENT.ZAPIER_CLIENT_ID}
          theme="light"
          app-search-bar-display="show"
        />
      </div>
    </div>
  );
};

function ClipboardCopy({ copyText }: { copyText: string }) {
  const [isVisible, setIsVisible] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  async function copyTextToClipboard(text: string) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  const handleCopyClick = () => {
    logEvent("API Key Copied", {});
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleHideShowClicked = () => {
    if (!isVisible) {
      logEvent("API Key Shown", {});
    }
    setIsVisible(!isVisible);
  };

  return (
    <div>
      <input
        className="atlas-min-w-[500px]"
        type={isVisible ? "text" : "password"}
        value={copyText}
        readOnly
      />
      <Button.Primary className="atlas-m-1" onClick={handleHideShowClicked}>
        {isVisible ? "Hide" : "Show"}
      </Button.Primary>
      <Button.Primary className="atlas-m-1" onClick={handleCopyClick}>
        <span>{isCopied ? "Copied!" : "Copy"}</span>
      </Button.Primary>
    </div>
  );
}
