import { useBetaFeatureFlag } from "react-migration/lib/user/useFeatureFlag";
import { Action, ActionGroup, ActionGroups, Actions } from "./DebugActions";
import Feature from "src/js/stores/user/Feature";
import { usePerformanceStore } from "./performanceSettingsStore";
import { ENVIRONMENT } from "src/js/util/environment";
import { Icon } from "react-migration/components/Icon";
import { useSnapshot } from "valtio";
import { mapStore } from "src/js/stores/map/store";

export function PerformanceDebugMenu() {
  const hasPerfDebugMenu = useBetaFeatureFlag(Feature.perfDebugMenu);
  const [
    {
      rasterConstraints,
      rasterOwnership,
      removeInvisibleLayers,
      notLoadSitesDataIfSitesLayerDisabled,
      googleMapsVector,
      enableDiagnosticDeckGLLogging,
    },
    setPerfSetting,
  ] = usePerformanceStore();

  const {
    // MapX and MapY are transposed in the store. MapX should be lng and MapY should be lat
    settings: { mapX: lat, mapY: lng, zoom },
  } = useSnapshot(mapStore);

  if (!hasPerfDebugMenu) return null;

  const appUrl = ENVIRONMENT.APP_URL ?? "";
  const debugToolUrlWithCoordinates = `${appUrl}/perf-debug/index.html?zoom=${zoom}&lat=${lat}&lng=${lng}`;

  return (
    <>
      <ActionGroup name={ActionGroups.Performance}>
        <Action group={ActionGroups.Performance} name={Actions.RasterOwnership}>
          <input
            type="checkbox"
            className="atlas-w-4 atlas-h-4"
            checked={rasterOwnership}
            onChange={(e) => setPerfSetting("rasterOwnership", e.target.checked)}
          />
        </Action>
        <Action
          group={ActionGroups.Performance}
          name={Actions.Raster}
          action={() => setPerfSetting("rasterConstraints", !rasterConstraints)}
        >
          <input
            type="checkbox"
            className="atlas-w-4 atlas-h-4"
            checked={rasterConstraints}
            readOnly
          />
        </Action>
        <Action
          group={ActionGroups.Performance}
          name={Actions.HiddenLayers}
          action={() => {
            setPerfSetting("removeInvisibleLayers", !removeInvisibleLayers);
            setPerfSetting("notLoadSitesDataIfSitesLayerDisabled", false);
          }}
        >
          <input
            type="checkbox"
            className="atlas-w-4 atlas-h-4 atlas-cursor-pointer"
            checked={removeInvisibleLayers}
            readOnly
          />
        </Action>
        <Action
          group={ActionGroups.Performance}
          name={Actions.HiddenSites}
          action={() =>
            removeInvisibleLayers &&
            setPerfSetting(
              "notLoadSitesDataIfSitesLayerDisabled",
              !notLoadSitesDataIfSitesLayerDisabled
            )
          }
        >
          <input
            type="checkbox"
            className="atlas-w-4 atlas-h-4 atlas-cursor-pointer"
            checked={notLoadSitesDataIfSitesLayerDisabled}
            disabled={!removeInvisibleLayers}
            readOnly
          />
        </Action>
        <Action
          group={ActionGroups.Performance}
          name={Actions.VectorAPI}
          action={() => setPerfSetting("googleMapsVector", !googleMapsVector)}
        >
          <input
            type="checkbox"
            className="atlas-w-4 atlas-h-4"
            checked={googleMapsVector}
            readOnly
          />
        </Action>
        <Action
          group={ActionGroups.Performance}
          name={Actions.Logging}
          action={() =>
            setPerfSetting("enableDiagnosticDeckGLLogging", !enableDiagnosticDeckGLLogging)
          }
        >
          <input
            type="checkbox"
            className="atlas-w-4 atlas-h-4 atlas-cursor-pointer"
            checked={enableDiagnosticDeckGLLogging}
            readOnly
          />
        </Action>
      </ActionGroup>
      <div className="atlas-text-right">
        <a href={debugToolUrlWithCoordinates} target="__blank">
          <span>Performance debug tool </span>
          <Icon icon="icon-lt-external-link-line" />
        </a>
      </div>
      <div className="atlas-text-right">
        <a href={`${debugToolUrlWithCoordinates}&useVectorMap=true`} target="__blank">
          <span>Performance debug tool (Vector) </span>
          <Icon icon="icon-lt-external-link-line" />
        </a>
      </div>
    </>
  );
}
