import { useMemo } from "react";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { SkeletonLoading } from "react-migration/components/SkeletonLoading";
import { LayerTypeSelectionHeaderProps } from "../../types";
import { usePlanningAppById } from "./usePlanningAppsById";
import { feature } from "@turf/helpers";
import clsx from "clsx";

export function PlanningApplicationSelectionHeader({
  selection,
  onHeaderClick,
}: LayerTypeSelectionHeaderProps) {
  const { t } = useTranslation();
  const [data, loading] = usePlanningAppById(selection?.id);

  const handleHeaderClick = useMemo(() => {
    if (!onHeaderClick) return;

    const planningGeometry = data?.boundary || data?.location;

    if (!planningGeometry) return;

    return () => onHeaderClick({ ...selection, feature: feature(planningGeometry) });
  }, [data, onHeaderClick, selection]);

  if (loading)
    return (
      <div className="atlas-relative atlas-h-10" data-testid="designation-header-loading">
        <SkeletonLoading rows={1} showPadding={false} />
      </div>
    );

  return (
    <div key={selection?.id} className="atlas-flex atlas-flex-col atlas-items-start">
      <div className="atlas-flex atlas-flex-col atlas-items-stretch atlas-grow">
        <div className="atlas-text-xs atlas-font-semibold atlas-text-content-tertiary atlas-uppercase">
          {t("planning.planning_application_list.header")}
        </div>

        <div
          onClick={handleHeaderClick}
          className={clsx("atlas-text-sm atlas-font-semibold", {
            "atlas-cursor-pointer atlas-text-platformBlue-600 hover:atlas-text-platformBlue-700":
              handleHeaderClick,
            "atlas-text-content-primary": !handleHeaderClick,
          })}
        >
          {data.ref}
        </div>
      </div>
    </div>
  );
}
