import { isNumber } from "lodash";
import { Icon } from "react-migration/components/Icon";

interface AttributeComparisonTextSummaryProps {
  value?: number;
  label?: string;
  comparativeValue?: number | string | null;
  comparativeDisplayName?: string;
}
export function AttributeComparisonTextSummary({
  value,
  label,
  comparativeValue,
  comparativeDisplayName,
}: AttributeComparisonTextSummaryProps) {
  if (!isNumber(value) || !label) return null;

  return (
    <div className="atlas-flex atlas-gap-x-1 atlas-items-center atlas-text-sm atlas-text-neutral-800 atlas-overflow-hidden">
      {!isNumber(comparativeValue) || !comparativeDisplayName ? (
        <span className="atlas-truncate">
          <span className="atlas-font-medium">{label}</span> for this area
        </span>
      ) : value > comparativeValue ? (
        <>
          <Icon size="xs" icon="icon-lt-arrow-up-line" />
          <span className="atlas-truncate">
            <span className="atlas-font-medium">{label}</span> higher than {comparativeDisplayName}
          </span>
        </>
      ) : value === comparativeValue ? (
        <>
          <Icon size="xs" icon="icon-lt-equal-line" />
          <span className="atlas-truncate">
            <span className="atlas-font-medium">{label}</span> equal to {comparativeDisplayName}
          </span>
        </>
      ) : value < comparativeValue ? (
        <>
          <Icon size="xs" icon="icon-lt-arrow-up-line" extraClasses={"atlas-rotate-180"} />
          <span className="atlas-truncate">
            <span className="atlas-font-medium">{label}</span> lower than {comparativeDisplayName}
          </span>
        </>
      ) : null}
    </div>
  );
}
