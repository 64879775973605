export type IconSizeType = "xs" | "sm" | "md" | "lg";
export type IconCursorType = "pointer" | "default";

// These classes, controlled via 'cursor' prop need to be written in full to
// prevent them from being removed during optimization step:
// atlas-cursor-pointer atlas-cursor-default

export interface IconProps {
  icon: string;
  size?: IconSizeType;
  action?: () => void;
  dataTestId?: string;
  extraClasses?: string;
  cursor?: IconCursorType;
}

export const Icon = ({
  icon,
  action,
  dataTestId,
  extraClasses = "",
  size,
  cursor = "default",
  ...rest
}: IconProps) => {
  const getIconSize = () => {
    if (size === "xs") {
      return "atlas-text-xs";
    } else if (size === "sm") {
      return "atlas-text-base";
    } else if (size === "lg") {
      return "atlas-text-2xl";
    } else {
      return "atlas-text-xl";
    }
  };

  return (
    <i
      data-testid={dataTestId}
      onClick={action}
      className={`${icon} ${getIconSize()} atlas-cursor-${cursor} atlas-leading-none ${extraClasses}`}
      {...rest}
    />
  );
};
