import { Button } from "react-migration/components/DeprecatedButton";
import classNames from "classnames";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { router } from "src/js/router/react-router";
import ROUTES from "src/js/router/routes";
import { resetSkipTracingStore } from "../../store/store";
import logSiteCardEvent from "src/js/view/sites/logSiteCardEvent";

export const OrderConfirmationSuccessPage = () => {
  const { t } = useTranslation();

  const onViewSkipTracingBatchClicked = async () => {
    logSiteCardEvent({ name: "Skip Trace - Order Confirmation View Batches Clicked" });
    await router.push({ name: ROUTES.SKIP_TRACING });
    resetSkipTracingStore();
  };

  const onGoToSavedSites = async () => {
    logSiteCardEvent({ name: "Skip Trace - Order Confirmation Saved Sites Clicked" });
    await router.push({ name: ROUTES.SITES });
    resetSkipTracingStore();
  };

  return (
    <div
      className={classNames(
        "atlas-bg-background-light",
        "atlas-flex",
        "atlas-flex-col",
        "atlas-gap-y-5",
        "atlas-items-center",
        "atlas-justify-center",
        "atlas-h-full",
        "atlas-w-full"
      )}
      data-testid="skip-tracing-order-confirmation"
    >
      <div
        className={classNames(
          "atlas-flex",
          "atlas-flex-col",
          "atlas-gap-y-5",
          "atlas-text-center",
          "atlas-items-center",
          "atlas-justify-center",
          "atlas-w-[330px]"
        )}
      >
        <i
          className={classNames(
            "icon-lt-checkbox-circle-line",
            "atlas-text-content-positive",
            "atlas-text-8xl"
          )}
        />
        <span className="atlas-text-xl atlas-font-semibold">
          {t("sites.skip_tracing.order_confirmed.order_confirmed")}
        </span>
        <span className="atlas-text-md">
          {t("sites.skip_tracing.order_confirmed.first_message_line")}
        </span>
        <span className="atlas-text-md">
          {t("sites.skip_tracing.order_confirmed.second_message_line")}
        </span>
      </div>
      <Button.Primary onClick={onViewSkipTracingBatchClicked}>
        {t("sites.skip_tracing.order_confirmed.view_skip_tracing_batch")}
      </Button.Primary>
      <Button.Secondary onClick={onGoToSavedSites}>
        {t("sites.skip_tracing.order_confirmed.go_to_saved_sites")}
      </Button.Secondary>
    </div>
  );
};
