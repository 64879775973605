import React, { useLayoutEffect, useMemo } from "react";
import { SelectYear } from "./SelectYear";
import { CalendarContainer, calendarContainerWithFooter } from "./CalendarContainer";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import { DateTextInput } from "./DateTextInput";
import { DatePickerGranularity } from "./types";
import "./yearMonthOrDatePicker.scss";
import clsx from "clsx";

export type YearMonthOrDayPickerProps = Omit<ReactDatePickerProps, "isClearable"> & {
  className?: string;
  renderInPortal?: boolean;
  granularity: DatePickerGranularity;
  renderFooter?: React.ComponentType;
  onChange: (date: Date | null | undefined) => void;
  minDate?: Date | null;
  maxDate?: Date;
  testId?: string;
};

export function YearMonthOrDayPicker({
  className,
  renderInPortal,
  granularity,
  onChange,
  renderFooter,
  selected,
  minDate,
  maxDate,
  testId,
  ...props
}: YearMonthOrDayPickerProps) {
  const NUMBER_OF_YEARS_TO_SHOW = 9;

  const element = useMemo(() => {
    const el = document.createElement("div");
    el.style.cssText = "position: absolute; top: 0; left: 0; z-index: 1000;";
    el.id = `year-month-or-date-picker-${Math.floor(Math.random() * 100000)}`;
    el.className = clsx(
      `year-month-or-date-picker year-month-or-date-picker__${granularity} atlas-flex atlas-flex-col`,
      className
    );
    return el;
  }, []);

  useLayoutEffect(() => {
    if (renderInPortal) {
      document.body.appendChild(element);
      return () => {
        document.body.removeChild(element);
      };
    }
  }, [renderInPortal, element]);

  const handleChangeDate = (date: Date | null | undefined) => {
    onChange(date);
  };

  return (
    <div
      className={`year-month-or-date-picker year-month-or-date-picker__${granularity} atlas-flex atlas-flex-col`}
    >
      <ReactDatePicker
        showIcon
        endDate={new Date()}
        selected={selected}
        icon={<i className="icon-lt-calendar-line atlas-flex atlas-right-0" />}
        portalId={renderInPortal ? element.id : undefined}
        strictParsing
        calendarContainer={
          renderFooter ? calendarContainerWithFooter(renderFooter) : CalendarContainer
        }
        onChange={(date, event) => {
          onChange(date, event);
        }}
        showPopperArrow={false}
        clearButtonClassName="atlas-mr-6"
        previousYearButtonLabel={<i className="icon-lt-arrow-left-s-line" />}
        nextYearButtonLabel={<i className="icon-lt-arrow-right-s-line" />}
        renderCustomHeader={
          granularity === "month"
            ? function CustomDatePickerHeader(customHeaderProps) {
                return (
                  <SelectYear
                    {...customHeaderProps}
                    selectedDate={selected || new Date()}
                    onChange={handleChangeDate}
                    maxYear={maxDate?.getFullYear()}
                    minYear={minDate?.getFullYear()}
                  />
                );
              }
            : undefined
        }
        yearItemNumber={NUMBER_OF_YEARS_TO_SHOW}
        customInput={
          <div>
            <DateTextInput
              testId={`date-picker${testId ? "-" + testId : ""}`}
              granularity={granularity}
              onDateChange={handleChangeDate}
              selectedDate={selected}
            />
          </div>
        }
        {...granularityBasedProps[granularity]}
        minDate={minDate}
        maxDate={maxDate}
        {...props}
        //we need to set it to false as we use a custom textfield
        isClearable={false}
      />
    </div>
  );
}
type DateGranularityBasedProps = Partial<
  Pick<
    ReactDatePickerProps,
    | "dateFormat"
    | "calendarClassName"
    | "showMonthYearPicker"
    | "showYearPicker"
    | "showFullMonthYearPicker"
    | "renderDayContents"
    | "renderMonthContent"
    | "renderCustomHeader"
  >
>;

const granularityBasedProps: Record<DatePickerGranularity, DateGranularityBasedProps> = {
  year: {
    dateFormat: "yyyy",
    showYearPicker: true,
  },
  month: {
    dateFormat: "MM/yyyy",
    showMonthYearPicker: true,
  },
  day: {
    dateFormat: "dd/MM/yyyy",
    showFullMonthYearPicker: true,
  },
};
