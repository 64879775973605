import { Icon } from "react-migration/components/Icon";
import { Layer } from "react-migration/layouts/map/Multilayer/types";

interface OutsideZoomLimitMessageProps {
  layer: Layer;
}

export function OutsideZoomLimitMessage({ layer }: OutsideZoomLimitMessageProps) {
  return (
    <div className="atlas-flex atlas-items-center atlas-gap-4">
      <div>
        <Icon
          size="lg"
          icon="icon-lt-error-warning-line"
          extraClasses="atlas-text-statusAmber-600"
        />
      </div>
      <div className="atlas-flex atlas-flex-col atlas-gap-2">
        <div className="atlas-text-xs atlas-text-blueGrey-950 atlas-font-semibold">Unknown</div>
        <div className="atlas-text-xs atlas-text-blueGrey-900">
          We&apos;re unable to show considerations for &quot;{layer.title}&quot; at your current
          zoom level. Please adjust your zoom and try again
        </div>
      </div>
    </div>
  );
}
