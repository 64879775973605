import { Button } from "react-migration/components/DeprecatedButton";
import classNames from "classnames";

type SwitchButtonSize = "small" | "large";

interface SwitchButtonsProps {
  size?: SwitchButtonSize;
  labelPrefix?: string;
  currentIndex: number;
  totalCount: number;
  onPreviousBtnClick: () => void;
  onNextBtnClick: () => void;
}

const ActionButton = ({
  icon,
  size,
  onClick,
  disabled,
}: {
  icon: string;
  size: SwitchButtonSize | undefined;
  onClick: () => void;
  disabled?: boolean;
}) => {
  return (
    <Button.Secondary
      size={size || "large"}
      className={classNames(`atlas-w-${size && size === "small" ? 8 : 10}`)}
      onClick={onClick}
      disabled={disabled}
    >
      <i
        className={classNames(
          `icon-lt-arrow-${icon}-s-line`,
          "atlas-flex",
          "atlas-items-center",
          `atlas-text-${size && size === "small" ? "lg" : "2xl"}`,
          "atlas-text-content-primary"
        )}
      />
    </Button.Secondary>
  );
};

export const SwitchButtons = ({
  size,
  labelPrefix = "",
  currentIndex,
  totalCount,
  onPreviousBtnClick,
  onNextBtnClick,
}: SwitchButtonsProps) => {
  const labelText = `${labelPrefix} ${currentIndex + 1} of ${totalCount}`.trim();

  return (
    <div className="atlas-flex atlas-gap-x-2" data-testid="switch-buttons-wrapper">
      <ActionButton
        icon="left"
        size={size}
        onClick={onPreviousBtnClick}
        disabled={currentIndex === 0}
      />
      <span
        className={classNames(
          "atlas-flex",
          "atlas-whitespace-nowrap",
          "atlas-items-center",
          "atlas-font-semibold",
          "atlas-text-xs",
          "atlas-leading-3",
          "atlas-text-content-secondary"
        )}
      >
        {labelText}
      </span>
      <ActionButton
        icon="right"
        size={size}
        onClick={onNextBtnClick}
        disabled={currentIndex === totalCount - 1}
      />
    </div>
  );
};
