import { PropsWithChildren, ReactNode } from "react";
import * as React from "react";
import clsx from "clsx";

export type BannerProps = {
  className?: string;
  children: ReactNode;
};

const Informative: React.FC<PropsWithChildren<BannerProps>> = ({ children, className }) => {
  return (
    <Base className={clsx("atlas-border-border-info atlas-bg-background-info", className)}>
      {children}
    </Base>
  );
};

const Base: React.FC<PropsWithChildren<BannerProps>> = ({ children, className, ...rest }) => {
  return (
    <div
      className={clsx("atlas-rounded-lg atlas-py-2 atlas-px-3 atlas-mt-2 atlas-border", className)}
      {...rest}
    >
      <h4 className="atlas-text-sm atlas-font-medium">{children}</h4>
    </div>
  );
};

export const Banner = {
  Informative,
  Base,
};

export default Banner;
