import { Translation } from "react-migration/lib/typings";
import { MeasurementSystem } from "src/js/stores/user/store";
import { dateIsoString } from "src/js/util/dates";
import { pricePerUnitArea } from "./Prices";
import { toUsersPreferredArea } from "react-migration/lib/util/measurements";
import { identity } from "ramda";
import { ftsqToMsq } from "src/js/util/units_and_constants";
import { ValuationTypeMapping } from "./ValuationTypeMapping";
import { isZoopla } from "./isZoopla";
import Feature from "src/js/stores/user/Feature";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import { PriceModes } from "../typings/PriceModes";
import { ZooplaComparableDTO } from "react-migration/lib/typings/Zoopla";
import { RecordType } from "../typings/Record";

interface CSVHeaderProps {
  t: Translation;
  unitPreference: MeasurementSystem;
}

interface CSVRowProps {
  no: number;
  address: string;
  date?: string | null;
  price?: number | null;
  ppa?: string | null;
  emv?: number | null;
  mppa?: string | null;
  new?: string | null;
  designation?: string | null;
  category?: string | null;
  subcategory?: string | null;
  bedrooms?: number | null;
  estBedMin?: number | null;
  estBedMax?: number | null;
  floor?: string | null;
  tenure: string | null;
}
export const landTechCSVHeaders = ({ t, unitPreference }: CSVHeaderProps) => {
  const unit =
    unitPreference === MeasurementSystem.METRIC
      ? t("comparables.sqm.alt")
      : t("comparables.sqft.alt");
  if (hasFeature(Feature.usAccess)) {
    return [
      { label: t("comparables.calculator.csv.headers.no"), key: "no" },
      { label: t("comparables.calculator.csv.headers.address"), key: "address" },
      { label: t("comparables.calculator.csv.headers.postcode"), key: "postcode" },
      { label: t("comparables.calculator.csv.headers.date"), key: "date" },
      { label: t("comparables.calculator.csv.headers.price"), key: "price" },
      { label: t("comparables.calculator.csv.headers.emv"), key: "emv" },
      { label: t("comparables.calculator.csv.headers.designation"), key: "designation" },
      { label: t("comparables.calculator.csv.headers.category"), key: "category" },
      { label: t("comparables.calculator.csv.headers.subcategory"), key: "subcategory" },
      { label: t("comparables.calculator.csv.headers.bedrooms"), key: "bedrooms" },
      { label: `${t("comparables.calculator.csv.headers.floor")} ${unit}`, key: "floor" },
      { label: `${t("comparables.calculator.csv.headers.ppa")} ${unit}`, key: "ppa" },
      { label: `${t("comparables.calculator.csv.headers.mppa")} ${unit}`, key: "mppa" },
    ];
  } else {
    return [
      { label: t("comparables.calculator.csv.headers.no"), key: "no" },
      { label: t("comparables.calculator.csv.headers.address"), key: "address" },
      { label: t("comparables.calculator.csv.headers.postcode"), key: "postcode" },
      { label: t("comparables.calculator.csv.headers.date"), key: "date" },
      { label: t("comparables.calculator.csv.headers.price"), key: "price" },
      { label: t("comparables.calculator.csv.headers.emv"), key: "emv" },
      { label: t("comparables.calculator.csv.headers.new"), key: "new" },
      { label: t("comparables.calculator.csv.headers.designation"), key: "designation" },
      { label: t("comparables.calculator.csv.headers.category"), key: "category" },
      { label: t("comparables.calculator.csv.headers.subcategory"), key: "subcategory" },
      { label: t("comparables.calculator.csv.headers.bedrooms"), key: "bedrooms" },
      { label: t("comparables.calculator.csv.headers.estBedMin"), key: "estBedMin" },
      { label: t("comparables.calculator.csv.headers.estBedMax"), key: "estBedMax" },
      { label: `${t("comparables.calculator.csv.headers.floor")} ${unit}`, key: "floor" },
      { label: `${t("comparables.calculator.csv.headers.ppa")} ${unit}`, key: "ppa" },
      { label: `${t("comparables.calculator.csv.headers.mppa")} ${unit}`, key: "mppa" },
      { label: t("comparables.calculator.csv.headers.tenure"), key: "tenure" },
    ];
  }
};

export const zooplaCSVHeaders = ({ t, unitPreference }: CSVHeaderProps) => {
  const unitType =
    unitPreference === MeasurementSystem.METRIC
      ? t("comparables.sqm.alt")
      : t("comparables.sqft.alt");
  return [
    { label: t("comparables.calculator.csv.headers.no"), key: "no" },
    { label: t("comparables.calculator.csv.headers.address"), key: "address" },
    { label: t("comparables.calculator.csv.headers.postcode"), key: "postcode" },
    { label: t("comparables.calculator.csv.headers.zoopla.market_value"), key: "currentValuation" },
    { label: t("comparables.calculator.csv.headers.zoopla.last_value"), key: "lastValue" },
    { label: t("comparables.calculator.csv.headers.zoopla.last_value_type"), key: "lastValueType" },
    {
      label: t("comparables.calculator.csv.headers.zoopla.last_value_date"),
      key: "lastValueDate",
    },
    {
      label: t("comparables.calculator.csv.headers.zoopla.last_value_per_area", { unitType }),
      key: "lastValuePPA",
    },
    {
      label: t("comparables.calculator.csv.headers.zoopla.market_value_per_area", { unitType }),
      key: "currentValuePPA",
    },
    { label: t("comparables.calculator.csv.headers.zoopla.property_type"), key: "propertyType" },
    {
      label: t("comparables.calculator.csv.headers.zoopla.property_sub_type"),
      key: "propertySubType",
    },
    {
      label: t("comparables.calculator.csv.headers.zoopla.year_built"),
      key: "yearBuilt",
    },
    { label: t("comparables.calculator.csv.headers.bedrooms"), key: "bedrooms" },
    {
      label: t("comparables.calculator.csv.headers.zoopla.floor_area", { unitType }),
      key: "floorArea",
    },
    { label: t("comparables.calculator.csv.headers.zoopla.asking_rental"), key: "askingRental" },
    {
      label: t("comparables.calculator.csv.headers.zoopla.asking_rental_date"),
      key: "askingRentalDate",
    },
    {
      label: t("comparables.calculator.csv.headers.zoopla.estimated_rental"),
      key: "estimatedRental",
    },
    {
      label: t("comparables.calculator.csv.headers.zoopla.asking_rental_per_area", { unitType }),
      key: "askingRentalPPA",
    },
    {
      label: t("comparables.calculator.csv.headers.zoopla.estimated_rental_per_area", { unitType }),
      key: "estimatedRentalPPA",
    },
    { label: t("comparables.calculator.csv.headers.tenure"), key: "tenure" },
  ];
};

export function getHeadersFactory(priceMode: PriceModes) {
  return isZoopla(priceMode) ? zooplaCSVHeaders : landTechCSVHeaders;
}

interface LandTechCSVTransactionsProps {
  t: Translation;
  transactions: RecordType[];
  unitPreference: MeasurementSystem;
}
export const landTechCSVTransactions = ({
  t,
  transactions,
  unitPreference,
}: LandTechCSVTransactionsProps): CSVRowProps[] => {
  return transactions.map((tr, i) => ({
    no: i + 1,
    address: tr.address,
    postcode: tr.post_code,
    date: dateIsoString(tr.date_of_transfer),
    price: tr.price ?? undefined,
    emv: tr.est_price ?? undefined,
    new: String(tr.is_new_property),
    designation: tr.designation,
    category: tr.category,
    subcategory: tr.sub_category,
    bedrooms: tr.num_bedrooms,
    estBedMin: tr.est_bedrooms?.[0],
    estBedMax:
      tr.est_bedrooms && (tr.est_bedrooms.length === 2 ? tr.est_bedrooms[1] : tr.est_bedrooms[0]),
    floor: toUsersPreferredArea({ area: tr.total_floor_area || 0, unitPreference }).toFixed(2),
    ppa: pricePerUnitArea({
      price: tr.price || 0,
      area: tr.total_floor_area || 0,
      unitPreference,
    }).toFixed(2),
    mppa: pricePerUnitArea({
      price: tr.est_price || 0,
      area: tr.total_floor_area || 0,
      unitPreference,
    }).toFixed(2),
    tenure:
      tr.tenure && tr.tenure === "F"
        ? t("comparables.property.types.freehold")
        : t("comparables.property.types.leasehold"),
  }));
};

interface ZooplaCSVTransactionsProps {
  t: Translation;
  transactions: ZooplaComparableDTO[];
  unitPreference: MeasurementSystem;
}

export const writeZooplaAddress = (address?: ZooplaComparableDTO["location"]["address"]) => {
  if (!address) {
    return "";
  }

  return [
    `${address.propertyNumberOrName} ${address.streetName}`,
    address.townOrCity,
    address.locality,
    address.postcode,
  ]
    .filter(identity)
    .join(", ");
};

export const zooplaCSVTransactions = ({
  transactions,
  unitPreference,
}: ZooplaCSVTransactionsProps): CSVRowProps[] => {
  return transactions.map((tr, i) => {
    return {
      no: i + 1,
      address: writeZooplaAddress(tr.location?.address),
      postcode: tr.location?.address?.postcode,
      currentValuation: tr.valuation?.sale?.currentValue,
      lastValue: tr.valuation?.sale?.lastValue,
      lastValueType:
        ValuationTypeMapping[
          (tr.valuation?.sale?.lastValueType as keyof typeof ValuationTypeMapping) ?? ""
        ] ?? tr.valuation?.sale?.lastValueType,
      lastValueDate: tr.valuation?.sale?.lastValueDate,
      lastValuePPA: Number(
        pricePerUnitArea({
          price: tr.valuation?.sale?.lastValue ?? 0,
          area: ftsqToMsq(tr.attributes?.floorArea ?? 0),
          unitPreference,
        }).toFixed(2)
      ),
      currentValuePPA: Number(
        pricePerUnitArea({
          price: tr.valuation?.sale?.currentValue ?? 0,
          area: ftsqToMsq(tr.attributes?.floorArea ?? 0),
          unitPreference,
        }).toFixed(2)
      ),
      propertyType: tr.attributes?.propertyType ?? null,
      propertySubType: tr.attributes?.propertySubType ?? null,
      yearBuilt: tr.attributes?.yearBuilt ?? null,
      bedrooms: tr.attributes?.bedrooms ?? null,
      floorArea: Number(
        (unitPreference === MeasurementSystem.METRIC
          ? ftsqToMsq(tr.attributes?.floorArea ?? 0)
          : tr.attributes?.floorArea ?? 0
        ).toFixed(2)
      ),
      askingRental: /asking/i.test(tr.valuation?.rent?.lastValueType ?? "")
        ? tr.valuation?.rent?.lastValue
        : null,
      askingRentalDate: tr.valuation?.rent?.lastValueDate,
      estimatedRental: tr.valuation?.rent?.currentValue,
      askingRentalPPA: Number(
        pricePerUnitArea({
          price: /asking/i.test(tr.valuation?.rent?.lastValueType ?? "")
            ? Number(tr.valuation?.rent?.lastValue)
            : 0,
          area: ftsqToMsq(tr.attributes?.floorArea ?? 0),
          unitPreference,
        }).toFixed(2)
      ),
      estimatedRentalPPA: Number(
        pricePerUnitArea({
          price: tr.valuation?.rent?.currentValue ?? 0,
          area: ftsqToMsq(tr.attributes?.floorArea ?? 0),
          unitPreference,
        }).toFixed(2)
      ),
      tenure: tr.attributes.tenure ?? null,
    };
  });
};

export function getTransformer(priceMode: PriceModes) {
  return isZoopla(priceMode) ? zooplaCSVTransactions : landTechCSVTransactions;
}
