export const ArrowRightIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" fill="none" viewBox="0 0 8 12">
      <path
        fill="currentColor"
        d="M4.977 6L.852 1.875 2.03.697 7.333 6 2.03 11.303.852 10.125 4.977 6z"
      ></path>
    </svg>
  );
};
