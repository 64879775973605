import { useCallback, useMemo, useRef } from "react";
import classNames from "classnames";
import Feature from "src/js/stores/user/Feature";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import { SwitchButtons } from "../SwitchButtons";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useSnapshot } from "valtio";
import { updateActiveRecipientId } from "src/js/stores/letter/actions";
import {
  DropdownMenu,
  DropdownMenuSize,
  DropdownMenuTriggerAlignment,
} from "react-migration/components/DropdownMenu";
import { Tooltip } from "react-migration/components/Tooltip";
import { Theme } from "react-migration/lib/theme/Theme";
import { Recipient } from "react-migration/domains/sites/typings/Recipient";
import { letterStore } from "src/js/stores/letter/store";

export enum DropdownTitleLayout {
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical",
}

type RecipientsDropdownProps = {
  titleLayout?: DropdownTitleLayout;
};

export const RecipientsDropdown = ({
  titleLayout = DropdownTitleLayout.VERTICAL,
}: RecipientsDropdownProps) => {
  const { t } = useTranslation();
  const usAccess = hasFeature(Feature.usAccess);

  const { recipients, selectedRecipientIds, activeRecipientId } = useSnapshot(letterStore);

  const isHorizontal = titleLayout === DropdownTitleLayout.HORIZONTAL;

  const triggerRef = useRef<HTMLButtonElement>(null);
  const contentWidth = triggerRef?.current?.clientWidth ?? (isHorizontal ? 580 : 320);

  const selectedRecipient = useMemo(() => {
    return recipients?.find((recipient: Recipient) => recipient._id === activeRecipientId);
  }, [activeRecipientId, recipients]);

  const selectedRecipients = recipients?.filter((recipient) =>
    selectedRecipientIds?.includes(recipient._id)
  );

  const currentRecipientIndex = useMemo(() => {
    return activeRecipientId && selectedRecipientIds
      ? selectedRecipientIds.indexOf(activeRecipientId)
      : -1;
  }, [activeRecipientId, selectedRecipientIds]);

  const onGetPreviousRecipient = useCallback(() => {
    if (currentRecipientIndex > 0 && selectedRecipientIds) {
      updateActiveRecipientId(selectedRecipientIds[currentRecipientIndex - 1]);
    }
  }, [currentRecipientIndex, selectedRecipientIds]);

  const onGetNextRecipient = useCallback(() => {
    if (selectedRecipientIds && currentRecipientIndex !== selectedRecipientIds.length - 1) {
      updateActiveRecipientId(selectedRecipientIds[currentRecipientIndex + 1]);
    }
  }, [currentRecipientIndex, selectedRecipientIds]);

  const selectedRecipientValue = useMemo(
    () => (
      <Tooltip.Wrapper>
        <Tooltip.Trigger className="atlas-pointer-events-auto atlas-overflow-hidden">
          <div
            className={classNames(
              "atlas-flex",
              "atlas-flex-row",
              "atlas-items-start",
              "atlas-text-left",
              "atlas-flex-nowrap",
              "atlas-mr-2",
              "atlas-gap-x-2"
            )}
            data-testid="selected-recipient-placeholder"
          >
            <strong
              className={classNames(
                isHorizontal ? "atlas-max-w-[350px]" : "atlas-w-[190px]",
                "atlas-overflow-hidden",
                "atlas-text-ellipsis",
                "atlas-whitespace-nowrap"
              )}
            >
              {selectedRecipient?.fullName}
            </strong>
            {!usAccess && selectedRecipient?.title}
          </div>
        </Tooltip.Trigger>
        <Tooltip.Content align="center" side="bottom" theme={Theme.Dark} size="sm">
          <span
            className="atlas-whitespace-normal"
            data-testid="selected-recipient-tooltip-content"
          >
            {selectedRecipient?.fullName}
          </span>
        </Tooltip.Content>
      </Tooltip.Wrapper>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRecipient]
  );

  return (
    <div
      className={classNames(
        "atlas-flex",
        "atlas-flex-row",
        "atlas-w-full",
        "atlas-border-b",
        "atlas-border-border-divider-subtle",
        "atlas-justify-center",
        isHorizontal ? "atlas-py-2" : "atlas-pt-11 atlas-pb-3 atlas-mb-5"
      )}
      data-testid="recipient-select-dropdown"
    >
      <div
        className={classNames(
          "atlas-flex atlas-justify-between",
          isHorizontal
            ? [usAccess ? "atlas-w-letter" : "atlas-w-a4", "atlas-mr-3", "atlas-gap-2"]
            : "atlas-w-full"
        )}
      >
        <div
          className={classNames(
            "atlas-items-center",
            isHorizontal
              ? "atlas-flex atlas-flex-row atlas-w-full atlas-gap-2"
              : "atlas-flex-auto atlas-flex-wrap atlas-mr-3 -atlas-mt-8"
          )}
        >
          <div
            className={classNames(
              "atlas-font-semibold",
              "atlas-text-sm",
              "atlas-leading-5",
              "atlas-text-content-secondary",
              !isHorizontal && "atlas-mb-3"
            )}
          >
            {t(isHorizontal ? "sites.letters.recipient" : "sites.card.letters.select_recipient")}
          </div>

          <DropdownMenu.Provider size={DropdownMenuSize.AUTO} className="atlas-w-full">
            <DropdownMenu.Trigger
              align={DropdownMenuTriggerAlignment.BOTTOM}
              className="atlas-w-full"
              data-testid="recipients-dropdown-trigger"
              ref={triggerRef}
            >
              <div
                className={classNames(
                  "atlas-flex",
                  "atlas-flex-1",
                  "atlas-justify-between",
                  "atlas-items-center",
                  "atlas-pl-3",
                  "atlas-pr-2",
                  "atlas-h-10",
                  "atlas-bg-white",
                  "atlas-border",
                  "atlas-border-border-divider",
                  "atlas-rounded"
                )}
              >
                <span>{selectedRecipientValue}</span>
                <i className="icon-lt-arrow-down-s-line atlas-text-xl" />
              </div>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content
              style={{ width: contentWidth }}
              className={classNames(
                "atlas-border",
                "atlas-border-border-divider",
                "atlas-rounded",
                "!atlas-max-h-[500px]",
                "atlas-overflow-y-auto"
              )}
            >
              {selectedRecipients?.map((recipient) => (
                <DropdownMenu.Item
                  key={recipient._id}
                  style={{ maxWidth: contentWidth }}
                  className={classNames(
                    {
                      "atlas-bg-background-action-subtle-hover":
                        recipient._id === selectedRecipient?._id,
                    },
                    "atlas-flex",
                    "atlas-flex-col",
                    "atlas-py-2",
                    "atlas-px-3"
                  )}
                  onClick={() => updateActiveRecipientId(recipient._id)}
                >
                  <strong>{recipient.fullName}</strong>
                  {!usAccess && <span>{recipient.title}</span>}
                </DropdownMenu.Item>
              ))}
            </DropdownMenu.Content>
          </DropdownMenu.Provider>
        </div>

        <SwitchButtons
          currentIndex={currentRecipientIndex}
          totalCount={selectedRecipientIds?.length ?? 0}
          onPreviousBtnClick={onGetPreviousRecipient}
          onNextBtnClick={onGetNextRecipient}
        />
      </div>
    </div>
  );
};
