import { InfoList } from "react-migration/components/InfoList";
import PropertySaleValueQuery from "./PropertySaleValueQuery.gql";
import { useQuery } from "@apollo/client";
import {
  LandTechEndpoints,
  routedClient,
} from "react-migration/lib/persistence/apollo/routedClient";
import { Currency, formatToCurrency } from "react-migration/lib/util/numberFormat";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useMemo } from "react";
import { dateString } from "src/js/util/dates";
import { userStore } from "src/js/stores/user/store";

interface PropertySaleValue {
  _id: string;
  currency: Currency;
  date_of_transfer: string;
  price: number;
  property_id: string;
}

interface PropertySaleValueProps {
  uprn: string;
}

export function PropertySaleValue({ uprn }: Readonly<PropertySaleValueProps>) {
  const { t } = useTranslation();
  const { loading, data } = useQuery<{ comparablesByUPRN: PropertySaleValue[] }>(
    PropertySaleValueQuery,
    {
      variables: { uprn },
      client: routedClient,
      context: {
        endpoint: LandTechEndpoints.Gateway,
      },
    }
  );

  return (
    <InfoList.Item>
      <InfoList.ItemTitle>
        {t("ownership.react.map.sidebar.property_info.value_title")}
      </InfoList.ItemTitle>

      <InfoList.ItemValue>
        {loading ? (
          <span className="atlas-italic atlas-text-neutral-500">
            {t("ownership.react.map.sidebar.property_info.value_loading")}
          </span>
        ) : (
          <Price sales={data?.comparablesByUPRN} sortBy={sortSalesByDate} />
        )}
      </InfoList.ItemValue>
    </InfoList.Item>
  );
}

function sortSalesByDate(a: PropertySaleValue, b: PropertySaleValue) {
  return new Date(b.date_of_transfer).getTime() - new Date(a.date_of_transfer).getTime();
}

interface PriceProps {
  sales?: PropertySaleValue[];
  sortBy: (a: PropertySaleValue, b: PropertySaleValue) => number;
}

function Price({ sales, sortBy }: Readonly<PriceProps>) {
  const { t } = useTranslation();
  const { locale } = useSnapshot(userStore);

  const latestSale = useMemo(() => {
    const sortedTransactions = sales?.sort(sortBy);

    if (!sortedTransactions?.length) return;

    return {
      price: formatToCurrency({
        amount: sortedTransactions[0].price,
        currency: sortedTransactions[0].currency,
        locale,
      }),
      date: dateString(sortedTransactions[0].date_of_transfer, locale),
    } as const;
  }, [locale, sales, sortBy]);

  if (!latestSale?.date || !latestSale.price)
    return <span>{t("ownership.react.map.sidebar.property_info.value_unavailable")}</span>;

  return (
    <span>
      {t("ownership.react.map.sidebar.property_info.value", {
        price: latestSale.price,
        date: latestSale.date,
      })}
    </span>
  );
}
