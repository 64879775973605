import { Button } from "react-migration/components/DeprecatedButton";
import { TemporaryModal } from "react-migration/components/Modal/TemporaryModal";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import classNames from "classnames";

interface BalanceWarningModalProps {
  isOpen: boolean;
  onCloseClick: () => void;
  onTopUpClick: () => void;
}

export const BalanceWarningModal = ({
  isOpen,
  onCloseClick,
  onTopUpClick,
}: BalanceWarningModalProps) => {
  const { t } = useTranslation();

  return (
    <TemporaryModal
      isOpen={isOpen}
      title={t("sites.letters.payment_warning_modal.unable_to_complete_payment")}
      width="atlas-w-1/5 atlas-min-w-[450px]"
      headerClass="atlas-h-12 atlas-p-3"
      titleClass="atlas-font-semibold atlas-text-base atlas-leading-6"
      handleClose={onCloseClick}
    >
      <div className={classNames("atlas-flex", "atlas-flex-col", "atlas-gap-y-2")}>
        <div
          className={classNames(
            "atlas-font-normal",
            "atlas-text-sm",
            "atlas-leading-5",
            "atlas-border-b",
            "atlas-border-neutral-300",
            "atlas-p-3"
          )}
        >
          {t("sites.letters.payment_warning_modal.you_dont_have_enough_balance_to_buy_the_letters")}
        </div>
        <div
          className={classNames(
            "atlas-flex",
            "atlas-justify-end",
            "atlas-gap-2",
            "atlas-pb-2",
            "atlas-pr-2"
          )}
        >
          <Button.Secondary onClick={onCloseClick}>
            {t("sites.letters.payment_warning_modal.cancel")}
          </Button.Secondary>
          <Button.Primary onClick={onTopUpClick}>
            {t("sites.letters.payment_warning_modal.top_up_balance")}
          </Button.Primary>
        </div>
      </div>
    </TemporaryModal>
  );
};
