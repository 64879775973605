import { LayerTypePrintableProps } from "../../types";
import {
  BuildingHeightSummary,
  UniqueUseClasses,
  usePropertiesBySelection,
} from "./UKPropertyInformationSelectionSidebar/UKPropertyInformationSelectionSidebar";
import { useEffect } from "react";

export function UKPropertyInformationPrintable({
  selection,
  onLoaded,
  onUnmount,
}: LayerTypePrintableProps) {
  const { properties, loading } = usePropertiesBySelection(selection);

  useEffect(() => {
    if (!loading) {
      onLoaded();
    }
  }, [onLoaded, loading]);

  useEffect(() => {
    return () => {
      onUnmount();
    };
  }, [onUnmount]);

  return (
    <>
      <h3>Property details</h3>

      <div className="atlas-my-4 atlas-flex atlas-gap-2 atlas-flex-col">
        <BuildingHeightSummary properties={properties} />
        <UniqueUseClasses properties={properties} />
      </div>
    </>
  );
}
