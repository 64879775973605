import { DrawingItem } from "src/js/types/DrawingItem";
import { drawingStore } from "../store";

export const updateDrawing = (drawing: DrawingItem, persist: boolean) => {
  const store = drawingStore.reduxStore?.store;
  const actions = drawingStore.reduxStore?.actions;
  if (!store || !actions) return;

  store.dispatch(actions.updateDrawing(drawing, persist));
};
