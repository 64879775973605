import { Dropdown } from "react-migration/components/Dropdown";
import { useMemo } from "react";
import { ReactDatePickerCustomHeaderProps } from "react-datepicker";

const range = (size: number, endAt = 0) => {
  return [...Array(size).keys()].map((i) => endAt - i);
};

export function SelectYear({
  changeYear,
  selectedDate,
  onChange,
  maxYear = 2100,
  minYear = 1900,
}: ReactDatePickerCustomHeaderProps & {
  selectedDate: Date;
  onChange: (date: Date) => void;
  maxYear?: number;
  minYear?: number;
}) {
  const yearDifference = maxYear - minYear + 1;
  const yearOptions = useMemo(() => {
    return range(yearDifference, maxYear).map((value) => {
      return {
        label: String(value),
        value: String(value),
      };
    });
  }, []);

  return (
    <div className="atlas-flex atlas-justify-stretch atlas-mt-3">
      <div className="atlas-w-full atlas-px-3">
        <Dropdown
          handleValueChange={({ value }) => {
            selectedDate.setFullYear(Number(value));
            changeYear(Number(value));
            onChange(new Date(selectedDate));
          }}
          value={String(selectedDate?.getFullYear())}
          placeholder="Select year"
          options={yearOptions}
        />
      </div>
    </div>
  );
}
