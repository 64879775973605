import clsx from "clsx";
import { ReactNode, useState } from "react";
import { Theme } from "react-migration/lib/theme/Theme";
import { Tooltip } from "../Tooltip";
import { Alignment, Column, IRow, TableSize } from "./Table";
import { Checkbox, CheckboxState } from "react-migration/components/DeprecatedCheckbox";

const ItemWithTooltip = ({
  trigger,
  content,
  cellClassName,
  width,
}: {
  trigger: ReactNode;
  content?: ReactNode;
  cellClassName: string;
  width?: string;
}) => {
  return (
    <Tooltip.Wrapper>
      <Tooltip.Trigger className={cellClassName} style={{ width }}>
        {trigger}
      </Tooltip.Trigger>
      <Tooltip.Content align="center" side="bottom" theme={Theme.Dark} size="sm">
        {content}
      </Tooltip.Content>
    </Tooltip.Wrapper>
  );
};

interface TableRowsProps<Row extends IRow> {
  primaryKey: keyof Row;
  rows: Row[];
  columns: Column<Row>[];
  selection?: {
    isRowSelected: (row: Row) => boolean;
    selectRow: (row: Row) => void;
    selectWithRowClick?: boolean;
    disabled?: (row: Row) => boolean;
  };
  size: TableSize;
  cellClassName?: (row: Row, column: Column<Row>) => string | undefined;
  rowClassName?: (row: Row) => string | undefined;
  onRowHover?: (row?: Row) => void;
  highlightRowOnHover?: boolean;
}

export function TableRows<Row extends IRow>({
  rows,
  columns,
  selection,
  primaryKey,
  size,
  rowClassName,
  cellClassName: cellClassNameExt,
  onRowHover,
  highlightRowOnHover = true,
}: TableRowsProps<Row>) {
  const [truncatedElementIds, setTruncatedElementIds] = useState<string[]>([]);

  const selectRow = (row: Row) => () => {
    if (!selection?.disabled?.(row)) {
      selection?.selectRow(row);
    }
  };

  const getCellClass = (align: Alignment, grow?: boolean, isRowDisabled?: boolean) =>
    clsx(
      isRowDisabled ? "atlas-text-content-disabled" : "atlas-text-content-secondary",
      "atlas-font-normal atlas-text-sm atlas-leading-5",
      "atlas-whitespace-nowrap atlas-text-ellipsis atlas-overflow-hidden",
      {
        "atlas-py-2 atlas-px-3": size === "small",
        "atlas-py-4 atlas-px-6": size !== "small",
        "atlas-text-right": align === "right",
        "atlas-text-left": align === "left",
        "atlas-text-center": align === "center",
        "atlas-whitespace-nowrap atlas-w-px": !grow,
      }
    );

  const verifyOverflowingElement = (element: HTMLTableCellElement | null, colId: string) => {
    if (!element) {
      return;
    }
    const isEllipsisActive =
      element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth;

    if (isEllipsisActive && !truncatedElementIds.includes(colId)) {
      setTruncatedElementIds((prev) => [...prev, colId]);
    }
  };

  return (
    <tbody className="atlas-bg-white" data-testid="table-body">
      {rows.map((row, rowIndex) => {
        const isRowDisabled = selection?.disabled?.(row);
        return (
          <tr
            key={`row-${row[primaryKey]}`}
            data-testid={`row-${row[primaryKey]}`}
            onClick={selection?.selectWithRowClick ? selectRow(row) : undefined}
            onMouseEnter={() => onRowHover?.(row)}
            onMouseLeave={() => onRowHover?.(undefined)}
            className={clsx(
              "atlas-border-x-0 atlas-border-t-0 atlas-border-b last:atlas-border-b-0 atlas-border-solid atlas-border-border-divider-subtle",
              {
                "hover:atlas-bg-background-light": highlightRowOnHover,
              },
              {
                "atlas-cursor-pointer": selection?.selectWithRowClick,
                "atlas-pointer-events-none atlas-bg-background-light/50": isRowDisabled,
              },
              rowClassName?.(row)
            )}
          >
            {selection && (
              <td
                className="atlas-w-11 atlas-pt-1 atlas-pl-4"
                data-testid={`row-${row[primaryKey]}-checkbox-cell`}
              >
                <Checkbox
                  value={
                    !isRowDisabled && selection.isRowSelected(row)
                      ? CheckboxState.CHECKED
                      : CheckboxState.EMPTY
                  }
                  onChange={selectRow(row)}
                  disabled={isRowDisabled}
                />
              </td>
            )}
            {columns.map((column, colIndex) => {
              const { grow, render, rowKey, align = "left", maxWidth, colSpan } = column;
              let key: string = colIndex.toString();
              let value: ReactNode = "–";
              if (render) {
                value = render(row, rowIndex);
              } else {
                key = rowKey.toString();
                const keyValue = row[rowKey];
                value =
                  typeof keyValue === "string" || typeof keyValue === "number" ? keyValue : "–";
              }

              const colId = `row-${rowIndex}-col-${key}`;
              const showTooltip = truncatedElementIds.includes(colId) && !render;
              const cellClassName = clsx(
                getCellClass(align, grow, isRowDisabled),
                cellClassNameExt?.(row, column)
              );

              return (
                <td
                  key={`col-${key}`}
                  className={!showTooltip ? cellClassName : ""}
                  style={{ maxWidth }}
                  colSpan={colSpan}
                  ref={(el) => verifyOverflowingElement(el, colId)}
                >
                  {showTooltip ? (
                    <ItemWithTooltip
                      trigger={value}
                      content={row[String(rowKey)] as ReactNode}
                      cellClassName={cellClassName}
                      width={maxWidth}
                    />
                  ) : (
                    value
                  )}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
}
