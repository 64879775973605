<template>
  <div class="atlas-flex-grow atlas-w-full">
    <SiteReportPrintWrapper
      :siteId="siteId"
      :activeAssessmentLayers="activeAssessmentLayers"
      :siteOptions="siteOptions"
    />
  </div>
</template>

<script>
import { SiteReportPrintWrapper } from "react-migration/layouts/SiteReport/SiteReportPrintWrapper.tsx";

const defaultSiteOptions = [];
const defaultAssessmentTabs = [];

export default {
  data() {
    return { siteId: null, activeAssessmentLayers: null, siteOptions: null };
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    this.siteId = urlParams.get("siteId");
    this.activeAssessmentLayers = urlParams.get("activeAssessmentLayers") || defaultAssessmentTabs;
    this.siteOptions = urlParams.get("siteOptions") || defaultSiteOptions;
  },
  components: {
    SiteReportPrintWrapper,
  },
};
</script>
