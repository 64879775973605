import { useMemo, useState } from "react";
import { ZodError, SafeParseReturnType } from "zod";
import { ApolloError, useQuery } from "@apollo/client";
import { cleanTypename } from "react-migration/lib/util/cleanTypename";
import { LandTechEndpoints, routedClient } from "react-migration/lib/persistence/apollo";
import SLOPE_STATS_QUERY from "./slopeStatsByGeometry.gql";
import type {
  QueryTopographySlopeStatsByGeometryArgs,
  SlopeStatsData,
  SlopeStatsStrict,
} from "./types";
import { slopeStatsSchema } from "./slopeStatsSchema";

interface UseSlopeStatsQueryOptions {
  onLoaded?(): void;
}

export function useSlopeStatsQuery(
  geometry?: QueryTopographySlopeStatsByGeometryArgs["geometry"] | null,
  options?: UseSlopeStatsQueryOptions
) {
  const cleanedGeometry = useMemo(() => geometry && cleanTypename(geometry), [geometry]);
  const [parsed, setParsed] = useState<SafeParseReturnType<unknown, SlopeStatsStrict>>();
  const { data, ...rest } = useQuery<SlopeStatsData, QueryTopographySlopeStatsByGeometryArgs>(
    SLOPE_STATS_QUERY,
    {
      variables: {
        geometry: cleanedGeometry!,
      },
      skip: !cleanedGeometry,
      client: routedClient,
      context: {
        endpoint: LandTechEndpoints.Gateway,
      },
      onCompleted(data) {
        setParsed(slopeStatsSchema.safeParse(data?.topographySlopeStatsByGeometry));
        options?.onLoaded?.();
      },
      onError() {
        options?.onLoaded?.();
      },
    }
  );

  let error: ZodError | ApolloError | undefined = rest.error;

  if (parsed && !parsed.success) {
    error = parsed.error;
  }

  return { data: data?.topographySlopeStatsByGeometry, error, ...rest };
}
