import { SLOPE_CATEGORIES } from "../constants";
import { useMemo } from "react";
import { Hatch } from "react-migration/domains/constraints/components/Hatch/Hatch";
import { Column, Table } from "react-migration/components/Table";

export function TopographySlopeMapLegend() {
  const slopeLegend = SLOPE_CATEGORIES.map(({ name, color, description }) => ({
    name,
    color,
    description,
  }));

  const columns = useMemo(() => {
    return [
      {
        label: "Category",
        grow: false,
        render: ({ color, name }) => (
          <div className="atlas-flex atlas-gap-2">
            <Hatch borderColor={color} fillColor={color} />
            <span>{name}</span>
          </div>
        ),
      },
      {
        label: "Gradient",
        render: ({ description }) => <span>{description}</span>,
      },
    ] as Column<(typeof slopeLegend)[number]>[];
  }, []);

  return (
    <Table
      primaryKey="name"
      columns={columns}
      rows={slopeLegend}
      size="small"
      hideHeader={true}
      highlightRowOnHover={false}
    />
  );
}
