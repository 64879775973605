import { useState } from "react";
import { Accordion } from "react-migration/components/Accordion";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { TemplateFormAccordionTrigger } from "./components/TemplateFormAccordionTrigger";
import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import { TemplateFormData } from "./TemplateFormDataSchema";
import { FooterSection } from "./components/FooterSection";
import { FirstPageSection, SecondPageSection } from "./components/FormContent";

export enum TemplateFormSection {
  FIRST_PAGE = "firstPage",
  SECOND_PAGE = "secondPage",
  FOOTER = "footer",
}

export interface TemplateFormProps {
  isUploadingLogo: boolean;
  isUploadingSignature: boolean;
  hasSecondPage?: boolean;
  onDeletePage: () => void;
}

export const TemplateForm: React.FC<TemplateFormProps> = ({
  isUploadingLogo,
  isUploadingSignature,
  hasSecondPage,
  onDeletePage,
}) => {
  const { t } = useTranslation();
  const [openedPageIndexes, setOpenedPageIndexes] = useState<TemplateFormSection[]>([
    TemplateFormSection.FIRST_PAGE,
    TemplateFormSection.FOOTER,
  ]);

  const { watch } = useFormContext<TemplateFormData>();

  const signaturePage = watch("signaturePage");

  const onDeleteClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onDeletePage();
  };

  return (
    <form id="templateForm" data-testid="template-form">
      <Accordion
        type={Accordion.Type.multiple}
        defaultValue={[TemplateFormSection.FIRST_PAGE]}
        value={openedPageIndexes}
        onValueChange={(pages: TemplateFormSection[]) => setOpenedPageIndexes(pages)}
        data-testid="template-form-accordion"
      >
        <Accordion.Item
          key={TemplateFormSection.FIRST_PAGE}
          value={TemplateFormSection.FIRST_PAGE}
          data-testid="template-form-page-one-accordion-item"
        >
          <Accordion.Trigger
            data-testid="template-form-page-one-accordion-trigger"
            disabled={isUploadingLogo || (isUploadingSignature && signaturePage === 1)}
          >
            <TemplateFormAccordionTrigger
              label={t("sites.letters.letter_form.page_one")}
              isOpen={openedPageIndexes.includes(TemplateFormSection.FIRST_PAGE)}
            />
          </Accordion.Trigger>
          <Accordion.Content>
            <FirstPageSection />
          </Accordion.Content>
        </Accordion.Item>
        {hasSecondPage && (
          <Accordion.Item
            key={TemplateFormSection.SECOND_PAGE}
            value={TemplateFormSection.SECOND_PAGE}
            data-testid="template-form-page-two-accordion-item"
          >
            <Accordion.Trigger
              data-testid="template-form-page-two-accordion-trigger"
              disabled={isUploadingSignature && signaturePage === 2}
            >
              <TemplateFormAccordionTrigger
                label={t("sites.letters.letter_form.page_two")}
                isOpen={openedPageIndexes.includes(TemplateFormSection.SECOND_PAGE)}
                additionalElements={
                  <i
                    className={classNames(
                      "icon-lt-delete-bin-line",
                      "atlas-text-xl",
                      "atlas-text-background-negative",
                      "atlas-font-semibold",
                      "atlas-px-2.5"
                    )}
                    onClick={onDeleteClick}
                    data-testid="template-form-page-two-accordion-delete-icon"
                  />
                }
              />
            </Accordion.Trigger>
            <Accordion.Content>
              <SecondPageSection />
            </Accordion.Content>
          </Accordion.Item>
        )}

        <Accordion.Item
          key={TemplateFormSection.FOOTER}
          value={TemplateFormSection.FOOTER}
          data-testid="template-form-footer-accordion-item"
        >
          <Accordion.Trigger data-testid="template-form-footer-accordion-trigger">
            <TemplateFormAccordionTrigger
              label={t("sites.letters.template_form.footer_section_title")}
              isOpen={openedPageIndexes.includes(TemplateFormSection.FOOTER)}
            />
          </Accordion.Trigger>
          <Accordion.Content>
            <FooterSection />
          </Accordion.Content>
        </Accordion.Item>
      </Accordion>
    </form>
  );
};
