import { Button } from "react-migration/components/DeprecatedButton";
import { NumericInput } from "react-migration/components/NumericInput";
import { Loading } from "react-migration/components/Loading";
import { Notification } from "react-migration/components/Notification";
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useTopupBalanceMutation } from "../hooks/useTopupBalanceMutation";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ENVIRONMENT } from "src/js/util/environment";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { logEvent } from "react-migration/lib/util/logEvent";
import { formatToCurrency } from "react-migration/lib/util/numberFormat";
import { useAccountQuery } from "../hooks/useAccountQuery";
import GET_ACCOUNT from "../apollo/queries/account.gql";
import { StripePayment } from "react-migration/components/StripePayment/StripePayment";
import {
  AttemptChargeParams,
  PaymentResponse,
} from "react-migration/components/StripePayment/typings";
import { useSnapshot } from "valtio";
import Feature from "src/js/stores/user/Feature";
import { setDashboardTopUpRedirectUrl } from "../../../../../js/stores/site/actions/setDashboardTopUpRedirectUrl";
import { router } from "src/js/router/react-router";
import { siteStore } from "src/js/stores/site/store";
import { updateMapSettings } from "src/js/stores/map/actions/updateMapSettings";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import { USQuickOptions } from "react-migration/domains/nav/components/Modals/TopupModal/components/USQuickOptions";
import { UKQuickOptions } from "react-migration/domains/nav/components/Modals/TopupModal/components/UKQuickOptions";

const stripePromise = loadStripe(ENVIRONMENT.STRIPE_PUBLIC_KEY || "");
export const Credits = () => {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const [disableTopup, setDisableTopup] = useState(false);
  const [paymentComplete, setPaymentComplete] = useState(false);
  const [amount, setAmount] = useState<number>();
  const [errorMessage, setErrorMessage] = useState("");
  const [topupBalance, { loading }] = useTopupBalanceMutation();
  const [amountError, setAmountError] = useState("");
  const { dashboardTopUpRedirectUrl } = useSnapshot(siteStore);
  const [paymentPending, setPaymentPending] = useState(false);

  useEffect(() => {
    if (dashboardTopUpRedirectUrl && paymentComplete) {
      setDashboardTopUpRedirectUrl(undefined);
      updateMapSettings("showSiteUtilityReport", true);
      router.push(dashboardTopUpRedirectUrl);
    }
  }, [dashboardTopUpRedirectUrl, paymentComplete]);

  const handleOpenForm = () => {
    logEvent("Top Up Started", { location: "dashboard" });
    setShowForm(true);
    setDisableTopup(true);
    setPaymentComplete(false);
  };

  const { data: accountData, loading: accountLoading } = useAccountQuery();

  if (accountLoading) {
    return <Loading />;
  }

  if (!accountData) {
    return <Notification.Error title={t("dashboard.something_went_wrong")} icon="info" />;
  }

  const attemptCharge = (
    params: AttemptChargeParams,
    onCompleted: (res: PaymentResponse) => void
  ) => {
    setPaymentPending(true);
    topupBalance({
      variables: {
        pence: params.amount,
        stripeCharge: params.stripeCharge,
      },
      onCompleted: (res) => {
        onCompleted({
          id: res.topupBalance.id,
          status: res.topupBalance.status,
          error_message: res.topupBalance.error_message,
          client_secret: res.topupBalance.client_secret,
        });
        setPaymentPending(false);
        logEvent("Top Up Complete", { amount: params.amount / 100, location: "dashboard" });
      },
      onError: (res) => {
        setErrorMessage(res.message);
        setPaymentPending(false);
        logEvent("Top Up Error", { message: res.message, location: "dashboard" });
      },
    });
  };
  const queriesToInvalidate = [GET_ACCOUNT];

  const handleAmountValueChange = (value: number | undefined) => {
    setAmount(value);
    if (!value) {
      setAmountError("Amount required");
    } else {
      setAmountError("");
    }
  };

  return (
    <Elements stripe={stripePromise}>
      <div>
        <div
          className={clsx(
            "atlas-max-w-[440px] atlas-p-4 atlas-border atlat-border atlas-border-neutral-200 atlas-rounded-t",
            !showForm && "atlas-rounded-b"
          )}
        >
          <div className="atlas-flex atlas-justify-between atlas-items-center">
            <div>
              <p className="atlas-m-0 atlas-text-xl atlas-font-semibold">
                {accountData.accountWithViews.land_reg_credit_pounds >= 0
                  ? `${formatToCurrency({
                      amount: accountData.accountWithViews.land_reg_credit_pounds,
                      currency: hasFeature(Feature.usAccess) ? "USD" : "GBP",
                    })}`
                  : t("dashboard.overdrawn_by", {
                      amount: `${formatToCurrency({
                        amount: accountData.accountWithViews.land_reg_credit_pounds,
                        currency: hasFeature(Feature.usAccess) ? "USD" : "GBP",
                      })}`,
                    })}
              </p>
            </div>
            <Button.Primary
              disabled={(disableTopup && !paymentComplete) || !hasFeature(Feature.documentPurchase)}
              onClick={handleOpenForm}
              data-testid="topup-button"
            >
              {t("dashboard.topup.top_up")}
            </Button.Primary>
          </div>
          {!hasFeature(Feature.documentPurchase) && !hasFeature(Feature.usAccess) && (
            <Notification.Info
              additionalClassName="atlas-mt-3"
              title={t("dashboard.topup.credit_topup_disabled")}
              message={t("dashboard.topup.credit_topup_disabled_text")}
            />
          )}
        </div>

        {showForm && !paymentComplete && (
          <div className="atlas-max-w-[440px] atlas-p-4 atlas-bg-neutral-50 atlas-border atlas-border-t-0 atlas-border-neutral-200 atlas-rounded-b">
            <div className="atlas-mb-4">
              {paymentPending && <Loading />}
              {!paymentPending && (
                <>
                  <NumericInput
                    value={amount}
                    onChange={(v) => handleAmountValueChange(v)}
                    onKeyUp={(v) => handleAmountValueChange(Number(v.currentTarget.value))}
                    placeholder="0"
                    label={t("dashboard.topup.topup.amount")}
                    errorMessage={amountError}
                    units={hasFeature(Feature.usAccess) ? "$" : "£"}
                    unitsPrefix={true}
                    hideSteppers={true}
                  />
                  {hasFeature(Feature.usAccess) ? (
                    <USQuickOptions handleAmountValueChange={handleAmountValueChange} />
                  ) : (
                    <UKQuickOptions handleAmountValueChange={handleAmountValueChange} />
                  )}
                </>
              )}
            </div>
            <StripePayment
              amount={amount || 0}
              attemptCharge={attemptCharge}
              setPaymentComplete={setPaymentComplete}
              queriesToInvalidate={queriesToInvalidate}
              errorMessage={errorMessage}
              isFormInvalid={Boolean(amountError)}
              loading={loading}
            />
          </div>
        )}
        {paymentComplete && (
          <Notification.Success
            additionalClassName="atlas-w-[440px] atlas-mt-4"
            title={t("dashboard.topup.payment_complete")}
            message={t("dashboard.topup.payment_complete_text")}
            icon="check"
          />
        )}
      </div>
    </Elements>
  );
};
