import classNames from "classnames";

export type DropDownMenuArrowProps = {
  className?: string;
  "data-testid"?: string;
};

export const DropDownMenuArrow = ({
  className: additionalClasses,
  "data-testid": dataTestId,
}: DropDownMenuArrowProps) => (
  <i
    className={classNames(
      "icon-lt-arrow-right-s-line",
      "atlas-ml-2",
      "atlas-text-content-primary",
      "atlas-text-md",
      additionalClasses
    )}
    data-testid={dataTestId ?? "dropdown-menu-arrow"}
  />
);
