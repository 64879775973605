import { useQuery } from "@apollo/client";
import propertyInformationByUPRN from "./propertyInformationByUPRN.gql";
import { routedClient } from "react-migration/lib/persistence/apollo/routedClient";

interface Valuation {
  ba_name?: string;
  primary_description: number;
  total_value: number;
  total_area: number;
  list_year: number;
  ppsqm: number;
}

interface PropertyInformation {
  address: {
    uprn: string;
    full_address?: string;
    use_class_description_2020?: string;
    property_class_description?: string;
    property_class_code?: string;
    state?: string;
    location: {
      coordinates: [number, number];
    };
    voa_record?: {
      uarn: string;
      valuations?: Valuation[];
    };
  };

  dimensions?: {
    area_floor_total_metric?: number;
  };

  building_height?: {
    uprn?: string;
    rel_h_max?: number;
    rel_h_to_roof?: number;
    no_floors_estimate?: number;
  };
}

export function usePropertyInformationByUPRN(uprn?: string) {
  return useQuery<{ propertyInformationByUPRN: PropertyInformation }>(propertyInformationByUPRN, {
    variables: { uprn: uprn! },
    skip: !uprn,
    client: routedClient,
  });
}
