import { useEffect, useMemo, useState } from "react";
import { ZodError, SafeParseReturnType } from "zod";
import { ApolloError, useQuery } from "@apollo/client";
import { cleanTypename } from "react-migration/lib/util/cleanTypename";
import { LandTechEndpoints, routedClient } from "react-migration/lib/persistence/apollo";
import TOPOGRAPHY_STATS_QUERY from "./topographyStatsByGeometry.gql";
import type {
  QueryTopographyElevationStatsByGeometryArgs,
  ElevationStatsData,
  ElevationStatsStrict,
} from "./types";
import { topographyStatsSchema } from "./topographyStatsSchema";

interface UseTopographyStatsQueryOptions {
  onLoaded?(): void;
  selectionIsWithinAreaConstraint: boolean;
}

export function useTopographyStatsQuery(
  geometry?: QueryTopographyElevationStatsByGeometryArgs["geometry"] | null,
  options?: UseTopographyStatsQueryOptions
) {
  const { selectionIsWithinAreaConstraint } = options || { selectionIsWithinAreaConstraint: true };
  const cleanedGeometry = useMemo(() => geometry && cleanTypename(geometry), [geometry]);
  const [parsed, setParsed] = useState<SafeParseReturnType<unknown, ElevationStatsStrict>>();
  const { data, ...rest } = useQuery<
    ElevationStatsData,
    QueryTopographyElevationStatsByGeometryArgs
  >(TOPOGRAPHY_STATS_QUERY, {
    variables: {
      geometry: cleanedGeometry!,
    },
    skip: !cleanedGeometry || !selectionIsWithinAreaConstraint,
    client: routedClient,
    context: {
      endpoint: LandTechEndpoints.Gateway,
    },
    onCompleted(data) {
      setParsed(topographyStatsSchema.safeParse(data?.topographyElevationStatsByGeometry));
    },
    onError() {
      setParsed(topographyStatsSchema.safeParse({ elevation: null }));
    },
  });

  // We can't rely on onCompleted above, as if the cache is hit, it will never fire,
  // and onLoaded will not be called despite data being populated
  useEffect(() => {
    if (parsed) {
      options?.onLoaded?.();
    }
  }, [parsed, options]);

  let error: ZodError | ApolloError | undefined = rest.error;

  if (parsed && !parsed.success) {
    error = parsed.error;
  }

  return { data: (data as ElevationStatsData)?.topographyElevationStatsByGeometry, error, ...rest };
}
