import "src/js/util/map_loader";

import * as turf from "@turf/turf";

import {
  ConstraintLayerProps,
  buildConstraintsTileUrl,
} from "react-migration/domains/constraints/components/ConstraintLayer/ConstraintLayer";
import { useSnapshot } from "valtio";
import {
  withSkeletonLoading,
  WithSkeletonLoadingProps,
} from "react-migration/components/SkeletonLoading";
import { useEffect, useMemo, useReducer } from "react";

import { CONSTRAINTS_MIN_ZOOM } from "react-migration/layouts/map/Multilayer/layer_types/ConstraintsLayerType/constants";
import { DesignationLayer } from "react-migration/domains/constraints/components/ConstraintLayer/DesignationLayer";
import SITE_OVERVIEW_US from "react-migration/domains/sites/apollo/li-api/queries/siteOverviewUS.gql";
import { SiteLayer } from "./layers/site/SiteLayer";
import { geoJsonPolyToGoogleBounds, getZoomLevelForBounds } from "src/js/util/map_util";
import { routedClient } from "react-migration/lib/persistence/apollo";
import { useQuery } from "@apollo/client/react/hooks/useQuery";
import { siteStore } from "src/js/stores/site/store";
import { SiteOverviewResponseData } from "../../typings/apollo";
import { StaticMap } from "./renderers/StaticMapReact";

type ConstraintsOptions = Required<
  Pick<
    ConstraintLayerProps,
    "featureIsVisible" | "dedupePoints" | "designationAttributes" | "rootCategory"
  >
>;

type StaticMapContainerProps = {
  siteId?: string;
  mapId: string;
  mapZoom?: number;
  mapDimensions: { height: number; width: number };
  initialMapTypeId?: string;
  constraintsOptions?: ConstraintsOptions[];
  onMapLoaded?: () => void;
};

/**
 * Given various layer config options will fetch necessary data and then build DeckGL layers for use in the Static Map
 *
 * The longer term vision with this is to remove layer building from here and delegate that to consumers to build the layers they want
 */
export const StaticMapContainer: React.FC<StaticMapContainerProps> = withSkeletonLoading(
  ({
    hideSkeletonLoading,
    siteId,
    mapId,
    initialMapTypeId,
    mapZoom,
    mapDimensions: { height, width },
    onMapLoaded,
    constraintsOptions,
  }: WithSkeletonLoadingProps & StaticMapContainerProps) => {
    const { siteCardId } = useSnapshot(siteStore);
    const siteIdFromPropsOrStore = siteId ?? siteCardId;

    const [mapRendered, setMapRendered] = useReducer(() => true, false);

    const { loading, data } = useQuery<SiteOverviewResponseData>(SITE_OVERVIEW_US, {
      variables: { _id: siteIdFromPropsOrStore },
      client: routedClient,
    });

    const bounds = useMemo(() => {
      if (!loading && data) {
        return geoJsonPolyToGoogleBounds(data.siteOverview.geometry as GeoJSON.Polygon);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, data?.siteOverview.geometry]);

    const siteLatLng = useMemo(() => {
      if (!loading && data?.siteOverview.location) {
        return {
          lat: data.siteOverview.location.coordinates[1],
          lng: data.siteOverview.location.coordinates[0],
        };
      }
    }, [loading, data?.siteOverview.location]);

    useEffect(() => {
      if (!loading) {
        hideSkeletonLoading();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    useEffect(() => {
      if (!loading && mapRendered) {
        onMapLoaded?.();
      }
    }, [loading, mapRendered, onMapLoaded]);

    const siteFeatures = useMemo(() => {
      const siteGeometry = data?.siteOverview.geometry;
      if (!siteGeometry) {
        return [];
      } else {
        return [turf.feature(siteGeometry)];
      }
    }, [data?.siteOverview.geometry]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const layers = [];

    if (constraintsOptions?.length) {
      constraintsOptions.forEach((layerOptions) => {
        const url = buildConstraintsTileUrl({ ...layerOptions });
        const designationsLayer = new DesignationLayer({
          id: layerOptions.rootCategory,
          data: url,
          featureIsVisible: layerOptions.featureIsVisible,
          showLabels: false,
          minZoom: CONSTRAINTS_MIN_ZOOM,
        });
        layers.push(designationsLayer);
      });
    }

    if (siteFeatures) {
      // Site outline should always be pushed last so it sits on "top" of other layers.
      layers.push(
        new SiteLayer({
          features: siteFeatures,
          hexColor: data?.siteOverview._stage.color ?? "#FFFF00",
        })
      );
    }

    if (loading || !bounds || !siteLatLng) return null;

    return (
      <StaticMap
        id={mapId}
        height={height}
        width={width}
        center={siteLatLng}
        mapType={initialMapTypeId}
        zoom={mapZoom || getZoomLevelForBounds(bounds, { width, height })}
        layers={layers}
        onReady={setMapRendered}
      />
    );
  },
  {
    showPadding: false,
  }
);
