export const CheckedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <g>
        <circle cx="10" cy="10" r="9" fill="#fff" stroke="#D1D5DB" strokeWidth="2"></circle>
        <circle cx="10" cy="10" r="5" fill="#0284C7"></circle>
      </g>
    </svg>
  );
};
