import { i18n } from "src/js/i18n";
import { DropdownItem } from "react-migration/components/Dropdown";
export const emailFrequencyOptions: DropdownItem[] = [
  {
    label: i18n.t("dashboard.planning_alerts.never").toString(),
    value: "0",
  },
  {
    label: i18n.t("dashboard.planning_alerts.day").toString(),
    value: "1",
  },
  {
    label: i18n.t("dashboard.planning_alerts.2_days").toString(),
    value: "2",
  },
  {
    label: i18n.t("dashboard.planning_alerts.3_days").toString(),
    value: "3",
  },
  {
    label: i18n.t("dashboard.planning_alerts.4_days").toString(),
    value: "4",
  },
  {
    label: i18n.t("dashboard.planning_alerts.5_days").toString(),
    value: "5",
  },
  {
    label: i18n.t("dashboard.planning_alerts.6_days").toString(),
    value: "6",
  },
  {
    label: i18n.t("dashboard.planning_alerts.7_days").toString(),
    value: "7",
  },
];
