import type { Layer, Widget } from "@deck.gl/core";

/**
 * Used to set the loading state of deck.gl layers in valtio.
 */
export class DeckStaticMapLayersLoadNotifierWidget implements Widget<Record<string, never>> {
  id;
  props = {};

  constructor(private readonly mapId: string, private readonly onLoadComplete: () => void) {
    this.id = `deckgl-static-map-${mapId}-widget`;
  }

  setProps() {
    // noop - Required by Widget interface
  }

  onAdd() {
    // noop - Required by Widget interface
    return null;
  }

  onRemove() {
    return null;
  }

  onRedraw({ layers }: { layers: Layer[] }) {
    const loaded = layers
      .filter((layer) => layer.root.props.visible)
      .every((layer) => layer.isLoaded);

    if (loaded) {
      this.onLoadComplete();
    }
  }
}
