import { FC } from "react";
import { Section } from "react-migration/components/Section";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { ModalV2 } from "react-migration/components/ModalV2/ModalV2";

interface OwnershipLegendProps {
  showModal: boolean;
  setShowModal: (v: boolean) => void;
}

export const OwnershipLegend: FC<OwnershipLegendProps> = ({ showModal, setShowModal }) => {
  const { t } = useTranslation();

  return (
    <>
      {showModal && (
        <ModalV2.Root defaultOpen={true} onOpenChange={() => setShowModal(false)}>
          <ModalV2.Body title={t("map.legends.ownership_legend.ownership_type")}>
            <div className="atlas-flex atlas-flex-col atlas-gap-y-5 atlas-p-4">
              <Section>
                <h4 className="atlas-text-sm atlas-font-medium atlas-pl-3">
                  {t("map.legends.ownership_legend.known_owners")}
                </h4>
                <div className="proprietors-info atlas-flex atlas-between">
                  <div className="atlas-p-3">
                    <div className="atlas-flex">
                      <div className="company atlas-w-9 atlas-h-4 atlas-bg-[#fc4b65]" />
                      <div className="atlas-ml-2">{t("map.legends.ownership_legend.company")}</div>
                    </div>
                    <div className="atlas-flex">
                      <div className="corporate-body company atlas-w-9 atlas-h-4 atlas-bg-[#fbe2b4]" />
                      <div className="atlas-ml-2">
                        {t("map.legends.ownership_legend.corporate_body")}
                      </div>
                    </div>
                    <div className="atlas-flex">
                      <div className="council company atlas-w-9 atlas-h-4 atlas-bg-[#59c4c5]" />
                      <div className="atlas-ml-2">
                        {t("map.legends.ownership_legend.local_county_authority")}
                      </div>
                    </div>
                    <div className="atlas-flex">
                      <div className="housing-association company atlas-w-9 atlas-h-4 atlas-bg-[#fdc33c]" />
                      <div className="atlas-ml-2">
                        {t("map.legends.ownership_legend.housing_association")}
                      </div>
                    </div>
                  </div>
                </div>
              </Section>
              <div className="atlas-text-sm">
                {t("maps.legend.ownership_legend.colour_information")}
              </div>
              <h4 className="atlas-text-lg atlas-font-medium">
                {t("maps.legend.ownership_legend.what_are_the_ownership_types_exactly")}
              </h4>
              <ul className="atlas-px-6">
                <li className="atlas-text-sm">
                  {t(
                    "maps.legend.ownership_legend.companies_limited_public_limited_limited_liability_partnerships_and_unlimited_companies"
                  )}
                </li>
                <li className="atlas-text-sm">
                  {t(
                    "maps.legend.ownership_legend.local_county_authority_local_district_and_county_councils"
                  )}
                </li>
                <li className="atlas-text-sm">
                  {t(
                    "maps.legend.ownership_legend.housing_associations_housing_associations_and_societies_industrial_and_provident_societies"
                  )}
                </li>
              </ul>
              <h4 className="atlas-text-lg atlas-font-medium">
                {t("maps.legend.ownership_legend.why_does_the_colour_only_show_in_some_places")}
              </h4>
              <p className="atlas-text-sm atlas-px-2">
                {t(
                  "maps.legend.ownership_legend.land_that_belongs_to_private_individuals_charities_foreign_companies_will_not_be_coloured"
                )}
              </p>
              <p className="atlas-text-sm">
                {t("map.legends.ownership_legend.the_data_is_updated_monthly")}
              </p>
            </div>
          </ModalV2.Body>
        </ModalV2.Root>
      )}
    </>
  );
};
