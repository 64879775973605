import { MeasurementVariants } from "react-migration/lib/util/getAreaAndPerimeter";
import { DetailsList } from "../DetailsList";
import { TextWithVariants } from "../TextWithVariants";

type Measurement = {
  label: string;
  variants: MeasurementVariants;
};

type GenericMeasurementsProps = {
  measurements: Measurement[];
};

export function GenericMeasurements({ measurements }: GenericMeasurementsProps) {
  return (
    <DetailsList>
      {measurements.map((measurement) => (
        <DetailsList.Item key={measurement.label} label={measurement.label}>
          <TextWithVariants variants={measurement.variants} />
        </DetailsList.Item>
      ))}
    </DetailsList>
  );
}
