import { FC } from "react";
import { Button } from "react-migration/components/DeprecatedButton";
import { Section } from "react-migration/components/Section";
import { InfoList } from "react-migration/components/InfoList";
import { TextWithVariants } from "react-migration/components/TextWithVariants";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { PropertySaleValue } from "../PropertySaleValue";
import { PropertyInfoItem } from "../PropertyInfoDetailed/PorpertyInfoItem";
import { getAreaVariants } from "react-migration/lib/util/getAreaAndPerimeter";
import { useSnapshot } from "valtio";
import { userStore } from "src/js/stores/user/store";
import { Title } from "react-migration/domains/ownership/typings/Title";
import { usePropertyInformationByUPRNQuery } from "../../apollo/usePropertyInformationByUPRNQuery";

interface PropertyInfoSummaryProps {
  mainTitle: Title;
  action: () => void;
}

export const PropertyInfoSummary: FC<PropertyInfoSummaryProps> = ({ mainTitle, action }) => {
  const { t } = useTranslation();

  return (
    <Section>
      <InfoList>
        <InfoList.Title>{t("ownership.react.map.sidebar.property_info.title")}</InfoList.Title>
        <InfoList.Item>
          <InfoList.ItemTitle>
            {t("ownership.react.map.sidebar.property_info.address_title")}
          </InfoList.ItemTitle>

          {mainTitle.addresses?.length ? (
            <InfoList.ItemValue>{String(mainTitle.addresses[0]).trim()}</InfoList.ItemValue>
          ) : (
            <InfoList.ItemValue>
              {t("ownership.react.map.sidebar.property_info.value_unavailable")}
            </InfoList.ItemValue>
          )}
        </InfoList.Item>

        {mainTitle.id && <PropertyDimensions uprn={mainTitle.id} />}
        {mainTitle.id && <PropertySaleValue uprn={mainTitle.id} />}
        <div className="atlas-p-2">
          <Button.Secondary onClick={action}>
            <span>{t("ownership.react.map.sidebar.property_info.view_info")}</span>
            <i className="icon-lt-arrow-right-s-line "></i>
          </Button.Secondary>
        </div>
      </InfoList>
    </Section>
  );
};

interface PropertyDimensionsProps {
  uprn: string;
}

function PropertyDimensions({ uprn }: PropertyDimensionsProps) {
  const { user } = useSnapshot(userStore);
  const { propertyInformation, loading: propertyInfoLoading } =
    usePropertyInformationByUPRNQuery(uprn);
  const { t } = useTranslation();

  const { area_lot_metric } = propertyInformation?.dimensions ?? {};

  return (
    <PropertyInfoItem title={t("property_information.sidebar.lot_size")} testId="lot-size">
      {propertyInfoLoading ? (
        <span className="atlas-italic atlas-text-neutral-500">
          {t("ownership.react.map.sidebar.property_info.value_loading")}
        </span>
      ) : area_lot_metric ? (
        <TextWithVariants variants={getAreaVariants(area_lot_metric, user.settings.unit)} />
      ) : (
        <span>{t("ownership.react.map.sidebar.property_info.value_unavailable")}</span>
      )}
    </PropertyInfoItem>
  );
}
