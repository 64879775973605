import { ReactNode } from "react";
import * as RadixAccordion from "@radix-ui/react-accordion";
import clsx from "clsx";

export interface CustomAccordionTriggerProps extends RadixAccordion.AccordionTriggerProps {
  children: ReactNode;
  className?: string;
  style?: Record<string, string | number>;
}

export function AccordionTrigger({
  children,
  className,
  style,
  disabled,
  ...rest
}: CustomAccordionTriggerProps) {
  return (
    <RadixAccordion.Trigger
      {...rest}
      className={clsx(
        "atlas-bg-white",
        "atlas-w-full",
        "hover:atlas-bg-neutral-100",
        disabled && "atlas-pointer-events-none",
        className
      )}
      style={style}
      disabled={disabled}
    >
      {children}
    </RadixAccordion.Trigger>
  );
}

export default AccordionTrigger;
