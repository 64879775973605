import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "react-migration/components/DeprecatedButton";
import { Loading } from "react-migration/components/Loading";
import { useLoadSiteBoundaryImageBulk } from "react-migration/domains/sites/hooks/useLoadSiteBoundaryImageBulk";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useSnapshot } from "valtio";
import { LetterStep, letterStore } from "src/js/stores/letter/store";
import {
  updateCurrentStep,
  updateSiteBoundaryImageUrlBySiteId,
} from "src/js/stores/letter/actions";
import Feature from "src/js/stores/user/Feature";
import hasFeature from "src/js/stores/user/actions/hasFeature";

import { LetterEventName, logLetterEvent } from "./helpers/logLetterEvent";
import { siteStore } from "src/js/stores/site/store";
import { Header } from "./components/Header";
import { DropdownTitleLayout, RecipientsDropdown } from "./components/RecipientsDropdown";
import { LetterPreview } from "./components/LetterPreview";
import { Footer } from "./components/Footer";

export const LetterPreviewStepContent = () => {
  const { t } = useTranslation();
  const [isNextBlocked, setIsNextBlocked] = useState(false);

  const isPhysicalSendAvailable = hasFeature(Feature.physicalSend);

  const onBackClick = () => {
    updateCurrentStep(LetterStep.TemplateEditor);
    logLetterEvent({
      name: LetterEventName.LETTERS_FORM_PREVIEW_STEP_BACK_BUTTON_CLICKED,
    });
  };
  const onNextClick = useCallback(() => {
    if (!isPhysicalSendAvailable) {
      updateCurrentStep(LetterStep.FinishAndDownload);
      logLetterEvent({
        name: LetterEventName.LETTERS_FORM_PREVIEW_STEP_FINISH_CLICKED,
      });
    } else {
      updateCurrentStep(LetterStep.ConfirmAndSend);
      logLetterEvent({
        name: LetterEventName.LETTERS_FORM_PREVIEW_STEP_CONFIRM_AND_SEND_CLICKED,
      });
    }
  }, [isPhysicalSendAvailable]);

  const percentage = useMemo(() => (isPhysicalSendAvailable ? 50 : 66), [isPhysicalSendAvailable]);

  return (
    <div
      data-testid="letters-template-preview-step"
      className="atlas-h-full atlas-w-full atlas-flex atlas-flex-col"
    >
      <Header />
      <RecipientsDropdown titleLayout={DropdownTitleLayout.HORIZONTAL} />
      <div
        data-testid="letter-preview-wrapper"
        className="atlas-flex atlas-justify-center atlas-grow atlas-w-full atlas-overflow-hidden atlas-bg-background-light atlas-m-auto"
      >
        <LetterPreview showValues onBlockNext={setIsNextBlocked} />
      </div>
      <Footer percentage={percentage}>
        <div className="atlas-flex atlas-flex-grow atlas-justify-between">
          <Button.Secondary onClick={onBackClick}>{t("sites.letters.back")}</Button.Secondary>
          <Button.Primary disabled={isNextBlocked} form="recipientsForm" onClick={onNextClick}>
            {isPhysicalSendAvailable
              ? t("sites.letters.next_confirm_and_send_button")
              : t("sites.letters.finish_and_download")}
          </Button.Primary>
        </div>
      </Footer>
    </div>
  );
};

export const LetterPreviewStep = () => {
  const {
    selectedStageId,
    letter: { showSiteBoundary },
  } = useSnapshot(letterStore);
  const { siteCardId: siteId } = useSnapshot(siteStore);

  const { loading, siteNodes } = useLoadSiteBoundaryImageBulk({
    siteIds: siteId ? [siteId] : undefined,
    stageIds: selectedStageId ? [selectedStageId] : undefined,
    skip: !showSiteBoundary,
  });

  useEffect(() => {
    if (!loading && siteNodes.length) {
      const boundaryImagesById = siteNodes.reduce<Record<string, string>>((acc, siteNode) => {
        acc[siteNode._id] = siteNode.boundary_image.uri;
        return acc;
      }, {});
      updateSiteBoundaryImageUrlBySiteId(boundaryImagesById);
    }
  }, [loading, siteNodes]);

  return (
    <>
      {loading && (
        <div
          className={classNames(
            "atlas-h-full",
            "atlas-w-full",
            "atlas-flex",
            "atlas-justify-center",
            "atlas-items-center",
            "atlas-overflow-hidden"
          )}
        >
          <Loading />
        </div>
      )}
      {!loading && <LetterPreviewStepContent />}
    </>
  );
};
