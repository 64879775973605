import { FC } from "react";

const isBoundNumber = (n?: number) => !isNaN(n!) && isFinite(n!);

interface CountPillProps {
  count?: number;
}

export const CountPill: FC<CountPillProps> = ({ count }) =>
  isBoundNumber(count) ? (
    <span className="atlas-inline-block atlas-px-1 atlas-rounded atlas-text-white atlas-bg-neutral-400 atlas-text-xs">
      {count}
    </span>
  ) : null;
