import React, { PropsWithChildren, useState } from "react";
import clsx from "clsx";
import { CountPill } from "react-migration/components/CountPill";
import { Icon } from "react-migration/components/Icon";
import { CardTitle } from "react-migration/components/CardTitle";
import { logEventV2 } from "react-migration/lib/util/logEventV2";
import { useLocalStorage } from "usehooks-ts";

type CollapsibleConsiderationsCardProps = PropsWithChildren<{
  heading: string | React.ReactNode;
  name: string;
  defaultExpanded?: boolean;
  label?: string;
  count?: number;
}>;

export function CollapsibleConsiderationsCard({
  heading,
  name,
  children,
  count,
  label,
  defaultExpanded = true,
}: CollapsibleConsiderationsCardProps) {
  const [expandedCards, setExpandedCards] = useLocalStorage<Record<string, boolean>>(
    "expandedAssessmentCards",
    {}
  );
  const [expanded, setExpanded] = useState(
    typeof expandedCards[name] != "undefined" ? expandedCards[name] : defaultExpanded
  );

  const toggleCard = () => {
    setExpanded((oldState) => !oldState);
    logEventV2({
      name: "Workbench card expanded",
      properties: { state: !expanded, cardName: name },
    });
    setExpandedCards({
      ...expandedCards,
      [name]: !expanded,
    });
  };

  return (
    <section
      data-testid={`collapsible-considerations-card${name && "-" + name.toLowerCase()}`}
      className="collapsible-considerations-card atlas-bg-white atlas-overflow-hidden atlas-rounded-lg atlas-border atlas-border-neutral-300"
    >
      <button
        data-testid="collapsible-considerations-card-header"
        className="atlas-w-full atlas-flex atlas-justify-between atlas-items-center atlas-px-2 atlas-py-1"
        onClick={() => toggleCard()}
      >
        <div className="atlas-flex atlas-gap-1 atlas-items-center">
          {typeof heading !== "string" ? (
            heading
          ) : (
            <>
              <CardTitle>{heading}</CardTitle>
              <CountPill count={count} />
            </>
          )}
        </div>
        {label && (
          <div className="atlas-flex atlas-flex-col atlas-flex-1 atlas-mr-2">
            <div className="atlas-text-xs atlas-self-end atlas-px-1 atlas-rounded atlas-bg-platformBlue-200 atlas-text-platformBlue-700">
              {label}
            </div>
          </div>
        )}
        <Icon
          size="md"
          icon="icon-lt-arrow-down-s-line"
          extraClasses={clsx("atlas-transition-transform", { "atlas-rotate-180": expanded })}
        />
      </button>

      {expanded && children}
    </section>
  );
}
