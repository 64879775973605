import { Button } from "react-migration/components/DeprecatedButton";
import { Point } from "geojson";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { router } from "src/js/router/react-router";
import ROUTES from "src/js/router/routes";
import { ViewApplicationButton } from "react-migration/domains/planning/components/ViewApplicationButton/ViewApplicationButton";
import { AlertResult } from "react-migration/domains/dashboard/typings/PlanningAlertResultsResponse";
import { SaveSiteButton } from "react-migration/domains/sites/components/SaveSiteButton/SaveSiteButton";
import { SaveSiteLayer } from "react-migration/domains/sites/typings/SaveSiteLayer";

type PlanningResultActionsProps = {
  result: AlertResult;
};

export const PlanningResultActions = ({ result }: PlanningResultActionsProps) => {
  const { t } = useTranslation();
  const handleRedirectToMap = () => {
    if (!result?.result_item?.location.coordinates) {
      return;
    }
    const coords = result.result_item.location.coordinates;
    router.push({
      name: ROUTES.MAP,
      params: { z: "19", x: String(coords[1]), y: String(coords[0]) },
      query: {
        sidebartype: "planning",
        appId: `${result.result_item.gss_code}+${result.result_item.ref}`,
        clicklatlng: JSON.stringify({
          lat: coords[1],
          lng: coords[0],
        }),
        zoom: "19",
      },
    });
  };

  const siteGeometry = {
    type: "Point",
    coordinates: result.result_item.location.coordinates,
  } as Point;

  return (
    <div className="atlas-flex atlas-gap-2 atlas-mb-2">
      <Button.Secondary onClick={handleRedirectToMap} size="small">
        {t("dashboard.planning_alerts.view_on_map")}
      </Button.Secondary>
      <ViewApplicationButton
        authorityName={result.result_item.authority.name}
        authorityUrl={result.result_item.extract_url}
        applicationRef={result.result_item.ref}
      />
      <SaveSiteButton
        source="PlanningResultActions"
        layer={SaveSiteLayer.Alerts}
        planning_alert_result={result._id}
        siteGeometry={siteGeometry}
        tidbit={{
          planning_application: {
            gss_code: result.result_item.gss_code,
            ref: result.result_item.ref,
          },
        }}
        coordId={`${result.result_item.location.coordinates[1]}, ${result.result_item.location.coordinates[0]}`}
      />
    </div>
  );
};
