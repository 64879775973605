import { PropsWithChildren } from "react";
import clsx from "clsx";

type ContainerProps = {
  className?: string;
};

export const SkeletonLoadingContainer = ({
  className,
  children,
}: PropsWithChildren<ContainerProps>) => (
  <div
    data-testid="skeleton-loading-container"
    className={clsx("atlas-relative atlas-h-full atlas-w-full", ...(className?.split(" ") ?? []))}
  >
    {children}
  </div>
);

SkeletonLoadingContainer.displayName = "SkeletonLoadingContainer";
