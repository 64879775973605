import { Select } from "react-migration/components/Select";
import { LayerTypeControlPageProps } from "../../../types";
import { TopographyLayer } from "../constants";
import { TopographyLayerDialog } from "../TopographyLayerDialog";
import { useTrackedTopographyLayerFilter } from "../useTrackedTopographyLayerFilter";

export function USTopographyControlPage({ disabled }: LayerTypeControlPageProps) {
  const { handleLayerChange, selectedLayer } = useTrackedTopographyLayerFilter();

  return (
    <div className="atlas-flex atlas-flex-col atlas-gap-y-2">
      <TopographyLayerDialog region="US" disabled={disabled} label="Display by" />
      <Select value={selectedLayer} disabled={disabled} onValueChange={handleLayerChange}>
        <Select.Option value={TopographyLayer.hillshade}>Hillshade</Select.Option>
        <Select.Option value={TopographyLayer.contour}>Contour</Select.Option>
        <Select.Option value={TopographyLayer.hillshadeContour}>
          Hillshade &amp; Contour
        </Select.Option>
      </Select>
    </div>
  );
}
