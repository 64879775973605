import { useQuery } from "@apollo/client";
import { LandTechEndpoints, routedClient } from "react-migration/lib/persistence/apollo";
import COMPARABLE_BY_UPRN from "../apollo/comparablesByUPRN.gql";
import { IComparablesTransactionDTO } from "react-migration/lib/typings/Comparables";
import { useMemo } from "react";

interface ComparableByUPRNIdHookArgs {
  uprn: string;
}

export function useComparableByUPRN({ uprn }: ComparableByUPRNIdHookArgs) {
  const result = useQuery<{
    comparablesByUPRN: IComparablesTransactionDTO[];
  }>(COMPARABLE_BY_UPRN, {
    variables: { uprn },
    client: routedClient,
    context: {
      endpoint: LandTechEndpoints.Gateway,
    },
  });

  return useMemo(
    () => ({
      data: result.data?.comparablesByUPRN?.[0],
      loading: result.loading,
      error: result.error,
    }),
    [result.data, result.loading, result.error]
  );
}
