import { CSSProperties } from "react";
import { CornerOffsets } from "./getElementCornerOffsets";
import { DropdownMenuTriggerAlignment } from "../components/DropdownMenuTrigger";

export type Options = {
  buttonOffsets: CornerOffsets;
  alignment: DropdownMenuTriggerAlignment;
  menuWidth: number;
  menuHeight?: number;
  menuOffset: number;
  windowWidth: number;
  windowHeight: number;
  additionalOffset: number;
};

export const calculateMenuStyleProperties = ({
  windowWidth,
  windowHeight,
  buttonOffsets,
  alignment,
  menuOffset,
  menuWidth,
  menuHeight = 0,
  additionalOffset,
}: Options): CSSProperties => {
  const offsetToLeftEdge = buttonOffsets.topLeft.x;
  const offsetToRightEdge = windowWidth - buttonOffsets.topRight.x;
  const offsetToBottomEdge = windowHeight - buttonOffsets.bottomRight.y;

  const stylePropertiesRight = {
    left: buttonOffsets.topRight.x + menuOffset + additionalOffset,
    top:
      offsetToBottomEdge > menuHeight
        ? buttonOffsets.topRight.y
        : buttonOffsets.bottomRight.y - menuHeight,
  };

  const stylePropertiesLeft = {
    left: buttonOffsets.topLeft.x - menuOffset - additionalOffset - menuWidth,
    top:
      offsetToBottomEdge > menuHeight
        ? buttonOffsets.topRight.y
        : buttonOffsets.bottomRight.y - menuHeight,
  };

  switch (alignment) {
    case DropdownMenuTriggerAlignment.BOTTOM_LEFT:
      return {
        left:
          offsetToRightEdge > menuWidth
            ? buttonOffsets.bottomLeft.x
            : windowWidth - buttonOffsets.bottomRight.x,
        top: buttonOffsets.bottomRight.y + menuOffset,
      };
    case DropdownMenuTriggerAlignment.BOTTOM:
      return {
        right: windowWidth - buttonOffsets.bottomRight.x,
        top: buttonOffsets.bottomRight.y + menuOffset,
      };
    case DropdownMenuTriggerAlignment.RIGHT:
      return offsetToRightEdge > menuWidth ? stylePropertiesRight : stylePropertiesLeft;
    case DropdownMenuTriggerAlignment.LEFT:
      return offsetToLeftEdge > menuWidth ? stylePropertiesLeft : stylePropertiesRight;
  }
};
