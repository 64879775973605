import { useMemo } from "react";
import { usePaginationContext } from "./hooks/usePaginationContext";

export const PaginationInfo = () => {
  const { itemsPerPage, currentPageInternal, total } = usePaginationContext();
  const firstIndex = useMemo(
    () => currentPageInternal * itemsPerPage + 1,
    [currentPageInternal, itemsPerPage]
  );
  const lastIndex = useMemo(() => {
    return (currentPageInternal + 1) * itemsPerPage;
  }, [currentPageInternal, itemsPerPage, total, firstIndex]);
  return (
    <span className="atlas-text-neutral-600">
      {total > 0 ? (
        <span>
          Showing {firstIndex}-{lastIndex <= total ? lastIndex : total} out of {total}
        </span>
      ) : (
        <span>Showing 0 out of 0</span>
      )}
    </span>
  );
};
