import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { InfoList } from "react-migration/components/InfoList";
import { isZoopla, isZooplaValuePrice } from "react-migration/domains/comparables/util/isZoopla";
import { PriceModes } from "../../../typings/PriceModes";
import { RecordType } from "../../../typings/Record";
import { AdditionalLandTechTransactions } from "../AdditionalTransactions/AdditionalLandTechTransactions";
import { AdditionalZooplaTransactions } from "../AdditionalTransactions/AdditionalZooplaTransactions";
import { AddressItem } from "../AddressItem";
import { ExpandibleList } from "react-migration/components/ExpandibleList/ExpandibleList";

export enum TransactionsAccordionStates {
  INCLUDED = "includedResults",
  EXCLUDED = "excludedResults",
  CLOSED = "",
}

export interface TransactionsProps {
  accordionState: TransactionsAccordionStates;
  setAccordionState: Dispatch<SetStateAction<TransactionsAccordionStates>>;
  includedResults: RecordType[];
  loading?: boolean;
  setSelectedRecord: (record: RecordType) => void;
  priceMode: PriceModes;
  distribution?: number[];
}

export const Transactions: FC<TransactionsProps> = ({
  includedResults,
  setSelectedRecord,
  distribution,
  priceMode,
  loading,
}) => {
  const [showLoader, setShowLoader] = useState(loading);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (loading && !showLoader) {
      timeout = setTimeout(() => setShowLoader(true), 600);
    }

    return () => {
      if (loading) {
        setShowLoader(false);
      }

      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const includedUPRNs = includedResults.map((record) => record.uprn).filter(Boolean) as [];

  return (
    <InfoList className="atlas-m-2 atlas-mt-0" data-testid="transactions">
      <ExpandibleList limit={4} itemsName="comparables">
        {includedResults.map((transaction: RecordType) => {
          return (
            <div key={`${transaction.transaction_id}-address-item`}>
              <AddressItem
                address={transaction.address}
                currency={transaction.currency ?? "GBP"}
                dateOfRecord={transaction.date_of_transfer}
                distribution={distribution}
                floorArea={transaction.total_floor_area ?? undefined}
                price={transaction.price}
                priceMode={priceMode}
                setSelectedRecord={() => setSelectedRecord(transaction)}
                uprns={includedUPRNs}
                uprn={transaction.uprn}
              />
              <div>
                {isZoopla(priceMode) ? (
                  isZooplaValuePrice(priceMode) && (
                    <AdditionalZooplaTransactions
                      key={`${transaction.transaction_id}-additional-transactions`}
                      transaction={transaction}
                    />
                  )
                ) : (
                  <AdditionalLandTechTransactions
                    key={`${transaction.transaction_id}-additional-transactions`}
                    transaction={transaction}
                  />
                )}
              </div>
            </div>
          );
        })}
      </ExpandibleList>
    </InfoList>
  );
};
