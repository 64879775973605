import { Variants } from "framer-motion";

export const containerVariants: Variants = {
  hidden: {
    scaleY: 0,
  },
  visable: {
    scaleY: 1,
    transformOrigin: "top",
  },
};

export const listItemVariants: Variants = {
  hidden: {
    opacity: 0,
  },
  visable: {
    opacity: 1,
  },
};
