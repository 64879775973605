import { _WMSLayer as WMSLayer } from "@deck.gl/geo-layers";
import { useMemo } from "react";
import { useMapLayer } from "react-migration/lib/map/useMapLayer";

export enum USGS3DEPLayer {
  HillshadeGray = "3DEPElevation:Hillshade Gray",
  AspectDegrees = "3DEPElevation:Aspect Degrees",
  AspectMap = "3DEPElevation:Aspect Map",
  Contour25 = "3DEPElevation:Contour 25",
  HillshadeElevationTinted = "3DEPElevation:Hillshade Elevation Tinted",
  HeightEllipsoidal = "3DEPElevation:Height Ellipsoidal",
  GreyHillshade_elevationFill = "3DEPElevation:GreyHillshade_elevationFill",
  HillshadeMultidirectional = "3DEPElevation:Hillshade Multidirectional",
  SlopeMap = "3DEPElevation:Slope Map",
  SlopeDegrees = "3DEPElevation:Slope Degrees",
  ContourSmoothed25 = "3DEPElevation:Contour Smoothed 25",
  None = "3DEPElevation:None",
}

interface USGS3DEPElevationWMSMapLayerProps {
  layer: USGS3DEPLayer;
  opacity?: number;
  zOrder?: number;
}

export const USGS3DEPElevationWMSMapLayer = ({
  layer,
  opacity = 1,
  zOrder = -Infinity,
}: USGS3DEPElevationWMSMapLayerProps) => {
  const layers = useMemo(() => [layer], [layer]);

  useMapLayer(
    () => ({
      zOrder,
      layer: new WMSLayer({
        id: `usgs-3dep-elevation-wms-layer__${layer}`,
        data: "https://elevation.nationalmap.gov:443/arcgis/services/3DEPElevation/ImageServer/WMSServer",
        serviceType: "wms",
        layers,
        opacity,
      }),
    }),
    [layers, layer, opacity, zOrder]
  );

  return null;
};
