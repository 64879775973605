import { PropsWithChildren } from "react";
import * as RadixAccordion from "@radix-ui/react-accordion";
import { AccordionItem, AccordionTrigger } from "./components";

export enum AccordionType {
  single = "single",
  multiple = "multiple",
}

export type AccordionProps = PropsWithChildren<
  RadixAccordion.AccordionSingleProps | RadixAccordion.AccordionMultipleProps
>;

export function Accordion({ children, ...rest }: AccordionProps) {
  return <RadixAccordion.Root {...rest}>{children}</RadixAccordion.Root>;
}

Accordion.Type = AccordionType;
Accordion.Item = AccordionItem;
Accordion.Trigger = AccordionTrigger;
Accordion.Content = RadixAccordion.Content;
Accordion.Header = RadixAccordion.Header;

export default Accordion;
