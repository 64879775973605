import { useMemo } from "react";
import length from "@turf/length";
import { GenericMeasurements } from "react-migration/components/GenericMeasurements";
import { getLengthVariants } from "react-migration/lib/util/getAreaAndPerimeter";
import { AnnotationSpecProps } from "./types";
import { useSnapshot } from "valtio";
import { userStore } from "src/js/stores/user/store";
import { DrawingItem } from "src/js/types/DrawingItem";

function useDrawingLengthVariants(drawing: DrawingItem) {
  const { unit } = useSnapshot(userStore.user.settings);

  return useMemo(() => {
    const lengthInMeters = length(drawing.geometry, { units: "meters" });
    return getLengthVariants(lengthInMeters, unit);
  }, [drawing.geometry, unit]);
}

export function RulerSpec({ drawing }: AnnotationSpecProps) {
  const lengthVariants = useDrawingLengthVariants(drawing);

  return (
    <div>
      <GenericMeasurements measurements={[{ label: "Length", variants: lengthVariants }]} />
    </div>
  );
}
