import { useCallback, useEffect, useMemo } from "react";
import { emptyArray } from "react-migration/domains/comparables/components/MapLayer/ComparablesLayer/comparablesLayerUtil";
import { logEvent } from "react-migration/lib/util/logEvent";
import { useSnapshot } from "valtio";
import { isPolygonOrMultiPolygon } from "../../geometry_utilities";
import { SelectionFeature, SelectionType } from "src/js/stores/map/store";
import { isZoopla } from "react-migration/domains/comparables/util/isZoopla";
import { comparablesStore } from "src/js/stores/comparables";
import { IComparablesTransactionDTO } from "react-migration/lib/typings/Comparables";
import { useClickRadius } from "react-migration/domains/comparables/hooks/useClickRadius";
import { convertPositionsToLatLngCoordinates } from "react-migration/lib/util/geoJson";
import { useComparableSearch } from "react-migration/domains/comparables/hooks/useComparableSearch";
import {
  filterRecords,
  filterZooplaRecords,
} from "react-migration/domains/comparables/util/Filters";
import { formatZooplaRecordAsLTComp } from "react-migration/domains/comparables/util/formatZooplaRecordAsLTComp";
import { PriceTypes } from "react-migration/domains/comparables/typings/PriceTypes";
import { convex } from "@turf/turf";
import { useComparablesLayerContext } from "./ComparableLayerContext/useComparableLayerContext";
import { logEventV2 } from "react-migration/lib/util/logEventV2";

function hometrackDetailSelection(record: IComparablesTransactionDTO): SelectionFeature {
  return {
    type: SelectionType.HOMETRACK_COMPARABLE_TRANSACTION,
    id: record.uprn,
    feature: {
      type: "Feature",
      geometry: record.geometry,
      properties: {},
    },
  };
}

function landTechDetailSelection(record: IComparablesTransactionDTO): SelectionFeature {
  return {
    type: SelectionType.LANDTECH_COMPARABLE_TRANSACTION,
    id: record.transaction_id,
    feature: {
      type: "Feature",
      geometry: record.geometry,
      properties: {},
    },
  };
}

const EMPTY_FILTER = {};

export function useTransactionsAtSelection(
  selection: SelectionFeature,
  onSelection?: (selection: SelectionFeature | undefined) => void
) {
  const { setTransactions, setZooplaTransactions, distribution } = useComparablesLayerContext();
  const { filters, priceMode, mapMode, priceType } = useSnapshot(comparablesStore);

  const clickRadius = useClickRadius();

  const locationFilter = useMemo(() => {
    if (selection.feature?.geometry && isPolygonOrMultiPolygon(selection.feature?.geometry)) {
      const envelope = convex(selection.feature.geometry)?.geometry;
      const coordinates = envelope && convertPositionsToLatLngCoordinates(envelope);
      return coordinates ? { poly_geo_filter: { coordinates } } : {};
    } else if (selection.feature?.geometry?.type === "Point") {
      return {
        radius_geo_filter: {
          location: {
            lat: selection.feature.geometry.coordinates[1],
            lng: selection.feature.geometry.coordinates[0],
          },
          max_dist: clickRadius,
        },
      };
    }
  }, [selection, clickRadius]);

  const transactionsQueryResults = useComparableSearch({
    priceMode,
    search: locationFilter || EMPTY_FILTER,
    sort: {
      fieldName: "date_of_transfer",
      sortType: {
        order: "desc",
      },
    },
    skip: !locationFilter,
  });

  const transactions = useMemo(() => {
    return transactionsQueryResults?.data.comparableSearch?.edges.map((a) => a.node) || emptyArray;
  }, [transactionsQueryResults.data]);

  const zooplaTransactions = useMemo(() => {
    return (
      transactionsQueryResults?.data.zooplaComparableSearch?.edges.map((a) => a.node) || emptyArray
    );
  }, [transactionsQueryResults.data]);

  const selectRecord = useCallback(
    (record: IComparablesTransactionDTO | undefined) => {
      if (!record) {
        return;
      }
      const zooplaPM = isZoopla(priceMode);
      logEvent("Comparables Select Record", {
        mapMode,
        priceMode,
        priceType: priceType === PriceTypes.PER_UNIT ? "full" : "ppa",
      });
      logEventV2({
        name: "Workbench card click",
        properties: {
          cardName: "Comparables",
          action: "goTo",
          clickDetail: "Transaction",
        },
      });
      onSelection?.(
        zooplaPM
          ? // at the moment zoopla comps are cast to landtech comps we need to fix this
            hometrackDetailSelection(record as IComparablesTransactionDTO)
          : landTechDetailSelection(record as IComparablesTransactionDTO)
      );
    },
    [mapMode, priceMode, priceType, onSelection]
  );

  const { includedResults } = useMemo(() => {
    if (isZoopla(priceMode)) {
      const { includedResults } = filterZooplaRecords(filters, zooplaTransactions ?? [], priceMode);

      return {
        includedResults: formatZooplaRecordAsLTComp({
          results: includedResults,
          priceMode,
        }),
      };
    }

    const transactionsExcludingSelection = transactions.filter(
      ({ transaction_id }) => transaction_id !== selection?.id
    );

    return filterRecords(filters, transactionsExcludingSelection);
  }, [priceMode, transactions, filters, zooplaTransactions, selection.id]);

  const loading = transactionsQueryResults.loading;

  useEffect(() => {
    if (!loading && !transactions?.length) {
      logEvent("Comparables Hometrack No Match", {
        locationFilter,
        priceMode,
      });
    }
  }, [loading, transactions, priceMode, locationFilter]);

  useEffect(() => {
    setTransactions(transactions);
  }, [transactions, setTransactions]);

  useEffect(() => {
    setZooplaTransactions(zooplaTransactions);
  }, [zooplaTransactions, setZooplaTransactions]);

  return {
    includedResults,
    loading,
    distribution,
    priceMode,
    selectRecord,
  };
}
