import { scaleLinear } from "d3-scale";
import { arc } from "d3-shape";
import { AspectCategoryKey } from "./types";
import { ASPECT_CATEGORIES } from "./constants";

const CHART_DIAMETER = 234;
const CHART_WIDTH = 270;
const CHART_HEIGHT = 270;
const CHART_RADIUS = CHART_DIAMETER / 2;
const INNER_BAR_RADIUS = 32;
const OUTER_BAR_RADIUS = CHART_RADIUS;

interface AspectCircularBarPlotProps {
  hoveredCategory: AspectCategoryKey | undefined;
  setHoveredCategory: (category: AspectCategoryKey | undefined) => void;
  categoryPercentages: {
    [key in AspectCategoryKey]: number;
  };
}

const shortLabelLookup: Record<AspectCategoryKey, string> = {
  [AspectCategoryKey.Flat]: "Flat",
  [AspectCategoryKey.N]: "N",
  [AspectCategoryKey.NE]: "NE",
  [AspectCategoryKey.E]: "E",
  [AspectCategoryKey.SE]: "SE",
  [AspectCategoryKey.S]: "S",
  [AspectCategoryKey.SW]: "SW",
  [AspectCategoryKey.W]: "W",
  [AspectCategoryKey.NW]: "NW",
};

const cardinalDirectionsOrdered = [
  AspectCategoryKey.N,
  AspectCategoryKey.NE,
  AspectCategoryKey.E,
  AspectCategoryKey.SE,
  AspectCategoryKey.S,
  AspectCategoryKey.SW,
  AspectCategoryKey.W,
  AspectCategoryKey.NW,
];

const arcGen = arc();
const barScale = scaleLinear()
  .clamp(true)
  .domain([0, 100])
  .range([INNER_BAR_RADIUS, OUTER_BAR_RADIUS]);

export function AspectCircularBarPlot({
  categoryPercentages,
  hoveredCategory,
  setHoveredCategory,
}: AspectCircularBarPlotProps) {
  const angleSpanPerCategory = (Math.PI * 2) / 8;
  const activeCategory = hoveredCategory ?? getMaximumCategoryKey(categoryPercentages);
  const activeCategoryLabel = ASPECT_CATEGORIES.find(
    ({ categoryKey }) => categoryKey === activeCategory
  )?.label;

  return (
    <svg width={CHART_WIDTH} height={CHART_HEIGHT} className="atlas-cursor-default">
      <g transform={`translate(${CHART_WIDTH / 2}, ${CHART_HEIGHT / 2})`}>
        <circle r={CHART_RADIUS} fill="#F1F3F5" />

        {cardinalDirectionsOrdered.map((direction, i) => {
          const category = ASPECT_CATEGORIES.find(({ categoryKey }) => categoryKey === direction);

          if (!category) return null;

          const { categoryKey, color, label } = category;
          const startAngle = -angleSpanPerCategory / 2 + angleSpanPerCategory * i;
          const endAngle = startAngle + angleSpanPerCategory;
          const labelAngle = startAngle + angleSpanPerCategory / 2 - Math.PI / 2;
          const percentage = categoryPercentages[categoryKey] ?? 0;
          const labelPositionX = Math.cos(labelAngle) * CHART_RADIUS;
          const labelPositionY = Math.sin(labelAngle) * CHART_RADIUS;
          const segmentHoverOffsetX = Math.cos(labelAngle) * 5;
          const segmentHoverOffsetY = Math.sin(labelAngle) * 5;
          const isHovered = hoveredCategory === categoryKey;

          return (
            <g key={label}>
              <path
                fill={color}
                className="atlas-transition-transform atlas-transition-transform-duration-200"
                transform={
                  isHovered ? `translate(${segmentHoverOffsetX} ${segmentHoverOffsetY})` : ""
                }
                d={
                  arcGen({
                    startAngle,
                    endAngle,
                    innerRadius: INNER_BAR_RADIUS,
                    outerRadius: barScale(percentage),
                  })!
                }
              />
              <path
                className="atlas-cursor-pointer"
                fill="transparent"
                onMouseEnter={() => setHoveredCategory(categoryKey)}
                onMouseLeave={() => setHoveredCategory(undefined)}
                d={
                  arcGen({
                    startAngle,
                    endAngle,
                    innerRadius: INNER_BAR_RADIUS,
                    outerRadius: OUTER_BAR_RADIUS,
                  })!
                }
              />
              <text
                fill="rgba(0, 9, 21, 0.5)"
                fontSize="10"
                textAnchor="middle"
                transform={`translate(${labelPositionX} ${labelPositionY})`}
                dy=".4em"
              >
                {shortLabelLookup[categoryKey]}
              </text>
            </g>
          );
        })}
        <g>
          <circle r={INNER_BAR_RADIUS} fill="#FFFFFF" />
          <g>
            <text dy="-.2em" fill="#000915" fontSize="10" fontWeight="600">
              <tspan textAnchor="middle" x="0%">
                {activeCategoryLabel ?? ""}
              </tspan>
              <tspan textAnchor="middle" x="0%" dy="1.2em">
                {activeCategory && `${categoryPercentages[activeCategory]}%`}
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
}

function getMaximumCategoryKey(
  categoryPercentages: AspectCircularBarPlotProps["categoryPercentages"]
) {
  return (Object.entries(categoryPercentages) as [AspectCategoryKey, number][])
    .sort(([, a], [, b]) => b - a)
    .shift()?.[0];
}
