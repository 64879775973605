import { Address } from "../typings/UserAddressesResponse";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { Button } from "react-migration/components/DeprecatedButton";
import { Tooltip } from "react-migration/components/Tooltip";
import { IconButton } from "react-migration/components/IconButton";
import { DeprecatedModal as Modal } from "react-migration/components/DeprecatedModal";
import { Theme } from "react-migration/lib/theme/Theme";
import { useState } from "react";
import { useDeleteUserAddressMutation } from "../hooks/useDeleteUserAddressMutation";
import { PencilIcon } from "../../icons/PencilIcon";
import { DeleteIcon } from "../../icons/DeleteIcon";

type AddressRowProps = {
  editAction: React.MouseEventHandler<HTMLSpanElement>;
  address: Address;
};

export const AddressRow = ({ editAction, address }: AddressRowProps) => {
  const { t } = useTranslation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteAddress] = useDeleteUserAddressMutation();

  const handleDelete = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    await deleteAddress({ variables: { address_id: address._id } });
    setIsDeleteModalOpen(false);
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsDeleteModalOpen(false);
  };

  const handleOpenDeleteModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsDeleteModalOpen(true);
  };

  return (
    <div className="atlas-flex atlas-justify-between">
      <div className="atlas-flex atlas-grow atlas-gap-4 atlas-items-baseline">
        <div className="atlas-font-semibold">{address.receiver_name}</div>
        <div className="atlas-text-sm atlas-text-neutral-600">
          {address.line1}, {address.line2}, {address.city}, {address.postcode}, {address.country}
        </div>
      </div>
      <div className="atlas-flex atlas-gap-1 atlas-mr-1">
        <Tooltip.Wrapper>
          <Tooltip.Trigger
            className="atlas-pointer-events-auto"
            onClick={(event) => event.preventDefault()}
          >
            <IconButton action={editAction} dataTestId={`address-edit-${address._id}`}>
              <span className="atlas-text-neutral-500 hover:atlas-text-blue-600 ">
                <PencilIcon />
              </span>
            </IconButton>
          </Tooltip.Trigger>
          <Tooltip.Content align="center" side="bottom" theme={Theme.Dark} size="sm">
            <span className="atlas-whitespace-normal">
              {t("dashboard.addresses.overview.edit_address")}
            </span>
          </Tooltip.Content>
        </Tooltip.Wrapper>

        <Tooltip.Wrapper>
          <Tooltip.Trigger
            className="atlas-pointer-events-auto"
            onClick={(event) => event.preventDefault()}
          >
            <IconButton action={handleOpenDeleteModal} dataTestId={`address-delete-${address._id}`}>
              <span className="atlas-text-neutral-500 hover:atlas-text-blue-600 ">
                <DeleteIcon />
              </span>
            </IconButton>
          </Tooltip.Trigger>
          <Tooltip.Content align="center" side="bottom" theme={Theme.Dark} size="sm">
            <span className="atlas-whitespace-normal">
              {t("dashboard.addresses.overview.delete_address")}
            </span>
          </Tooltip.Content>
        </Tooltip.Wrapper>
      </div>
      {isDeleteModalOpen && (
        <div className="atlas-absolute">
          <Modal
            description={t("dashboard.addresses.overview.permanently_delete_address")}
            title={t("dashboard.addresses.overview.warning")}
          >
            <div className="atlas-flex atlas-gap-2 atlas-justify-end">
              <Button.Danger onClick={handleDelete}>
                {t("dashboard.addresses.overview.delete")}
              </Button.Danger>
              <Button.Secondary onClick={handleCancel}>
                {t("dashboard.addresses.overview.cancel")}
              </Button.Secondary>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};
