<template>
  <PlanningAlertDetails :alertId="$route.params.alert_id" />
</template>

<script>
import { PlanningAlertDetails } from "react-migration/domains/dashboard/planningAlerts/PlanningAlertDetails";

export default {
  components: {
    PlanningAlertDetails,
  },
};
</script>
