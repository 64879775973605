import { InfoList } from "react-migration/components/InfoList";
import { FC } from "react";

interface PropertyInfoItemProps {
  className?: string;
  title: string;
  testId?: string;
  children: React.ReactNode;
}

export const PropertyInfoItem: FC<PropertyInfoItemProps> = ({
  className,
  title,
  testId,
  children,
}) => {
  return (
    <InfoList.Item data-testid={testId} className={className}>
      <InfoList.ItemTitle>{title}</InfoList.ItemTitle>
      <InfoList.ItemValue>{children}</InfoList.ItemValue>
    </InfoList.Item>
  );
};
