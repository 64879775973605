import { useMemo } from "react";
import { StaticMapWithSelection } from "react-migration/domains/sites/card/Map/renderers/StaticMapReact";
import { LayerTypePrintableProps } from "../../../types";
import { renderAspectLayer } from "../UK/UKTopographyMapLayer";

type AspectMapLayerPrintableProps = Pick<LayerTypePrintableProps, "selection" | "onLoaded">;

export function AspectMapLayerPrintable({ selection, onLoaded }: AspectMapLayerPrintableProps) {
  const aspectLayer = useMemo(() => {
    return [renderAspectLayer({ allowUnderzoom: true })];
  }, []);

  return (
    <StaticMapWithSelection
      id="aspect-printable"
      layers={aspectLayer}
      onReady={onLoaded}
      selectionBufferMeters={0}
      selection={selection}
    />
  );
}
