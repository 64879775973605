import { FC, PropsWithChildren } from "react";
import clsx from "clsx";

interface BaseProps extends PropsWithChildren {
  type: "INFO" | "ERROR";
}

function Base({ children, type }: BaseProps) {
  return (
    <div
      className={clsx(
        "atlas-p-2 atlas-leading-5 atlas-text-center atlas-text-sm atlas-font-medium  atlas-border atlas-border-solid atlas-rounded",
        {
          "atlas-bg-blue-100 atlas-text-blue-600 atlas-border-blue-600": type === "INFO",
          "atlas-bg-statusRed-100 atlas-text-statusRed-600 atlas-border-statusRed-600":
            type === "ERROR",
        }
      )}
    >
      {children}
    </div>
  );
}

function Info(props: PropsWithChildren) {
  return <Base {...props} type="INFO" />;
}

function Error(props: PropsWithChildren) {
  return <Base {...props} type="ERROR" />;
}

interface WarningProps {
  onClickClose?: () => void;
  additionalClassName?: string;
}

export const Warning: FC<PropsWithChildren<WarningProps>> = ({
  children,
  onClickClose,
  additionalClassName,
}) => (
  <div
    className={clsx(
      "atlas-bg-orange-500",
      "atlas-rounded",
      "atlas-border-orange-500",
      "atlas-border",
      "atlas-border-solid",
      additionalClassName
    )}
  >
    <div className="atlas-flex atlas-ml-2 atlas-p-2 atlas-text-sm atlas-font-medium atlas-bg-orange-50 atlas-text-neutral-800 atlas-leading-5 atlas-rounded-r atlas-justify-between">
      <div className="atlas-flex atlas-gap-2 atlas-items-center">
        <i className="icon icon-lt-alert-line atlas-text-orange-500 atlas-text-lg"></i>
        <div>{children}</div>
      </div>

      {onClickClose && (
        <div>
          <button
            onClick={() => onClickClose()}
            className="atlas-bg-transparent atlas-border-none atlas-p-0 atlas-cursor-pointer"
          >
            <i className="icon-lt-close-line atlas-text-lg atlas-pl-2 atlas-text-neutral-800" />
          </button>
        </div>
      )}
    </div>
  </div>
);

export const Alert = {
  Info,
  Error,
  Warning,
};
