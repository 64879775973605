import classNames from "classnames";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { RadioGroup } from "react-migration/components/RadioGroup";
import { SendOption } from "./SendOptions";

export type DownloadOptionProps = {
  onClick: React.MouseEventHandler<HTMLDivElement>;
};

export const DownloadOption = ({ onClick }: DownloadOptionProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        "atlas-flex",
        "atlas-flex-row",
        "atlas-items-center",
        "atlas-gap-3",
        "atlas-p-4",
        "atlas-cursor-pointer"
      )}
      data-testid="send-option-download"
      onClick={onClick}
    >
      <RadioGroup.Item value={SendOption.Download} className="!atlas-h-4 !atlas-w-4">
        <RadioGroup.Indicator />
      </RadioGroup.Item>
      <div className="atlas-h-10 atlas-w-10 atlas-flex atlas-items-center atlas-justify-center atlas-border-blue-50 atlas-bg-background-info atlas-border-4 atlas-rounded-full">
        <i className="icon-lt-download-line atlas-text-base atlas-text-background-action-alt" />
      </div>
      <div className={classNames("atlas-flex", "atlas-flex-col")}>
        <span
          className={classNames(
            "atlas-text-content-primary",
            "atlas-text-sm",
            "atlas-font-semibold",
            "atlas-leading-5"
          )}
        >
          {t("sites.letters.download_and_send_yourself")}
        </span>
        <span
          className={classNames(
            "atlas-text-content-secondary",
            "atlas-text-sm",
            "atlas-font-normal",
            "atlas-leading-5"
          )}
        >
          {t("sites.letters.youll_have_full_control")}
        </span>
      </div>
    </div>
  );
};
