import { useCallback, useState } from "react";
import { Button } from "react-migration/components/DeprecatedButton";
import { Icon } from "react-migration/components/Icon";
import { mapStore } from "src/js/stores/map/store";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { SiteOverview } from "react-migration/domains/sites/typings/Site";
import { useUpdateSiteMutation } from "../SaveSiteButton/useUpdateSiteMutation/useUpdateSiteMutation";
import { useGoogleMapsEditOverlay } from "./useGoogleMapsEditOverlay";
import { pick } from "lodash";

interface EditableSiteBoundaryProps {
  site: Pick<SiteOverview, "_id" | "geometry">;
  startedSaving(): void;
  stoppedSaving(source: string, success: boolean): void;
}

export function EditableSiteBoundary(props: EditableSiteBoundaryProps) {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);

  return isEditing ? (
    <EditableSiteBoundaryEditMode {...props} onFinishedEditing={() => setIsEditing(false)} />
  ) : (
    <Button.Secondary
      title="Edit site boundary"
      size="small"
      className="atlas-h-8 atlas-gap-1.5 atlas-items-center"
      onClick={() => setIsEditing(true)}
    >
      <span>{t("sites.card.site_card.edit_site_boundary")}</span>
      <Icon icon="icon-lt-edit-box-line" size="sm" cursor="pointer" />
    </Button.Secondary>
  );
}

interface EditableSiteBoundaryEditModeProps extends EditableSiteBoundaryProps {
  onFinishedEditing(): void;
}

function EditableSiteBoundaryEditMode({
  site: { _id, geometry },
  onFinishedEditing,
  startedSaving,
  stoppedSaving,
}: EditableSiteBoundaryEditModeProps) {
  const { t } = useTranslation();
  const { editedGeometry } = useGoogleMapsEditOverlay(geometry, mapStore);
  const [updateSite, { loading }] = useUpdateSiteMutation();

  const onSaveHandler = useCallback(async () => {
    startedSaving();

    await updateSite({
      variables: { updateData: { _id, geometry: pick(editedGeometry, ["type", "coordinates"]) } },
      onError: (err) => {
        console.error("EditableSiteBoundary:updateSite Error", err);
        stoppedSaving("Site Boundary", false);
      },
      onCompleted() {
        stoppedSaving("Site Boundary", true);
        onFinishedEditing();
      },
    });
  }, [_id, editedGeometry, onFinishedEditing, startedSaving, stoppedSaving, updateSite]);

  return (
    <div className="atlas-flex atlas-gap-2">
      <Button.Secondary
        data-testid="cancel-edit-site"
        title="Cancel"
        size="small"
        onClick={onFinishedEditing}
        disabled={loading}
      >
        {t("sites.card.site_card.cancel")}
      </Button.Secondary>
      <Button.Primary
        onClick={onSaveHandler}
        size="small"
        className="atlas-relative"
        disabled={loading}
      >
        {t("sites.save_site.save_site_dropdown.save")}
      </Button.Primary>
    </div>
  );
}
