import { useSitesWithSubscriptionsQuery } from "../hooks/useSitesWithSubscriptionsQuery";
import { SavedSitesAlertsSection } from "./SavedSitesAlertsSection/SavedSitesAlertsSection";
import { Loading } from "react-migration/components/Loading";
import { Waypoint } from "react-waypoint";
import cloneDeep from "lodash/cloneDeep";
import { NoData } from "./NoData/NoData";
import { NoDataSitesAlerts } from "./NoData/NoDataSitesAlerts";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { getParamFromQueryParams } from "../util/helpers";

export const SavedSitesAlerts = () => {
  const { t } = useTranslation();
  const email = getParamFromQueryParams("email");
  const { data, loading, fetchMore } = useSitesWithSubscriptionsQuery({
    alertsEmailedAt: email,
    cursor: null,
    hasSites: true,
    limit: 15,
    withPlanningAlertSubscriptionsOnly: true,
  });

  const handleWaypointEnter = () => {
    if (data?.sitesWithSubscriptions?.pageInfo.hasNextPage) {
      fetchMore({
        variables: {
          alertsEmailedAt: email,
          cursor: data.sitesWithSubscriptions.pageInfo.endCursor,
          hasSites: true,
          limit: 15,
          withPlanningAlertSubscriptionsOnly: true,
        },
        updateQuery: (old, { fetchMoreResult }) => {
          const ret = cloneDeep(old);
          const newResults = fetchMoreResult.sitesWithSubscriptions;

          ret.sitesWithSubscriptions.nodes = ret.sitesWithSubscriptions.nodes.concat(
            newResults.nodes
          );
          ret.sitesWithSubscriptions.pageInfo.hasNextPage = newResults.pageInfo.hasNextPage;
          ret.sitesWithSubscriptions.pageInfo.endCursor = newResults.pageInfo.endCursor;
          return ret;
        },
      });
    }
  };

  if (!loading && !data?.sitesWithSubscriptions?.nodes.length) {
    return (
      <NoData title={t("dashboard.planning_alerts.no_alerts")}>
        <NoDataSitesAlerts />
      </NoData>
    );
  }

  return (
    <div className="atlas-pt-4 atlas-pb-5">
      {data?.sitesWithSubscriptions.nodes.map((site) => (
        <SavedSitesAlertsSection site={site} key={site._id} />
      ))}
      <Waypoint onEnter={handleWaypointEnter} />
      {loading && <Loading />}
    </div>
  );
};
