import { FC, useCallback, useEffect, useMemo, useState } from "react";
import * as Popover from "@radix-ui/react-popover";
import classNames from "classnames";

import { Button } from "react-migration/components/DeprecatedButton";
import { Icon } from "react-migration/components/Icon";
import { TextInputV2 } from "../TextInputV2";

import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { ActionBar } from "./ActionBar";
import { SelectOptionItem } from "./SelectOptionItem";

export type SelectOption = {
  id: string;
  name: string;
  color?: string;
};

export type SelectFilterProps = {
  label: string;
  labelClassName?: string;
  contentTitle: string;
  options: SelectOption[];
  selectedOptionIds: string[];
  onSelectedOptionsUpdate: (optionIds: string[]) => void;
  className?: string;
  dataTestId?: string;
};

export const SelectFilter: FC<SelectFilterProps> = ({
  label,
  labelClassName,
  contentTitle,
  options,
  selectedOptionIds,
  onSelectedOptionsUpdate,
  className,
  dataTestId = "select-filter",
}: SelectFilterProps) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [filterValue, setFilterValue] = useState("");

  const [activeOptionIds, setActiveOptionIds] = useState<string[]>(selectedOptionIds);

  useEffect(() => {
    setActiveOptionIds(selectedOptionIds);
  }, [selectedOptionIds]);

  const triggerText = useMemo(() => {
    if (!selectedOptionIds.length) {
      return t("sites.pipeline.filters.all");
    }
    return t("sites.pipeline.filters.number_of_filters_selected", {
      selectedFiltersCount: selectedOptionIds.length,
    });
  }, [selectedOptionIds.length, t]);

  const onCancel = () => {
    setActiveOptionIds(selectedOptionIds);
    setOpen(false);
  };

  const onApply = () => {
    onSelectedOptionsUpdate(
      options.map(({ id }) => id).filter((id) => activeOptionIds.includes(id))
    );
    setOpen(false);
  };

  const onClearFilterClick = () => {
    setActiveOptionIds([]);
  };

  const onSelectAllToggle = useCallback(() => {
    if (activeOptionIds.length !== options.length) {
      setActiveOptionIds(options.map(({ id }) => id));
    } else {
      setActiveOptionIds([]);
    }
  }, [activeOptionIds.length, options]);

  const onOptionToggle = useCallback(
    (optionId: string) => {
      if (activeOptionIds.includes(optionId)) {
        setActiveOptionIds(activeOptionIds.filter((id) => id !== optionId));
      } else {
        setActiveOptionIds([...activeOptionIds, optionId]);
      }
    },
    [activeOptionIds]
  );

  return (
    <div
      data-testid={dataTestId}
      className={classNames(
        "atlas-flex",
        "atlas-flex-row",
        "atlas-flex-nowrap",
        "atlas-items-center",
        className
      )}
    >
      <span
        className={classNames("atlas-text-neutral-500", "atlas-mr-2", labelClassName)}
        data-testid="select-filter-label"
      >
        {label}
      </span>
      <Popover.Root open={open} onOpenChange={setOpen}>
        <Popover.Trigger data-testid="select-filter-dropdown-trigger" asChild>
          <span
            className={classNames(
              "atlas-font-semibold",
              "atlas-px-2",
              "atlas-py-1",
              "atlas-rounded",
              "atlas-text-content-primary",
              "atlas-flex",
              "atlas-items-center",
              "atlas-justify-center",
              "hover:atlas-bg-neutral-200"
            )}
          >
            {triggerText}
            <Icon
              cursor="pointer"
              data-testid="site-options-trigger-icon"
              icon="icon-lt-arrow-down-s-fill"
              size="sm"
              extraClasses="atlas-ml-1"
            />
          </span>
        </Popover.Trigger>

        <Popover.Portal>
          <Popover.Content
            data-testid="select-filter-dropdown-content"
            sideOffset={5}
            side="bottom"
            align="start"
            asChild
          >
            <div className="atlas-flex atlas-flex-col atlas-overflow-hidden atlas-w-[300px] atlas-max-h-[444px] atlas-z-10 atlas-shadow-md atlas-rounded-lg atlas-bg-white atlas-border atlas-border-blueGrey-200">
              <div
                data-testid="select-filter-dropdown-title"
                className="atlas-flex atlas-flex-col atlas-p-3 atlas-m-1"
              >
                <h1 className="atlas-text-sm atlas-font-semibold atlas-text-content-primary atlas-cursor-default atlas-truncate">
                  {contentTitle}
                </h1>
              </div>
              <div data-testid="select-filter-search" className="atlas-pb-2 atlas-pt-0 atlas-px-3">
                <TextInputV2
                  size="medium"
                  dataTestId="select-filter-search-input"
                  placeholder={t("sites.pipeline.filters.search")}
                  IconLeft={
                    <div className={classNames("atlas-w-4", "atlas-h-4", "atlas-text-neutral-300")}>
                      <SearchIcon />
                    </div>
                  }
                  value={filterValue}
                  onChange={(e) => setFilterValue(e.target.value)}
                />
              </div>

              <div className="atlas-justify-start">
                <Button.Borderless
                  data-testid="select-filter-clear-button"
                  disabled={activeOptionIds.length === 0}
                  className={classNames("atlas-text-content-action", "atlas-my-2")}
                  onClick={onClearFilterClick}
                >
                  {t("sites.pipeline.filters.clear_filter")}
                </Button.Borderless>
              </div>

              <SelectOptionItem
                id="all"
                name={t("sites.pipeline.filters.select_all")}
                isSelected={activeOptionIds.length === options.length}
                onToggle={onSelectAllToggle}
              />

              <div
                className={classNames(
                  "atlas-pl-4",
                  "atlas-flex",
                  "atlas-overflow-scroll",
                  "atlas-flex-col"
                )}
                data-testid="select-filter-option-list"
              >
                {options
                  .filter(({ name }) => {
                    const filter = new RegExp(filterValue, "i");
                    return filter.test(name);
                  })
                  .sort(({ name: a }, { name: b }) => {
                    if (a < b) return -1;
                    if (a > b) return 1;
                    return 0;
                  })
                  .map(({ id, name, color }) => (
                    <SelectOptionItem
                      key={id}
                      id={id}
                      name={name}
                      color={color}
                      isSelected={activeOptionIds.includes(id)}
                      onToggle={onOptionToggle}
                    />
                  ))}
              </div>
              <ActionBar onApply={onApply} onCancel={onCancel} />
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};

const SearchIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
      <g clipPath="url(#clip0_3421_2488)">
        <path
          fill="currentColor"
          d="M12.021 11.078l2.856 2.854-.944.944-2.854-2.856a6.002 6.002 0 01-9.745-4.687c0-3.312 2.688-6 6-6a6.002 6.002 0 014.687 9.745zm-1.337-.495a4.665 4.665 0 00-3.35-7.917 4.665 4.665 0 00-4.667 4.667 4.665 4.665 0 007.917 3.35l.1-.1z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_3421_2488">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
