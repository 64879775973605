import clsx from "clsx";
import * as React from "react";
import { DividerV2 } from "../DividerV2";

interface InfoListLinkProps {
  children: React.ReactNode;
  action: () => void;
  title?: string;
}

export const InfoList = ({ children, ...rest }: React.HTMLProps<HTMLDivElement>) => {
  return (
    <div className="atlas-rounded" {...rest}>
      {children}
    </div>
  );
};

export const InfoListTitle = ({ children, ...rest }: React.HTMLProps<HTMLDivElement>) => {
  return (
    <div>
      <div
        {...rest}
        className="atlas-px-3 atlas-text-content-primary atlas-leading-none atlas-font-semibold atlas-py-3 atlas-text-sm md:atlas-text-base atlas-items-center"
      >
        {children}
      </div>
      <DividerV2 orientation={"horizontal"} />
    </div>
  );
};
InfoList.Title = InfoListTitle;

const InfoListItem = ({ children, ...rest }: React.HTMLProps<HTMLDivElement>) => {
  return (
    <div
      {...rest}
      className={clsx(
        "atlas-px-3 atlas-py-2 atlas-text-xs md:atlas-text-sm atlas-gap-5 atlas-bg-white",
        rest && rest.className
      )}
    >
      {children}
    </div>
  );
};

InfoList.Item = InfoListItem;

const InfoListItemTitle = ({ children, ...rest }: React.HTMLProps<HTMLDivElement>) => {
  return (
    <div className="atlas-text-content-primary atlas-font-semibold" {...rest}>
      {children}
    </div>
  );
};

InfoList.ItemTitle = InfoListItemTitle;

const InfoListItemValue = ({ children, ...rest }: React.HTMLProps<HTMLDivElement>) => {
  return (
    <div className="atlas-text-neutral-800 atlas-py-0.5" {...rest}>
      {children}
    </div>
  );
};

InfoList.ItemValue = InfoListItemValue;

const InfoListItemTag = ({ color, children, ...rest }: React.HTMLProps<HTMLDivElement>) => {
  return (
    <div
      className="atlas-rounded atlas-py-1 atlas-px-2 atlas-text-3xs atlas-font-semibold atlas-text-content-inverse-primary atlas-inline-block"
      style={{ backgroundColor: color }}
      {...rest}
    >
      {children}
    </div>
  );
};

InfoList.ItemTag = InfoListItemTag;

const InfoListLink = ({ action, title, children }: InfoListLinkProps) => {
  if (typeof action === "function") {
    return (
      <div
        onClick={action}
        title={title}
        className="atlas-flex atlas-items-center atlas-px-3 atlas-justify-between atlas-cursor-pointer atlas-py-3 atlas-text-sm atlas-select-none atlas-text-content-link hover:atlas-text-content-link-hover atlas-font-semibold"
      >
        {children}
      </div>
    );
  }

  return (
    <a
      href={action}
      title={title}
      className="atlas-flex atlas-items-center atlas-justify-between atlas-text-blue-600 atlas-cursor-pointer atlas-select-none atlas-px-3 atlas-py-2 atlas-text-sm atlas-font-semibold atlas-border-solid atlas-border-0 atlas-border-b atlas-border-transparent"
    >
      {children}
    </a>
  );
};

InfoList.Link = InfoListLink;
