import { ApolloError, useQuery } from "@apollo/client";
import { useMemo, useRef } from "react";
import { LandTechEndpoints, routedClient } from "react-migration/lib/persistence/apollo";
import COMPARABLE_SEARCH from "../apollo/comparableSearch.gql";
import ZOOPLA_COMPARABLE_SEARCH from "../apollo/zooplaComparableSearch.gql";
import { mapDCSConditionsToZooplaPropertySubTypes } from "../util/PropertyTypes";
import { isZoopla } from "../util/isZoopla";
import {
  ComparableConnection,
  IComparableArgsGQL,
  IZooplaComparableArgsGQL,
} from "react-migration/lib/typings/Comparables";
import { ZooplaComparableConnection } from "react-migration/lib/typings/Zoopla";
import {
  PriceModes,
  ZooplaPriceModes,
  ZooplaPricePerAreaModes,
  ZooplaPricePerUnitModes,
} from "../typings/PriceModes";

interface ComparableSearchHookArgs extends IComparableArgsGQL {
  priceMode: PriceModes;
  skip?: boolean;
}

const emptyData = {
  comparableSearch: undefined,
  zooplaComparableSearch: undefined,
};

interface ComparableSearchHookResponse extends IComparableArgsGQL {
  loading: boolean;
  data: {
    comparableSearch?: ComparableConnection;
    zooplaComparableSearch?: ZooplaComparableConnection;
  };
  error: ApolloError | undefined;
}

function toZooplaSearchProps(
  priceMode: ZooplaPriceModes,
  search: IComparableArgsGQL["search"]
): IZooplaComparableArgsGQL["search"] {
  return {
    marketValue:
      priceMode === ZooplaPricePerUnitModes.VAL_ESTIMATE ||
      priceMode === ZooplaPricePerAreaModes.VAL_ESTIMATE
        ? {
            from: 0,
          }
        : undefined,
    rentalValue:
      priceMode === ZooplaPricePerUnitModes.RENT_ASKING ||
      priceMode === ZooplaPricePerAreaModes.RENT_ASKING
        ? {
            from: 0,
          }
        : undefined,
    rentalEstimate:
      priceMode === ZooplaPricePerUnitModes.RENT_ESTIMATE ||
      priceMode === ZooplaPricePerAreaModes.RENT_ESTIMATE
        ? {
            from: 0,
          }
        : undefined,
    rentalValueType:
      priceMode === ZooplaPricePerUnitModes.RENT_ASKING ||
      priceMode === ZooplaPricePerAreaModes.RENT_ASKING
        ? ["AskingPrice"]
        : undefined,
    is_new_property: search.is_new_property,
    sold_date_range: search.sold_date_range,
    bedrooms: search.bedrooms,
    bounding_box_geo_filter: search.bounding_box_geo_filter,
    poly_geo_filter: search.poly_geo_filter,
    radius_geo_filter: search.radius_geo_filter,
    tenure: search.tenure,
    propertySubType: search.dcs_conditions?.length
      ? mapDCSConditionsToZooplaPropertySubTypes(search.dcs_conditions)
      : undefined,
    total_floor_area_range: search.total_floor_area_range,
    yearBuilt: search.year_built,
  };
}
export function useComparableSearch({
  search,
  sort,
  priceMode,
  skip,
}: ComparableSearchHookArgs): ComparableSearchHookResponse {
  const { loading, error, data } = useQuery<{
    comparableSearch: ComparableConnection;
    zooplaComparableSearch: ZooplaComparableConnection;
  }>(isZoopla(priceMode) ? ZOOPLA_COMPARABLE_SEARCH : COMPARABLE_SEARCH, {
    variables: isZoopla(priceMode)
      ? { search: toZooplaSearchProps(priceMode, search) }
      : {
          search,
          sort,
        },
    client: routedClient,
    context: {
      endpoint: LandTechEndpoints.Gateway,
    },
    skip,
  });

  const lastResult = useRef(data);

  if (data !== lastResult.current) {
    lastResult.current = data;
  }

  return useMemo(
    () => ({
      loading,
      data: data ?? emptyData,
      error,
      search,
      sort,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, loading, error]
  );
}
