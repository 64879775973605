import { FC, HTMLProps, useMemo } from "react";
import clsx from "clsx";
import { InfoList } from "react-migration/components/InfoList";
import { getMeasurementSystemPreference } from "src/js/stores/user/actions/getSetting";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";
import { useZooplaListingsByUPRNs } from "react-migration/domains/comparables/hooks/useZooplaListingsByUPRNs";
import { PriceModes } from "react-migration/domains/comparables/typings/PriceModes";
import { ZooplaListings } from "../Record/ZooplaListings";
import { Transaction } from "../Transaction";

export interface AddressItemProps extends HTMLProps<HTMLDivElement> {
  address: string;
  dateOfRecord: string;
  currency: string;
  price?: number;
  floorArea?: number;
  uprn?: string;
  distribution?: number[];
  priceMode: PriceModes;
  setSelectedRecord: () => void;
  uprns?: string[];
}

export const AddressItem: FC<AddressItemProps> = ({
  address,
  currency,
  dateOfRecord,
  floorArea,
  price,
  uprn,
  distribution,
  priceMode,
  setSelectedRecord,
  uprns,
}) => {
  const unitPreference = getMeasurementSystemPreference();

  const { loading: zooplaListingsByUPRNsDataLoading, data: zooplaListingsDataByUPRNs } =
    useZooplaListingsByUPRNs({
      uprns,
      skip:
        (!hasFeature(Feature.zooplaLightAccess) && !hasFeature(Feature.zooplaFullAccess)) || !uprns,
    });

  const selectedZooplaListing = useMemo(() => {
    return zooplaListingsDataByUPRNs?.find((zooplaListing) => zooplaListing?.uprn === uprn);
  }, [uprn, zooplaListingsDataByUPRNs]);

  return (
    <InfoList.Item
      className={clsx("atlas-cursor-pointer !atlas-px-0 !atlas-py-0")}
      data-testid="address-item"
    >
      <div className={clsx("hover:atlas-bg-background-dark")}>
        <button
          className="atlas-flex atlas-flex-row atlas-w-full atlas-justify-between atlas-items-center"
          onClick={setSelectedRecord}
          onKeyDown={setSelectedRecord}
        >
          <div className="atlas-flex atlas-flex-col atlas-flex-grow atlas-text-start atlas-pl-2 atlas-pt-2 atlas-overflow-hidden">
            <InfoList.ItemTitle>
              <span title={address} className="atlas-block atlas-truncate">
                {address}
              </span>
            </InfoList.ItemTitle>

            <div className="atlas-flex atlas-justify-between atlas-items-center">
              <div className="atlas-my-2">
                <ZooplaListings
                  zooplaListingsData={selectedZooplaListing}
                  zooplaListingsDataLoading={zooplaListingsByUPRNsDataLoading}
                />
              </div>
              <Transaction
                area={floorArea || 0}
                currency={currency}
                date={dateOfRecord}
                distribution={distribution}
                priceMode={priceMode}
                price={price || 0}
                unitPreference={unitPreference}
              />
            </div>
          </div>
          <i className="icon-lt-arrow-right-s-line atlas-fill-content-link atlas-text-xl" />
        </button>
      </div>
    </InfoList.Item>
  );
};
