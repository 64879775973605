import { IComparablesTransactionDTO } from "react-migration/lib/typings/Comparables";
import { SkeletonLoading } from "react-migration/components/SkeletonLoading";
import { userStore } from "src/js/stores/user/store";
import { useComparableByTransactionId } from "react-migration/domains/comparables/hooks/useComparableByTransactionId";
import { useZooplaComparableByUPRN } from "react-migration/domains/comparables/hooks/useZooplaComparableByUPRN";
import { formatZooplaData } from "react-migration/domains/comparables/components/Results/Record/helpers/formatZooplaData";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { Record } from "react-migration/domains/comparables/components/Results/Record/Record";
import { LayerTypeSelectionDetailViewProps } from "../../types";
import { OtherComparablesSection } from "./OtherComparablesSection";
import { useSnapshot } from "valtio";

function ComparablesSelectionDetailViewInner({
  record,
}: Readonly<{ record: IComparablesTransactionDTO }>) {
  const { t } = useTranslation();
  const { user, locale } = useSnapshot(userStore);
  const { data, loading } = useZooplaComparableByUPRN({ uprn: record.uprn });
  const unitPreference = user.settings.unit;

  return (
    <Record
      locale={locale}
      record={record}
      zooplaData={formatZooplaData({
        data,
        locale,
        unitPreference,
        t,
      })}
      zooplaDataLoading={loading}
      unitPreference={unitPreference}
    />
  );
}

export function LandTechComparableSelectionDetailView({
  selection,
  setDetailSelection,
}: Readonly<LayerTypeSelectionDetailViewProps>) {
  const { loading, data } = useComparableByTransactionId({ transactionId: selection.id! });

  if (loading) {
    return (
      <div className="atlas-relative">
        <SkeletonLoading rows={8} />
      </div>
    );
  }

  if (!data) {
    return <div>Could not find record</div>;
  }

  return (
    <div className="atlas-flex atlas-flex-col atlas-gap-y-2 atlas-bg-white">
      <ComparablesSelectionDetailViewInner record={data} />

      <OtherComparablesSection selection={selection} onSelection={setDetailSelection} />
    </div>
  );
}
