import { useState } from "react";
import { Button } from "react-migration/components/DeprecatedButton";
import { scroller } from "react-scroll";
import classNames from "classnames";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import EditorContainer from "./EditorContainer";
import { LetterPreview } from "./LetterPreview";
import { SwitchButtons } from "./SwitchButtons";
import { useSnapshot } from "valtio";
import { TemporaryModal as DeleteModal } from "react-migration/components/Modal/TemporaryModal";
import { updatePageCount } from "src/js/stores/letter/actions";
import { LetterEventName, logLetterEvent } from "../helpers/logLetterEvent";
import { letterStore } from "src/js/stores/letter/store";
import { TemplateForm } from "../../forms/TemplateForm/TemplateForm";
const SCROLL_DELAY_MS = 50;
const SCROLL_DURATION_MS = 500;

type ContentEditorProps = {
  onBlockNext: (isBlocked: boolean) => void;
};

export const TemplateEditor: React.FC<ContentEditorProps> = ({ onBlockNext }) => {
  const { t } = useTranslation();
  const { letter, pageCount, isUploadingLogo, isUploadingSignature } = useSnapshot(letterStore);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [pageSwitchCurrentIndex, setPageSwitchCurrentIndex] = useState(0);

  const onAddNewPage = () => {
    const newPageCount = pageCount + 1;
    updatePageCount(newPageCount);
    onCurrentIndexUpdate(newPageCount - 1);
    logLetterEvent({
      name: LetterEventName.LETTERS_FORM_CREATE_LETTER_STEP_ADD_NEW_PAGE_CLICKED,
    });
  };
  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const onDeletePage = () => {
    if (letter?.bodyTextPage2) {
      setDeleteModalOpen(true);
    } else {
      onDeleteConfirmed();
    }
  };
  const onDeleteConfirmed = () => {
    onCurrentIndexUpdate(0);
    if (isDeleteModalOpen) {
      closeDeleteModal();
    }
    setTimeout(() => {
      updatePageCount(pageCount - 1);
    }, SCROLL_DELAY_MS + SCROLL_DURATION_MS);
    logLetterEvent({ name: LetterEventName.LETTERS_FORM_CREATE_LETTER_STEP_PAGE_DELETED });
  };

  const onCurrentIndexUpdate = function (pageIndex: number) {
    if (pageIndex >= 0) {
      scroller.scrollTo("page" + (pageIndex + 1), {
        containerId: "letter-preview-container",
        duration: SCROLL_DURATION_MS,
        delay: SCROLL_DELAY_MS,
        offset: -20,
        smooth: "easeInOutQuart",
      });
    }
  };

  const onScrollUpdate = (pageIdx: number) => {
    setPageSwitchCurrentIndex(pageIdx);
  };

  return (
    <div
      data-testid="template-editor-container"
      className={classNames("atlas-flex", "atlas-grow", "atlas-w-full", "atlas-overflow-hidden")}
    >
      <DeleteModal
        isOpen={isDeleteModalOpen}
        title={t("sites.letters.delete_modal.are_you_sure_you_want_to_remove_this_page")}
        width="atlas-w-1/5 atlas-min-w-[450px]"
        headerClass="atlas-h-12 atlas-p-3"
        titleClass="atlas-font-semibold atlas-text-base atlas-leading-6"
        handleClose={closeDeleteModal}
      >
        <div className={classNames("atlas-flex", "atlas-flex-col", "atlas-gap-y-2")}>
          <div
            className={classNames(
              "atlas-font-normal",
              "atlas-text-sm",
              "atlas-leading-5",
              "atlas-border-b",
              "atlas-border-neutral-300",
              "atlas-p-3"
            )}
          >
            {t(
              "sites.letters.delete_modal.removing_this_page_will_also_remove_all_the_content_on_this_page"
            )}
          </div>
          <div
            className={classNames(
              "atlas-flex",
              "atlas-justify-end",
              "atlas-gap-2",
              "atlas-pb-2",
              "atlas-pr-2"
            )}
          >
            <Button.Secondary onClick={closeDeleteModal}>
              {t("sites.letters.delete_modal.cancel")}
            </Button.Secondary>
            <Button.Danger onClick={onDeleteConfirmed}>
              {t("sites.letters.delete_modal.remove_page")}
            </Button.Danger>
          </div>
        </div>
      </DeleteModal>

      <EditorContainer>
        <EditorContainer.Form
          title={t("sites.letters.content_editor")}
          headerItem={
            <Button.Secondary size="small" onClick={onAddNewPage} disabled={pageCount >= 2}>
              <i className="icon-lt-add-line atlas-mr-2 atlas-text-base" />
              <span className="atlas-flex atlas-items-center">{t("sites.letters.add_page")}</span>
            </Button.Secondary>
          }
        >
          <TemplateForm
            isUploadingLogo={isUploadingLogo}
            isUploadingSignature={isUploadingSignature}
            hasSecondPage={pageCount >= 2}
            onDeletePage={onDeletePage}
          />
        </EditorContainer.Form>
        <EditorContainer.Preview
          title={t("sites.letters.letter_design")}
          headerItem={
            <SwitchButtons
              size="small"
              labelPrefix={t("sites.letters.page")}
              currentIndex={pageSwitchCurrentIndex}
              totalCount={pageCount}
              onPreviousBtnClick={onCurrentIndexUpdate.bind(null, 0)}
              onNextBtnClick={onCurrentIndexUpdate.bind(null, 1)}
            />
          }
        >
          <LetterPreview onScrollUpdate={onScrollUpdate} onBlockNext={onBlockNext} />
        </EditorContainer.Preview>
      </EditorContainer>
    </div>
  );
};
