import { SiteFiltersStore, buildSiteFiltersStoreMethods } from "./SiteFiltersStore/store";
import { createContext, useCallback, useContext, useMemo } from "react";
import logSiteCardEvent, { SiteFiltersView } from "src/js/view/sites/logSiteCardEvent";

import { SiteFilters as TSiteFilters } from "../../typings/SiteFilters";
import classNames from "classnames";
import { noop } from "lodash";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { SiteSearch } from "./components/SiteSearch";
import { ClearFiltersButton } from "./components/ClearFiltersButton";
import { useSiteLocalAuthorityOptions } from "../../hooks/useSiteLocalAuthorityOptions";
import { SelectFilter } from "react-migration/components/SelectFilter";
import { useSiteLabelOptions } from "../../hooks/useSiteLabelOptions";
import { useSiteAssigneeOptions } from "../../hooks/useSiteAssigneeOptions";
import { useSiteStagesOptions } from "../../hooks/useSiteStages";

export type OnFilterUpdateCallback = (filter: keyof TSiteFilters) => void;

export type OnFiltersClearedCallback = () => void;

export type SiteFiltersEventMetadata = {
  view: SiteFiltersView;
};

export type SiteFiltersProps = {
  /**
   * Which type of Filters layout should be displayed
   */
  layout?: SiteFiltersLayout;
  /** Essential metadata for Site Filters mixpanel event logging */
  eventMetadata: SiteFiltersEventMetadata;
  /** Which store to use */
  store: SiteFiltersStore;
};

export enum SiteFiltersLayout {
  Flex = "flex",
  Grid = "grid",
}

/** Context for all *INTERNAL* rendering concerns  to the Site Filters
 * For example, setting up common functions that contain context from consumers for event logging
 *
 * If state must be shared with consumers of SiteFilters then please use the Valtio SiteFiltersStore
 * For example, the values of the filters
 */
export const SiteFiltersContext = createContext<
  {
    onFilterUpdate: OnFilterUpdateCallback;
    onFiltersCleared: OnFiltersClearedCallback;
  } & ReturnType<typeof buildSiteFiltersStoreMethods>
>({
  onFilterUpdate: noop,
  onFiltersCleared: noop,
  useAssignees: () => [],
  updateAssignees: noop,
  useLocalAuthorities: () => [],
  updateLocalAuthorities: noop,
  useSearchString: () => "",
  updateSearchString: noop,
  useSiteLabels: () => [],
  updateSiteLabels: noop,
  useStageIds: () => [],
  updateStageIds: noop,
  resetSiteFiltersStore: noop,
});

export const SiteFilters = ({
  layout = SiteFiltersLayout.Flex,
  eventMetadata: { view },
  store,
}: SiteFiltersProps) => {
  const onFilterUpdate: OnFilterUpdateCallback = useCallback(
    (filter) => {
      logSiteCardEvent({
        name: "Site Filters - Filter Updated",
        context: {
          view,
          filter,
        },
      });
    },
    [view]
  );

  const onFiltersCleared: OnFiltersClearedCallback = useCallback(() => {
    logSiteCardEvent({
      name: "Site Filters - Filters Cleared",
      context: {
        view,
      },
    });
  }, [view]);

  const contextValues = useMemo(() => {
    const storeMethods = buildSiteFiltersStoreMethods(store);
    return { onFilterUpdate, onFiltersCleared, ...storeMethods };
  }, [onFilterUpdate, onFiltersCleared, store]);

  switch (layout) {
    case SiteFiltersLayout.Flex:
      return (
        <SiteFiltersContext.Provider value={contextValues}>
          <div
            data-testid="site-filters"
            className={classNames(
              "atlas-flex",
              "atlas-flex-row",
              "atlas-items-center",
              "atlas-gap-4"
            )}
          >
            <SiteSearch />
            <SiteLocalAuthorityFilter />
            <SiteLabelsFilter />
            <AssigneesFilter />
            <StagesFilter />
            <ClearFiltersButton />
          </div>
        </SiteFiltersContext.Provider>
      );
    case SiteFiltersLayout.Grid:
      return (
        <SiteFiltersContext.Provider value={contextValues}>
          <div
            data-testid="site-filters"
            className={classNames(
              "atlas-grid",
              "atlas-grid-cols-10",
              "atlas-grid-rows-3",
              "atlas-gap-1"
            )}
          >
            <SiteSearch className={classNames("atlas-order-1", "atlas-col-span-6")} />
            <SiteLocalAuthorityFilter className={classNames("atlas-order-3", "atlas-col-span-5")} />
            <SiteLabelsFilter className={classNames("atlas-order-4", "atlas-col-span-5")} />
            <AssigneesFilter className={classNames("atlas-order-5", "atlas-col-span-5")} />
            <StagesFilter className={classNames("atlas-order-6", "atlas-col-span-5")} />
            <ClearFiltersButton className={classNames("atlas-order-2", "atlas-col-span-4")} />
          </div>
        </SiteFiltersContext.Provider>
      );

    default:
      return null;
  }
};

type SiteLocalAuthorityFilterProps = {
  className?: string;
};
const SiteLocalAuthorityFilter = ({ className }: SiteLocalAuthorityFilterProps) => {
  const { t } = useTranslation();

  const { onFilterUpdate, useLocalAuthorities, updateLocalAuthorities } =
    useContext(SiteFiltersContext);

  const siteLocalAuthorityOptions = useSiteLocalAuthorityOptions();
  const selectedLocalAuthorityIds = useLocalAuthorities();

  const onSelectedOptionsUpdate = useCallback(
    (optionIds: string[]) => {
      onFilterUpdate("localAuthorities");
      updateLocalAuthorities(optionIds);
    },
    [onFilterUpdate, updateLocalAuthorities]
  );

  if (siteLocalAuthorityOptions.length > 0) {
    return (
      <SelectFilter
        dataTestId="local-authority-select-filter"
        label={t("sites.pipeline.filters.local_authority")}
        contentTitle={t("sites.pipeline.filters.select_local_authorities")}
        options={siteLocalAuthorityOptions}
        selectedOptionIds={selectedLocalAuthorityIds}
        onSelectedOptionsUpdate={onSelectedOptionsUpdate}
        className={className}
      />
    );
  } else {
    return null;
  }
};

type SiteLabelsFilterProps = {
  className?: string;
};
const SiteLabelsFilter = ({ className }: SiteLabelsFilterProps) => {
  const { t } = useTranslation();
  const { onFilterUpdate, useSiteLabels, updateSiteLabels } = useContext(SiteFiltersContext);

  const siteLabelOptions = useSiteLabelOptions();
  const selectedLabelIds = useSiteLabels();

  const onSelectedOptionsUpdate = useCallback(
    (optionIds: string[]) => {
      onFilterUpdate("siteLabels");
      updateSiteLabels(optionIds);
    },
    [onFilterUpdate, updateSiteLabels]
  );

  if (siteLabelOptions.length > 0) {
    return (
      <SelectFilter
        dataTestId="labels-select-filter"
        label={t("sites.pipeline.filters.label")}
        contentTitle={t("sites.pipeline.filters.select_labels")}
        options={siteLabelOptions}
        selectedOptionIds={selectedLabelIds}
        onSelectedOptionsUpdate={onSelectedOptionsUpdate}
        className={className}
      />
    );
  } else {
    return null;
  }
};

type AssigneesFilterProps = {
  className?: string;
};
const AssigneesFilter = ({ className }: AssigneesFilterProps) => {
  const { t } = useTranslation();
  const { onFilterUpdate, useAssignees, updateAssignees } = useContext(SiteFiltersContext);

  const siteAssigneeOptions = useSiteAssigneeOptions();
  const selectedAssigneeIds = useAssignees();

  const onSelectedOptionsUpdate = useCallback(
    (optionIds: string[]) => {
      onFilterUpdate("assignees");
      updateAssignees(optionIds);
    },
    [onFilterUpdate, updateAssignees]
  );

  if (siteAssigneeOptions.length > 0) {
    return (
      <SelectFilter
        dataTestId="assignee-select-filter"
        label={t("sites.pipeline.filters.assignee")}
        contentTitle={t("sites.pipeline.filters.select_assignees")}
        options={siteAssigneeOptions}
        selectedOptionIds={selectedAssigneeIds}
        onSelectedOptionsUpdate={onSelectedOptionsUpdate}
        className={className}
      />
    );
  } else {
    return null;
  }
};

type StagesFilterProps = {
  className?: string;
};
const StagesFilter = ({ className }: StagesFilterProps) => {
  const { t } = useTranslation();
  const { onFilterUpdate, useStageIds, updateStageIds } = useContext(SiteFiltersContext);

  const siteStagesOptions = useSiteStagesOptions();
  const selectedStageIds = useStageIds();

  const onSelectedOptionsUpdate = useCallback(
    (optionIds: string[]) => {
      onFilterUpdate("stageIds");
      updateStageIds(optionIds);
    },
    [onFilterUpdate, updateStageIds]
  );

  if (siteStagesOptions.length > 0) {
    return (
      <SelectFilter
        dataTestId="stages-select-filter"
        label={t("sites.pipeline.filters.stage")}
        contentTitle={t("sites.pipeline.filters.select_stages")}
        options={siteStagesOptions}
        selectedOptionIds={selectedStageIds}
        onSelectedOptionsUpdate={onSelectedOptionsUpdate}
        className={className}
      />
    );
  } else {
    return null;
  }
};
