import { useCallback, useState } from "react";
import * as React from "react";
import { SkeletonLoadingContainer } from "./SkeletonLoadingContainer";
import { SkeletonLoading } from "./SkeletonLoading";

export interface WithSkeletonLoadingProps {
  hideSkeletonLoading: () => void;
  setSkeletonLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

type Options = {
  rows?: number;
  bgClassName?: string;
  showPadding?: boolean;
};

export const withSkeletonLoading =
  <P extends object>(
    Component: React.FC<P & WithSkeletonLoadingProps>,
    options?: Options
  ): React.FC<P> =>
  // eslint-disable-next-line react/display-name
  ({ ...props }) => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const hideSkeletonLoading = useCallback(() => setIsLoaded(true), []);

    return (
      <SkeletonLoadingContainer>
        {!isLoaded && <SkeletonLoading {...options} />}
        <Component
          hideSkeletonLoading={hideSkeletonLoading}
          setSkeletonLoading={setIsLoaded}
          {...(props as P)}
        />
      </SkeletonLoadingContainer>
    );
  };
