import { LayerTypeMapLayerProps } from "../../../types";
import { useSelectedTopographyLayer } from "../useSelectedTopographyLayer";
import { TopographyLayer } from "../constants";
import {
  USGS3DEPElevationWMSMapLayer,
  USGS3DEPLayer,
} from "react-migration/domains/map/usgs/USGS3DEPElevationWMSMapLayer";

const US_HILLSHADE_OPACITY = 0.5;
const US_CONTOUR_OPACITY = 0.6;

export const USTopographyMapLayer = ({ visible, zOrder }: LayerTypeMapLayerProps) => {
  const [selectedLayer] = useSelectedTopographyLayer();

  if (!visible) {
    return null;
  }

  if (selectedLayer === TopographyLayer.hillshade) {
    return (
      <USGS3DEPElevationWMSMapLayer
        layer={USGS3DEPLayer.HillshadeGray}
        zOrder={zOrder}
        opacity={US_HILLSHADE_OPACITY}
      />
    );
  }

  if (selectedLayer === TopographyLayer.contour) {
    return (
      <USGS3DEPElevationWMSMapLayer
        zOrder={zOrder}
        opacity={US_CONTOUR_OPACITY}
        layer={USGS3DEPLayer.Contour25}
      />
    );
  }

  if (selectedLayer === TopographyLayer.hillshadeContour) {
    return (
      <>
        <USGS3DEPElevationWMSMapLayer
          layer={USGS3DEPLayer.HillshadeGray}
          zOrder={zOrder}
          opacity={US_HILLSHADE_OPACITY}
        />
        <USGS3DEPElevationWMSMapLayer
          zOrder={zOrder}
          opacity={US_CONTOUR_OPACITY}
          layer={USGS3DEPLayer.Contour25}
        />
      </>
    );
  }

  return null;
};
