import { useSelectedTopographyLayer } from "./useSelectedTopographyLayer";
import { useControlTracking } from "../../ControlTrackingContext";
import { useCallback } from "react";
import { TopographyLayer } from "./constants";

export function useTrackedTopographyLayerFilter() {
  const [selectedLayer, setSelectedLayer] = useSelectedTopographyLayer();

  const { trackControlChange } = useControlTracking();

  const handleLayerChange = useCallback(
    (value: TopographyLayer) => {
      setSelectedLayer(value);

      const map: Record<TopographyLayer, string> = {
        [TopographyLayer.contour]: "Contour",
        [TopographyLayer.hillshade]: "Hillshade",
        [TopographyLayer.hillshadeContour]: "Hillshade & Contour",
        [TopographyLayer.slope]: "Slope",
        [TopographyLayer.aspect]: "Aspect",
        [TopographyLayer.aspectSlope]: "Aspect-slope",
      };

      trackControlChange({ filterName: "Topography attribute", filterState: map[value] });
    },
    [trackControlChange, setSelectedLayer]
  );

  return { selectedLayer, handleLayerChange };
}
