import * as RadixTabs from "@radix-ui/react-tabs";
import { ComponentProps, ElementType } from "react";
import clsx from "classnames";

export type TabsRootType = ComponentProps<ElementType> & {
  defaultValue?: string;
  onValueChange: (value: string) => void;
  value: string;
};

export const Tabs = ({
  children,
  defaultValue,
  value,
  onValueChange,
  className,
  ...rest
}: TabsRootType) => {
  return (
    <RadixTabs.Root
      className={clsx("atlas-flex atlas-flex-col atlas-group atlas-bg-background-white", className)}
      defaultValue={defaultValue}
      onValueChange={onValueChange}
      value={value}
      {...rest}
    >
      {children}
    </RadixTabs.Root>
  );
};

export type TabsListType = RadixTabs.TabsListProps;
export const TabsList = ({ children, className, ...rest }: TabsListType) => {
  return (
    <RadixTabs.List
      className={clsx(
        "atlas-shrink-0 atlas-flex atlas-border-b atlas-border-neutral-300",
        className
      )}
      {...rest}
    >
      {children}
    </RadixTabs.List>
  );
};

export type TabsTriggerType = ComponentProps<ElementType> & {
  value: string;
  subtitle?: string;
  disabled?: boolean;
};

export const TabsTrigger = ({
  children,
  value,
  className,
  disabled,
  subtitle,
  ...rest
}: TabsTriggerType) => {
  return (
    <RadixTabs.Trigger
      className={clsx(
        "atlas-py-4 atlas-px-2 atlas-flex atlas-flex-col atlas-gap-1 atlas-flex-1 atlas-justify-center atlas-items-center atlas-leading-none atlas-select-none",
        "data-[state=active]:atlas-font-bold data-[state=active]:atlas-text-blue-600 data-[state=active]:atlas-shadow-[inset_0_-2px_0_0,0_1px_0_0]",
        "atlas-bg-background-white hover:atlas-bg-background-light",

        className
      )}
      value={value}
      disabled={disabled}
      {...rest}
    >
      {children}

      {subtitle && (
        <span className="atlas-text-xs atlas-text-content-secondary atlas-font-normal data-[state=active]:atlas-font-normal">
          {subtitle}
        </span>
      )}
    </RadixTabs.Trigger>
  );
};

export type TabsContentType = ComponentProps<ElementType> & { value: string };
export const TabsContent = ({ children, value, className, ...rest }: TabsContentType) => {
  return (
    <RadixTabs.Content
      className={clsx(
        "data-[state=inactive]:hidden data-[state=active]:atlas-flex data-[state=active]:atlas-flex-1",
        className
      )}
      value={value}
      {...rest}
    >
      {children}
    </RadixTabs.Content>
  );
};

Tabs.List = TabsList;
Tabs.Trigger = TabsTrigger;
Tabs.Content = TabsContent;
