import { Button } from "react-migration/components/DeprecatedButton";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import {
  LetterEventName,
  logLetterEvent,
  mapToLetterFormStepStringEnum,
} from "../helpers/logLetterEvent";
import { useSnapshot } from "valtio";
import { clearStore } from "src/js/stores/letter/actions";
import { router } from "src/js/router/react-router";
import ROUTES from "src/js/router/routes";
import { LetterStepper } from "./LetterStepper";
import { letterStore } from "src/js/stores/letter/store";
import { siteStore } from "src/js/stores/site/store";

export const Header = () => {
  const { t } = useTranslation();
  const { currentStep } = useSnapshot(letterStore);
  const { siteCardId } = useSnapshot(siteStore);

  const onCloseClick = () => {
    clearStore();
    router.push({
      name: ROUTES.SITES,
      query: {
        card_page: "letters",
        site_id: siteCardId,
      },
    });
  };

  const onLeftButtonClick = () => {
    onCloseClick();
    logLetterEvent({
      name: LetterEventName.LETTERS_FORM_BACK_TO_SITES_CLICKED,
      context: { step: mapToLetterFormStepStringEnum(currentStep) },
    });
  };

  const onRightButtonClick = () => {
    onCloseClick();
    logLetterEvent({
      name: LetterEventName.LETTERS_FORM_SAVE_AND_CLOSE_CLICKED,
      context: { step: mapToLetterFormStepStringEnum(currentStep) },
    });
  };

  return (
    <div
      data-testid="letters-header"
      className="atlas-w-full atlas-border atlas-py-2 atlas-bg-background-white atlas-px-6 atlas-border-b atlas-border-border-divider atlas-flex atlas-flex-row atlas-justify-between atlas-items-center"
    >
      <Button.Base
        size="large"
        className="-atlas-ml-2 atlas-border-0 atlas-text-content-tertiary atlas-flex-none"
        onClick={onLeftButtonClick}
        data-testid="letters-header-left-button"
      >
        <i
          className={
            "icon-lt-arrow-left-s-line atlas-w-2 atlas-h-3.5 atlas-mr-3 atlas-fill-content-tertiary"
          }
        />
        {t("sites.letters.back_to_sites_button")}
      </Button.Base>

      <LetterStepper currentStep={currentStep} />

      <Button.Secondary data-testid="letters-header-right-button" onClick={onRightButtonClick}>
        {t("sites.letters.save_and_close_button")}
      </Button.Secondary>
    </div>
  );
};
