export const PowerLinesDescription = () => (
  <div className="atlas-flex atlas-flex-col atlas-gap-y-4">
    <div>
      <div className="atlas-font-semibold">Network line data</div>
      <div>The network data is divided into overhead lines and underground cables.</div>
    </div>

    <div>
      <div className="atlas-font-semibold">Tower data</div>
      <div>
        Towers, pylons, and poles for carrying overhead power lines are represented by points
        indicating the location of the feature and are not presented with a voltage class.
      </div>
    </div>

    <div>
      <div className="atlas-font-semibold">Note on data usage</div>
      <div>
        This data is drawn from records made publicly available by Distribution Network Operators
        (DNOs), National Grid and other data sources, and no guarantees are given regarding the
        completeness of the data. This data is indicative and <b>should not</b> be relied on for
        planning intrusive works at a site.
      </div>
    </div>
  </div>
);
